import { useState, useCallback, useEffect } from "react";
import { Button, DialogActions, DialogContent } from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import Table from "./FormController";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface ManageSitesDialogProps extends Omit<DialogProps, "onClose" | "role"> {
  paymentId?: string;
  onClose?: (result?: boolean) => void;
}

interface FromData extends PaymentServicesApi.UpdateSites {}

const defaultValues = {
  sites: [],
};

/**
 * @memberof PaymentService
 * @component
 * @desc Dialog for site management.
 * @property {string} paymentId - Payment Service Id
 * @property {Function} onClose - passes true if the list was updated successfully. (result?: boolean) => void;
 */

const ManageSitesDialog = ({
  paymentId,
  open,
  onClose,
}: ManageSitesDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({});
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!paymentId) return;
      setLoading(true);
      try {
        await api.paymentServices.updateSites(paymentId, formData);
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, paymentId]
  );

  useEffect(() => {
    setInitialValues(defaultValues);
  }, [open]);

  if (!open || !paymentId) return null;

  return (
    <Dialog
      open={open}
      title={t("title.manageSites")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                <Table
                  paymentId={paymentId}
                  onChange={(rows) => setFieldValue("sites", rows)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.save")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default ManageSitesDialog;
