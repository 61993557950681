import { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Icon, IconButton } from ".";
import clsx from "clsx";

export interface Props {
  text: string | undefined;
  showByHover?: boolean
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      right: 1,
      top: 16,
    },
    showByHover: {
      visibility: "hidden",
      opacity: 0,
    }
  })
);

const CopyToClipboardButton = ({ text, showByHover }: Props) => {

  const [copied, setCopied] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setCopied(true);
    text && navigator.clipboard.writeText(text)
    setTimeout(() => setCopied(false), 2000)
  };

  return (
    <div className={clsx(classes.root, { [classes.showByHover]: showByHover })}>
      {!copied &&<IconButton size="small" onClick={handleClick}>
        <Icon name="Copy" />
      </IconButton>}
      {copied && <Icon name="Success" />}
    </div>
  );
};

export default CopyToClipboardButton;
