import { useState, useEffect, useCallback } from "react";
import Select, { SelectProps, OptionProps } from "~/components/UI/Select";
import { getServiceCategories } from "~/store/services/actions";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

const SelectServiceCategory = ({
  onChange,
  onSelected,
  ...props
}: SelectProps & {
  onSelected?: (s: ServicesCategoriesApi.ServicesCategoryDto) => void;
}) => {
  const [options, setOptions] = useState<OptionProps[]>([]);
  const categories = useSelector(
    (state: ReduxStore.State) => state.services.serviceCategories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!categories) {
    dispatch(getServiceCategories());
    // }
  }, []);

  useEffect(() => {
    if (categories && categories.length) {
      const options = categories.map(
        (c) =>
          ({
            label: c.name,
            value: c.categoryId,
          } as OptionProps)
      );
      const sortedOptions = _.sortBy(options, (o) =>
        o.label ? o.label.toLowerCase() : undefined
      );
      setOptions(sortedOptions);
    }
  }, [categories]);

  const onchange = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      child: React.ReactNode
    ) => {
      onChange && onChange(e, child);

      if (categories) {
        const selected = categories.find(
          (c) => c.categoryId === e.target.value
        );
        selected &&
          onSelected &&
          onSelected(selected as ServicesCategoriesApi.ServicesCategoryDto);
      }
    },
    [props.value, categories, onChange]
  );

  return (
    <Select
      {...{ ...props, onChange: onchange }}
      options={options}
      variant="outlined"
    />
  );
};

export default SelectServiceCategory;
