import { useState, useEffect } from "react";
import { Section, Box, TableTree } from "~/components/UI";
import { Entities } from "~/constants/Permissions";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "i18nKey",
    i18nKey: "name",
  },
  {
    key: "permissions",
    i18nKey: "permissions",
    cellOptions: {
      align: "right",
      width: 200,
    },
  },
];

interface EntitiesSectionProps {
  permissions?: PermissionsApi.eBasePermissions[];
}

function getDataSource(array: any[], permissions?: string[]) {
  let o;
  if (!permissions) return [];

  o = _.cloneDeep(array).filter(function iter(i: any) {
    if (i.items && Array.isArray(i.items)) {
      const isIncludes = i.items.some(iter);
      if (!isIncludes) {
        delete i.items;
      } else {
        i.items = i.items.filter(iter);
      }
      return isIncludes || _.values(i).some((p) => permissions.includes(p));
    }

    return _.values(i).some((p) => permissions.includes(p));
  });
  return o;
}

const EntitiesSection = ({ permissions }: EntitiesSectionProps) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);

    switch (column.key) {
      case "i18nKey":
        return {
          ...column,
          render: (v: string) => (
            <span style={{ textTransform: "capitalize" }}>{t(v)}</span>
          ),
        };
      case "permissions":
        return {
          ...column,
          render: (_value: string, row: any) => {
            return permissions
              ? _.keys(row)
                  .reduce((res, key) => {
                    if (permissions.includes(row[key])) {
                      res.push(key);
                    }
                    return res;
                  }, [] as string[])
                  .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
                  .join(", ")
              : "";
          },
        };
      default:
        return column;
    }
  });

  useEffect(() => {
    setDataSource(getDataSource(Entities, permissions));
  }, [permissions]);

  return (
    <Section title={t("label.entities")} collapsed openByDefault={true}>
      <Box my={4} border={1} borderColor="divider">
        <TableTree columns={columns} dataSource={dataSource} />
      </Box>
    </Section>
  );
};

export default EntitiesSection;
