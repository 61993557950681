import { useState, useEffect } from "react";
import {
  Box,
  LinearProgress,
  Table,
  withStyles,
  createStyles,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime, currencyString } from "~/utils/format";
import DetailsColumn from "~/components/DetailsColumn";
import ReportStatus from "./ReportStatus";
import BatchStatus from "./BatchStatus";
import TransactionStatus from "./TransactionStatus";
import api from "~/api";

interface AuditZReportLogDetailsProps {
  data: ZReportsJournalApi.ReportDto;
}

const defaultColumns = [
  {
    key: "dateBatch",
    i18nKey: "dateBatch",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "transactionId",
    sort: true,
    i18nKey: "transId",
  },
  {
    key: "transactionDate",
    i18nKey: "transDate",
  },
  {
    key: "transactionAmount",
    i18nKey: "transAmount",
    render: (amount?: number) => currencyString(amount),
  },
  {
    key: "batchAmount",
    i18nKey: "batchAmount",
    render: (bachAmount?: number) => currencyString(bachAmount),
  },
  {
    key: "transactionStatus",
    i18nKey: "transStatus",
    render: (status: ZReportsJournalApi.eTransactionStatus) => (
      <TransactionStatus status={status} />
    ),
  },
  {
    key: "batchStatus",
    i18nKey: "batchStatus",
    render: (status: ZReportsJournalApi.eBatchStatus) => (
      <BatchStatus status={status} />
    ),
  },
  {
    key: "status",
    i18nKey: "status",
    render: (status: ZReportsJournalApi.eReportStatus) => (
      <ReportStatus status={status} />
    ),
  },
];

const Container = withStyles(() =>
  createStyles({
    root: {
      maxHeight: 300,
      overflow: "auto",
      maxWidth: "100%",
    },
  })
)(Box);

/**
 * @memberof AuditZReportLog
 * @component
 * @desc Render detailed information for a Report.
 * @property { ZReportsJournalApi.ReportDto} data - Report log data;
 */

const AuditZReportLogDetails = ({ data }: AuditZReportLogDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const { t } = useTranslation();
  const [columns] = useState(defaultColumns.map((c) => i18nTableColumn(c, t)));
  const [reportDetails, setReportDetails] =
    useState<ZReportsJournalApi.ReportDetailsDto[]>();

  async function getDetailes(id: string) {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await api.zReportsJournal.getReport(id);
      setReportDetails(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDetailes(data.audit_number);
  }, [data]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (reportDetails) {
    return (
      <Container>
        <DetailsColumn title={t("title.transactions")}>
          <Table size="small" columns={columns} dataSource={reportDetails} />
        </DetailsColumn>
      </Container>
    );
  } else {
    return null;
  }
};

export default AuditZReportLogDetails;
