import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withStyles, createStyles } from "@material-ui/core/styles";

interface FormControlLabelProps {
  label?: string;
  children?: React.ReactChild | React.ReactChild[];
  extra?: React.ReactChild | React.ReactChild[];
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
}

const LabelContainer = withStyles(() =>
  createStyles({
    root: {
      width: 160,
      paddingRight: 5,
      "& > label": {
        marginTop: "5px",
        height: 32,
        lineHeight: 1.5,
        verticalAlign: "middle",
        "&::first-letter": {
          textTransform: "uppercase",
        },
      },
    },
  })
)(Grid);

const FormControlLabel = ({
  label,
  children,
  extra,
  alignItems = "flex-start",
}: FormControlLabelProps) => (
  <Box mb={3} mt={2}>
    <Grid container alignItems={alignItems} wrap="nowrap">
      <Grid item>
        <LabelContainer item>
          <InputLabel>{label}</InputLabel>
        </LabelContainer>
      </Grid>
      <Box flexGrow={1} maxWidth={380}>
        {children}
      </Box>
      {extra && <Box ml={3}>{extra}</Box>}
    </Grid>
  </Box>
);

export default FormControlLabel;
