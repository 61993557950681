import { enumToI18nKey } from '~/utils/helpers';

export const INCIDENT_STATUS = {
  OPENED: 'Opened',
  CLOSED: 'Closed',
} as { [key: string]: IncidentsApi.eIncidentStatus }

export const INCIDENT_STATUS_I18n = enumToI18nKey(INCIDENT_STATUS, 'INCIDENT_STATUS');

export const INCIDENT_TYPE = {
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
} as { [key: string]: IncidentsApi.eIncidentType }

export const INCIDENT_TYPE_I18n = enumToI18nKey(INCIDENT_TYPE, 'INCIDENT_TYPE');