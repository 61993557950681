import { useState, useEffect } from "react";
import { Box, Grid, Typography, LinearProgress, Table } from "~/components/UI";
import TabContent from "./TabContent";
import {
  formatDateWithTime,
  currencyString,
  quantityString,
} from "~/utils/format";
import { i18nTableColumn } from "~/packages/i18n";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface OrderTabProps {
  orderId: string;
}

const defaultColumns = [
  {
    key: "vendorCode",
    i18nKey: "vendorCode",
  },
  {
    key: "name",
    i18nKey: "name",
  },
  {
    key: "quantity",
    i18nKey: "quantity",
  },
  {
    key: "price",
    i18nKey: "price",
    render: (amount: number) => currencyString(amount),
  },
  {
    key: "amount",
    i18nKey: "amount",
    render: (amount: number) => currencyString(amount),
  },
];

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render information about Order.
 * @property {string} orderId order id
 */

const OrderTab = ({ orderId }: OrderTabProps) => {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] =
    useState<JournalBusinessOperationsApi.OrderOperationDetailsDto>();
  const { t } = useTranslation();

  async function getOrder(orderId: string) {
    setLoading(true);
    try {
      const { data } = await api.businessOperationsJournal.getOrder(orderId);
      setOrderData(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "quantity":
        return {
          ...column,
          render: (
            quantity: number,
            good: JournalBusinessOperationsApi.GoodOrderDto
          ) => (quantity ? quantityString(quantity, good.unit, t) : "0"),
        };
      default:
        return column;
    }
  });

  useEffect(() => {
    getOrder(orderId);
  }, [orderId]);

  if (loading) {
    return <LinearProgress />;
  } else if (orderData) {
    return (
      <TabContent>
        <Box flex={1} overflow="auto">
          <Table
            columns={columns}
            dataSource={orderData.goods}
            size="medium"
            rowKey="vendorCode"
            expandable={{
              highlightRow: true,
              rowExpandable: (data) => data?.attributes?.length ? true : false,
              expandedRowRender: (
                data: JournalBusinessOperationsApi.GoodOrderDto
              ) =>
                data &&
                data.attributes?.length && (
                    <Table
                      columns={columns}
                      dataSource={data?.attributes}
                      size="small"
                      rowKey="vendorCode"
                    />
                ),
            }}
          />
        </Box>
        <Box pt={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">
                {t("label.orderingStatus")}: {orderData.status}
              </Typography>
              <Typography variant="body1">
                {t("label.time")}: {formatDateWithTime(orderData.createdAt)}
              </Typography>
              <Typography variant="body1">
                {t("label.reason")}: {orderData.reason}
              </Typography>
              <Typography variant="body1">
                {t("label.serviceName")}: {orderData.serviceName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {t("label.orderID")}: {orderData.orderId}
              </Typography>
              <Typography variant="body1">
                {t("label.totalAmount")}:{" "}
                {currencyString(orderData.totalAmount)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </TabContent>
    );
  } else {
    return null;
  }
};

export default OrderTab;
