import PaymentServiceDetails from "./PaymentServiceDetails";
import LinkedEntities from "./LinkedEntities";

interface PaymentServiceProps {
  paymentService?: PaymentServicesApi.PaymentServiceDetailsDto;
  onUpdate?: (
    pymentService: PaymentServicesApi.PaymentServiceDetailsDto
  ) => void;
}

const PaymentService = ({ paymentService, onUpdate }: PaymentServiceProps) => {
  if (!paymentService) return null;
  return (
    <>
      <PaymentServiceDetails
        paymentService={paymentService}
        onUpdate={onUpdate}
      />
      <LinkedEntities paymentService={paymentService} />
    </>
  );
};

export default PaymentService;
