import { useContext, useCallback } from "react";
import {
  PaymentServiceDialogsContext,
  CreatePaymentService,
  UpdatePaymentService
} from "./PaymentServiceDialogsContext";

type UsePaymentServiceDialog = () =>  {
  createPaymentService: CreatePaymentService,
  updatePaymentService: UpdatePaymentService
}

/**
 * React hook for Payment Service dialogs windows
 */

export const usePaymentServiceDialog: UsePaymentServiceDialog = () => {
  const context = useContext(PaymentServiceDialogsContext);
  const createPaymentService: CreatePaymentService = useCallback((options) => {
    context.createPaymentService(options);
  }, []);
  const updatePaymentService: UpdatePaymentService = useCallback((paymentService, options) => {
    context.updatePaymentService(paymentService, options);
  }, []);

  return { createPaymentService, updatePaymentService };
};

export default usePaymentServiceDialog;
