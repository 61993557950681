import ClientPaymentDurationCard from "~/components/Metrics/ClientPaymentDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const ClientPaymentDuration = () => {
  const { t } = useTranslation();
  const getClientPaymentDurationMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } = await api.metrics.getClientPaymentDurationByNetwork(
        params
      );
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ClientPaymentDurationCard
      title={`${t("title.clientPaymentDurationBy")} ${t("text.network")}`}
      onUpdate={getClientPaymentDurationMetrics}
    />
  );
};

export default ClientPaymentDuration;
