import BusinessTransactionsCountCard from "~/components/Metrics/BusinessTransactionsCountCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const BusinessTransactionsCount = ({ siteId }: { siteId: string }) => {
  const { t } = useTranslation();
  const getBusinessTransactionsMetrics = async (
    params: MetricsAPI.GetBusinessTransactionsQuery
  ) => {
    try {
      const { data } = await api.metrics.getBusinessTransactionsCountBySite({
        ...params,
        siteId,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BusinessTransactionsCountCard
      title={`${t("title.businessTransactionCountsBy")} ${t(
        "text.site"
      )}: ${siteId}`}
      onUpdate={getBusinessTransactionsMetrics}
    />
  );
};

export default BusinessTransactionsCount;
