import BaseBreadcrumbs, { BreadcrumbsProps } from "~/components/UI/Breadcrumbs";
import { Box } from "~/components/UI";
import history from "~/packages/history";

const Breadcrumbs = ({ links }: BreadcrumbsProps) => (
  <Box mb={2}>
    <BaseBreadcrumbs
      links={links.map(({ href, ...link }) =>
        typeof href === "string"
          ? { ...link, onClick: () => history.push(href) }
          : link
      )}
    />
  </Box>
);

export default Breadcrumbs;
