import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class idPrefixApi {
  rootPath: string = "/id-prefix";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.prefixes');
  }

  getAll = (limit: number = 10, page: number = 1, query?: IdPrefixApi.GetIdPrefixQuery ): Promise<Api.GetAll<IdPrefixApi.IdPrefixDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) }  })

  createIDPrefix = (formData: IdPrefixApi.CreateIdPrefix): Promise<Api.GetOne<IdPrefixApi.IdPrefixDto>> =>
    this.instance.post(this.rootPath, formData)

  switchActivityIDPrefix = (id: string): Promise<Api.GetOne<IdPrefixApi.IdPrefixDto>> =>
    this.instance.put(`${this.rootPath}/switchActivity/${id}`)
  
  deleteIDPrefixes = (ids: string[]) =>
    this.instance.delete(`${this.rootPath}`, { params: { ids } })

  getIDPrefix = (id: string): Promise<Api.GetOne<IdPrefixApi.IdPrefixDto>> =>
    this.instance.get(`${this.rootPath}/${id}`)
  
  deleteIDPrefix = (id: string) => 
    this.instance.delete(`${this.rootPath}/${id}`)
}
