import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import _ from "lodash";
import api from "~/api";

interface FormikSelectMerchantProps {
  name: string;
  defaultInputValue?: string;
  keyPurpose: KeyManagementApi.eKeyPurpose;
  filterOptions?: (
    options: KeyManagementApi.KeyDto[],
    state: any
  ) => KeyManagementApi.KeyDto[];
}

interface SelectMerchantProps {
  inputValue?: string;
  name?: string;
  onChange?: (key?: KeyManagementApi.KeyDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  keyPurpose: KeyManagementApi.eKeyPurpose;
  filterOptions?: (
    options: KeyManagementApi.KeyDto[],
    state: any
  ) => KeyManagementApi.KeyDto[];
}

const requestSearchKey = (
  keyPurpose: KeyManagementApi.eKeyPurpose,
  search: string
) => api.keyManagement.getPurposeKeys(keyPurpose, 50, 1, { search });

export const SelectKey = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue,
    keyPurpose,
  }: SelectMerchantProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<
      KeyManagementApi.KeyDto[]
    >([]);
    const [key, setMerchant] =
      useState<Partial<KeyManagementApi.KeyDto> | null>(null);

    const searchKeys = useMemo(
      () =>
        _.debounce((keyId: string) => {
          setLoading(true);
          requestSearchKey(keyPurpose, keyId)
            .then(({ data }) => {
              setSearchResults(data.data);
            })
            .finally(() => {
              setLoading(false);
            });
        }, 500),
      [keyPurpose]
    );

    const onChangeInputValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
      searchKeys(e.target.value || "");
    };

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.name);
          setMerchant(newValue);
        } else {
          setValue("");
          setMerchant(null);
        }
        onChange && onChange(newValue);
      },
      [onChange]
    );

    const getOptionSelected = useCallback(
      (o: KeyManagementApi.KeyDto, value: KeyManagementApi.KeyDto) =>
        o.keyId === value.keyId,
      []
    );

    const getOptionLabel = useCallback(
      (o: KeyManagementApi.KeyDto) => o.name || "",
      []
    );

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setMerchant(null);
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchKeys("");
    }, [searchKeys]);

    return (
      <Autocomplete
        value={key}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText="No Keys"
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        loading={loading}
        disableClearable
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            fullWidth
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FromikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FormikSelectMerchantProps & SelectMerchantProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChange = useCallback(
    (key?: KeyManagementApi.KeyDto) => {
      field.onChange({
        target: {
          name,
          value: key ? key.name : "",
        },
      });
    },
    [field, name]
  );

  return (
    <SelectKey
      {...field}
      {...other}
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default React.memo(FromikContainer);
