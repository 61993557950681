import { useContext } from "react";
import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import PaymentServicesContext from "./paymentServicesContext";
import history from "~/packages/history";
import { generatePath } from "react-router";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import usePaymentServiceDialog from "~/components/PaymentService/Dialogs/usePaymentServiceDialogs";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface PaymentServiceOptionsProps {
  paymentService: PaymentServicesApi.PaymentServiceDto;
}

/**
 * @memberof PaymentServices
 * @component
 * @desc Render options for a Service.
 * @property {PaymentServicesApi.PaymentServiceDto} paymentService - Service data
 * @requires PaymentServicesContext
 */

const ServiceOptions = ({ paymentService }: PaymentServiceOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onUpdate, onUpdateItem, onDelete } = useContext(
    PaymentServicesContext
  );
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.PAYMENT_SERVICE);
  const { updatePaymentService } = usePaymentServiceDialog();
  const { enabled } = paymentService;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<void>) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (paymentService.incidents) {
        paymentService.incidents.count++;
        paymentService.incidents.incidentIds.push(incident.incidentId);
      } else {
        paymentService.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(paymentService);
    },
    [paymentService, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.READ_PAYMENT_SERVICE,
      PERMISSION.EDIT_PAYMENT_SERVICE,
      PERMISSION.DELETE_PAYMENT_SERVICE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(
            onUpdate(paymentService.paymentId, {
              enabled: !enabled,
            } as PaymentServicesApi.UpdatePaymentService)
          ),
      },
    },
    {
      label: t("option.details"),
      hidden: !hasAll(PERMISSION.READ_PAYMENT_SERVICE),
      onClick: () =>
        history.push(
          generatePath(ROUTES.PAYMENT_SERVICE, {
            paymentId: paymentService.paymentId,
          }),
          { paymentServiceMeta: paymentService }
        ),
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE),
      onClick: () =>
        updatePaymentService(paymentService, { onClose: onUpdateItem }),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_PAYMENT_SERVICE),
      onClick: () => onDelete && onUpdateHandler(onDelete(paymentService)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () =>
        createIncident(paymentService.paymentId, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default ServiceOptions;
