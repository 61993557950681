import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "~/store";
import { updateToken } from "~/store/auth/actions";
import { getToken } from "~/packages/msalInstance";
import { stringify } from "query-string";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";

import ENV from "~/constants";

import { httpError } from "./common"

import Auth from "./auth";
import Permissions from "./permissions";
import Profile from "./profile";
import Roles from "./roles";
import Sites from "./sites";
import Poses from "./poses";
import Bundles from "./bundles";
import Search from "./search";
import Services from "./services";
import Users from "./users";
import Incidents from "./incidents";
import Groups from "./groups";
import PaymentServices from "./paymentServices";
import DashboardSettings from "./dashboardSettings";
import UserActivityJournal from "./userActivityJournal";
import EventsLogJournal from "./eventsLogJournal";
import SystemLogJournal from "./systemLogJournal";
import JournalBusinessOperations from "./journalBusinessOperations";
import SystemSettings from "./systemSettings";
import Metrics from "./metrics";
import DashboardMetrics from "./dashboardMetrics";
import DashboardCatalogs from "./dashboardCatalogs";
import ServicesCategories from "./servicesCategories";
import ServiceTemplates from "./serviceTemplates";
import ZReportsJournal from "./zReportsJournal";
import Goods from "./goods";
import KeyManagement from "./keyManagement";
import idPrefix from "./id-prefix";

const serverConfig = {
  baseURL: ENV.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  paramsSerializer: stringify,
};

const serverInstance = axios.create(serverConfig);

const requestInterceptor = (config: AxiosRequestConfig) => {
  const appState: ReduxStore.State = store.getState();
  const { authData } = appState.auth;
  if (authData) {
    config.headers.common["Authorization"] = `Bearer ${authData.accessToken}`;
  }

  return config;
};

const responseErrorInterceptor = (httpResponse: {
  response: AxiosResponse<Api.ErrorResponse>;
}) => {
  const { response } = httpResponse;

  if (response) {
    if (response.status === 401) {
      const appState: ReduxStore.State = store.getState();
      const { authData } = appState.auth;
      const { config } = response;

      const refPromise = getToken()
        .then((token) => {
          if (authData) {
            return api.auth.refresh(authData.refreshToken, token);
          }
          return Promise.reject();
        })
        .then(({ data }) => {
          store.dispatch(updateToken(data.data));
          return data.data.accessToken;
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });

      return refPromise.then((token) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return axios(config);
      });
    } else if (response.status >= 400) {
      if (response.status === 403) {
        if (response.config.method === 'get' && history.location.pathname !== ROUTES.FORBIDDEN) {
          history.push(`${ROUTES.FORBIDDEN}?redirectedFrom=${history.location.pathname}`);
        }
      }

      return Promise.reject(response);
    }
  }

  return Promise.reject(httpResponse);
};

serverInstance.interceptors.request.use(requestInterceptor);
serverInstance.interceptors.response.use(
  (res) => res,
  responseErrorInterceptor
);
serverInstance.interceptors.response.use(undefined, httpError.handleHttpError);

const api = {
  auth: new Auth(serverInstance),
  permissions: new Permissions(serverInstance),
  profile: new Profile(serverInstance),
  roles: new Roles(serverInstance),
  sites: new Sites(serverInstance),
  poses: new Poses(serverInstance),
  services: new Services(serverInstance),
  search: new Search(serverInstance),
  users: new Users(serverInstance),
  groups: new Groups(serverInstance),
  paymentServices: new PaymentServices(serverInstance),
  dashboardSettings: new DashboardSettings(serverInstance),
  incidents: new Incidents(serverInstance),
  businessOperationsJournal: new JournalBusinessOperations(serverInstance),
  userActivityJournal: new UserActivityJournal(serverInstance),
  systemSettings: new SystemSettings(serverInstance),
  eventsLogJournal: new EventsLogJournal(serverInstance),
  systemLogJournal: new SystemLogJournal(serverInstance),
  metrics: new Metrics(serverInstance),
  dashboardCatalogs: new DashboardCatalogs(serverInstance),
  dashboardMetrics: new DashboardMetrics(serverInstance),
  servicesCategories: new ServicesCategories(serverInstance),
  bundles: new Bundles(serverInstance),
  serviceTemplates: new ServiceTemplates(serverInstance),
  zReportsJournal: new ZReportsJournal(serverInstance),
  goods: new Goods(serverInstance),
  keyManagement: new KeyManagement(serverInstance),
  idPrefix: new idPrefix(serverInstance)
};

export default api;
