import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import Checkbox from "~/components/SideBarFilter/Checkbox";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import { useTranslation } from "react-i18next";

interface FilterProps {
  onChangeFilter: (
    data: IncidentsApi.GetIncidentsQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup
          label={t("title.sitesWithDisabledPOSes")}
          collapsed
          defaultOpen={true}
        >
          <Checkbox label={t("button.yes")} name="enabled" value="true" />
          <Checkbox label={t("button.no")} name="enabled" value="false" />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
