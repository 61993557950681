import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class EventsLogJournalApi {
  rootPath: string = "/goods";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.goods');
  }

  getGoodsForSite = (siteId: string): Promise<Api.GetAll<GoodsApi.GoodDto>> =>
    this.instance.get(`${this.rootPath}/${siteId}`);
}
