import React from "react";
import Table, { TableProps } from "~/components/UI/Table/Table";
import EventDetails from "./EventDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import EnumValue from "~/components/EnumValue";
import { API_I18n, PRIORITY_I18n } from "~/api/eventsLogJournal/enums";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";

interface EventsTableProps
  extends Omit<
    TableProps<EventsLogJournalApi.EventDto>,
    "columns" | "rowSelection"
  > {
  onNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  // {
  //   key: "id",
  //   title: "ID",
  //   cellOptions: {
  //     width: 300,
  //   },
  // },
  {
    key: "timestamp",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "siteId",
    i18nKey: "siteId",
    render: (siteId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.SITE} sourceId={siteId}>
        {siteId || " "}
      </LinkToObject>
    ),
  },
  {
    key: "posId",
    title: "ID POS",
    render: (posId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.POS} sourceId={posId}>
        {posId || " "}
      </LinkToObject>
    ),
  },
  {
    key: "priority",
    i18nKey: "priority",
    render: (priority: string) => (
      <EnumValue value={priority} enumI18n={PRIORITY_I18n} />
    ),
  },
  {
    key: "path",
    i18nKey: "path",
  },
  {
    key: "eventType",
    i18nKey: "type",
  },
  {
    key: "api",
    i18nKey: "apiVersion",
    render: (api: string) => <EnumValue value={api} enumI18n={API_I18n} />,
  },
];

/**
 * @memberof EventsLog
 * @component
 * @desc Render table of Events Log.
 * @property {EventsLogJournalApi.EventDto[]} dataSource - Event data
 */

const EventsTable = ({
  onSort,
  onNext,
  hasMore,
  dataLength,
  ...props
}: EventsTableProps) => {
  const { t } = useTranslation();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return (
      <Table
        {...props}
        columns={columns}
        expandable={{
          highlightRow: true,
          expandedRowRender: (data: EventsLogJournalApi.EventDto) => (
            <EventDetails data={data} />
          ),
        }}
        onRequestSort={onSort}
        rowKey="id"
        stickyHeader
      />
  );
};

export default React.memo(EventsTable);
