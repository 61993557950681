import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { SelectProps, OptionProps } from "~/components/UI/Select";

export interface SelectEnumProps extends SelectProps {
  enumValue: { [key: string]: string };
  enumI18n: { [key: string]: string };
  sort?: (a: OptionProps, b: OptionProps) => number;
}

const SelectEnum = ({
  enumValue,
  enumI18n,
  sort,
  formikControll = true,
  ...otherProps
}: SelectEnumProps) => {
  const { t } = useTranslation(["enums"]);
  const keys = Object.keys(enumValue);

  const options = useMemo(() => {
    const options = keys
      ? keys.map((key) => ({
          label: enumI18n ? t(enumI18n[enumValue[key]]) : enumValue[key],
          value: enumValue[key],
        }))
      : [];
    
      return typeof sort === 'function' ? options.sort(sort) : options
  }, [keys, sort]);

  return (
    <Select
      {...otherProps}
      options={options}
      sortOptions="asc"
      variant="outlined"
      formikControll={formikControll}
    />
  );
};

export default SelectEnum;
