import { Grid } from "~/components/UI";
import Card from "./Card";
import Skeleton from "../../CommonSkeleton";

interface ListProps {
  loading?: boolean;
  poses?: Api.PosDto[];
  onSelectPos?: (service?: Api.PosDto) => void;
}

const List = ({ loading, poses, onSelectPos }: ListProps) => {
  if (loading) {
    return <Skeleton />;
  }

  return (
    <Grid container direction="column" spacing={2}>
      {poses
        ? poses.map((pos) => (
            <Grid item key={pos.id}>
              <Card pos={pos} onClick={() => onSelectPos && onSelectPos(pos)} />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default List;
