import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  FormControlLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  LinearProgress,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import EntitiesForm from "./EntitiesForm";
import ProcessesForm from "./ProcessesForm";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import _ from "lodash";
import yup from "~/packages/yup";
import api from "~/api";

interface UpdateRoleProps extends Omit<DialogProps, "onClose" | "role"> {
  role?: RolesApi.RoleDto;
  onClose?: (result?: boolean) => void;
}

export interface FromData {
  name: string;
  permissions: {
    [key: string]: boolean;
  };
}

const defaultValues = {
  name: "",
  permissions: {},
};

const replacePermissions = (
  formData: FromData,
  permissions: { [key: string]: string }
): RolesApi.CreateRole | RolesApi.UpdateRole => ({
  ...formData,
  permissions: _.reduce(
    formData.permissions,
    (result, value, key: string) => {
      value && permissions[key] && result.push(permissions[key]);
      return result;
    },
    [] as string[]
  ),
});

/**
 * @memberof Roles
 * @component
 * @desc Create/Edit a Role Dialog.
 * @property {RolesApi.RoleDto} role - Role data
 * @property {Function} onClose - passes true if the group was created successfully. (result?: boolean) => void;
 */

const UpdateRole = ({ role, open, onClose }: UpdateRoleProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [permissions, setPermissions] = useState<{ [key: string]: string }>();
  const [isEditMode, setEditMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingRoleDetails, setLoadingRoleDetails] = useState(false);
  const { t } = useTranslation();

  const getPermissions = async () => {
    const {
      data: { data: permissions },
    } = await api.permissions.getAll();
    return permissions
      ? _.flatten(_.values(permissions)).reduce(
          (o, p) => Object.assign(o, { [p.scope]: p.permissionId }),
          {}
        )
      : [];
  };

  const onSubmit = useCallback(
    async (formData: FromData) => {
      let allPermissions = permissions;
      setLoading(true);

      try {
        if (!permissions) {
          allPermissions = await getPermissions();
          setPermissions(allPermissions);
        }

        const requestData = replacePermissions(formData, allPermissions || {});

        if (isEditMode && role && role.roleId) {
          await api.roles.updateRole(role.roleId, requestData);
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.roles.createRole(requestData);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, role, permissions, onClose]
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
  });

  const getRole = useCallback(async (roleId: string) => {
    setLoadingRoleDetails(true);
    try {
      const {
        data: {
          data: { name, permissions },
        },
      } = await api.roles.getRole(roleId);

      setInitialValues({
        name,
        permissions: _.flatten(_.values(permissions)).reduce(
          (o, p) => Object.assign(o, { [p.scope]: true }),
          {}
        ),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingRoleDetails(false);
    }
  }, []);

  useEffect(() => {
    if (role && role.roleId) {
      setInitialValues({
        name: role.name,
        permissions: {},
      });

      getRole(role.roleId);

      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [role, getRole]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      title={isEditMode ? t("title.editRole") : t("title.createNewRole")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label={t("label.roleName")}>
                  <OutlinedInput name="name" formikControll fullWidth />
                </FormControlLabel>
              </Box>
              <Accordion defaultExpanded={true}>
                <AccordionSummary>
                  <Typography variant="h4">{t("text.permissions")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingRoleDetails ? (
                    <LinearProgress />
                  ) : (
                    <Tabs
                      tabs={[
                        {
                          label: t("label.entities"),
                          content: <EntitiesForm />,
                        },
                        {
                          label: t("label.processes"),
                          content: <ProcessesForm />,
                        },
                      ]}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateRole;
