import { chain } from 'lodash';

export type GroupedByCategory = { title: string; services: Array<Api.ServiceDto & { arrayIndex: number }> }[];

export const groupByCategory = (services?: Api.ServiceDto[]): GroupedByCategory => {
    return services && services.length
      ? chain(services.map((s, i) => ({ ...s, arrayIndex: i })))
        .sortBy("category")
        .groupBy("category")
        .map((value, key) => ({ title: key, services: value }))
        .value()
      :[]
}