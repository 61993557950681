import Constants from "./constants";

const { ACTIONS } = Constants;

const initialState: ServicesStore.State = {
  serviceCategories: undefined,
  serviceTemplates: undefined
};

const Reducer = (
  state = initialState,
  action: ServicesStore.Actions
): ServicesStore.State => {
  switch (action.type) {
    case ACTIONS.SET_SERVICE_CATEGORIES:
      return {
        ...state,
        serviceCategories: action.payload,
      };
    case ACTIONS.SET_SERVICE_TEMPLATES:
      return {
        ...state,
        serviceTemplates: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
