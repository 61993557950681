import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Label,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { currencyString } from "~/utils/format";
import api from "~/api";

interface FromData extends PaymentServicesApi.RefundOfPaymentRequestDto {}

interface ReversalPaymentDialogProps
  extends Omit<DialogProps, "onClose" | "role"> {
  reversalPayment?: JournalBusinessOperationsApi.PaymentDto;
  onClose?: (result?: boolean) => void;
}

const defaultValues = {
  transitionId: "",
  amount: "",
  rrn: "",
  approvalCode: "",
  reason: "",
};

/**
 * @memberof BusinessOperations
 * @component
 * @desc Reversal Payment Dialog.
 * @property {Function} onClose - (result?: boolean) => void;
 */

const ReversalPaymentDialog = ({
  reversalPayment,
  open,
  onClose,
}: ReversalPaymentDialogProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!reversalPayment) return null;
      setLoading(true);
      try {
        await api.paymentServices.refundOfPayment(
          reversalPayment.transactionId,
          formData
        );
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t]
  );

  const validationSchema = yup.object().shape({
    rrn: yup.string().required(),
    approvalCode: yup.string().required(),
    reason: yup.string().required(),
  });

  useEffect(() => {
    setInitialValues(Object.assign(defaultValues, {}));
  }, [open]);

  if (!reversalPayment) return null;

  return (
    <Dialog
      open={open}
      title={t("title.reversalPayment")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ values, handleSubmit }) => (
            <>
              <DialogContent>
                <Label text={t("label.transactionID")}>
                  {reversalPayment.transactionId}
                </Label>
                <Label text={t("label.amount")}>
                  {currencyString(values.amount)}
                </Label>
                <Box maxWidth={420}>
                  <FormControlLabel label="RRN">
                    <OutlinedInput name="rrn" formikControll />
                  </FormControlLabel>
                  <FormControlLabel label={t("label.approvalCode")}>
                    <OutlinedInput name="approvalCode" formikControll />
                  </FormControlLabel>
                </Box>
                <FormControlLabel label={t("label.reason")}>
                  <OutlinedInput
                    name="reason"
                    multiline
                    rows={4}
                    formikControll
                    fullWidth
                  />
                </FormControlLabel>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.submit")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default ReversalPaymentDialog;
