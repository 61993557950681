import { Grid, Typography, Box, StatusIndicator } from "~/components/UI";
import ExpandableTile from "~/components/ExpandableTile";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import SiteDetails from "~/pages/Catalogs/Sites/Sites/Components/SiteDetails";
import SiteOptions from "~/pages/Catalogs/Sites/Sites/Components/SiteOptions";

interface SiteCardProps {
  site: Api.SiteDto;
  onClose: () => void;
}

/**
 * @memberof Dashboard
 * @component
 * @desc Render a Card of Site.
 * @property {Api.SiteDto} site - Site data;
 */

const SiteCard = ({ site, onClose }: SiteCardProps) => (
  <ExpandableTile
    expanded={true}
    onCollapse={onClose}
    expandedContentRender={<SiteDetails site={site} size="small" />}
  >
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          ID: {site.siteId}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <IncidentsIcon incidents={site.incidents} />
          </Grid>
          <Grid item>
            <SiteOptions site={site} hideEditOptions={true} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Box mb={1}>
      <Typography variant="body1">
        <StatusIndicator status={site.enabled ? "active" : "disabled"} />
        {site.name}
      </Typography>
    </Box>
    <Box mb={1}>
      <Typography variant="subtitle1">{site.address}</Typography>
    </Box>
  </ExpandableTile>
);

export default SiteCard;
