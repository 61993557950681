import { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Popconfirm,
  Grid,
  OutlinedInput,
  FieldError,
  Icon,
  Button,
} from "~/components/UI";
import {
  getServicesTemplates,
  addServicesTemplate,
  updateServicesTemplate,
  deleteServicesTemplate,
} from "~/store/services/actions";
import { useField, FormikHandlers } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface FormikContainerProps {
  name: string;
  defaultInputValue?: string;
}

interface SelectServiceTemplatesProps {
  serviceCategoryId?: string;
  editMode?: boolean;
  inputValue?: string;
  name?: string;
  onChange?: (serviceTemplate?: ServiceTemplatesApi.ServiceTemplateDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (
    options: ServiceTemplatesApi.ServiceTemplateDto[],
    state: any
  ) => ServiceTemplatesApi.ServiceTemplateDto[];
}

export const SelectServiceTemplates = ({
  serviceCategoryId,
  inputValue,
  touched,
  error,
  editMode,
  filterOptions,
  onBlur,
  onChangeValue,
  onChange,
}: SelectServiceTemplatesProps) => {
  const [value, setValue] = useState<string | undefined>(inputValue || "");
  const [serviceTemplate, setServiceTemplate] =
    useState<ServiceTemplatesApi.ServiceTemplateDto | null>(null);
  const [newTemplatesName, setNewTemplatesName] = useState<string>();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const templates = useSelector(
    (state: ReduxStore.State) => state.services.serviceTemplates
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChangeAutocomplete = useCallback(
    (e, newValue?: ServiceTemplatesApi.ServiceTemplateDto) => {
      if (newValue) {
        setValue(newValue.name);
        setServiceTemplate(newValue);
      } else {
        setValue("");
        setServiceTemplate(null);
      }
      onChange && onChange(newValue);
    },
    [onChange]
  );

  const onEditMode = useCallback(() => {
    if (!serviceTemplate) return;
    setIsEdit(true);
    setNewTemplatesName(serviceTemplate.name);
  }, [serviceTemplate]);

  const onAdd = useCallback(async () => {
    if (!serviceCategoryId || !value) return;
    const newTemplate: any = await dispatch(
      addServicesTemplate({ serviceCategoryId, name: value })
    );
    onChangeAutocomplete(undefined, newTemplate);
    setIsEdit(false);
  }, [serviceCategoryId, value, onChangeAutocomplete]);

  const onSave = useCallback(async () => {
    if (!serviceTemplate || !newTemplatesName) return;
    const template: any = await dispatch(
      updateServicesTemplate(serviceTemplate.id, {
        ...serviceTemplate,
        name: newTemplatesName,
      })
    );
    setValue(newTemplatesName);
    setServiceTemplate(template);
    setIsEdit(false);
  }, [serviceTemplate, newTemplatesName]);

  const onDelete = useCallback(async () => {
    if (!serviceTemplate) return;
    await dispatch(deleteServicesTemplate(serviceTemplate.id));
    setValue("");
    setServiceTemplate(null);
    onChangeAutocomplete(undefined, undefined);
  }, [serviceTemplate]);

  const onChangeInputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
    },
    [setValue, onChangeValue]
  );

  useEffect(() => {
    if (templates) {
      const template = templates.find((t) => t.name === value);
      if (template) {
        setServiceTemplate(template);
      }
    } else {
      const promise: any = dispatch(getServicesTemplates());
      if (promise && promise.finally) {
        setLoading(true);
        promise.finally(() => {
          setLoading(false);
        });
      }
    }
  }, [dispatch, templates]);

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      {editMode && isEdit ? (
        <>
          <Grid item>
            <OutlinedInput
              value={newTemplatesName}
              onChange={(e) => setNewTemplatesName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={onSave}>
              {t("button.save")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setIsEdit(false)}>
              {t("button.cancel")}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <Autocomplete
              value={serviceTemplate}
              inputValue={value}
              onChange={onChangeAutocomplete}
              getOptionSelected={(
                o: ServiceTemplatesApi.ServiceTemplateDto,
                value: ServiceTemplatesApi.ServiceTemplateDto
              ) => o && value && o.id === value.id}
              onBlur={onBlur}
              noOptionsText={t("text.noResultsFound")}
              options={templates || []}
              getOptionLabel={(o: ServiceTemplatesApi.ServiceTemplateDto) =>
                o.name
              }
              filterOptions={filterOptions}
              renderInput={(params) => (
                <>
                  <OutlinedInput
                    loading={loading}
                    className={params.InputProps.className}
                    endAdornment={params.InputProps.endAdornment}
                    inputProps={params.inputProps}
                    error={!!error}
                    onChange={onChangeInputValue}
                  />
                  {error && <FieldError>{error}</FieldError>}
                </>
              )}
            />
          </Grid>
          {editMode && Boolean(serviceTemplate) && (
            <>
              <Grid item>
                <Button iconContainer onClick={onEditMode}>
                  <Icon name="Pen" />
                </Button>
              </Grid>
              <Grid item>
                <Popconfirm
                  title={t("text.confirmationRequest")}
                  cancelText={t("button.no")}
                  okText={t("button.yes")}
                  onConfirm={onDelete}
                >
                  <Button iconContainer>
                    <Icon name="Delete" />
                  </Button>
                </Popconfirm>
              </Grid>
            </>
          )}
          {editMode && !loading && (
            <Grid item>
              <Button
                hidden={
                  !serviceCategoryId ||
                  Boolean(serviceTemplate) ||
                  !value ||
                  value.length < 2
                }
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {t("button.add")}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const FormikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FormikContainerProps & SelectServiceTemplatesProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <SelectServiceTemplates
      {...field}
      {...other}
      onChange={(servicesTemplate?: ServiceTemplatesApi.ServiceTemplateDto) => {
        field.onChange({
          target: {
            name,
            value: servicesTemplate?.id || "",
          },
        });
      }}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default FormikContainer;
