import { Box, createStyles, withStyles } from "~/components/UI";

const TabContent = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100% - 35px)",
      overflow: "auto",
      boxSizing: "border-box",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  })
)(Box);

export default TabContent;
