import MUITextField, { TextFieldProps } from "@material-ui/core/TextField";
import { useField } from "formik";
import FieldError from "../Form/FieldError";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const getOutlinedClasses = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      minHeight: 35,
      backgroundColor: "transparent",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize - 2,
      padding: "10px 10px 10px 20px",
      boxSizing: "border-box",
      minWidth: 205,
    },
    input: {
      padding: 0,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
  })
);

const getBaseInputClasses = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      minHeight: 42,
      backgroundColor: "transparent",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
      fontSize: 16,
      padding: 5,
      boxSizing: "border-box",
      "& label.Mui-focused": {
        color: "green",
      },
      "&.MuiInput-underline:after": {
        border: "none",
        content: "",
      },
      "&.MuiInput-underline:before": {
        border: "none",
        content: "",
      },
      "& > :not(input)": {
        margin: "0 15px",
      },
    },
    input: {
      fontSize: theme.typography.fontSize,
      lineHeight: 1.4,
    },
  })
);

const FormikInput = ({ name, ...other }: TextFieldProps & { name: string }) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <>
      <MUITextField
        {...field}
        value={field.value || ""}
        error={touched && !!error}
        {...other}
      />
      {touched && error && <FieldError>{error}</FieldError>}
    </>
  );
};

const TextField = ({
  name,
  formikControll,
  ...inputProps
}: TextFieldProps & { formikControll?: boolean }) => {
  let classes = {};

  switch (inputProps.variant) {
    case "outlined":
      classes = getOutlinedClasses();
      break;
    case "standard":
      classes = getBaseInputClasses();
      break;
    default:
      classes = {};
  }

  return formikControll && name ? (
    <FormikInput name={name} {...inputProps} InputProps={{ classes }} />
  ) : (
    <MUITextField {...inputProps} />
  );
};

export default TextField;
