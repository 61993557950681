import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class MetricsAPI {
  rootPath: string = "/metrics";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.metrics');
  }

  /*** Network ***/
  getClientByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientNetworkDto>> =>
    this.instance.get(`${this.rootPath}/clients/network`, { params })

  getClientOnlineByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<number>> =>
    this.instance.get(`${this.rootPath}/clients/network/online`, { params })

  getBillsByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.BillsBySiteDto>> =>
    this.instance.get(`${this.rootPath}/bills/network`, { params })

  getMoneyByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.MoneyBySiteDto>> =>
    this.instance.get(`${this.rootPath}/money/network`, { params })

  getBusinessTransactionsCountByNetwork = (params: MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/network`, { params })

  getBusinessTransactionsCountOnlineByNetwork = (params: MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/network/online`, { params })

  getClientPaymentDurationByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientPaymentDurationResponceDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration/network`, { params })

  getPSPPaymentDurationByNetwork = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.PSPPaymentDuratioResponceDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration/network`, { params })

  getFinancialTransactionsByNetwork = (params: MetricsAPI.GetMetricQuery & MetricsAPI.GetFinancialTransactionQuery): Promise<Api.GetOne<MetricsAPI.FinancialTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/financial-transactions/network`, { params })

  /*** Site ***/
  getClientNetworkBySite = ({ siteId, period }: any): Promise<Api.GetOne<MetricsAPI.ClientNetworkBySiteDto>> =>
    this.instance.get(`${this.rootPath}/clients/sites/${siteId}`, { params: { period } })

  getClientOnlineBySite = ({ siteId, period }: any): Promise<Api.GetOne<number>> =>
    this.instance.get(`${this.rootPath}/clients/sites/${siteId}/online`, { params: { period } })  

  getInvoceDurationBySite = ({ siteId, period }: { siteId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MetricsResponceDto>> =>
    this.instance.get(`${this.rootPath}/invoice-duration/site/${siteId}`, { params: { period } })

  getBusinessTransactionsCountBySite = ({ siteId, ...params }: { siteId: string } & MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/site/${siteId}`, { params })
  
  getBusinessTransactionsCountOnlineBySite = ({ siteId, ...params }: { siteId: string } & MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/site/${siteId}/online`, { params })
    
  getBillsBySite = ({ siteId, period }: { siteId: string } & MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.BillsBySiteDto>> =>
    this.instance.get(`${this.rootPath}/bills/site/${siteId}`, { params: { period }})
  
  getMoneyBySite = ({ siteId, period }: { siteId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MoneyBySiteDto>> =>
    this.instance.get(`${this.rootPath}/money/site/${siteId}`, { params: { period }})

  getClientPaymentDurationBySite = ({ siteId, ...params }: { siteId: string } & MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientPaymentDurationResponceDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration/site/${siteId}`, { params })

  getPSPPaymentDurationBySite = ({ siteId, ...params }: { siteId: string } & MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.PSPPaymentDuratioResponceDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration/site/${siteId}`, { params })

  /*** POS ***/
  getQueuesByPOS = ({ posId, period }: { posId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MetricsResponceDto>> =>
    this.instance.get(`${this.rootPath}/queues/poses/${posId}`, { params: { period }})

  getQueuesOnlineByPOS = ({ posId, period }: { posId: string, period: string }): Promise<Api.GetOne<number>> =>
    this.instance.get(`${this.rootPath}/queues/poses/${posId}/online`, { params: { period }})

  getInvoiceDurationByPOS = ({ posId, period }: { posId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MetricsResponceDto>> =>
    this.instance.get(`${this.rootPath}/invoice-duration/pos/${posId}`, { params: { period }})

  getBusinessTransactionsCountByPOS = ({ posId, ...params }: { posId: string } & MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/pos/${posId}`, { params })

  getBusinessTransactionsCountOnlineByPOS = ({ posId, ...params }: { posId: string } & MetricsAPI.GetBusinessTransactionsQuery): Promise<Api.GetOne<MetricsAPI.BusinessTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count/pos/${posId}/online`, { params })

  /*** Service ***/
  getQueuesByService = ({ serviceId, siteId, period }: { serviceId: string, siteId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MetricsResponceDto>> =>
    this.instance.get(`${this.rootPath}/queues/service/${serviceId}/site/${siteId}`, { params: { period }})

  getQueuesOnlineByService = ({ serviceId, siteId, period }: { serviceId: string, siteId: string, period: string }): Promise<Api.GetOne<number>> =>
    this.instance.get(`${this.rootPath}/queues/service/${serviceId}/site/${siteId}/online`, { params: { period }})

  getInvoiceDurationByService = ({ serviceId, siteId, period }: { serviceId: string, siteId: string, period: string }): Promise<Api.GetOne<MetricsAPI.MetricsResponceDto>> =>
    this.instance.get(`${this.rootPath}/invoice-duration/service/${serviceId}/site/${siteId}`, { params: { period }})

  /*** Payment Service ***/
  getClientPaymentDurationByPaymentService = ({ paymentId, ...params }: { paymentId: string } & MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientPaymentDurationResponceDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration/payment/${paymentId}`, { params })

  getPSPPaymentDurationByPaymentService = ({ paymentId, ...params }: { paymentId: string } & MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.PSPPaymentDuratioResponceDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration/payment/${paymentId}`, { params })

  getFinancialTransactionsByPaymentService = ({ paymentId, ...params }: { paymentId: string } & MetricsAPI.GetMetricQuery & MetricsAPI.GetFinancialTransactionQuery): Promise<Api.GetOne<MetricsAPI.FinancialTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/financial-transactions/payment/${paymentId}`, { params })
}
