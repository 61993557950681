import { useState, useEffect, useCallback } from "react";
import Select, { SelectProps, OptionProps } from "~/components/UI/Select";

interface SelectValueProps extends SelectProps {
  loadOptions: () => Promise<OptionProps[]>;
}

const SelectValue = ({ loadOptions, ...props }: SelectValueProps) => {
  const [options, setOptions] = useState<OptionProps[]>([]);

  const getOptions = useCallback(async () => {
    if (typeof loadOptions === "function") {
      const list = await loadOptions();
      setOptions(list);
    }
  }, [loadOptions]);

  useEffect(() => {
    getOptions();
  }, []);

  return <Select {...props} options={options} variant="outlined" />;
};

export default SelectValue;
