import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  OutlinedInput,
  Checkbox,
  Icon,
  IconButton,
  Switch,
  Grid,
  Typography,
} from "~/components/UI";
import SelectServiceCategory from "~/components/CommonFormControls/SelectServiceCategory";
import SelectServiceTemplates from "~/components/CommonFormControls/SelectServiceTemplates";
import SelectSite from "~/components/CommonFormControls/SelectSite";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

interface CreateServiceFromProps {
  index: number;
  path: string;
  onDelete?: (index: number) => unknown;
}

const CreateServiceFrom = ({
  path,
  index,
  onDelete,
}: CreateServiceFromProps) => {
  const { t } = useTranslation();
  const [serviceCategory, , { setValue: setServiceCategory }] = useField(
    `${path}.categoryId`
  );
  const [, , { setValue: setServiceMaxQueue }] = useField(
    `${path}.serviceMaxQueue`
  );

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <Typography variant="h4">
              {t("title.service")} #{index + 1}
            </Typography>
          </Grid>
          <Grid>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete && onDelete(index);
              }}
            >
              <Icon name="Delete" />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <FormControlLabel label={t("label.serviceID")}>
          <OutlinedInput name={`${path}.serviceId`} formikControll />
        </FormControlLabel>
        <FormControlLabel label={t("label.serviceCategory")}>
          <SelectServiceCategory
            value={serviceCategory.value}
            onSelected={(c) => setServiceMaxQueue(c.serviceMaxQueue)}
            onChange={(e) => setServiceCategory(e.target.value)}
            fullWidth
          />
        </FormControlLabel>
        <FormControlLabel label={t("label.serviceMaxQueue")}>
          <OutlinedInput
            name={`${path}.serviceMaxQueue`}
            type="number"
            formikControll
          />
        </FormControlLabel>
        <FormControlLabel label={t("label.serviceName")}>
          <SelectServiceTemplates
            name={`${path}.templateId`}
            serviceCategoryId={serviceCategory && serviceCategory.value}
            filterOptions={(options) =>
              options.filter((o) =>
                serviceCategory
                  ? o.serviceCategoryId === serviceCategory.value
                  : true
              )
            }
            editMode
          />
        </FormControlLabel>
        <FormControlLabel
          label={t("label.availableForDelivery")}
          alignItems="center"
        >
          <Checkbox name={`${path}.isAvailableForDelivery`} formikControll />
        </FormControlLabel>
        <FormControlLabel label={t("label.siteName")}>
          <SelectSite name={`${path}.siteId`} />
        </FormControlLabel>
        <FormControlLabel label={t("label.globalCatalog")} alignItems="center">
          <Checkbox name={`${path}.globalCatalog`} formikControll />
        </FormControlLabel>
        <FormControlLabel label={t("label.enabled")} alignItems="center">
          <Switch name={`${path}.enabled`} formikControll />
        </FormControlLabel>
        <FormControlLabel label={t("label.description")}>
          <OutlinedInput
            name={`${path}.description`}
            formikControll
            multiline
            rows={4}
            fullWidth
          />
        </FormControlLabel>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(CreateServiceFrom);
