import { useState, useCallback, useEffect } from "react";
import {
  Section,
  Box,
  Button,
  LinearProgress,
  CardActionArea,
  Typography,
  Divider,
  FlipCard,
} from "~/components/UI";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CommonTileProps {
  title?: string | React.ReactNode;
  showAllText?: string;
  showAllLink?: string;
  hideShowAll?: boolean;
  children: [React.ReactNode, React.ReactNode];
  onUpdate?: () => Promise<any>;
  summaryComponent?: () => React.ReactElement;
}

const CommonTile = ({
  title,
  showAllText,
  showAllLink,
  hideShowAll,
  children,
  onUpdate,
}: CommonTileProps) => {
  const [flipped, setFlipped] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const showAll = useCallback(() => {
    if (!showAllLink) return;
    history.push(showAllLink);
  }, [history, showAllLink]);

  const refreshData = useCallback(async () => {
    if (onUpdate) {
      setLoading(true);
      try {
        await onUpdate();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [onUpdate]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <Section
      title={title}
      extra={
        <Button onClick={() => setFlipped((value) => !Boolean(value))}>
          {flipped ? t("button.hide") : t("button.showSummary")}
        </Button>
      }
    >
      <LinearProgress hidden={!loading} />
      <FlipCard
        isFlipped={flipped}
        flipDirection="vertical"
        flipSpeedBackToFront={0.3}
        flipSpeedFrontToBack={0.3}
      >
        {children}
      </FlipCard>

      {!flipped && !hideShowAll && (
        <>
          <Divider />
          <Box>
            {showAllText && (
              <CardActionArea onClick={showAll}>
                <Box textAlign="center" p={4} bgcolor="background.paper">
                  <Typography variant="subtitle1" color="textSecondary">
                    {showAllText}
                  </Typography>
                </Box>
              </CardActionArea>
            )}
          </Box>
        </>
      )}
    </Section>
  );
};

export default CommonTile;
