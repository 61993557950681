import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class BundlesApi {
  rootPath: string = "/bundles";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.bundles')
  }

  getAll = (limit: number = 10, page: number = 1, query?: Api.GetBundlesQuery ): Promise<Api.BundlesResponse> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  deleteBundles = (ids: string[]): Promise<Api.Response<{}>> =>
    this.instance.delete(this.rootPath, { params: { ids } })

  getBundle = (id: string): Promise<Api.BundleResponse> =>
    this.instance.get(`${this.rootPath}/${id}`)

  createBundle = (data: Api.CreateBundle): Promise<Api.GetOne<Api.BundleDto>> =>
    this.instance.post(this.rootPath, data)

  updateBundle = (id: string, data: Api.UpdateBundle): Promise<Api.GetOne<Api.BundleDto>> =>
    this.instance.put(`${this.rootPath}/${id}`, data)

  deleteBundle = (id: string): Promise<Api.Response<{}>> =>
    this.instance.delete(`${this.rootPath}/${id}`)

}
