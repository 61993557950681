import { useState, useCallback, useEffect } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Metrics from "./Components/Metrics";
import { RouteChildrenProps } from "react-router";
import Routes from "~/constants/routes";
import api from "~/api";

/**
 * @namespace Services
 */

const POSesPage = ({
  location,
  match,
}: RouteChildrenProps<{ serviceId: string }, { service?: Api.ServiceDto }>) => {
  const [service, setService] = useState<Api.ServiceDto>();
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.services"), href: Routes.SERVICES },
      {
        label: service
          ? service.serviceName
          : match
          ? match.params.serviceId
          : "",
        href: Routes.SERVICES + `?serviceId=${match && match.params.serviceId}`,
      },
      { label: t("title.metrics") },
    ];
  }, [t, service, match]);

  const getService = async (serviceId: string) => {
    try {
      const {
        data: { data },
      } = await api.services.getService(serviceId);
      setService(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setService(location.state.service);
    } else if (match) {
      getService(match.params.serviceId);
    }
  }, [location]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Metrics service={service} />
    </>
  );
};

export default POSesPage;
