import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Switch,
  Divider,
  DialogContent,
  DialogActions,
  Section,
  Typography,
  Label,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik, FieldArray } from "formik";
import api from "~/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Metrics from "./Metrics";
import Widgets from "./Widgets";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardSettings } from "~/store/settings/actions";
import { pick } from "lodash";
import { WIDGET_ENTITY } from "~/api/dashboardSettings/enums";
import CanIUse from "~/components/CanIUse";
import { PERMISSION, METRICS } from "~/api/permissions/enums";

interface FromData extends DashboardSettingsApi.Settings {
  alertPanelIsOpenedByDefault: boolean;
}

const getMetricsUpdatingData = (
  metrics?: DashboardSettingsApi.Metric[]
): DashboardSettingsApi.SetMetric[] =>
  metrics
    ? metrics.map((m) => pick(m, ["metricId", "position", "enabled"]))
    : [];

const getWidgetsUpdatingData = (
  widgets?: DashboardSettingsApi.Widget[]
): DashboardSettingsApi.SetWidget[] =>
  widgets
    ? widgets.map((w) => {
        if (!w.currentSortBy && !w.currentServices) {
          if (w.entity === WIDGET_ENTITY.PAYMENT_SERVICE) {
            w.currentServices = [];
          } else {
            w.currentSortBy = Array.isArray(w.sortBy) ? w.sortBy[0] : w.sortBy;
          }
        }

        return {
          ...pick(w, [
            "widgetId",
            "currentSortBy",
            "currentServices",
            "status",
          ]),
        };
      })
    : [];

const defaultValues = {
  metrics: [],
  widgets: [],
  alertPanelIsOpenedByDefault: false,
};

const DashboardSettingsDialog = ({
  onClose,
  ...props
}: Omit<DialogProps, "onClose"> & { onClose: () => void }) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const settings = useSelector(
    (store: ReduxStore.State) => store.settings.dashboardSettings
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ widgets, metrics, ...otherValues }: FromData) => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await api.dashboardSettings.updateSettings({
          ...otherValues,
          widgets: getWidgetsUpdatingData(widgets),
          metrics: getMetricsUpdatingData(metrics),
        });
        dispatch(setDashboardSettings(data));
        toast.success("New configuration has been saved!");
        onClose && onClose();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, dispatch]
  );

  useEffect(() => {
    if (settings) {
      const {
        metrics = [],
        widgets = [],
        alertPanelIsOpenedByDefault = false,
      } = settings;

      setInitialValues({
        alertPanelIsOpenedByDefault,
        metrics,
        widgets,
      });
    }
  }, [settings]);

  return (
    <Dialog
      {...props}
      onClose={onClose}
      title={t("title.dashboardSettings")}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            enableReinitialize: true,
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                <CanIUse permissions={METRICS}>
                  <Box pb={6}>
                    <Section title={t("title.dashboardMetrics")}>
                      <Typography variant="body1">
                        {t("text.select4metrics")}
                      </Typography>
                      <Box py={4} width={400}>
                        <Metrics
                          metrics={values.metrics}
                          onChange={(data) => setFieldValue("metrics", data)}
                        />
                      </Box>
                      <Divider />
                    </Section>
                  </Box>
                </CanIUse>
                <Box pb={6}>
                  <Section title={t("title.dashboardWidgets")}>
                    <Typography variant="body1">
                      {t("text.chooseTheWidgets")}
                    </Typography>
                    <Box py={4}>
                      <FieldArray
                        name="widgets"
                        render={(arrayHelpers) => (
                          <Widgets
                            widgets={values.widgets}
                            onChange={(index, value) =>
                              arrayHelpers.replace(index, value)
                            }
                          />
                        )}
                      />
                    </Box>
                    <Divider />
                  </Section>
                </Box>
                <CanIUse permissions={PERMISSION.READ_JOURNAL_INCIDENTS}>
                  <Section title={t("title.alertPanel")}>
                    <Label text={`${t("label.openedByDefault")}:`}>
                      <Switch
                        name="alertPanelIsOpenedByDefault"
                        formikControll
                      />
                    </Label>
                  </Section>
                </CanIUse>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.saveSettings")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={onClose}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default DashboardSettingsDialog;
