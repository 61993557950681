import QueuesCreatedCard from "~/components/Metrics/QueuesCreatedCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

interface QueuesCreatedProps {
  service: Api.ServiceDto;
}

const Queues = ({ service }: QueuesCreatedProps) => {
  const { t } = useTranslation();
  const getQueuesCreated = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getQueuesByService({
        serviceId: service.serviceId,
        siteId: service.siteId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getQueuesOnline = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getQueuesOnlineByService({
        serviceId: service.serviceId,
        siteId: service.siteId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <QueuesCreatedCard
      title={`${t("title.queuesCreatedBy")} ${t("text.service")}: ${
        service.serviceId
      }`}
      onUpdate={getQueuesCreated}
      onUpdateOnline={getQueuesOnline}
    />
  );
};

export default Queues;
