import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';

export default class ServicesCategoriesApi {
  rootPath: string = "/service-templates";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  addTemplate = (data: ServicesCategoriesApi.AddServicesCategory): Promise<Api.GetOne<ServiceTemplatesApi.ServiceTemplateDto>> =>
    this.instance.post(this.rootPath, data);

  updateTemplate = (templateId: string, data: ServicesCategoriesApi.UpdateServicesCategory): Promise<Api.GetOne<ServiceTemplatesApi.ServiceTemplateDto>> =>
    this.instance.put(`${this.rootPath}/${templateId}`, data);

  getServiceTemplates = (limit: number = 99, page: number = 1, query?: ServicesCategoriesApi.GetServicesCategoriesQuery): Promise<Api.GetAll<ServiceTemplatesApi.ServiceTemplateDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  deleteTemplate = (templateId: string): Promise<{}> =>
    this.instance.delete(`${this.rootPath}/${templateId}`);
}
