import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { REPORT_STATUS, REPORT_STATUS_I18n } from "~/api/zReportsJournal/enum";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface FilterProps {
  initialValues?: ZReportsJournalApi.GetReportsQuery;
  onChangeFilter: (
    data: ZReportsJournalApi.GetReportsQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer {...props}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={REPORT_STATUS}
            enumI18n={REPORT_STATUS_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
