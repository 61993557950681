import { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  Icon,
  IconButton,
  Collapse,
  makeStyles,
  createStyles,
} from "~/components/UI";

interface ExpandableTileProps {
  children?: React.ReactChild[];
  expanded?: boolean;
  onCollapse?: () => void;
  expandedContentRender?: React.ReactNode;
  onShowExpandedContent?: (open: boolean) => void;
}

const COLLAPSE_TIME = 100;

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      padding: 0,
      gridRowEnd: "span 1",
      "& .main-container": {
        height: "100%",
      },
      "&:hover": {
        boxShadow: theme.shadows[1],
        "& .main-container": {
          cursor: "pointer",
          backgroundColor: theme.palette.background.level1,
        },
      },
    },
    open: {
      gridRowEnd: "span 3",
      border: `1px solid ${theme.palette.divider}`,
      "& .main-container": {
        height: "auto",
        backgroundColor: theme.palette.background.level1,
      },
    },
    closeBtn: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: 0,
    },
    content: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  })
);

const ExpandableTile = ({
  children,
  expanded,
  onCollapse,
  expandedContentRender,
  onShowExpandedContent,
}: ExpandableTileProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(expanded || false);
  const [applyOpenCss, setApplyOpenCss] = useState<boolean | undefined>(false);

  useEffect(() => {
    onShowExpandedContent && onShowExpandedContent(Boolean(open));
    if (!open) {
      setTimeout(() => {
        setApplyOpenCss(open);
      }, COLLAPSE_TIME);
    } else {
      setApplyOpenCss(open);
    }
  }, [open, onShowExpandedContent]);

  return (
    <Card
      className={clsx(classes.root, {
        [classes.open]: applyOpenCss,
      })}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
      }}
    >
      <div className="main-container">
        {open && (
          <IconButton
            className={classes.closeBtn}
            onClick={(e) => {
              e.stopPropagation();
              onCollapse && expanded ? onCollapse() : setOpen(false);
            }}
          >
            <Icon name="MenuArrowLeft" />
          </IconButton>
        )}
        <Box className={classes.content}>{children}</Box>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Collapse in={open} timeout={COLLAPSE_TIME}>
          {expandedContentRender}
        </Collapse>
      </div>
    </Card>
  );
};

export default ExpandableTile;
