import { useState, useEffect } from "react";
import { ImageList, ImageListItem } from "~/components/UI";
import BusinessOperations from "./BusinessOperations";
import EventsLog from "./EventsLog";
import Incidents from "./Incidents";
import SystemLog from "./SystemLog";
import UserActivityLog from "./UserActivityLog";
import { ENV } from "~/constants";
import { WIDGET_ENTITY, JournalEntitys } from "~/api/dashboardSettings/enums";

const getWidgetComponent = (
  setting: DashboardSettingsApi.Widget
): (() => JSX.Element | null) => {
  switch (setting.entity) {
    case WIDGET_ENTITY.JOURNAL_BUSINESS_OPERATIONS:
      return BusinessOperations;
    case WIDGET_ENTITY.EVENT_LOG:
      return EventsLog;
    case WIDGET_ENTITY.JOURNAL_INCIDENTS:
      return Incidents;
    case WIDGET_ENTITY.JOURNAL_LOG_SYSTEM:
      return SystemLog;
    case WIDGET_ENTITY.JOURNAL_OF_USERS_ACTIONS:
      return UserActivityLog;
    default:
      return () => null;
  }
};

interface JournalsProps {
  settings?: DashboardSettingsApi.Widget[];
}

const Journals = ({ settings }: JournalsProps) => {
  const [components, setComponents] = useState<Array<() => JSX.Element | null>>(
    []
  );

  useEffect(() => {
    if (settings) {
      const components = settings
        .filter((s) => JournalEntitys.includes(s.entity) && s.status)
        .map((s) => getWidgetComponent(s))
        .splice(0, Number(ENV.REACT_APP_MAX_LIMIT_FOR_JOURNALS_ON_DASHBOARD));
      setComponents(components);
    }
  }, [settings]);

  return (
    <ImageList cols={4} gap={20} rowHeight="auto">
      {components &&
        components.map((WidgetComponent, index) => (
          <ImageListItem key={index} cols={4}>
            <WidgetComponent />
          </ImageListItem>
        ))}
    </ImageList>
  );
};

export default Journals;
