import { PERMISSION } from "~/api/permissions/enums";
import { SOURCE_TYPE } from "~/api/common";

type Entitie = {
  i18nKey?: string;
  read?: string | null;
  create?: string | null;
  edit?: string | null;
  delete?: string | null;
  items?: Entitie[];
};

export const Entities: Entitie[] = [
  {
    i18nKey: "title.catalogs",
    items: [
      {
        i18nKey: "title.sites",
        read: PERMISSION.READ_SITE,
        create: PERMISSION.CREATE_SITE,
        edit: PERMISSION.EDIT_SITE,
        delete: PERMISSION.DELETE_SITE,
      },
      {
        i18nKey: "POSes",
        read: PERMISSION.READ_POS,
        create: PERMISSION.CREATE_POS,
        edit: PERMISSION.EDIT_POS,
        delete: PERMISSION.DELETE_POS,
      },
      {
        i18nKey: "title.services",
        read: PERMISSION.READ_SERVICE,
        create: PERMISSION.CREATE_SERVICE,
        edit: PERMISSION.EDIT_SERVICE,
        delete: PERMISSION.DELETE_SERVICE,
      },
      {
        i18nKey: "title.paymentServices",
        read: PERMISSION.READ_PAYMENT_SERVICE,
        create: PERMISSION.CREATE_PAYMENT_SERVICE,
        edit: PERMISSION.EDIT_PAYMENT_SERVICE,
        delete: PERMISSION.DELETE_PAYMENT_SERVICE,
      },
      {
        i18nKey: "title.keyManagment",
        read: PERMISSION.READ_KEY_MANAGEMENT,
        create: PERMISSION.CREATE_KEY_MANAGEMENT,
        edit: null,
        delete: PERMISSION.DELETE_KEY_MANAGEMENT,
      },
      {
        i18nKey: "title.roles",
        read: PERMISSION.READ_ROLE,
        create: PERMISSION.CREATE_ROLE,
        edit: PERMISSION.EDIT_ROLE,
        delete: PERMISSION.DELETE_ROLE,
      },
      {
        i18nKey: "title.users",
        read: PERMISSION.READ_USER,
        create: PERMISSION.CREATE_USER,
        edit: PERMISSION.EDIT_USER,
        delete: PERMISSION.DELETE_USER,
      },
      {
        i18nKey: "title.groups",
        read: PERMISSION.READ_GROUP,
        create: PERMISSION.CREATE_GROUP,
        edit: PERMISSION.EDIT_GROUP,
        delete: PERMISSION.DELETE_GROUP,
      },
      {
        i18nKey: "title.bundles",
        read: PERMISSION.READ_BUNDLE,
        create: PERMISSION.CREATE_BUNDLE,
        edit: PERMISSION.EDIT_BUNDLE,
        delete: PERMISSION.DELETE_BUNDLE,
      },
    ],
  },
  {
    i18nKey: "title.journals",
    items: [
      {
        i18nKey: "title.businessOperations",
        read: PERMISSION.READ_JOURNAL_BUSINESS_OPERATIONS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.eventsLog",
        read: PERMISSION.READ_EVENT_LOG,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.incidents",
        read: PERMISSION.READ_JOURNAL_INCIDENTS,
        create: PERMISSION.CREATE_JOURNAL_INCIDENTS,
        edit: PERMISSION.EDIT_JOURNAL_INCIDENTS,
        delete: null,
      },
      {
        i18nKey: "title.systemLog",
        read: PERMISSION.READ_JOURNAL_LOG_SYSTEM,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.userActivityLog",
        read: PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.auditZReportLog",
        read: PERMISSION.READ_Z_REPORT,
        create: null,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    i18nKey: "title.metrics",
    items: [
      {
        i18nKey: "title.money",
        read: PERMISSION.READ_MONEY_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.clients",
        read: PERMISSION.READ_CLIENTS_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.businessTransactions",
        read: PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.queues",
        read: PERMISSION.READ_QUEUES_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.invoicingDuration",
        read: PERMISSION.READ_INVOICING_DURATION_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.financialTransactionCounts",
        read: PERMISSION.READ_FINANCIAL_TRANSACTIONS_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.clientPaymentDuration",
        read: PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.PSPPaymentDuration",
        read: PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    i18nKey: "title.systemSettings",
    items: [{
        i18nKey: "title.companyProfile",
        read: PERMISSION.READ_SYSTEM_SETTINGS,
        create: PERMISSION.CREATE_SYSTEM_SETTINGS,
        edit: PERMISSION.EDIT_SYSTEM_SETTINGS,
        delete: PERMISSION.DELETE_SYSTEM_SETTINGS,
      }, {
        i18nKey: "title.prefixes",
        read: PERMISSION.READ_ID_PREFIX,
        create: PERMISSION.CREATE_ID_PREFIX,
        edit: PERMISSION.EDIT_ID_PREFIX,
        delete: PERMISSION.DELETE_ID_PREFIX,
      }
    ]
  },
];

function generateDependencies(entities: Entitie[]) {
  const res: { [key: string]: string } = {};

  function iterator(item: Entitie) {
    if (typeof item.read === "string") {
      if (item.create) res[item.create] = item.read;
      if (item.edit) res[item.edit] = item.read;
      if (item.delete) res[item.delete] = item.read;
    }

    if (item.items) {
      item.items.forEach(iterator);
    }
  }

  entities.forEach(iterator);

  return res;
}

export const DependenciesForPermissions = generateDependencies(Entities);

export const Processes = [
  {
    i18nKey: "title.businessOperationsIncidents",
    execute: PERMISSION.BUSINESS_OPERATIONS_INCIDENTS,
  },
];

export const PERMISSION_FOR_RESOURCE = {
  [SOURCE_TYPE.BUSINESS_OPERATION]: PERMISSION.READ_JOURNAL_BUSINESS_OPERATIONS,
  [SOURCE_TYPE.EVENT_LOG]: PERMISSION.READ_EVENT_LOG,
  [SOURCE_TYPE.SITE]: PERMISSION.READ_SITE,
  [SOURCE_TYPE.POS]: PERMISSION.READ_POS,
  [SOURCE_TYPE.SERVICE]: PERMISSION.READ_SERVICE,
  [SOURCE_TYPE.PAYMENT_SERVICE]: PERMISSION.READ_PAYMENT_SERVICE,
  [SOURCE_TYPE.USER]: PERMISSION.READ_USER,
  [SOURCE_TYPE.GROUP]: PERMISSION.READ_GROUP,
  [SOURCE_TYPE.BUNDLE]: PERMISSION.READ_BUNDLE,
};
