import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  Box,
  Switch,
  Divider,
  LinearProgress,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import CommonTile from "../CommonTile";
import Summary from "./Summary";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import Routes from "~/constants/routes";
import api from "~/api";

interface PaymentServicesProps {
  setting: DashboardSettingsApi.Widget;
}

const PaymentService = ({
  initialState,
  paymentType,
}: {
  initialState?: DashboardCatalogsAPI.PaymentServiceDto;
  paymentType: PaymentServicesApi.ePaymentServiceType;
}) => {
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { hasAll } = usePermissions();

  const onChange = async (
    serviceType: PaymentServicesApi.ePaymentServiceType,
    checked: boolean
  ) => {
    setLoading(true);
    try {
      await api.paymentServices.updateByPaymentServiceType(
        serviceType,
        checked
      );
      setEnabled(checked);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialState) {
      setEnabled(initialState.enabled);
    }
  }, [initialState]);

  return (
    <>
      <Box p={4}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1">{paymentType}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              {/* <Grid item>{loading && <CircularProgress size={14} />}</Grid> */}
              <Grid item>
                <ConfirmDialog
                  placement="top"
                  disabled={!hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)}
                  onConfirm={() => onChange(paymentType, !enabled)}
                >
                  <Switch
                    checked={enabled}
                    disabled={
                      !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE) || loading
                    }
                  />
                </ConfirmDialog>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};

const PaymentServices = ({ setting }: PaymentServicesProps) => {
  const [initialStates, setInitialStates] =
    useState<DashboardCatalogsAPI.PaymentServiceDto[]>();
  const [loading, setLoading] = useState(false);
  const [totalCount] = useState<number | undefined>(
    setting.currentServices ? setting.currentServices.length : 0
  );
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const getInitialState = async () => {
    setLoading(true);
    try {
      const { data } = await api.dashboardCatalogs.getPaymentServices();
      setInitialStates(data.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialState();
  }, []);

  if (!hasAll(PERMISSION.READ_PAYMENT_SERVICE)) return null;

  return (
    <CommonTile
      title={
        <Typography variant="h4">
          {t("title.paymentServices")}{" "}
          <Typography variant="h4" component="span" color="textSecondary">
            {totalCount}
          </Typography>
        </Typography>
      }
      showAllLink={Routes.PAYMENT_SERVICES}
      showAllText={`${t("button.showAll")} ${t("title.paymentServices")}`}
    >
      <div style={{ minHeight: 400, backgroundColor: "white" }}>
        <LinearProgress hidden={!loading} />
        {setting.currentServices && !loading
          ? setting.currentServices.map((payment, index) => (
              <div key={index}>
                <PaymentService
                  paymentType={
                    payment as PaymentServicesApi.ePaymentServiceType
                  }
                  initialState={
                    initialStates
                      ? initialStates.find(
                          (s) => s.paymentServiceType === payment
                        )
                      : undefined
                  }
                />
              </div>
            ))
          : null}
      </div>
      <Summary />
    </CommonTile>
  );
};

export default PaymentServices;
