import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Typography,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { formatDate } from "~/utils/format";
import { KEY_PURPOSE_I18n } from "~/api/keyManagement/enums";
import api from "~/api";

interface EditKeyDialogProps extends Omit<DialogProps, "onClose"> {
  keyData?: KeyManagementApi.KeyDto;
  onClose?: (key?: KeyManagementApi.KeyDto) => void;
}

/**
 * @memberof KeyManagementPage
 * @component
 * @desc Dialog for updating a Key.
 * @property {Function} onClose - passes key data if the key was updated. (key?: KeyManagementApi.KeyDto) => void;
 */

const EditKeyDialog = ({ open, keyData, onClose }: EditKeyDialogProps) => {
  const [initialValues, setInitialValues] =
    useState<KeyManagementApi.UpdateKey>({ name: "" });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["translation", "enums"]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
  });

  const onSubmit = useCallback(
    async (formData) => {
      if (!keyData) return;
      setLoading(true);
      try {
        const { data } = await api.keyManagement.updateKey(
          keyData.keyId,
          formData
        );
        onClose && onClose(data.data);
      } finally {
        setLoading(false);
      }
    },
    [keyData, onClose]
  );

  useEffect(() => {
    if (keyData) {
      setInitialValues({ name: keyData.name });
    }
  }, [keyData]);

  return (
    <Dialog
      open={open}
      title={t("title.editKey")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label="ID">{keyData?.keyId}</FormControlLabel>
                <FormControlLabel label={t("label.keyName")}>
                  <OutlinedInput name="name" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.keyPurpose")}>
                  <Typography>
                    {keyData && t(`enums:${KEY_PURPOSE_I18n[keyData.purpose]}`)}
                  </Typography>
                </FormControlLabel>
                <FormControlLabel label={t("label.keySize")}>
                  {keyData && keyData.size}
                </FormControlLabel>
                <FormControlLabel label={t("label.algorytm")}>
                  {keyData && keyData.algorithm}
                </FormControlLabel>
                <FormControlLabel label={t("label.isWrapped")}>
                  <Typography>
                    {keyData
                      ? keyData.isWrapped
                        ? t("text.yes")
                        : t("text.no")
                      : null}
                  </Typography>
                </FormControlLabel>
                <FormControlLabel label={t("label.expirationDate")}>
                  {keyData && formatDate(keyData.expirationDate)}
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {t("button.save")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditKeyDialog;
