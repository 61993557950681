import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CommonTile from "./CommonTile";
import { timeTickFormatter } from "~/components/Metrics/Charts/Common";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const PSPPaymentDurationTile = () => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const onUpdate = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await api.dashboardMetrics.getPSPPaymentDurationMetric();
      return data;
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (!hasAll(PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS)) return null;

  return (
    <CommonTile
      label={t("title.PSPPaymentDuration")}
      trendDescription={t("text.last7days")}
      formattingValue={timeTickFormatter}
      onUpdate={onUpdate}
    />
  );
};

export default PSPPaymentDurationTile;
