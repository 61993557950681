import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class IncidentsApi {
  rootPath: string = "/journal-incidents";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.incidents');
  }

  getIncidents = (limit: number = 10, page: number = 1, query?: IncidentsApi.GetIncidentsQuery ): Promise<Api.GetAll<IncidentsApi.IncidentDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) }  })

  createIncident = (formData: IncidentsApi.CreateIncident): Promise<Api.GetOne<IncidentsApi.IncidentDto>> =>
    this.instance.post(this.rootPath, formData)

  closeIncident = (incidentId: string, formData: IncidentsApi.CloseIncident): Promise<Api.GetOne<IncidentsApi.IncidentDto>> =>
    this.instance.put(`${this.rootPath}/${incidentId}`, formData)

  getIncident = (incidentId: string): Promise<Api.GetOne<IncidentsApi.IncidentDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${incidentId}`)
}
