import { useState, useEffect } from "react";
import { Box, Grid, Typography, LinearProgress, Table } from "~/components/UI";
import TabContent from "./TabContent";
import {
  formatDateWithTime,
  currencyString,
  quantityString,
} from "~/utils/format";
import { i18nTableColumn } from "~/packages/i18n";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface InvoiceTabProps {
  invoiceId: string;
}

const defaultColumns = [
  {
    key: "vendorCode",
    i18nKey: "vendorCode",
  },
  {
    key: "name",
    i18nKey: "name",
  },
  {
    key: "quantity",
    i18nKey: "quantity",
  },
  {
    key: "price",
    i18nKey: "price",
    render: (price?: number) => currencyString(price),
  },
  {
    key: "discount",
    i18nKey: "discount",
    render: (discount?: number) => currencyString(discount),
  },
  {
    key: "amount",
    i18nKey: "amount",
    render: (amount?: number) => currencyString(amount),
  },
];

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render information about Invoice.
 * @property {string} invoiceId invoice id
 */

const InvoiceTab = ({ invoiceId }: InvoiceTabProps) => {
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] =
    useState<JournalBusinessOperationsApi.InvoiceOperationDetailsDto>();
  const { t } = useTranslation();

  async function getInvoice(invoiceId: string) {
    setLoading(true);
    try {
      const { data } = await api.businessOperationsJournal.getInvoice(
        invoiceId
      );
      setInvoiceData(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "quantity":
        return {
          ...column,
          render: (
            quantity: number,
            good: JournalBusinessOperationsApi.GoodOrderDto
          ) => (good.quantity ? quantityString(quantity, good.unit, t) : "0"),
        };
      default:
        return column;
    }
  });

  useEffect(() => {
    getInvoice(invoiceId);
  }, [invoiceId]);

  if (loading) {
    return <LinearProgress />;
  } else if (invoiceData) {
    return (
      <TabContent>
        <Box flex={1} overflow="auto">
          <Table
            columns={columns}
            dataSource={invoiceData.goods}
            size="small"
            rowKey="vendorCode"
          />
        </Box>
        <Box pt={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">
                {t("label.invoicingStatus")}: {invoiceData.status}
              </Typography>
              <Typography variant="body1">
                {t("label.time")}: {formatDateWithTime(invoiceData.createdAt)}
              </Typography>
              <Typography variant="body1">
                {t("label.reason")}: {invoiceData.reason}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {t("label.invoiceID")}: {invoiceData.invoiceId}
              </Typography>
              <Typography variant="body1">
                {t("label.totalDiscount")}:{" "}
                {currencyString(invoiceData.totalDiscount)}
              </Typography>
              <Typography variant="body1">
                {t("label.totalAmount")}:{" "}
                {currencyString(invoiceData.totalAmount)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </TabContent>
    );
  } else {
    return null;
  }
};

export default InvoiceTab;
