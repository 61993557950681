import { useState, useCallback } from "react";
import api from "~/api";
import {
  Box,
  Button,
  Icon,
  Divider,
  Section,
  ButtonGroup,
  Label,
  Grid,
  Switch,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import AllIncidentsButton from "~/components/Incidents/AllIncidentsButton";
import usePaymentServiceDialog from "~/components/PaymentService/Dialogs/usePaymentServiceDialogs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PERMISSION } from "~/api/permissions/enums";
import { useHistory, generatePath } from "react-router";
import ROUTES from "~/constants/routes";
import CanIUse from "~/components/CanIUse";
import usePermissions from "~/hook/usePermissions";
import GoToRelatedProcessesButton from "~/components/GoToRelatedProcessesButton";
// import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";

interface PaymentServiceDetailsProps {
  paymentService?: PaymentServicesApi.PaymentServiceDetailsDto;
  onUpdate?: (
    pymentService: PaymentServicesApi.PaymentServiceDetailsDto
  ) => void;
}

/**
 * @memberof PaymentService
 * @component
 * @desc Render detailed information of a Payment Service.
 * @property {PaymentServicesApi.PaymentServiceDetailsDto} paymentService - Payment Service data
 */

const PaymentServiceDetails = ({
  paymentService,
  onUpdate,
}: PaymentServiceDetailsProps) => {
  const [enabled, setEnabled] = useState(
    paymentService ? paymentService.enabled : false
  );
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const { updatePaymentService } = usePaymentServiceDialog();
  const history = useHistory();

  const onEdit = useCallback(() => {
    paymentService &&
      updatePaymentService(paymentService as any, {
        onClose: (service) => onUpdate && onUpdate(service as any),
      });
  }, [paymentService, onUpdate]);

  const onChangeEnable = useCallback(
    async (enabled: boolean) => {
      if (!paymentService) return;
      setEnabled(enabled);
      try {
        const {
          data: { data },
        } = await api.paymentServices.updatePaymentService(
          paymentService.paymentId,
          {
            enabled,
          } as PaymentServicesApi.UpdatePaymentService
        );
        toast.success(
          `${paymentService.paymentServiceType} ${
            data.enabled ? t("text.active") : t("text.disabled")
          }`
        );
        setEnabled(data.enabled);
        onUpdate && onUpdate({ ...paymentService, ...data });
      } catch (e) {
        console.error(e);
      }
    },
    [paymentService, onUpdate]
  );

  const goToMetrics = useCallback(() => {
    if (!paymentService) return;
    history.push(
      generatePath(ROUTES.PAYMENT_SERVICE_METRICS, {
        paymentId: paymentService.paymentId,
      }),
      {
        paymentService,
      }
    );
  }, [history, paymentService]);

  if (!paymentService) return null;

  return (
    <Box my={4}>
      <Section
        title={`${paymentService.paymentServiceType} ${t("title.details")}`}
        extra={
          <CanIUse permissions={PERMISSION.EDIT_PAYMENT_SERVICE}>
            <Button
              prefixIcon={<Icon name="Pen" />}
              variant="contained"
              onClick={onEdit}
            >
              {t("button.edit")}
            </Button>
          </CanIUse>
        }
      >
        <Box bgcolor="background.paper">
          <Box p={6}>
            <Grid container spacing={10}>
              <Grid item sm={4}>
                <Label text="ID">{paymentService.paymentId}</Label>
                <Label text={t("label.name")}>
                  {paymentService.paymentServiceType}
                </Label>
              </Grid>
              <Grid item sm={4}>
                <Label text={t("label.signKeyName")}>
                  {/* <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={paymentService.signKeyId} /> */}
                  {paymentService.signKeyId}
                </Label>
                <Label text={t("label.verifyKeyName")}>
                  {/* <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={paymentService.verifyKeyId} /> */}
                  {paymentService.verifyKeyId}
                </Label>
                <Label text="Merchant ID">{paymentService.merchantId}</Label>
                <Label text={t("label.terminalId")}>
                  {paymentService.terminalId}
                </Label>
              </Grid>
              <Grid item sm={4}>
                <Label text={t("label.enabled")}>
                <ConfirmDialog
                  placement="left"
                  disabled={!hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)}
                  onConfirm={() => onChangeEnable(!enabled)}
                >
                  <Switch
                    checked={enabled}
                    disabled={!hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)}
                  />
                  </ConfirmDialog>
                </Label>
                <Label text={t("label.linkedPOSes")}>
                  {paymentService.linkedPosesCount}
                </Label>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p={4}>
            <ButtonGroup>
              <AllIncidentsButton variant="outlined" />
              <GoToRelatedProcessesButton
                sourceId={paymentService.paymentId}
                sourceType={SOURCE_TYPE.PAYMENT_SERVICE}
                variant="outlined"
              />
              <Button variant="outlined" onClick={goToMetrics}>
                {t("button.metrics")}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Section>
    </Box>
  );
};

export default PaymentServiceDetails;
