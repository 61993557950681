import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface SpinProps {
  spinning?: boolean;
  children?: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      display: "flex-inline",
      alignContent: "center",
    },
    spinning: {
      animation: `$spin 1000ms linear`,
      animationIterationCount: "infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "25%": {
        transform: "rotate(90deg)",
      },
      "50%": {
        transform: "rotate(180deg)",
      },
      "75%": {
        transform: "rotate(270deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  })
);

const Spin = ({ spinning = true, children }: SpinProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.spinning]: spinning,
      })}
    >
      {children}
    </div>
  );
};

export default Spin;
