import { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Table,
  Switch,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import Routes from "~/constants/routes";
import { toast } from "react-toastify";
import api from "~/api";
import { useTranslation } from "react-i18next";
import { updateArrayItem } from "~/utils/helpers";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "posId",
    title: "POS ID",
    render: (id: string) => (
      <Typography variant="body1">
        <Link to={`${Routes.POSES}?posId=${id}`}>{id}</Link>
      </Typography>
    ),
  },
  {
    key: "orders",
    i18nKey: "currentOrders",
    render: (orders: {}[]) => (orders && orders.length ? orders.length : 0),
  },
  {
    key: "enabled",
    sort: true,
    i18nKey: "enabled",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: Api.Incidents) => (
      <IncidentsIcon incidents={incidents} size="small" />
    ),
  },
];

/**
 * @memberof Sites
 * @component
 * @desc Render information about available POSes.
 * @property {string} siteId Site id
 * @property {"default" | "small"} size table size
 */

interface DetailsOfPOSesProps {
  siteId: string;
  enabled?: boolean;
  size?: "default" | "small";
}

const DetailsOfPOSes = ({ siteId, enabled, size = "default" }: DetailsOfPOSesProps) => {
  const [loading, setLoading] = useState(false);
  const [poses, setPOSes] = useState<Api.PosDto[]>();
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdatePOS) => {
      try {
        const {
          data: { data: pos },
        } = await api.poses.updatePos(id, formData);
        setPOSes((poses) => updateArrayItem(poses, pos, "posId"));
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t]
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, pos: Api.PosDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_POS)}
              onConfirm={() => onUpdate(pos.posId, { enabled: !enabled })}
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_POS)}
                size="small"
              />
            </ConfirmDialog>
          ),
        };
      default:
        return column;
    }
  });

  async function getPOSes(siteId: string) {
    setLoading(true);
    try {
      const { data } = await api.sites.getPoses(siteId);
      setPOSes(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPOSes(siteId);
  }, [siteId, enabled]);

  if (loading) {
    return <LinearProgress />;
  } else if (poses) {
    return (
      <Box px={3}>
        <Grid container>
          <Grid item sm={size !== "small" ? 6 : 12}>
            <Table
              columns={columns}
              dataSource={poses}
              size="small"
              rowKey="posId"
            />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

export default DetailsOfPOSes;
