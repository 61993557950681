import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";
import AuditZReportLog from "./AuditZReportLog";

/**
 * @namespace AuditZReportLog
 */

const AuditZReportLogPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.journals") },
      { label: t("title.auditZReportLog") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <AuditZReportLog />
    </>
  );
};

export default AuditZReportLogPage;
