import { Grid, Typography, Box } from "~/components/UI";
import CommonCard from "./CommonCard";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import Skeleton from "@material-ui/lab/Skeleton";

const CardSkeleton = () => (
  <CommonCard>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Skeleton>
          <Typography variant="body1" color="textSecondary">
            {" "}
            test id: 999999
          </Typography>
        </Skeleton>
      </Grid>
      <Grid item>
        <Skeleton>
          <IncidentsIcon incidents={{ count: 10, incidentIds: [] }} />
        </Skeleton>
      </Grid>
    </Grid>
    <Box mb={1}>
      <Skeleton>
        <Typography variant="body1">test nema</Typography>
      </Skeleton>
    </Box>
    <Box mb={1}>
      <Skeleton>
        <Typography variant="subtitle1">test description</Typography>
      </Skeleton>
    </Box>
  </CommonCard>
);

const Catalog = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <CardSkeleton />
    </Grid>
    <Grid item>
      <CardSkeleton />
    </Grid>
  </Grid>
);

export default Catalog;
