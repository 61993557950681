import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Section,
  Label,
  Divider,
  Button,
  OutlinedInput,
  ButtonGroup,
} from "~/components/UI";
import CanIUse from "~/components/CanIUse";
import UploadLogo from "./UploadLogo";
import yup from "~/packages/yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import api from "~/api";
import { getFormData } from "~/utils/helpers";
import { omit } from "lodash";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";

interface SystemSettingsFormProps {
  systemSettings?: SystemSettingsApi.Settings;
  onChange?: (systemSettings: SystemSettingsApi.Settings) => void;
}

const SystemSettingsForm = ({
  systemSettings,
  onChange,
}: SystemSettingsFormProps) => {
  const [initialValues, setInitialValues] = useState({
    companyName: systemSettings ? systemSettings.companyName : "",
    logo: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(),
  });

  const onSubmit = useCallback(
    async (form) => {
       const regex = /^\[a-zA-Z0-9\]{1,200}\\.\[a-zA-Z0-9\]{1,10}$/;

      if (form?.logo?.size > 1048576 || form?.logo?.size < 10000) {
        setError(t("text.sizeError"));
        return;
      }
      if (
        form?.logo.name.match(regex) ||
        !(
          form?.logo?.type === "image/png" ||
          form?.logo?.type === "image/jpeg" ||
          form?.logo?.type === "image/jpg"
        )
      ) {
        setError(t("text.fileTypeError"));
        return;
      }
      setLoading(true);
      const formData = getFormData(
        typeof form.logo === "object" && form.logo !== null
          ? form
          : omit(form, ["logo"])
      );

      if (form.logo === null) {
        await api.systemSettings.deleteLogo();
      }

      try {
        const {
          data: { data: settings },
        } = await api.systemSettings.updateSettings(formData);

        if (onChange) {
          if (form.logo) {
            const reader = new FileReader();
            reader.readAsDataURL(form.logo);
            reader.onload = function () {
              onChange({ ...settings, logoUrl: reader.result as string });
            };
          } else {
            onChange(settings);
          }
        }
      } catch (e: any) {
        console.error(e);
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (systemSettings) {
      setInitialValues({
        companyName: systemSettings.companyName,
        logo: undefined,
      });
    }
  }, [systemSettings]);

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
      }}
    >
      {({ values, handleSubmit, resetForm, setFieldValue, dirty, isValid }) => {
        return (
          <Box
            p={4}
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            bgcolor="background.paper"
          >
            <Section title={t("title.systemSettings")} />
            <Divider />
            <Box maxWidth={450} flexGrow={1}>
              <UploadLogo
                src={systemSettings ? systemSettings.logoUrl : ""}
                file={values.logo}
                onChange={(file) => setFieldValue("logo", file)}
              />
              <Divider />
              <Box my={2}>
                <Label text={t("label.companyName")}>
                  <OutlinedInput
                    name="companyName"
                    disabled={!hasAll(PERMISSION.EDIT_SYSTEM_SETTINGS)}
                    formikControll
                    fullWidth
                  />
                </Label>
              </Box>
            </Box>
            {dirty && (
              <CanIUse permissions={PERMISSION.EDIT_SYSTEM_SETTINGS}>
                <>
                  <Divider />
                  <Box pt={4}>
                    <ButtonGroup>
                      <Button
                        color="primary"
                        variant="contained"
                        loading={loading}
                        disabled={!isValid}
                        onClick={() => handleSubmit()}
                      >
                        {t("button.save")}
                      </Button>
                      <Button
                        variant="contained"
                        disabled={loading}
                        onClick={resetForm}
                      >
                        {t("button.cancel")}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </>
              </CanIUse>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export default SystemSettingsForm;
