import React, { useState, useEffect, useCallback } from "react";
import {
  Divider,
  Typography,
  LinearProgress,
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
} from "~/components/UI";
import ListLayoutSwitcher, {
  LayoutType,
  eLayoutType,
} from "~/components/ListLayoutSwitcher";
import Filter from "./Filter";
import Table from "./Table";
import Tiles from "./Tiles";
import CreateServicesDialog from "./CreateServicesDialog/CreateServicesDialog";
import EditServiceDialog from "./EditServiceDialog";
import GoodsDialog from "./GoodsDialog";
import CreateQRDialog from './CreateQRDialog';
import { toast } from "react-toastify";
import { useQueryParams, StringParam } from "use-query-params";
import ServicesContext from "./servicesContext";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { Link } from "react-router-dom";
import ROUTES from "~/constants/routes";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

interface ListProps {
  type: LayoutType;
  data?: Api.ServiceDto[];
}

const List = React.memo(({ type, data }: ListProps) => {
  return type === eLayoutType.TABLE ? (
    <Table dataSource={data} />
  ) : (
    <Tiles list={data} />
  );
});

/**
 * @memberof Services
 * @component
 * @desc Services Controller.
 * @requires ServicesContext
 */

const ServicesList = () => {
  const [layoutType, setLayoutType] = useState(eLayoutType.TABLE);
  const [services, setServices] = useState<Api.ServiceDto[]>();
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [query, setQuery] = useState<Api.GetServicesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [openCreateServicesDialog, setCreateServicesDialog] = useState<
    boolean | undefined
  >();
  const [openGoodsDialog, setOpenGoodsDialog] = useState<boolean | undefined>();
  const [siteId, setSiteId] = useState<string>();
  const [editService, setEditService] = useState<Api.ServiceDto>();
  const [qrCodeServiceData, setQRCodeServiceData] = useState<Api.ServiceDto>();
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    serviceId: StringParam,
    siteId: StringParam,
  });
  const { t } = useTranslation();

  const getServices = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      try {
        setLoading(true);
        const { data } = await api.services.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setServices(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCreateService = () => {
    setCreateServicesDialog(true);
  };

  const onCloseEditServicesDialog = useCallback(
    (result?: boolean) => {
      setCreateServicesDialog(false);
      setEditService(undefined);
      result && pagination && getServices(pagination.page);
    },
    [pagination]
  );

  const onUpdateItem = useCallback((service: Api.ServiceDto) => {
    setServices((services) =>
      services ? updateArrayItem(services, service, "id") : []
    );
  }, []);

  const onUpdate = useCallback(
    async (serviceId: string, siteId: string, formData: Api.UpdatePOS) => {
      try {
        const {
          data: { data: service },
        } = await api.services.updateService(serviceId, siteId, formData);
        onUpdateItem(service);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [services]
  );

  const onDelete = useCallback(
    async (service: Api.ServiceDto) => {
      await deleteConfirm(service?.serviceName);
      await api.services.deleteService(service.serviceId, service.siteId);
      getServices();
      toast.success(`${service.serviceName} ${t("text.deleted")}`);
    },
    [t]
  );

  const onDeleteServices = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedServices?.length }));
    try {
      await api.services.deleteServices(selectedServices);
      getServices();
      toast.success(t("text.recordsSuccessfullyDeleted"));
      setSelectedServices([]);
    } catch (e) {
      console.error(e);
    }
  }, [t, selectedServices]);

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = React.useMemo(
    () => ({
      onSort,
      onSelect: setSelectedServices,
      onUpdateItem,
      onUpdate,
      onDelete,
      onCreateQRCode: setQRCodeServiceData,
      onEdit: setEditService,
      showGoodsDialog: (siteId?: string) => {
        setSiteId(siteId);
        setOpenGoodsDialog(true);
      },
    }),
    [services]
  );

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getServices(page);
  };

  useEffect(() => {
    setQuery(queryParams as Api.GetServicesQuery);
  }, [queryParams]);

  useEffect(() => {
    setSelectedServices([]);
  }, [layoutType]);

  useEffect(() => {
    query && getServices();
  }, [query, limit]);

  return (
    <ServicesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.services")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <Link to={ROUTES.BUNDLES}>
                <Button variant="contained">
                  {t("button.bundles")}
                </Button>
              </Link>
              <Link to={ROUTES.SERVICES_CATEGORIES}>
                <Button prefixIcon={<Icon name="Layers" />} variant="contained">
                  {t("button.serviceCategories")}
                </Button>
              </Link>
              <CanIUse permissions={PERMISSION.DELETE_SERVICE}>
                <Button
                  hidden={!selectedServices.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteServices}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_SERVICE}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateService}
                >
                  {t("button.createNew")}
                </Button>
              </CanIUse>
              <ListLayoutSwitcher
                defaultValue={layoutType}
                onChange={setLayoutType}
              />
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <List type={layoutType} data={services} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CanIUse permissions={PERMISSION.CREATE_SERVICE}>
        <CreateServicesDialog
          open={openCreateServicesDialog}
          onClose={onCloseEditServicesDialog}
        />
      </CanIUse>
      <EditServiceDialog
        open={!!editService}
        service={editService}
        onClose={onCloseEditServicesDialog}
      />
      <GoodsDialog
        open={openGoodsDialog}
        siteId={siteId}
        onClose={() => setOpenGoodsDialog(false)}
      />
      <CreateQRDialog
        open={!!qrCodeServiceData}
        service={qrCodeServiceData}
        onClose={() => setQRCodeServiceData(undefined)}
      />
    </ServicesContext.Provider>
  );
};

export default ServicesList;
