import React from "react";
import MUISelect, {
  SelectProps as MUISelectProps
} from "@material-ui/core/Select";
import MUIMenuItem from "@material-ui/core/MenuItem";
import Icon from "./Icon";
import { useField } from "formik";
import FieldError from "./Form/FieldError";
import { withStyles, createStyles } from "@material-ui/core/styles";
import _ from 'lodash';

export interface OptionProps {
  label?: string;
  value: any;
}

type tSortOptions = 'asc' | 'desc'; 

export interface SelectProps extends MUISelectProps {
  sortOptions?: tSortOptions;
  options?: OptionProps[];
  formikControll?: boolean;
}

const MenuItem = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "block",
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      textOverflow: "ellipsis",
      "&::first-letter": {
        textTransform: "uppercase"
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main
      }
    },
    selected: {
      color: theme.palette.primary.main
    }
  })
)(MUIMenuItem);

const StyledSelect = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      minWidth: 70,
      height: 35,
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      padding: "10px 10px 10px 20px",
      boxSizing: "border-box",
      "& fieldset": {
        border: "none !important"
      },
      "&:focus": {
        backgroundColor: "transparent"
      }
    },
    outlined: {
      fontSize: theme.typography.fontSize,
      borderRadius: theme.shape.borderRadius,
      minWidth: 205,
      "&::first-letter": {
        textTransform: "uppercase"
      },
      "& fieldset": {
        borderColor: theme.palette.divider,
        borderWidth: "1px !important",
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.shadows[0]
      },
      "&:hover fieldset": {
        borderColor: `${theme.palette.action.hover}`
      }
    },
    icon: {
      fontSize: 10,
      top: "calc(50% - 3px)",
      right: 10
    }
  })
)(MUISelect);

const renderOptions = (options: OptionProps[], sortOptions?: tSortOptions) => {
  let list = options || [];

  if(sortOptions) {
    list = _.sortBy(options, (o) =>
      o.label ? o.label.toLowerCase() : undefined,
      sortOptions
    )
  }

  return list.map((o, index) => (
    <MenuItem key={index} value={o.value}>
      {o.label}
    </MenuItem>
  ));
}

const BaseSelect = React.memo(
  ({
    options,
    sortOptions,
    children,
    value,
    ...props
  }: SelectProps & { options?: OptionProps[] }) => (
    <StyledSelect
      {...props}
      value={value || ""}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        getContentAnchorEl: null
      }}
      // disableUnderline
      IconComponent={(props) => <Icon name="ArrowDown" {...props} />}
    >
      {options ? renderOptions(options, sortOptions) : children}
    </StyledSelect>
  )
);

const FormikSelect = ({ name, ...other }: SelectProps & { name: string }) => {
  const [field, meta] = useField(name);
  const { error } = meta;

  return (
    <>
      <BaseSelect {...other} {...field} error={!!error} />
      {error && <FieldError>{error}</FieldError>}
    </>
  );
};

const Select = ({ name, formikControll, ...inputProps }: SelectProps) =>
  formikControll && name ? (
    <FormikSelect name={name} {...inputProps} />
  ) : (
    <BaseSelect {...inputProps} />
  );

export default Select;
