import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Box, LinearProgress } from "~/components/UI";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import { InfiniteScrollOptions } from "~/components/UI/Table/InfiniteScroll";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { i18nTableColumn } from "~/packages/i18n";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      height: 400,
    },
  })
);

interface ISTableProps
  extends Omit<TableProps<PaymentServicesApi.SiteDto>, "columns"> {
  search?: string;
  onNext: (page?: number | undefined) => Promise<{
    nextData: PaymentServicesApi.SiteDto[];
    pagination: Api.PaginationSchema | undefined;
  }>;
  onSelectRows?: (rows: PaymentServicesApi.SiteDto[]) => void;
  filter?: (row: PaymentServicesApi.SiteDto) => boolean;
}

export interface ISTableRefObject {
  localList: PaymentServicesApi.SiteDto[];
  addRows: (sites: PaymentServicesApi.SiteDto[]) => void;
  removeRows: (ids: string[]) => void;
}

const defaultColumns = [
  {
    key: "siteId",
    i18nKey: "siteId",
  },
];

const ISTable = forwardRef<any, ISTableProps>(
  ({ search, filter, onNext, onSelectRows, ...other }: ISTableProps, ref) => {
    const [localList, setLocalList] = useState<PaymentServicesApi.SiteDto[]>(
      []
    );
    const [list, setList] = useState<PaymentServicesApi.SiteDto[]>([]);
    const [tableDataSource, setTableDataSource] = useState<
      PaymentServicesApi.SiteDto[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<Api.PaginationSchema>();
    const [infiniteScrollOptions, setInfiniteScrollOptions] =
      useState<InfiniteScrollOptions>();
    const classes = useStyles();
    const { t } = useTranslation();
    const columns = defaultColumns.map((c) => i18nTableColumn(c, t));
    const tableRef = useRef<TableRef>(null);

    const getList = useCallback(
      async (page: number) => {
        try {
          setLoading(true);
          const { nextData, pagination } = await onNext(page);
          setList((prevState) => [...prevState, ...nextData]);
          setPagination(pagination);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      },
      [onNext]
    );

    const onAddRows = useCallback((sites: PaymentServicesApi.SiteDto[]) => {
      setLocalList((prevState) => [...sites, ...prevState]);
    }, []);

    const onRemoveRows = useCallback(
      (ids: string[]) => {
        setList((prevState) =>
          prevState.filter((site) => !ids.includes(site.siteId))
        );
        setLocalList((prevState) =>
          prevState.filter((site) => !ids.includes(site.siteId))
        );
        if (ids.length >= list.length) {
          getList(pagination ? pagination.page + 1 : 1);
        }
        tableRef.current && tableRef.current.resetSelectedRows();
      },
      [pagination, getList, tableRef]
    );

    const onSelect = useCallback(
      (ids: string[]) => {
        onSelectRows &&
          onSelectRows(
            tableDataSource.filter((site) => ids.includes(site.siteId))
          );
      },
      [tableDataSource]
    );

    useImperativeHandle(ref, () => {
      return {
        localList,
        addRows: onAddRows,
        removeRows: onRemoveRows,
      };
    });

    useEffect(() => {
      setInfiniteScrollOptions({
        next: () => pagination && getList(pagination.page + 1),
        hasMore: !!pagination && pagination.page < pagination.totalPages,
        dataLength: list.length,
        height: 390,
      });
    }, [list, pagination]);

    useEffect(() => {
      setPagination(undefined);
      getList(1);
      setList([]);
    }, [search]);

    useEffect(() => {
      let localTemp = localList;
      if (search) {
        localTemp = localList.filter((i) => i.siteId.includes(search));
      }

      const newDataSource = [
        ...localTemp,
        ...list.filter(
          (s) => localList.findIndex((ls) => ls.siteId === s.siteId) === -1
        ),
      ];

      setTableDataSource(
        filter && newDataSource ? newDataSource.filter(filter) : newDataSource
      );
    }, [localList, list, filter]);

    return (
      <Box
        className={classes.tableContainer}
        mt={4}
        border={1}
        borderColor="divider"
      >
        <LinearProgress hidden={!loading} />
        <Table
          {...other}
          ref={tableRef}
          columns={columns}
          dataSource={tableDataSource}
          rowKey="siteId"
          stickyHeader
          infiniteScroll={infiniteScrollOptions}
          rowSelection={{
            onChange: onSelect,
          }}
        />
      </Box>
    );
  }
);

export default ISTable;
