import React, { useCallback } from "react";
import { Box, Checkbox, Table } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { Processes } from "~/constants/Permissions";

const defaultColumns = [
  {
    key: "i18nKey",
    i18nKey: "name",
  },
  {
    key: "execute",
    i18nKey: "execute",
    render: (permission: string) => (
      <Checkbox name={`permissions.${permission}`} formikControll />
    ),
    cellOptions: {
      width: 70,
    },
  },
];

const ProcessesForm = () => {
  const { t } = useTranslation();
  const getColumns = useCallback(
    () =>
      defaultColumns.map((c) => {
        const column = i18nTableColumn(c, t);
        switch (column.key) {
          case "i18nKey":
            return {
              ...column,
              render: t,
            };
          default:
            return column;
        }
      }),
    [t]
  );

  return (
    <Box my={4} border={1} flexGrow={1} borderColor="divider">
      <Table columns={getColumns()} dataSource={Processes} />
    </Box>
  );
};

export default React.memo(ProcessesForm);
