import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class SitesApi {
  rootPath: string = "/sites";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.sites')
  }

  getAll = (limit: number = 10, page: number = 1, query?: Api.GetSitesQuery ): Promise<Api.SitesResponse> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  deleteSites = (ids: string[]): Promise<{ affected: number }> =>
    this.instance.delete(this.rootPath, { params: { ids } });

  getSite = (id: string): Promise<Api.SiteResponse> =>
    this.instance.get(`${this.rootPath}/${id}`);

  getPaymentServices = (siteId: string): Promise<Api.GetAll<PaymentServicesApi.PaymentServiceDto>> =>
    this.instance.get(`${this.rootPath}/${siteId}/payment-services`);

  getPoses = (siteId: string): Promise<Api.PosesResponse> =>
    this.instance.get(`${this.rootPath}/${siteId}/poses`);
  
  getServices = (siteId: string): Promise<Api.ServicesResponse> =>
    this.instance.get(`${this.rootPath}/${siteId}/services`);

  createSite = (data: Api.CreateSite): Promise<Api.SiteResponse> =>
    this.instance.post(this.rootPath, data);

  updateSite = (id: string, data: Api.UpdateSite): Promise<Api.GetOne<Api.SiteDto>> =>
    this.instance.put(`${this.rootPath}/${id}`, data);

  deleteSite = (id: string): Promise<{ affected: number }> =>
    this.instance.delete(`${this.rootPath}/${id}`);

  getFilterByPaymentServices = (): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDto[]>> =>
    this.instance.get(`${this.rootPath}/payment-services/filter`)

  getFilterByServiceCategories = (): Promise<Api.GetOne<ServicesCategoriesApi.ServicesCategoryDto[]>> =>
    this.instance.get(`${this.rootPath}/service-categories/filter`)

  getQRZip = (siteId?: string): Promise<{ data: string }> => 
    this.instance.get(`${this.rootPath}/${siteId}/qrCode`, { responseType: 'arraybuffer' })
  
};
