import { Box, Typography, Grid, Label, Icon } from "~/components/UI";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface ServiceDetailsProps {
  servicesCategory: ServicesCategoriesApi.ServicesCategoryDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      height: 280,
    },
  })
)(Grid);

/**
 * @memberof ServicesCategories
 * @component
 * @desc Render detailed information about a Services Category.
 * @property {ServicesCategoriesApi.ServicesCategoryDto} servicesCategory - a Services Category data;
 */

const ServicesCategoryDetails = ({ servicesCategory }: ServiceDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">
          {t("title.additionalInformation")}
        </Typography>
      </Box>
      <Box p={4} flexGrow={1}>
        <Grid container>
          <Grid item md={12}>
            <Label text={t("label.fixedNumberOfQueues")}>
              {servicesCategory.fixedQueue ? (
                <Icon name="CheckCircleOutline" size="small" color="success" />
              ) : (
                <Icon name="DangerousOutline" size="small" color="error" />
              )}
            </Label>
            <Label text={t("label.globalCatalog")}>
              {servicesCategory.globalCatalog ? (
                <Icon name="CheckCircleOutline" size="small" color="success" />
              ) : (
                <Icon name="DangerousOutline" size="small" color="error" />
              )}
              {/* <Typography variant="body1">{servicesCategory.s}</Typography> */}
            </Label>
            <Label text={t("label.description")}>
              {servicesCategory.description}
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ServicesCategoryDetails;
