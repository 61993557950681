import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class UserActivityJournalApi {
  rootPath: string = "/journal-user-activity";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.userActivityLog')
  }

  getUsersActivities = (limit: number = 10, page: number = 1, query?: UserActivityJournalApi.GetUserActivityQuery): Promise<Api.GetAll<UserActivityJournalApi.UserActivityDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } })
  getUserActivity = (id: string): Promise<Api.GetOne<UserActivityJournalApi.UserActivityDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${id}`)
  restoreObject = (id: string, catalogType: Api.eSourceType): Promise<Api.GetOne<UserActivityJournalApi.UserActivityDto>> =>
    this.instance.put(`${this.rootPath}/${id}/restore`, { catalogType })
}
