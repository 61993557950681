import { Switch, Route } from "react-router";
import ROUTES from "~/constants/routes";
import PaymentServices from "./Services";
import PaymentService from "./Service";
import PaymentServiceDialogsProvider from "~/components/PaymentService/Dialogs/PaymentServiceDialogsProvider";

const CatalogsRoutes = () => {
  return (
    <PaymentServiceDialogsProvider>
      <Switch>
        <Route path={ROUTES.PAYMENT_SERVICE} component={PaymentService} />
        <Route
          path={ROUTES.PAYMENT_SERVICES}
          exact
          component={PaymentServices}
        />
      </Switch>
    </PaymentServiceDialogsProvider>
  );
};

export default CatalogsRoutes;
