import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Label,
  LinearProgress,
  Icon,
  Typography,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { phoneNumber, timeString } from "~/utils/format";
import api from "~/api";

interface ClientTabProps {
  orderId: string;
}

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render information about Client.
 * @property {string} orderId order id
 */

const GrantsByIDP = ({
  title,
  hasGrant,
}: {
  title: string;
  hasGrant: boolean;
}) => {
  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid item>
        {hasGrant ? (
          <Icon name="CheckCircleOutline" color="success" />
        ) : (
          <Icon name="DangerousOutline" color="error" />
        )}
      </Grid>
      <Grid item>
        <Typography variant="body1">{title}</Typography>
      </Grid>
    </Grid>
  );
};

const ClientTab = ({ orderId }: ClientTabProps) => {
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] =
    useState<JournalBusinessOperationsApi.ClientOperationDetailsDto>();
  const { t } = useTranslation();

  async function getClient(orderId: string) {
    setLoading(true);
    try {
      const { data } = await api.businessOperationsJournal.getClient(orderId);
      setClientData(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getClient(orderId);
  }, [orderId]);

  if (loading) {
    return <LinearProgress />;
  } else if (clientData) {
    return (
      <Box p={4}>
        <Grid container>
          <Grid item md={4} sm={12}>
            <Label text={t("label.clientID")}>{clientData.clientId}</Label>
            <Label text={t("label.mobilePhone")}>
              {phoneNumber(clientData.mobilePhone)}
            </Label>
            <Label text={t("label.fullName")}>{clientData.fullName}</Label>
            <Label text={t("label.email")}>{clientData.email}</Label>
          </Grid>
          <Grid item md={4} sm={12}>
            <Label text={t("label.barCode")}>{clientData.barCode}</Label>
            <Label text={t("label.businessOperationStartTime")}>
              {timeString(clientData.createdAt)}
            </Label>
          </Grid>
          <Grid item md={4} sm={12}>
            <Label text={t("label.grantsByIDP")}>
              <GrantsByIDP title="Post-pay" hasGrant={clientData.hasPostPay} />
              <GrantsByIDP
                title="Scan & Go"
                hasGrant={clientData.hasScanAndGo}
              />
            </Label>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

export default ClientTab;
