import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik, FieldArray } from "formik";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import CreateServiceFrom from "./CreateServiceFrom";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";
import AddBundleForm from "./AddBundle";

interface UpdateServicesProps extends Omit<DialogProps, "onClose"> {
  onClose?: (result?: boolean) => void;
}

type FormData = {
  services: Api.CreateService[];
  serviceId: string;
  bundles: Api.BundleDto[]
};

const initialValues = {
  services: [],
  serviceId: "",
  bundles: []
};

const getNewService = (serviceId: string): Api.CreateService => ({
  serviceId,
  globalCatalog: false,
  isAvailableForDelivery: false,
  enabled: false,
});

interface ServicesFormsProps {
  services: Api.CreateService[];
  onDelete?: (index: number) => undefined;
}

const ServicesForms = React.memo(
  ({ services, onDelete }: ServicesFormsProps) => {
    const deleteHandler = useCallback((index: number) => {
      onDelete && onDelete(index);
    }, []);
    return (
      <>
        {services && services.length
          ? services.map((s, index) => (
              <CreateServiceFrom
                key={index}
                index={index}
                path={`services.${index}`}
                onDelete={deleteHandler}
              />
            ))
          : null}
      </>
    );
  }
);

/**
 * @memberof Services
 * @component
 * @desc Dialog for creating a service.
 * @property {Function} onClose - passes true if the service was created successfully. (result?: boolean) => void;
 */

const CreateServicesDialog = ({ open, onClose }: UpdateServicesProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (formData: FormData) => {
      setLoading(true);
      
      const { bundles, ...data } = formData

      const bundleIds = bundles &&
        bundles.length &&
        bundles.map((bundle) => bundle.id) || [];

      const promises = data.services.map((s) => {
        if ('description' in s && s?.description === '') {
          delete s.description;
        }

        return api.services.сreateService(
          {...s,
          ...(bundleIds? {bundleIds} : {})
        })
      });

      Promise.all(promises)
        .then(() => {
          toast.success(t("text.recordWasSuccessfullyCreated"));
          onClose && onClose(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [t, onClose]
  );

  const validationSchema = yup.object().shape({
    services: yup
      .array()
      .of(
        yup.object().shape({
          siteId: yup.string().required(),
          categoryId: yup.string().required(),
          serviceId: yup.string().serviceId().required(),
          templateId: yup.string().required(),
          status: yup.boolean(),
          serviceMaxQueue: yup.number().required(),
          globalCatalog: yup.boolean(),
        })
      )
      .min(1),
    serviceId: yup.string().serviceId(),
  });

  return (
    <Dialog
      open={open}
      title={t("title.createServices")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <DialogContent>
              <FieldArray
                name="services"
                render={(arrayHelpers) => (
                  <Box mb={6}>
                    <Box pb={4}>
                      <Typography variant="h4">
                        {t("title.newService")}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box pt={6} display="flex">
                      <Box>
                        <OutlinedInput
                          name="serviceId"
                          placeholder={t("text.enterIdService")}
                          formikControll
                        />
                      </Box>
                      <Box ml={4} display="inline">
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={!values.serviceId}
                          onClick={() => {
                            if (!errors.serviceId) {
                              arrayHelpers.push(
                                getNewService(values.serviceId)
                              );
                              setFieldValue("serviceId", "");
                            }
                          }}
                        >
                          {t("button.addService")}
                        </Button>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column-reverse">
                      <ServicesForms
                        services={values.services}
                        onDelete={arrayHelpers.remove}
                      />
                    </Box>
                  </Box>
                )}
              />
              <AddBundleForm />
            </DialogContent>
            <DialogActions>
              {values.services.length ? (
                <Button
                  loading={loading}
                  onClick={() => handleSubmit()}
                  color="primary"
                  variant="contained"
                >
                  {t("button.save&Create")}
                </Button>
              ) : null}

              <Button
                disabled={loading}
                onClick={() => onClose && onClose()}
                variant="contained"
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateServicesDialog;
