import { useState, useCallback, useEffect, useRef } from "react";
import { Box, Grid, Typography, Divider, Button, Icon } from "~/components/UI";
import Search from "~/components/Search";
import ISTable, { ISTableRefObject } from "./ISTable";
import api from "~/api";
import { useTranslation } from "react-i18next";

interface SiteManagementFromProps {
  paymentId: string;
  onChange: (sites?: string[]) => void;
}

const SiteManagementFrom = ({
  paymentId,
  onChange,
}: SiteManagementFromProps) => {
  const [assignedIds, setAssignedIds] = useState<string[]>();
  const [forAssign, setForAssign] = useState<PaymentServicesApi.SiteDto[]>([]);
  const [forUnassign, setForUnassign] = useState<PaymentServicesApi.SiteDto[]>(
    []
  );
  const [searchValue, setSearchValue] = useState("");
  const assignedTableRef = useRef<ISTableRefObject>();
  const unassignedTableRef = useRef<ISTableRefObject>();
  const { t } = useTranslation();

  const onNextAssignedSites = useCallback(
    async (page?: number) => {
      const { data } = await api.paymentServices.getPaymentSites(
        paymentId,
        20,
        page,
        {
          search: searchValue,
        }
      );
      return { nextData: data.data, pagination: data.meta.pagination };
    },
    [paymentId, searchValue]
  );

  const onNextUnassignedSites = useCallback(
    async (page?: number) => {
      const {
        data: {
          data: { sites, siteIds },
          meta,
        },
      } = await api.paymentServices.getSites(paymentId, 20, page, {
        // enabled: false,
        search: searchValue,
      });
      const assignedIdsTemp = assignedIds || siteIds;
      const nextData = sites.filter((s) => !assignedIdsTemp.includes(s.siteId));
      setAssignedIds((ids) => (!ids ? siteIds : ids));
      return { nextData, pagination: meta.pagination };
    },
    [paymentId, assignedIds, searchValue]
  );

  const onAssign = useCallback(() => {
    if (
      !assignedIds ||
      !assignedTableRef ||
      !assignedTableRef.current ||
      !unassignedTableRef ||
      !unassignedTableRef.current
    ) {
      return;
    }

    setAssignedIds([...assignedIds, ...forAssign.map((s) => s.siteId)]);
    assignedTableRef.current.addRows(forAssign);
    unassignedTableRef.current.removeRows(forAssign.map((s) => s.siteId));
    setForAssign([]);
  }, [assignedIds, forAssign, assignedTableRef, unassignedTableRef]);

  const onUnassign = useCallback(() => {
    const ids = forUnassign.map((s) => s.siteId);

    if (
      !assignedIds ||
      !assignedTableRef ||
      !assignedTableRef.current ||
      !unassignedTableRef ||
      !unassignedTableRef.current
    ) {
      return;
    }

    setAssignedIds(assignedIds.filter((siteId) => !ids.includes(siteId)));
    unassignedTableRef.current.addRows(forUnassign);
    assignedTableRef.current.removeRows(forUnassign.map((s) => s.siteId));
    setForUnassign([]);
  }, [assignedIds, forUnassign, assignedTableRef, unassignedTableRef]);

  const unassignedTableFilter = useCallback(
    (row: PaymentServicesApi.SiteDto) => {
      return !Boolean(
        assignedTableRef.current?.localList.find((r) => r.siteId === row.siteId)
      );
    },
    [assignedTableRef.current]
  );

  const assignedTableFilter = useCallback(
    (row: PaymentServicesApi.SiteDto) => {
      return !Boolean(
        unassignedTableRef.current?.localList.find(
          (r) => r.siteId === row.siteId
        )
      );
    },
    [unassignedTableRef.current]
  );

  useEffect(() => {
    onChange && onChange(assignedIds);
  }, [assignedIds]);

  return (
    <>
      <Box py={4}>
        <Grid container alignItems="center" spacing={7}>
          <Grid item>
            <Typography variant="h4">{t("label.site")}</Typography>
          </Grid>
          <Box maxWidth={300} flex={1}>
            <Search
              placeholder={t("text.searchByID")}
              onSearch={setSearchValue}
            />
          </Box>
        </Grid>
      </Box>
      <Divider />
      <Box py={4}>
        <Grid container alignItems="stretch">
          <Box flexGrow={1}>
            <Typography variant="h4">{t("title.unlinked")}</Typography>
            <ISTable
              ref={unassignedTableRef}
              search={searchValue}
              filter={unassignedTableFilter}
              onNext={onNextUnassignedSites}
              onSelectRows={setForAssign}
            />
          </Box>
          <Box p={6} display="flex" alignItems="stretch">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={3}
            >
              <Grid item>
                <Button
                  variant="contained"
                  iconContainer
                  disabled={!forAssign.length}
                  onClick={onAssign}
                >
                  <Icon name="ArrowRight" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  iconContainer
                  disabled={!forUnassign.length}
                  onClick={onUnassign}
                >
                  <Icon name="ArrowLeft" />
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="h4">{t("title.linked")}</Typography>
            <ISTable
              ref={assignedTableRef}
              search={searchValue}
              filter={assignedTableFilter}
              onNext={onNextAssignedSites}
              onSelectRows={setForUnassign}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default SiteManagementFrom;
