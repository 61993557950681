import React, { useRef } from "react";
import { makeStyles, createStyles } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import SystemLogDetails from "./SystemLogDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flexGrow: 1,
    },
  })
);

interface SystemLogsTableProps
  extends Omit<
    TableProps<SystemLogJournalApi.SystemLogDto>,
    "columns" | "rowSelection"
  > {
  onNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  {
    key: "timestamp",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "Message",
    i18nKey: "message",
    cellOptions: {
      maxWidth: 300,
    },
  },
  {
    key: "siteId",
    i18nKey: "siteId",
    render: (siteId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.SITE} sourceId={siteId}>
        {siteId || " "}
      </LinkToObject>
    ),
  },
  {
    key: "posId",
    title: "ID POS",
    render: (posId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.POS} sourceId={posId}>
        {posId || " "}
      </LinkToObject>
    ),
  },
  {
    key: "service",
    i18nKey: "source",
    // render: (service: SystemLogJournalApi.eService) => (
    //   <EnumValue value={service} enumI18n={SERVICE_I18n} />
    // ),
  },
  {
    key: "key",
    i18nKey: "type",
    // render: (type: SystemLogJournalApi.eLogType) => (
    //   <EnumValue value={type} enumI18n={LOG_TYPE_I18n} />
    // ),
  },
  {
    key: "logLevel",
    i18nKey: "severity",
    // render: (severity: SystemLogJournalApi.eSeverity) => (
    //   <EnumValue value={severity} enumI18n={SEVERITY_I18n} />
    // ),
  },
  {
    key: "api",
    i18nKey: "apiVersion",
  },
];

/**
 * @memberof SystemLogs
 * @component
 * @desc Render table of System Logs.
 * @property {SystemLogJournalApi.SystemLogDto[]} dataSource - System log data ???
 */

const SystemLogsTable = ({
  onSort,
  onNext,
  hasMore,
  dataLength,
  ...props
}: SystemLogsTableProps) => {
  // const [height, setHeight] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));
  const wrapperRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (wrapperRef.current) {
  //     setHeight(wrapperRef.current.clientHeight);
  //   }
  // }, [wrapperRef.current]);

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <Table
        {...props}
        columns={columns}
        expandable={{
          highlightRow: true,
          expandedRowRender: (data: SystemLogJournalApi.SystemLogDto) => (
            <SystemLogDetails data={data} />
          ),
        }}
        onRequestSort={onSort}
        rowKey="id"
        stickyHeader
      />
    </div>
  );
};

export default React.memo(SystemLogsTable);
