import React from "react";
import clsx from "clsx";
import { ReactComponent as AlertError } from "./svg/alertError.svg";
import { ReactComponent as AlertSuccess } from "./svg/alertSuccess.svg";
import { ReactComponent as Location } from "./svg/location.svg";
import { ReactComponent as ArrowDown } from "./svg/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./svg/arrow-right.svg";
import { ReactComponent as ArrowTop } from "./svg/arrow-top.svg";
import { ReactComponent as Book } from "./svg/book.svg";
import { ReactComponent as CarWash } from "./svg/car-wash.svg";
import { ReactComponent as Chart } from "./svg/chart.svg";
import { ReactComponent as Close } from "./svg/close.svg";
import { ReactComponent as Cookie } from "./svg/cookie.svg";
import { ReactComponent as CustomerOutline } from "./svg/customer-outline.svg";
import { ReactComponent as Customer } from "./svg/customer.svg";
import { ReactComponent as Dashboard } from "./svg/dashboard.svg";
import { ReactComponent as FuelStationOutline } from "./svg/fuel-station-outline.svg";
import { ReactComponent as FuelStation } from "./svg/fuel-station.svg";
import { ReactComponent as Lens } from "./svg/lens.svg";
import { ReactComponent as Moneybox } from "./svg/moneybox.svg";
import { ReactComponent as Bell } from "./svg/bell.svg";
import { ReactComponent as MenuArrowLeft } from "./svg/menu-arrow-left.svg";
import { ReactComponent as MenuArrowRight } from "./svg/menu-arrow-right.svg";
import { ReactComponent as Tiles } from "./svg/tiles.svg";
import { ReactComponent as List } from "./svg/list.svg";
import { ReactComponent as Filter } from "./svg/filter.svg";
import { ReactComponent as TrendArrowDown } from "./svg/trend-arrow-down.svg";
import { ReactComponent as TrendArrowUp } from "./svg/trend-arrow-up.svg";
import { ReactComponent as Options } from "./svg/options.svg";

import { ReactComponent as InsuranceService } from "./svg/insurance-service.svg";
import { ReactComponent as DeliveryService } from "./svg/delivery-service.svg";
import { ReactComponent as ChargingService } from "./svg/charging-service.svg";

import { ReactComponent as DieselEuro } from "./svg/diesel-euro.svg";
import { ReactComponent as DieselPulse } from "./svg/diesel-pulse.svg";
import { ReactComponent as Gas } from "./svg/gas.svg";
import { ReactComponent as Plug } from "./svg/plug.svg";
import { ReactComponent as Settings } from "./svg/settings.svg";

import { ReactComponent as BellOutline } from "./svg/bell-outline.svg";
import { ReactComponent as Printer } from "./svg/printer.svg";
import { ReactComponent as PowerOff } from "./svg/power_off.svg";
import { ReactComponent as Pen } from "./svg/pen.svg";
import { ReactComponent as Plus } from "./svg/plus.svg";
import { ReactComponent as Minus } from "./svg/minus.svg";
import { ReactComponent as Delete } from "./svg/delete.svg";
import { ReactComponent as CheckCircleOutline } from "./svg/check-circle-outline.svg";
import { ReactComponent as DangerousOutline } from "./svg/dangerous-outline.svg";
import { ReactComponent as NoSearchResults } from "./svg/no-search-results.svg";
import { ReactComponent as Catalogs } from "./svg/catalogs.svg";
import { ReactComponent as Moon } from "./svg/moon.svg";
import { ReactComponent as Refresh } from "./svg/refresh.svg";
import { ReactComponent as DragAndDropBtn } from "./svg/drag-and-drop-controls.svg";
import { ReactComponent as User } from "./svg/user.svg";
import { ReactComponent as Layers } from "./svg/layers.svg";
import { ReactComponent as Shuffle } from "./svg/shuffle.svg";
import { ReactComponent as Back } from "./svg/back.svg";
import { ReactComponent as Copy } from "./svg/copy.svg";
import { ReactComponent as Success } from "./svg/success.svg";

import { ReactComponent as PaymentStatusApproved } from "./svg/payment-status-approved.svg";
import { ReactComponent as PaymentStatusRefunded } from "./svg/payment-status-refunded.svg";

import { styled } from "@material-ui/core/styles";

export type IconName =
  | "AlertError"
  | "AlertSuccess"
  | "MenuArrowLeft"
  | "MenuArrowRight"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowTop"
  | "Close"
  | "Location"
  | "ArrowDown"
  | "Book"
  | "CarWash"
  | "Chart"
  | "Cookie"
  | "Copy"
  | "CustomerOutline"
  | "Customer"
  | "Dashboard"
  | "FuelStationOutline"
  | "FuelStation"
  | "Lens"
  | "NoSearchResults"
  | "Moneybox"
  | "Bell"
  | "Tiles"
  | "List"
  | "Filter"
  | "TrendArrowUp"
  | "TrendArrowDown"
  | "InsuranceService"
  | "DeliveryService"
  | "ChargingService"
  | "DieselEuro"
  | "DieselPulse"
  | "Gas"
  | "Plug"
  | "Options"
  | "Settings"
  | "BellOutline"
  | "Printer"
  | "PowerOff"
  | "Pen"
  | "Plus"
  | "Minus"
  | "Delete"
  | "CheckCircleOutline"
  | "DangerousOutline"
  | "Catalogs"
  | "Moon"
  | "DragAndDropBtn"
  | "User"
  | "Refresh"
  | "Layers"
  | "Shuffle"
  | "Back"
  | "PaymentStatusApproved"
  | "PaymentStatusRefunded"
  | "Success";


const iconTypes = {
  AlertError,
  AlertSuccess,
  MenuArrowLeft,
  MenuArrowRight,
  ArrowLeft,
  ArrowRight,
  Close,
  Location,
  ArrowDown,
  ArrowTop,
  Book,
  CarWash,
  Chart,
  Cookie,
  Copy,
  CustomerOutline,
  Customer,
  Dashboard,
  FuelStationOutline,
  FuelStation,
  Lens,
  NoSearchResults,
  Moneybox,
  Bell,
  Tiles,
  List,
  Filter,
  TrendArrowUp,
  TrendArrowDown,
  InsuranceService,
  DeliveryService,
  ChargingService,
  DieselEuro,
  DieselPulse,
  Gas,
  Plug,
  Options,
  Settings,
  Success,
  BellOutline,
  Printer,
  PowerOff,
  Pen,
  Plus,
  Minus,
  Delete,
  DangerousOutline,
  CheckCircleOutline,
  Catalogs,
  Moon,
  DragAndDropBtn,
  Refresh,
  User,
  Layers,
  Shuffle,
  Back,
  PaymentStatusApproved,
  PaymentStatusRefunded,
} as {
  [key: string]: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};
export interface Props {
  name: IconName;
  className?: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "warning"
    | "info";
  size?: "default" | "large" | "small";
}

const Icon = styled("i")(({ theme }: { theme: CustomTheme.RootObject }) => ({
  lineHeight: 1,
  width: "1em",
  height: "1em",
  "& > *": {
    verticalAlign: "middle",
  },
  "&.small": {
    fontSize: 13,
  },
  "&.large": {
    fontSize: 18,
  },
  "&.default": {
    color: "inherit",
  },
  "&.primary": {
    color: theme.palette.primary.main,
  },
  "&.secondary": {
    color: theme.palette.primary.main,
  },
  "&.success": {
    color: theme.palette.success.main,
  },
  "&.error": {
    color: theme.palette.error.main,
  },
  "&.warning": {
    color: theme.palette.warning.main,
  },
  "&.info": {
    color: theme.palette.info.main,
  },
}));

const IconComponent = React.forwardRef(
  (
    { name, className, size = "default", color = "default" }: Props,
    ref: React.LegacyRef<HTMLElement>
  ) => {
    let SvgIcon = iconTypes[name];
    return SvgIcon ? (
      <Icon
        ref={ref}
        className={clsx(className, {
          [size]: true,
          [color]: true,
        })}
      >
        <SvgIcon />
      </Icon>
    ) : null;
  }
);

export default IconComponent;
