import { useContext, useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import UsersActivitiesContext from "./usersActivitiesContext";
import { SOURCE_TYPE } from "~/api/common";
import { USER_ACTIVITY_TYPE } from "~/api/userActivityJournal/enums";

interface UserActivityOptionsProps {
  userActivity: UserActivityJournalApi.UserActivityDto;
}

/**
 * @memberof UsersActivities
 * @component
 * @desc UserActivity options.
 */

const UserActivityOptions = ({ userActivity }: UserActivityOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onRestoreObject } = useContext(UsersActivitiesContext);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const isAvailableForRestore = useCallback(() => {
    if (userActivity.type !== USER_ACTIVITY_TYPE.DELETE) return false;

    switch (userActivity.sourceType) {
      case SOURCE_TYPE.SITE:
        return hasAll(PERMISSION.EDIT_SITE);
      case SOURCE_TYPE.POS:
        return hasAll(PERMISSION.EDIT_POS);
      case SOURCE_TYPE.SERVICE:
        return hasAll(PERMISSION.EDIT_SERVICE);
      default:
        return false;
    }
  }, [userActivity]);

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const options = [
    {
      label: t("option.restore"),
      hidden: !isAvailableForRestore(),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onRestoreObject && onUpdateHandler(onRestoreObject(userActivity)),
      },
    },
  ];

  if (options.every((o) => o.hidden)) {
    return null;
  }

  return <Dropdown loading={loading} options={options} />;
};

export default UserActivityOptions;
