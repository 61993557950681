import { enumToI18nKey } from '~/utils/helpers';

export const PRIORITY = {
  URGENT: "URGENT",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  DEFAULT: "DEFAULT"
} as { [key: string]: EventsLogJournalApi.eEventPriority }

export const PRIORITY_I18n = enumToI18nKey(PRIORITY, 'PRIORITY');

export const API = {
  V1: "v1",
  V2: "v2",
  V3: "v3"
} as { [key: string]: EventsLogJournalApi.eApi }

export const API_I18n = enumToI18nKey(API, 'API');

export const EVENT_TYPE = {
  ADD_GOOD: 'Add good'
} as { [key: string]: EventsLogJournalApi.eEventType }

export const EVENT_TYPE_I18n = enumToI18nKey(EVENT_TYPE, 'EVENT_TYPE');