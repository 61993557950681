import Tiles, { TilesProps } from "~/components/UI/Tiles";
import { Box } from "~/components/UI";
import POSCard from "./POSCard";

/**
 * @memberof POSes
 * @component
 * @desc Render tiles of POSes.
 * @property {Api.PosDto[]} list - POSes data
 */

const POSTiles = (props: Omit<TilesProps<Api.PosDto> & {}, "renderTaile">) => (
  <Box pb={2}>
    <Tiles
      {...props}
      customHeight={true}
      renderTaile={(pos, key) => <POSCard key={key} pos={pos} />}
    />
  </Box>
);

export default POSTiles;
