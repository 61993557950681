import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class DashboardMetricsAPI {
  rootPath: string = "/dashboard-catalogs";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.dashboardWidgets');
  }

  getSitesSummary = (): Promise<any> =>
    this.instance.get(`${this.rootPath}/sites-summary`)
  getPosesSummary = (): Promise<any> =>
    this.instance.get(`${this.rootPath}/poses-summary`)
  getServicesSummary = (): Promise<any> =>
    this.instance.get(`${this.rootPath}/services-summary`)
  getPaymentServices = (): Promise<Api.GetAll<DashboardCatalogsAPI.PaymentServiceDto>> =>
    this.instance.get(`${this.rootPath}/payment-services`)
  getPaymentServicesSummary = (): Promise<any> =>
    this.instance.get(`${this.rootPath}/payment-services-summary`)
}
