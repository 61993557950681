import { createContext } from 'react';

export interface SitesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (site: Api.SiteDto) => void;
  onEdit?: (site: Api.SiteDto) => void;
  onUpdate?: (id: string, formData: Api.UpdateSite) => Promise<void>;
  onDelete?: (site: Api.SiteDto) => Promise<void>;
  onCreateQRZip?: (site: Api.SiteDto) => void;
}

/**
 * @memberof Sites
 * @component
 * @desc React context for providing methods of sites to child components.
 * @property {Function} onSelect - callback for update selected sites;
 * @property {Function} onEdit - callback for open the Edit Site dialog. (site: Api.SiteDto) => void
 * @property {Function} onUpdate - callback for update a Site. (id: string, formData: Api.UpdateSite) => Promise<void>
 * @property {Function} onDelete - callback for the delete a Site. (site: Api.SiteDto) => Promise<void>
 */

const SitesContext = createContext<SitesContextProps>({});


export default SitesContext;
