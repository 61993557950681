import React, { useState, useCallback, useEffect } from "react";
import { CircularProgress, PropTypes, lighten } from "@material-ui/core";
import MUIButton, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";

export interface Props extends Omit<ButtonProps, "color" | "onClick"> {
  prefixIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  iconContainer?: boolean;
  color?: PropTypes.Color | "success" | "danger" | "warning" | "info";
  onClick?: () => Promise<any> | void;
}

const defaultColors: Array<string | undefined> = [
  "default",
  "inherit",
  "primary",
  "secondary",
];

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      lineHeight: 0,
      boxSizing: "border-box",
      minHeight: 35,
      minWidth: 35,
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.button,
      textTransform: "lowercase",
      "@media (max-width:600px)": {
        minHeight: 18,
        minWidth: 18,
        fontSize: 10,
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.6,
        color: theme.palette.text.button,
      },
      "&[hidden]": {
        display: "none",
      },
      "& > span": {
        display: "inline-block",
        "&::first-letter": {
          textTransform: "uppercase",
        },
      },
    },
    sizeSmall: {
      fontSize: 12,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minWidth: "auto",
      minHeight: 24,
    },
    startIcon: {
      position: "absolute",
      left: 16,
      top: "50%",
      marginTop: -6,
      "& > *:first-child": {
        verticalAlign: "initial",
        fontSize: 12,
      },
    },
    textPrimary: {
      color: theme.palette.primary.main,
    },
    contained: (props: Props) => ({
      padding: props.iconContainer ? 9 : "9px 23px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.grey["50"],
      "@media (max-width:600px)": {
        padding: props.iconContainer ? 9 : "6px 15px",
      },
      "&:hover": {
        backgroundColor: theme.palette.grey["100"],
      },
      "&.success": {},
      "&.danger": {
        backgroundColor: lighten(theme.palette.error.main, 0.8),
        color: theme.palette.error.dark,
        border: "none",
        "&:hover": {
          backgroundColor: lighten(theme.palette.error.main, 0.7),
        },
      },
      "&.warning": {},
      "&.info": {},
    }),
    containedPrimary: {
      "&&": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
          "@media (hover: none)": {
            backgroundColor: theme.palette.primary.contrastText,
          },
        },
      },
    },
    containedSecondary: {
      "&&": {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
          "@media (hover: none)": {
            backgroundColor: theme.palette.secondary.contrastText,
          },
        },
      },
    },
    outlined: (props: Props) => ({
      padding: props.iconContainer ? 9 : "9px 23px",
      borderColor: theme.palette.divider,
      "@media (max-width:600px)": {
        padding: props.iconContainer ? 9 : "6px 15px",
      },
    }),
    outlinedPrimary: {
      "&&": {
        color: theme.palette.primary.main,
      },
    },
    withIcon: {
      "&&": {
        paddingLeft: 35,
      },
    },
  })
);

const Loader = withStyles(() =>
  createStyles({
    root: {
      width: "1em",
      height: "1em",
      color: "inherit",
    },
  })
)(CircularProgress);

const Button = ({
  loading: extLoading,
  children,
  prefix,
  size,
  variant,
  prefixIcon,
  iconContainer,
  disabled,
  color,
  onClick,
  ...other
}: Props) => {
  const { withIcon, ...classes } = useStyles({ iconContainer });
  const [loading, setLoading] = useState(Boolean(extLoading));

  const clickHandler = useCallback(() => {
    const res = onClick && onClick();

    if (res && res.finally) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, [onClick]);

  useEffect(() => {
    setLoading(Boolean(extLoading));
  }, [extLoading]);

  return (
    <MUIButton
      {...{
        variant,
        size,
        ...other,
      }}
      className={clsx({
        [color || ""]: true,
        [withIcon]: loading || prefixIcon,
      })}
      classes={{
        ...classes,
      }}
      startIcon={
        loading ? <Loader variant="indeterminate" size="small" /> : prefixIcon
      }
      color={
        defaultColors.includes(color) ? (color as PropTypes.Color) : "default"
      }
      onClick={() => setTimeout(() => clickHandler(), 0)}
      disabled={disabled || loading}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
