import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  SidebarMenu,
  SidebarMenuGroup,
  SidebarMenuItem,
} from "~/components/UI";
import { useLocation } from "react-router-dom";
import ROUTES from "~/constants/routes";
import history from "~/packages/history";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const openDrawerWidth = 325;
const closedDrawerWidth = 80;

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    menu: {
      width: openDrawerWidth,
      height: "100%",
    },
    drawer: {
      backgroundColor: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.divider}`,
      overflow: "hidden",
      flexShrink: 0,
      whiteSpace: "nowrap",
      height: "100%",
      boxSizing: "border-box",
    },
    drawerOpen: {
      width: openDrawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
      width: closedDrawerWidth,
      "@media (max-width:600px)": {
        width: 60,
      },
    },
    drawerPaper: {
      padding: "17px 19px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.default,
    },
    content: {
      boxSizing: "border-box",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      flexGrow: 1,
      overflow: "auto",
      "@media (max-width:600px)": {
        overflow: "inherit",
      },
    },
  })
);

interface Props {
  alerts?: object[];
  children?: React.ReactChildren | React.ReactNode;
}

const SideMenu = ({ children }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { hasAll, hasAny } = usePermissions();

  const goTo = useCallback((path: string) => {
    history.push(path);
  }, []);

  const isActive = useCallback(
    (path: string) => {
      return !!pathname && new RegExp(`^${path}`).test(pathname);
    },
    [pathname]
  );

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
      >
        <nav className={classes.menu}>
          <SidebarMenu
            open={open}
            width={open ? openDrawerWidth : closedDrawerWidth}
            onChangeState={() => setOpen(!open)}
          >
            <SidebarMenuItem
              label={t("title.dashboard")}
              icon="Dashboard"
              active={isActive(ROUTES.DASHBOARD)}
              onClick={() => goTo(ROUTES.DASHBOARD)}
            />
            <SidebarMenuGroup
              label={t("title.catalogs")}
              icon="Catalogs"
              active={isActive(ROUTES.CATALOGS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_SITE,
                  PERMISSION.READ_POS,
                  PERMISSION.READ_SERVICE,
                  PERMISSION.READ_PAYMENT_SERVICE,
                  PERMISSION.READ_ROLE,
                  PERMISSION.READ_USER,
                  PERMISSION.READ_GROUP,
                  PERMISSION.READ_BUNDLE,
                  PERMISSION.READ_KEY_MANAGEMENT,
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.sites")}
                active={isActive(ROUTES.SITES)}
                onClick={() => goTo(ROUTES.SITES)}
                hidden={!hasAll(PERMISSION.READ_SITE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.poses")}
                active={isActive(ROUTES.POSES)}
                onClick={() => goTo(ROUTES.POSES)}
                hidden={!hasAll(PERMISSION.READ_POS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.services")}
                active={isActive(ROUTES.SERVICES)}
                onClick={() => goTo(ROUTES.SERVICES)}
                hidden={!hasAll(PERMISSION.READ_SERVICE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.paymentServices")}
                active={isActive(ROUTES.PAYMENT_SERVICES)}
                onClick={() => goTo(ROUTES.PAYMENT_SERVICES)}
                hidden={!hasAll(PERMISSION.READ_PAYMENT_SERVICE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.keyManagment")}
                active={isActive(ROUTES.KEY_MANAGEMENT)}
                onClick={() => goTo(ROUTES.KEY_MANAGEMENT)}
                hidden={!hasAll(PERMISSION.READ_KEY_MANAGEMENT)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.userRoles")}
                active={isActive(ROUTES.USER_ROLES)}
                onClick={() => goTo(ROUTES.USER_ROLES)}
                hidden={!hasAll(PERMISSION.READ_ROLE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.users")}
                active={isActive(ROUTES.USERS)}
                onClick={() => goTo(ROUTES.USERS)}
                hidden={!hasAll(PERMISSION.READ_USER)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.groups")}
                active={isActive(ROUTES.GROUPS)}
                onClick={() => goTo(ROUTES.GROUPS)}
                hidden={!hasAll(PERMISSION.READ_GROUP)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.bundles")}
                active={isActive(ROUTES.BUNDLES)}
                onClick={() => goTo(ROUTES.BUNDLES)}
                hidden={!hasAll(PERMISSION.READ_BUNDLE)}
                depth={1}
              />
            </SidebarMenuGroup>
            <SidebarMenuGroup
              label={t("title.journals")}
              icon="Book"
              active={isActive(ROUTES.JOURNALS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_JOURNAL_BUSINESS_OPERATIONS,
                  PERMISSION.READ_EVENT_LOG,
                  PERMISSION.READ_JOURNAL_INCIDENTS,
                  PERMISSION.READ_JOURNAL_LOG_SYSTEM,
                  PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS,
                  PERMISSION.READ_Z_REPORT,
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.businessOperations")}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_BUSINESS_OPERATIONS)}
                active={isActive(ROUTES.BUSINESS_OPERATIONS)}
                onClick={() => goTo(ROUTES.BUSINESS_OPERATIONS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.eventsLog")}
                active={isActive(ROUTES.EVENTS)}
                onClick={() => goTo(ROUTES.EVENTS)}
                hidden={!hasAll(PERMISSION.READ_EVENT_LOG)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.incidents")}
                active={isActive(ROUTES.INCIDENTS)}
                onClick={() => goTo(ROUTES.INCIDENTS)}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.systemLog")}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_LOG_SYSTEM)}
                active={isActive(ROUTES.SYSTEM_LOG)}
                onClick={() => goTo(ROUTES.SYSTEM_LOG)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.userActivityLog")}
                active={isActive(ROUTES.USER_ACTIVITY)}
                onClick={() => goTo(ROUTES.USER_ACTIVITY)}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.auditZReportLog")}
                active={isActive(ROUTES.AUDIT_Z_REPORT_LOG)}
                onClick={() => goTo(ROUTES.AUDIT_Z_REPORT_LOG)}
                hidden={!hasAll(PERMISSION.READ_Z_REPORT)}
                depth={1}
              />
            </SidebarMenuGroup>
            <SidebarMenuItem
              label={t("title.metrics")}
              icon="Chart"
              active={isActive(ROUTES.METRICS)}
              onClick={() => goTo(ROUTES.METRICS)}
            />
            <SidebarMenuGroup
              label={t("title.settings")}
              icon="Settings"
              active={isActive(ROUTES.SETTINGS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_SYSTEM_SETTINGS,
                  PERMISSION.READ_ID_PREFIX
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.companyProfile")}
                active={isActive(ROUTES.COMPANY_PROFILE)}
                onClick={() => goTo(ROUTES.COMPANY_PROFILE)}
                hidden={!hasAll(PERMISSION.READ_SYSTEM_SETTINGS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.prefixes")}
                active={isActive(ROUTES.ID_PREFIXES)}
                onClick={() => goTo(ROUTES.ID_PREFIXES)}
                hidden={!hasAll(PERMISSION.READ_ID_PREFIX)}
                depth={1}
              />
            </SidebarMenuGroup>
          </SidebarMenu>
        </nav>
      </div>
      <div className={clsx(classes.content)}>{children}</div>
    </div>
  );
};

export default SideMenu;
