import { Box, Typography } from "~/components/UI";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";
import {
  ANIMATION_DURATION,
  moneyTickFormatter,
  dateFormatter,
} from "./Common";
import { currencyString } from "~/utils/format";
import GraphicGridContainer from "./GraphicGridContainer";

interface CollectedMoneyChartProps {
  period?: MetricsAPI.eTimePeriod;
  list?: MetricsAPI.MetricDataDto;
  yAxis?: string;
}

const TooltipContent = ({ time, check }: { time: string; check: number }) => {
  const { t } = useTranslation();
  return (
    <Box border={1} borderColor="divider" p={3} bgcolor="background.paper">
      <Typography gutterBottom>
        <strong>{t("label.time")}:</strong> {time}
      </Typography>
      <Typography>
        <strong>{t("label.amount")}:</strong> {currencyString(check)}
      </Typography>
    </Box>
  );
};

const CollectedMoneyChart = ({
  period,
  list,
  yAxis,
}: CollectedMoneyChartProps) => {
  if (!list) return null;

  return (
    <GraphicGridContainer yAxis={yAxis}>
      <LineChart data={list as any}>
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="createdAt"
          domain={["auto", "auto"]}
          tickCount={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={dateFormatter(period)}
        />
        <YAxis
          type="number"
          dataKey="total"
          allowDecimals={false}
          domain={[0, "auto"]}
          tickLine={false}
          axisLine={false}
          width={55}
          tickFormatter={moneyTickFormatter}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            return active && payload && payload.length ? (
              <TooltipContent
                time={dateFormatter(period)(label)}
                check={payload[0].value}
              />
            ) : null;
          }}
        />
        <Line
          type="monotone"
          dataKey="total"
          strokeWidth={3}
          dot={false}
          stroke="#56C75B"
          activeDot={{ r: 10, strokeWidth: 3, fill: "#56C75B" }}
          animationDuration={ANIMATION_DURATION}
        />
      </LineChart>
    </GraphicGridContainer>
  );
};

export default CollectedMoneyChart;
