import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  Table,
  Icon,
  Tooltip,
} from "~/components/UI";
import ReversalPaymentDialog from "./ReversalPaymentDialog";
import TabContent from "./TabContent";
import { formatDateWithTime, currencyString } from "~/utils/format";
import { i18nTableColumn } from "~/packages/i18n";
import { useTranslation } from "react-i18next";
import { BUSINESS_OPERATION_PAYMENT_STATUS } from "~/api/journalBusinessOperations/enums";
import api from "~/api";
import _ from "lodash";

interface PaymentTabProps {
  invoiceId: string;
}

const renderAmount = (
  amount: string,
  payment: JournalBusinessOperationsApi.PaymentOperationDetailsDto
) => {
  if (payment.isReversal) {
    if (payment.source === "OKKOOnline:Preauth") {
      return (
        <>
          <Box
            mr={2}
            display="inline"
            fontSize="0.7em"
            position="relative"
            top="-2px"
          >
            <Icon name="PaymentStatusRefunded" color="info" />
          </Box>
          {currencyString(Number(payment.invoiceAmount) - Number(amount))}
        </>
      );
    } else {
      return (
        <>
          <Box
            mr={2}
            display="inline"
            fontSize="0.7em"
            position="relative"
            top="-2px"
          >
            <Icon name="PaymentStatusRefunded" color="info" />
          </Box>
          -{currencyString(amount)}
        </>
      );
    }
  }
  return (
    <>
      <Box
        mr={2}
        display="inline"
        fontSize="0.7em"
        position="relative"
        top="-2px"
      >
        <Icon name="PaymentStatusApproved" color="success" />
      </Box>
      {currencyString(amount)}
    </>
  );
};

const defaultColumns = [
  // {
  //   key: "transactionId",
  //   i18nKey: "transactionID",
  // },
  {
    key: "createdAt",
    i18nKey: "timestamp",
    render: (date: string, item: JournalBusinessOperationsApi.PaymentDto) => (
      <Tooltip title={item.transactionId} placement="top">
        <span>{formatDateWithTime(date)}</span>
      </Tooltip>
    ),
  },
  {
    key: "rrn",
    title: "RRN",
  },
  {
    key: "approvalCode",
    i18nKey: "approvalCode",
  },
  {
    key: "source",
    i18nKey: "source",
  },
  {
    key: "transactionCode",
    i18nKey: "transactionCode",
    render: (code: string, item: JournalBusinessOperationsApi.PaymentDto) => (
      <Tooltip title={item.comment} placement="top">
        <span>{code}</span>
      </Tooltip>
    ),
  },
  {
    key: "amount",
    i18nKey: "amount",
    render: renderAmount,
  },
  {
    key: "status",
    i18nKey: "status",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (
      status: JournalBusinessOperationsApi.ePaymentStatuses,
      item: JournalBusinessOperationsApi.PaymentOperationDetailsDto
    ) => {
      if (
        status === BUSINESS_OPERATION_PAYMENT_STATUS.COMPLETED ||
        status === BUSINESS_OPERATION_PAYMENT_STATUS.APPROVED ||
        status === BUSINESS_OPERATION_PAYMENT_STATUS.REFUNDED ||
        status === BUSINESS_OPERATION_PAYMENT_STATUS.CREATED ||
        (status === BUSINESS_OPERATION_PAYMENT_STATUS.CANCELED &&
          item.isReversal)
      ) {
        return <Icon name="CheckCircleOutline" color="success" />;
      }
      if (
        status === BUSINESS_OPERATION_PAYMENT_STATUS.REFUNDED_ERROR ||
        status === BUSINESS_OPERATION_PAYMENT_STATUS.CANCELED
      ) {
        return <Icon name="DangerousOutline" color="error" />;
      }
      return null;
    },
  },
];

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render information about Payment.
 * @property {string} invoiceId invoice id
 */

const PaymentTab = ({ invoiceId }: PaymentTabProps) => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] =
    useState<JournalBusinessOperationsApi.PaymentOperationDetailsDto>();
  const { t } = useTranslation();
  const [reversalPayment, setReversalPayment] =
    useState<JournalBusinessOperationsApi.PaymentDto>();

  async function getPayment(invoiceId: string) {
    setLoading(true);
    try {
      const { data } = await api.businessOperationsJournal.getPayment(
        invoiceId
      );
      setPaymentData({
        ...data.data,
        payments: _.orderBy(
          data.data.payments.map(payment => {
            return {
              ...payment,
              invoiceAmount: data.data.invoiceAmount
            };
          }),
          ["createdAt"]
        ),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    return column;
  });

  useEffect(() => {
    getPayment(invoiceId);
  }, [invoiceId]);

  if (loading) {
    return <LinearProgress />;
  } else if (paymentData) {
    return (
      <TabContent>
        <Box flex={1} overflow="auto">
          <Table
            columns={columns}
            size="small"
            dataSource={paymentData.payments}
            rowKey="transactionID"
          />
        </Box>
        <Box pt={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">
                {t("label.orderID")}: {paymentData.orderId}
              </Typography>
              <Typography variant="body1">
                {t("label.clientID")}: {paymentData.clientId}
              </Typography>
              <Typography variant="body1">
                {t("label.reason")}: {paymentData.reason}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {t("label.invoiceID")}: {paymentData.invoiceId}
              </Typography>
              <Typography variant="body1">
                {t("label.invoiceAmount")}:{" "}
                {currencyString(paymentData.invoiceAmount)}
              </Typography>
              <Typography variant="body1">
                {t("label.totalAmount")}:{" "}
                {currencyString(paymentData.paymentAmount)}
              </Typography>
              <Typography variant="body1">
                {t("label.includedDiscount")}:{" "}
                {currencyString(paymentData.discount)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <ReversalPaymentDialog
          open={Boolean(reversalPayment)}
          reversalPayment={reversalPayment}
          onClose={() => setReversalPayment(undefined)}
        />
      </TabContent>
    );
  } else {
    return null;
  }
};

export default PaymentTab;
