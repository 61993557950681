import { useContext, useState } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps } from "~/components/UI/Table/Table";
import SiteDetails from "./SiteDetails";
import SiteOptions from "./SiteOptions";
import SitesContext from "./sitesContext";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "siteId",
    sort: true,
    title: "ID",
    cellOptions: {
      width: 200,
    },
  },
  {
    key: "name",
    sort: true,
    i18nKey: "siteName",
    cellOptions: {
      width: 350,
    },
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    sort: true,
    cellOptions: {
      width: 100,
    },
  },
  {
    key: "address",
    sort: true,
    i18nKey: "address",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: Api.Incidents) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Sites
 * @component
 * @desc Render table of sites.
 * @property {Api.SiteDto[]} dataSource - Sites data
 * @requires SitesContext
 */

const SitesTable = (props: Omit<TableProps<Api.SiteDto>, "columns">) => {
  const { onUpdate, onSelect, onSort } = useContext(SitesContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_SITE)
      ? {
          onChange: onSelect,
        }
      : undefined
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, site: Api.SiteDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_SITE)}
              onConfirm={() =>
                onUpdate && onUpdate(site.siteId, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_SITE)}
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, site: Api.SiteDto) => (
            <SiteOptions site={site} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      stickyHeader
      onRequestSort={onSort}
      rowKey="siteId"
      rowSelection={rowSelection}
      expandable={{
        highlightRow: true,
        expandedRowRender: (row: Api.SiteDto) => <SiteDetails site={row} />,
      }}
    />
  );
};

export default SitesTable;
