import { createContext } from 'react';

export interface POSesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (pos: Api.PosDto) => void;
  onUpdateItem?: (pos: Api.PosDto) => void;
  onUpdate?: (id: string, formData: Api.UpdatePOS) => Promise<void>;
  onDelete?: (pos: Api.PosDto) => Promise<void>;
}

/**
 * @memberof POSes
 * @component
 * @desc React context for providing methods of poses to child components.
 * @property {Function} onSelect - callback for update selected POSes;
 * @property {Function} onEdit - callback for open the Edit POS dialog. (pos: Api.PosDto) => void
 * @property {Function} onUpdate - callback for update a POS. (id: string, formData: Api.UpdatePOS) => Promise<void>
 * @property {Function} onDelete - callback for the delete a POS. (pos: Api.PosDto) => Promise<void>
 */

const POSesContext = createContext<POSesContextProps>({});


export default POSesContext;
