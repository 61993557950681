import { styled } from "~/components/UI";
import { useTranslation } from "react-i18next";

interface EnumValueProps {
  value: string;
  enumI18n: { [key: string]: string };
  color?: string;
}

/**
 * Translate enumeration value
 * @memberOf Components
 * @name PrivateRoute
 * @property {string} value
 * @property {{ [key: string]: string }} enumI18n
 * @return {React.Component}
 */

const Wrapper = styled("span")(() => ({
  display: "inline-block",
  "&::first-letter": {
    textTransform: "uppercase",
  },
}));

const EnumValue = ({ value, enumI18n, color }: EnumValueProps) => {
  const { t } = useTranslation(["enums"]);
  return <Wrapper style={{color: color}}>{enumI18n ? t(enumI18n[value]) : value}</Wrapper>;
};

export default EnumValue;
