import { AxiosInstance } from "axios";
import { deleteEmptyFields } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class ServicesApi {
  rootPath: string = "/services";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.services')
  }

  getAll = (limit: number = 10, page: number = 1, query?: Api.GetServicesQuery): Promise<Api.ServicesResponse> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  getService = (id: string): Promise<Api.GetOne<Api.ServiceDto>> =>
    this.instance.get(`${this.rootPath}/${id}`);

  getServiceDetails = (id: string, siteId: string): Promise<Api.GetOne<Api.ServiceDto>> =>
    this.instance.get(`${this.rootPath}/${id}/sites/${siteId}`);

  deleteServices = (ids: string[]): Promise<{ affected: number }> =>
    this.instance.delete(this.rootPath, { params: { ids } });

  getServiceCategories = (): Promise<Api.ServiceCategories> =>
    this.instance.get('/service-categories', { params: { limit: 99, skip: 0 } });

  сreateService = (data: Api.CreateService): Promise<{}> =>
    this.instance.post(this.rootPath, data);

  updateService = (serviceId: string, siteId: string, data: Api.UpdateService): Promise<Api.GetOne<Api.ServiceDto>> =>
    this.instance.put(`${this.rootPath}/${serviceId}/sites/${siteId}`, data);

  deleteService = (serviceId: string, siteId: string): Promise<{ affected: number }> =>
    this.instance.delete(`${this.rootPath}/${serviceId}/sites/${siteId}`);

  getQRCode = (serviceId?: string, siteId?: string): Promise<Api.GetOne<Api.QRCodeData>> =>
    this.instance.get(`${this.rootPath}/${serviceId}/sites/${siteId}/qrCode`);

  getUniqueServices = (): Promise<Api.UniqueServicesResponse> =>
    this.instance.get(`${this.rootPath}/unique-serviceids`);

  addService = (bundleId: string, serviceId: string): Promise<Api.BindBundleResponse> =>
    this.instance.put(`${this.rootPath}/bind-bundle`, { bundleId, serviceId })

}
