import { useContext, useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import ServicesContext from "./servicesContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface ServiceOptionsProps {
  service: Api.ServiceDto;
  hideEditOptions?: boolean;
}

/**
 * @memberof Services
 * @component
 * @desc Service options
 * @property {Api.ServiceDto} service - Service data;
 * @requires ServicesContext
 */

const ServiceOptions = ({ service, hideEditOptions }: ServiceOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onCreateQRCode, onUpdate, onEdit, onUpdateItem, onDelete } =
    useContext(ServicesContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.SERVICE);
  const { enabled } = service;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<void>) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (service.incidents) {
        service.incidents.count++;
        service.incidents.incidentIds.push(incident.incidentId);
      } else {
        service.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(service);
    },
    [service, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_SERVICE,
      PERMISSION.DELETE_SERVICE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_SERVICE),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(
            onUpdate(service.serviceId, service.siteId, { enabled: !enabled })
          ),
      },
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_SERVICE) || hideEditOptions,
      onClick: () => onEdit && onEdit(service),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_SERVICE) || hideEditOptions,
      onClick: () => onDelete && onUpdateHandler(onDelete(service)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(service.id, onCreatedIncident),
    },
    {
      label: t("option.createQRCode"),
      hidden: hideEditOptions,
      onClick: () => onCreateQRCode && onCreateQRCode(service),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default ServiceOptions;
