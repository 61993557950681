import { forwardRef } from "react";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import KeyOptions from "./KeyOptions";
import KeyDetails from './KeyDetails';
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDate } from "~/utils/format";

/**
 * @memberof KeyManagementPage
 * @component
 * @desc Table of Keys
 */

const defaultColumns = [
  {
    key: "keyId",
    title: "ID",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "name",
    sort: true,
    i18nKey: "keyName",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "purpose",
    i18nKey: "keyPurpose"
  },
  {
    key: "expirationDate",
    i18nKey: "expirationDate",
    sort: true,
    render: formatDate
  },
  {
    key: "size",
    i18nKey: "keySize",
    sort: true
  },
  {
    key: "algorithm",
    i18nKey: "algorithm",
    sort: true
  },
  // {
  //   key: "isWrapped",
  //   i18nKey: "isWrapped",
  //   render: IsWrapped
  // },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

const KeysTable = (
  props: Omit<TableProps<KeyManagementApi.KeyDto>, "columns">,
  ref: any
) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, key: KeyManagementApi.KeyDto) => (
            <KeyOptions keyData={key} />
          )
        };
      default:
        return column;
    }
  });

  return <Table
            ref={ref}
            {...props}
            columns={columns}
            expandable={{
              highlightRow: true,
              expandedRowRender: (key) => (
                <KeyDetails
                  keyData={key}
                />
              )
            }}
            stickyHeader
          />;
};

export default forwardRef<
  TableRef,
  Omit<TableProps<KeyManagementApi.KeyDto>, "columns">
>(KeysTable);
