import { useState, useEffect, ReactNode } from "react";
import { Box, ImageList } from "~/components/UI";
import Sites from "./Sites";
import POSes from "./POSes";
import Services from "./Services";
import PaymentServices from "./PaymentServices";
import { WIDGET_ENTITY } from "~/api/dashboardSettings/enums";

const CatalogsEntitys = [
  WIDGET_ENTITY.SITE,
  WIDGET_ENTITY.POS,
  WIDGET_ENTITY.SERVICE,
  WIDGET_ENTITY.PAYMENT_SERVICE,
];

const getWidgetComponent = (
  setting: DashboardSettingsApi.Widget
): React.ReactNode | null => {
  switch (setting.entity) {
    case WIDGET_ENTITY.SITE:
      return <Sites setting={setting} />;
    case WIDGET_ENTITY.POS:
      return <POSes setting={setting} />;
    case WIDGET_ENTITY.SERVICE:
      return <Services setting={setting} />;
    case WIDGET_ENTITY.PAYMENT_SERVICE:
      return <PaymentServices setting={setting} />;
    default:
      return () => null;
  }
};

interface CatalogsProps {
  settings?: DashboardSettingsApi.Widget[];
}

const Catalogs = ({ settings }: CatalogsProps) => {
  const [components, setComponents] = useState<Array<ReactNode>>([]);

  useEffect(() => {
    if (settings) {
      const components = settings
        .filter((s) => CatalogsEntitys.includes(s.entity) && s.status)
        .map((s) => getWidgetComponent(s));
      setComponents(components);
    }
  }, [settings]);

  return (
    <Box mt={4}>
      <ImageList cols={2} gap={20} rowHeight="auto">
        {components &&
          components.map((component, index) => (
            <div key={index}>{component}</div>
          ))}
      </ImageList>
    </Box>
  );
};

export default Catalogs;
