import Routes from "~/constants/routes";
import CommonTile from "./CommonTile";
import Table from "~/pages/Journals/EventsLog/Table";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const EventsLogWidget = () => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  if (!hasAll(PERMISSION.READ_EVENT_LOG)) return null;

  const getData = () =>
    api.eventsLogJournal
      .getEvents(5, { sort: "-timestamp" })
      .then((res) => res.data.data);

  return (
    <CommonTile
      title={t("title.eventsLog")}
      showAllText={t("button.showAll")}
      onUpdate={getData}
      showAllLink={Routes.EVENTS}
    >
      {(data) => <Table dataSource={data || []} hideSorting={true} />}
    </CommonTile>
  );
};

export default EventsLogWidget;
