import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "~/components/UI";
import CommonTile from "../CommonTile";
import Summary from "./Summary";
import List from "./List";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import PosesContext from "~/pages/Catalogs/POSes/POSes/Components/posesContext";
import { updateArrayItem } from "~/utils/helpers";
import Routes from "~/constants/routes";
import PosDetails from "./PosDetails";
import api from "~/api";

interface POSesProps {
  setting: DashboardSettingsApi.Widget;
}

const POSes = ({ setting }: POSesProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedPos, setSelectedPos] = useState<Api.PosDto>();
  const [poses, setPoses] = useState<Api.PosDto[]>();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const getList = async () => {
    setLoading(true);
    try {
      const { data } = await api.poses.getAll(2, 1, {
        sortBy: setting.currentSortBy,
      });
      setPoses(data.data);
      setTotalCount(
        data.meta && data.meta.pagination ? data.meta.pagination.totalCount : 0
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateItem = useCallback(
    (pos: Api.PosDto) => {
      setPoses((poses) => (poses ? updateArrayItem(poses, pos, "posId") : []));
      if (selectedPos && pos.posId === selectedPos.posId) {
        setSelectedPos(pos);
      }
    },
    [selectedPos]
  );

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdatePOS) => {
      const {
        data: { data: pos },
      } = await api.poses.updatePos(id, formData);
      onUpdateItem(pos);
    },
    [poses, t, onUpdateItem]
  );

  const contextValue = useMemo(
    () => ({
      onUpdateItem,
      onUpdate,
    }),
    [poses]
  );

  useEffect(() => {
    getList();
  }, []);

  if (!hasAll(PERMISSION.READ_POS)) return null;

  return (
    <PosesContext.Provider value={contextValue}>
      <CommonTile
        title={
          <Typography variant="h4">
            {t("title.poses")}{" "}
            <Typography variant="h4" component="span" color="textSecondary">
              {totalCount}
            </Typography>
          </Typography>
        }
        showAllLink={Routes.POSES}
        hideShowAll={Boolean(selectedPos)}
        showAllText={`${t("button.showAll")} ${t("title.poses")}`}
      >
        {selectedPos ? (
          <PosDetails
            pos={selectedPos}
            onClose={() => setSelectedPos(undefined)}
          />
        ) : (
          <List loading={loading} poses={poses} onSelectPos={setSelectedPos} />
        )}
        <Summary />
      </CommonTile>
    </PosesContext.Provider>
  );
};

export default POSes;
