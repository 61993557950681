import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress,
} from "~/components/UI";
import Table from "./Table";
import UserDetails from "./UserDetails";
import AddUserDialog from "./AddUserDialog";
import Filter from "./Filter";
import RolesManagementDialog from "./RolesManagementDialog";
import { useQueryParams, StringParam } from "use-query-params";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import CanIUse from "~/components/CanIUse";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof Users
 * @component
 * @desc Users Controller.
 */

const Users = () => {
  const [users, setUsers] = useState<UsersApi.UserDto[]>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [query, setQuery] = useState<UsersApi.GetUsersQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [addUserDialog, setAddUserDialog] = useState(false);
  const [editUser, setEditUser] = useState<UsersApi.UserDto>();
  const [queryParams] = useQueryParams({
    search: StringParam,
    userId: StringParam,
  });
  const deleteConfirm = useConfirmDialog("delete");
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_USER)
      ? {
          hideSelectAll: false,
          onChange: setSelectedUsers,
        }
      : undefined
  );

  const getUsers = useCallback(
    async (page?: number) => {
      setLoading(true);
      try {
        const { data } = await api.users.getUsers(limit, page, query);
        data.data && setUsers(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onAddUser = useCallback(() => {
    setEditUser(undefined);
    setAddUserDialog(true);
  }, []);

  const onEdit = useCallback((user: UsersApi.UserDto) => {
    setEditUser(user);
  }, []);

  const onUpdateItem = (user: UsersApi.UserDto) => {
    setUsers((users) => updateArrayItem(users, user, "userId"));
  };

  const onUpdateStatus = async (
    userId: string,
    formData: UsersApi.UpdateUserStatus
  ) => {
    try {
      await api.users.updateUserStatus(userId, formData);
      onUpdateItem({ userId, ...formData } as UsersApi.UserDto);
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = useCallback(async (user: UsersApi.UserDto) => {
    await deleteConfirm(user?.username);
    try {
      await api.users.deleteUser(user.userId);
    } catch (e) {
      console.error(e);
    } finally {
      getUsers();
    }
  }, []);

  const onDeleteUsers = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedUsers?.length }));
    try {
      await api.users.deleteUsers(selectedUsers);
    } catch (e) {
      console.error(e);
    } finally {
      getUsers();
    }
  }, [selectedUsers]);

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = (page: number) => {
    getUsers(page);
  };

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onCloseDialog = useCallback((res?: boolean) => {
    res && getUsers();
    setAddUserDialog(false);
    setEditUser(undefined);
  }, []);

  useEffect(() => {
    setQuery(queryParams as UsersApi.GetUsersQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getUsers();
  }, [query, limit]);

  return (
    <>
      <Box pr={2}>
        <Section
          title={t("title.users")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_USER}>
                <Button
                  hidden={!selectedUsers.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteUsers}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_USER}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onAddUser}
                >
                  {t("button.addUser")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          dataSource={users}
          onSort={onSort}
          onEdit={onEdit}
          onUpdate={onUpdateStatus}
          onUpdateItem={onUpdateItem}
          onDelete={onDelete}
          rowKey="userId"
          rowSelection={rowSelection}
          expandable={{
            highlightRow: true,
            expandedRowRender: (user) => <UserDetails user={user} />,
          }}
        />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={<RowsPerPage onChange={setLimit} />}
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CanIUse permissions={PERMISSION.CREATE_USER}>
        <AddUserDialog open={addUserDialog} onClose={onCloseDialog} />
      </CanIUse>
      <CanIUse permissions={PERMISSION.EDIT_USER}>
        <RolesManagementDialog
          open={!!editUser}
          user={editUser}
          onClose={onCloseDialog}
        />
      </CanIUse>
    </>
  );
};

export default Users;
