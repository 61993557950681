import { Switch, Route } from "react-router";
import ROUTES from "~/constants/routes";
import Service from "./Service";
import Metrics from "./Metrics";

const PaymentServicesRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.PAYMENT_SERVICE_METRICS} exact component={Metrics} />
      <Route path={ROUTES.PAYMENT_SERVICE} exact component={Service} />
    </Switch>
  );
};

export default PaymentServicesRoutes;
