import { useContext } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps } from "~/components/UI/Table/Table";
import PaymentServiceOptions from "./PaymentServiceOptions";
import PaymentServicesContext from "./paymentServicesContext";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
// import LinkToObject from "~/components/LinkToObject";
// import { SOURCE_TYPE } from "~/api/common";

const defaultColumns = [
  {
    key: "paymentId",
    sort: true,
    title: "ID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "paymentServiceType",
    sort: true,
    i18nKey: "paymentService",
    cellOptions: {
      width: 200,
    },
  },
  {
    key: "enabled",
    sort: true,
    i18nKey: "enabled",
    cellOptions: {
      width: 100,
    },
  },
  {
    key: "merchantId",
    sort: true,
    i18nKey: "merchantID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "signKeyId",
    sort: true,
    i18nKey: "signKeyName",
    // render: (id: string) => <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={id} />,
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "verifyKeyId",
    sort: true,
    i18nKey: "verifyKeyName",
    // render: (id: string) => <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={id} />,
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "terminalId",
    sort: true,
    i18nKey: "terminalId",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof PaymentServices
 * @component
 * @desc Render Payment Services table.
 * @property {PaymentServicesApi.PaymentServiceDto[]} dataSource - Services data
 * @requires PaymentServicesContext
 */

const PaymentServicesTable = ({
  ...props
}: Omit<
  TableProps<PaymentServicesApi.PaymentServiceDto>,
  "columns" | "rowSelection"
>) => {
  const { onUpdate, onDelete, onSort } = useContext(PaymentServicesContext);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (
            enabled: boolean,
            paymentService: PaymentServicesApi.PaymentServiceDto
          ) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)}
              onConfirm={() =>
                onUpdate &&
                onUpdate(paymentService.paymentId, {
                  enabled: !enabled,
                } as PaymentServicesApi.UpdatePaymentService)
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)}
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            paymentService: PaymentServicesApi.PaymentServiceDto
          ) => <PaymentServiceOptions {...{ paymentService, onDelete }} />,
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      onRequestSort={onSort}
      columns={columns}
      rowKey="id"
      stickyHeader
    />
  );
};

export default PaymentServicesTable;
