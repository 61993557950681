import { useState, useEffect } from "react";
import { Box, Grid, LinearProgress, InputLabel } from "~/components/UI";
import { withStyles, createStyles } from "@material-ui/core/styles";
import api from "~/api";
import ReactJsonViewCompare from "react-json-view-compare";
import { useTranslation } from "react-i18next";

interface UserActivityDetailsProps {
  data: UserActivityJournalApi.UserActivityDto;
}

const Container = withStyles(() =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "100%",
      maxHeight: 300,
      overflow: "auto",
      flexDirection: "column",
      alignItems: "stretch",
      "& .c-json-view": {
        maxWidth: 1200,
        margin: 0,
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent",
      },
    },
  })
)(Grid);

// interface ColumnProps {
//   title?: string;
//   children?: React.ReactChild | React.ReactChild[];
// }

// const Column = ({ title, children }: ColumnProps) => (
//   <Grid item md={6} sm={12}>
//     <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
//       <Typography variant="subtitle1">{title}</Typography>
//     </Box>
//     <Box p={4} flexGrow={1}>
//       {children}
//     </Box>
//   </Grid>
// );

/**
 * @memberof UserActivity
 * @component
 * @desc Render detailed information about a UserActivity.
 * @property {UserActivityJournalApi.UserActivityDto} data - UserActivity data;
 */

const UserActivityDetails = ({ data }: UserActivityDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [userActivityDetails, setUserActivityDetails] =
    useState<UserActivityJournalApi.UserActivityDetailsDto>();
  const { t } = useTranslation();

  async function getUserActivity(id: string) {
    setLoading(true);
    try {
      const {
        data: { data: details },
      } = await api.userActivityJournal.getUserActivity(id);
      setUserActivityDetails(details);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUserActivity(data.activityId);
  }, [data]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (userActivityDetails) {
    return (
      <Container>
        <Box ml={2} mt={2}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InputLabel>ID</InputLabel>
            </Grid>
            <Grid item>{data.activityId}</Grid>
          </Grid>
          {userActivityDetails.error && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <InputLabel>{t("label.error")}</InputLabel>
              </Grid>
              <Grid item>{userActivityDetails.error}</Grid>
            </Grid>
          )}
        </Box>
        <ReactJsonViewCompare
          oldData={userActivityDetails.stateBefore}
          newData={userActivityDetails.stateAfter}
        />
      </Container>
    );
  } else {
    return null;
  }
};

export default UserActivityDetails;
