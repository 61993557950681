import { Box, Typography, useTheme } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { BATCH_STATUS, BATCH_STATUS_I18n } from "~/api/zReportsJournal/enum";

interface BatchStatusProps {
  status: ZReportsJournalApi.eBatchStatus;
}

/**
 * @memberof AuditZReportLog
 * @component
 * @desc Render status of ZReport.
 * @property {ZReportsJournalApi.eReportStatus} status - ZReport status;
 */

const BatchStatus = ({ status }: BatchStatusProps) => {
  const { t } = useTranslation(["enums"]);
  const theme = useTheme();
  let color = theme.palette.text.primary;

  switch (status) {
    case BATCH_STATUS.APPROVED:
      color = theme.palette.primary.main;
      break;
    case BATCH_STATUS.IN_PROGRESS:
    case BATCH_STATUS.REFUND_IN_PROGRESS:
    case BATCH_STATUS.REVERSAL_IN_PROGRESS:
      color = theme.palette.info.main;
      break;
    case BATCH_STATUS.REFUNDED:
    case BATCH_STATUS.REVERSALED:
      color = theme.palette.warning.main;
      break;
    case BATCH_STATUS.REFUND_ERROR:
    case BATCH_STATUS.REVERSAL_ERROR:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.error.main;
  }

  return (
    <Box color={color}>
      <Typography variant="body1">{t(BATCH_STATUS_I18n[status])}</Typography>
    </Box>
  );
};

export default BatchStatus;
