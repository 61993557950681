import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class RoleApi {
  rootPath: string = "/roles";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.roles');
  }

  getAll = (limit: number = 10, page: number = 1, query?: RolesApi.GetRolesQuery): Promise<Api.GetAll<RolesApi.RoleDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) }})

  deleteRoles = (ids: string[]): Promise<Api.Response<Api.DeleteResponseDto>> =>
    this.instance.delete(this.rootPath, { params: { ids } })

  createRole = (data: RolesApi.CreateRole): Promise<Api.GetOne<RolesApi.RoleWithPermissionDto>> =>
    this.instance.post(this.rootPath, data)

  getRole = (id: number | string): Promise<Api.GetOne<RolesApi.RoleWithPermissionDto>> =>
    this.instance.get(`${this.rootPath}/${id}`)

  updateRole = (id: number | string, data: RolesApi.UpdateRole): Promise<Api.GetOne<RolesApi.RoleWithPermissionDto>> =>
    this.instance.put(`${this.rootPath}/${id}`, data)

  deleteRole = (id: number | string): Promise<Api.Response<Api.DeleteResponseDto>> =>
    this.instance.delete(`${this.rootPath}/${id}`)

}
