import { createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    wrapper: {
      marginBottom: "14px",
      marginTop: "30px",
      padding: "0 10px",
      width: "100%",
    },
    checkWrapper: {
      backgroundColor: "#fff",
      boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.2)",
      fontSize: "12px",
      padding: "30px 10px 20px 10px",
      width: "100%",
    },
    centerBlock: {
      display: "flex",
      justifyContent: "center",
    },
    block: {
      display: "flex",
      justifyContent: "space-between",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    divider: {
      borderBottom: "1px dashed #000",
      margin: "5px 0",
      width: "100%",
    },
    largeText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    textWithPadding: {
      paddingLeft: "20px",
    },
    centerText: {
      textAlign: "center",
    },
    text: {
      marginTop: "5px",
    },
    shareWrapper: {
      marginTop: "20px",
    },
    shareText: {
      fontSize: "16px",
      marginBottom: "10px",
    },
    ShareButton: {
      display: "inline-block",
      marginRight: "25px",
    },
  })
);

const Receipt = ({ checkData }: any) => {
  const classes = useStyles();
  console.log(checkData);
  return (
    <div className={classes.wrapper}>
      <div className={classes.checkWrapper}>
        <div className={classes.centerBlock}>
          <div className={classes.centerText}>
            АЗС: {checkData.site || checkData.siteId}
          </div>
        </div>
        <div className={classes.centerBlock}>
          <div className={classes.centerText}>Каса: {checkData.pos}</div>
        </div>
        <div className={classes.divider}></div>
        {checkData.receiptNumberRef && (
          <div className={classes.centerBlock}>
            <div className={classes.centerText}>
              ПОВЕРНЕННЯ КОШТІВ ПО ЧЕКУ № {checkData.receiptNumberRef}
            </div>
          </div>
        )}
        {(checkData.service && checkData.service < 101) ||
        (checkData.serviceId && checkData.serviceId < 101) ? (
          <>
            {`ПРК № ${checkData.service || checkData.serviceId}`}
            {checkData?.items?.map((item: any) => (
              <div className={classes.column}>
                <div className={classes.text}>
                  {(item["q-ty"] || item.quantity) / 1000} л x{" "}
                  {(item.price / 100).toFixed(2)} грн
                </div>
                {item.discount && item.discount > 0 ? (
                  <div className={classes.text}>
                    Знижка: {item.discount / 100}
                  </div>
                ) : null}
                <div className={classes.text}>
                  Код: {item.article_code || item.articleCode}
                </div>

                <div className={classes.block}>
                  <div className={classes.text}>
                    {item.name || item.articleName}
                  </div>
                  <div className={classes.text}>
                    {(item.amount / 100).toFixed(2)} грн
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {checkData?.items?.map((item: any) => (
              <div className={classes.column}>
                <div className={classes.text}>
                  {(item["q-ty"] || item.quantity) / 1000} шт x{" "}
                  {item.price / 100}
                </div>
                {item.discount && item.discount > 0 ? (
                  <div className={classes.text}>
                    Знижка: {item.discount / 100}
                  </div>
                ) : null}
                <div className={classes.text}>
                  Код: {item.article_code || item.articleCode}
                </div>
                <div className={classes.block}>
                  <div className={classes.text}>
                    {item.name || item.articleName}
                  </div>
                  <div className={classes.text}>
                    {(item.amount / 100).toFixed(2)} грн
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <div className={classes.divider}></div>
        <div className={classes.block}>
          <div className={classes.largeText}>СУМА, грн.</div>
          <div className={classes.largeText}>
            {((checkData.totalamount || checkData.totalAmount) / 100).toFixed(
              2
            )}
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.block}>
          <div className={classes.text}>
            {checkData.receiptNumberRef ? `Повернення` : `Спалата`}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.text}>Екв. установа: {checkData.aquier}</div>
        </div>
        {checkData.transactionDetails.map((item: any) => (
          <div className={classes.column}>
            <div className={classes.text}>
              Платіжний пристрій: {item.term_id || item.terminalId}
            </div>
            <div className={classes.text}>
              Код. транз.: {item.retrieval_reference_no || item.rrn}
            </div>
            <div className={classes.text}>
              ЕПЗ: {item.masked_account_no || item.pan}
            </div>
            <div className={classes.text}>
              Код. авт.: {item.approval_code || item.approvalCode}
            </div>
            <div className={classes.text}>
              Ід. Мерчанта: {item.merchant_id || item.merchantId}
            </div>
          </div>
        ))}
        <div className={classes.block}>
          <div className={classes.text}>
            Операція: {moment(checkData.datetime).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.text}>Верифіковано через моб. додаток</div>
        </div>

        <div className={classes.divider}></div>
        <div className={classes.block}>
          <div className={classes.text}>Чек №{checkData.receiptNumber}</div>
          <div className={classes.text}>ФН {checkData.fiscsalnumber || checkData.fiscalNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
