import { enumToI18nKey } from "~/utils/helpers";

export const BUSINESS_OPERATION_ORIGIN_OF_ORDER = {
  FISHKA_APP: "FishkaAPP",
  DRIVER_APP: "DriverAPP",
  SELF_CHECKOUT_POS: "SelfCheckoutPOS",
  MOBILE_POS: "MobilePOS",
  POS: "POS",
  GLOVO: "Glovo",
} as { [key: string]: JournalBusinessOperationsApi.eOriginOfOrder };

export const BUSINESS_OPERATION_ORIGIN_OF_ORDER_I18n = enumToI18nKey(
  BUSINESS_OPERATION_ORIGIN_OF_ORDER,
  "BUSINESS_OPERATION_ORIGIN_OF_ORDER"
);

export const BUSINESS_OPERATION_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  INVOICE_REQUESTED: "INVOICE_REQUESTED",
  CANCELED: "CANCELED",
  ACCEPTED: "ACCEPTED",
  READY: "READY",
} as { [key: string]: JournalBusinessOperationsApi.eStatus };

export const BUSINESS_OPERATION_STATUS_I18n = enumToI18nKey(
  BUSINESS_OPERATION_STATUS,
  "BUSINESS_OPERATION_STATUS"
);

export const BUSINESS_OPERATION_STAGE_TYPE = {
  ORDERING: "Ordering",
  INVOICING: "Invoicing",
  PAYMENT: "Payment",
  REFUND: "Refund",
  VOID: "Void",
  DONE: "Done",
  COPMLETION: "Completion"
} as { [key: string]: JournalBusinessOperationsApi.eStageType };

export const BUSINESS_OPERATION_STAGE_TYPE_I18n = enumToI18nKey(
  BUSINESS_OPERATION_STAGE_TYPE,
  "BUSINESS_OPERATION_STAGE_TYPE"
);

export const BUSINESS_OPERATION_PAYMENT_STATUS = {
  PROCESSING: "processing",
  CREATED: "created",
  APPROVED: "approved",
  CANCELED: "canceled",
  COMPLETED: "completed",
  REFUNDED: "refunded",
  REFUNDING_PROGRESS: "refundinprogress",
  REFUNDED_ERROR: "refunderror",
} as { [key: string]: JournalBusinessOperationsApi.ePaymentStatuses };
