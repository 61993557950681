export const ENV = Object.assign({
    REACT_APP_CURRENCY_CODE: "UAH",
    REACT_APP_CURRENCY_SYMBOL: "₴",
    REACT_APP_MAX_LIMIT_FOR_METRICS_ON_DASHBOARD: 4,
    REACT_APP_MAX_LIMIT_FOR_JOURNALS_ON_DASHBOARD: 1,
    REACT_APP_POS_STATUS_REFRESH_TIME: 30
  },
  process.env, window._env_
);

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export default ENV;