import { Provider as StoreProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pages from "./pages";
import CustomThemeProvider from "~/components/CustomThemeProvider";
import AuthProvider from "~/components/AuthProvider";
import history from "./packages/history";
import { store } from "./store";
import "./packages/i18n";

const App = () => {
  return (
    <div className="app">
      <StoreProvider {...{ store }}>
        <ConnectedRouter {...{ history }}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <CustomThemeProvider>
              <AuthProvider>
                <Pages />
              </AuthProvider>
            </CustomThemeProvider>
          </QueryParamProvider>
        </ConnectedRouter>
      </StoreProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
