import { Box } from "~/components/UI";
import Tiles, { TilesProps } from "~/components/UI/Tiles";
import SiteCard from "./SiteCard";

interface SitesTilesProps
  extends Omit<TilesProps<Api.SiteDto>, "renderTaile"> {}

/**
 * @memberof Sites
 * @component
 * @desc Render tiles of sites.
 * @property {Api.SiteDto[]} list - Sites data
 */

const SitesTiles = ({ ...props }: SitesTilesProps) => (
  <Box pb={2}>
    <Tiles
      {...props}
      customHeight={true}
      renderTaile={(site, key) => <SiteCard {...{ key, site }} />}
    />
  </Box>
);

export default SitesTiles;
