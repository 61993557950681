import CollectedMoneyCard from "~/components/Metrics/CollectedMoneyCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const CollectedMoney = ({ siteId }: { siteId: string }) => {
  const { t } = useTranslation();
  const getCollectedMoney = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getMoneyBySite({
        siteId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CollectedMoneyCard
      title={`${t("title.amountOfCollectedMoneyBy")} ${t(
        "text.site"
      )}: ${siteId}`}
      onUpdate={getCollectedMoney}
    />
  );
};

export default CollectedMoney;
