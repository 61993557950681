import { useTranslation } from "react-i18next";
import { Icon, Grid, Label, Box } from "~/components/UI";

interface MoreInformationProps {
  pos: Api.PosDto;
  size?: "default" | "small";
}

/**
 * @memberof POSes
 * @component
 * @desc Render information about POS.
 * @property {string} pos POS
 * @property {"default" | "small"} size table size
 */

const MoreInformation = ({ pos, size = "default" }: MoreInformationProps) => {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Grid container wrap="nowrap">
        <Grid item sm={6}>
          <Label text={t("label.description")}>{pos.description}</Label>
          <Label text={t("label.key")}>{pos.signatureKey}</Label>
          <Label text={t("label.signatureVerification")}>
            {pos.checkSignature ? (
              <Icon name="CheckCircleOutline" size="small" color="success" />
            ) : (
              <Icon name="DangerousOutline" size="small" color="error" />
            )}
          </Label>
        </Grid>
        <Grid item sm={6}>
          <Label text={t("label.certificate")}>
            <>
              {pos.certificate ? t("text.generated") : t("text.not_generated")}
            </>
          </Label>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoreInformation;
