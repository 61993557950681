import React, { useState, useEffect } from 'react';
import { useField, FieldHookConfig, FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_DELAY = 100;

export function useFastField<Val = any>(
  propsOrFieldName: string | FieldHookConfig<any>,
): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName);
  const [value, setValue] = useState<Val>(field.value);
  const { onBlur, onChange } = field;
  const { setValue: helpersSetValue } = helpers;

  const currentFieldValue = field.value;

  useEffect(() => {
    if (currentFieldValue !== value) {
      setValue(currentFieldValue);
    }
  }, [currentFieldValue]);

  const onSync = useDebouncedCallback((e) => {
    onChange(e);
    onBlur(e);
  }, DEBOUNCE_DELAY);

  const onSyncValue = useDebouncedCallback((val: Val) => {
    helpersSetValue(val);
    helpers.setTouched(true);
  }, DEBOUNCE_DELAY);

  field.value = value;
  field.onChange = (e: React.ChangeEvent<any>): void => {
    if (e && e.currentTarget) {
      setValue(e.currentTarget.value);
    }
    onSync(e);
  };
  field.onBlur = (e: any): void => {
    onChange(e);
    onBlur(e);
  };

  helpers.setValue = (val: Val) => {
    setValue(val);
    onSyncValue(val);
  };

  return [field, meta, helpers];
}

export default useFastField;