import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  InputLabel,
} from "~/components/UI";
import ReactJson from "react-json-view";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface EventDetailsProps {
  data: EventsLogJournalApi.EventDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      overflow: "auto",
      flexWrap: "nowrap",
    },
  })
)(Grid);

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    drug: {
      cursor: "s-resize",
      padding: "5px 20px",
      boxShadow: "3px 3px 6px 3px rgba(0,0,0,0.2)",
    },
  })
);

/**
 * @memberof EventsLog
 * @component
 * @desc Render detailed information about an Event.
 * @property {EventsLogJournalApi.EventDto} data - Event data;
 */

const EventDetailsDetails = ({ data }: EventDetailsProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [eventDetails, setEventDetails] =
    useState<EventsLogJournalApi.EventDetailsDto>();
  const { t } = useTranslation();

  async function getEventDetailes(id: string) {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await api.eventsLogJournal.getEvent(id);
      setEventDetails(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEventDetailes(data.id);
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  } else if (eventDetails) {
    return (
      <Container container direction="column" alignItems="stretch">
        <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
          <Typography variant="subtitle1">{t("title.context")}</Typography>
        </Box>
        <Box p={4} flexGrow={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InputLabel>ID</InputLabel>
            </Grid>
            <Grid item>{data.id}</Grid>
          </Grid>
          <ReactJson displayDataTypes={false} style={{wordBreak: "break-all"}} collapseStringsAfterLength={80} src={JSON.parse(eventDetails.contextData)} />

          {eventDetails.errorData && (
            <Box>
              <Grid item>
                <InputLabel>{t("title.errorData")}</InputLabel>
              </Grid>
              <ReactJson collapseStringsAfterLength={80} src={JSON.parse(eventDetails.errorData)} />
            </Box>
          )}
        </Box>
      </Container>
    );
  } else {
    return null;
  }
};

export default EventDetailsDetails;
