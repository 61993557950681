import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import POSesContext from "./posesContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface POSOptionsProps {
  pos: Api.PosDto;
  hideEditOptions?: boolean;
}

/**
 * @memberof POSes
 * @component
 * @desc POS options. Using a POSesContext.
 * @property {Api.PosDto} pos - POS data;
 * @requires POSesContext
 */

const POSOptions = ({ pos, hideEditOptions }: POSOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdate, onUpdateItem, onDelete } = useContext(POSesContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.POS);
  const { enabled } = pos;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (pos.incidents) {
        pos.incidents.count++;
        pos.incidents.incidentIds.push(incident.incidentId);
      } else {
        pos.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(pos);
    },
    [pos, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_POS,
      PERMISSION.DELETE_POS,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_POS),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(onUpdate(pos.posId, { enabled: !enabled })),
      },
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_POS) || hideEditOptions,
      onClick: () => onEdit && onEdit(pos),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_POS) || hideEditOptions,
      onClick: () => onDelete && onUpdateHandler(onDelete(pos)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(pos.posId, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default POSOptions;
