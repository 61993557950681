import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withStyles, createStyles } from "@material-ui/core/styles";

interface FormControlLabelProps {
  text?: string;
  verticalAlign?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
  children?: React.ReactChild | React.ReactChild[] | null;
  extra?: React.ReactChild | React.ReactChild[];
}

const LabelContainer = withStyles(() =>
  createStyles({
    root: {
      width: 150,
      "& > label": {
        marginTop: "5px",
        lineHeight: 1.5,
        "&::first-letter": {
          textTransform: "uppercase",
        },
      },
    },
  })
)(Grid);

const Label = ({
  text,
  verticalAlign = "flex-start",
  children,
  extra,
}: FormControlLabelProps) => (
  <Grid container alignItems={verticalAlign} wrap="nowrap">
    <Box my={2}>
      <LabelContainer item>
        <InputLabel>{text}</InputLabel>
      </LabelContainer>
    </Box>
    <Grid item sm={8}>
      <Box my={2} lineHeight={2}>
        {children}
      </Box>
    </Grid>
    {extra && (
      <Grid item sm={2}>
        <Box mb={2}>
          <Grid container justifyContent="flex-end">
            {extra}
          </Grid>
        </Box>
      </Grid>
    )}
  </Grid>
);

export default Label;
