import { TFunction } from "i18next";
import * as Yup from "yup";
import { isEmptyValue } from "~/utils/helpers";

let YupInstance = Yup;

export const initialize = (t: TFunction) => {

  /* String */
  YupInstance.addMethod(Yup.string, "required", function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Only characters  */
  YupInstance.addMethod(Yup.string, "onlyCharacters", function () {
    return this.test({
      name: "onlyCharacters",
      message: () => t("validation.onlyCharacters"),
      test: (value) => value ? /^[^\s]+$/.test(value) : true,
    })
  });

  /* POS ID */
  YupInstance.addMethod(Yup.string, "posId", function () {
    return this.test({
      name: "posId",
      message: () => t("validation.posId"),
      test: (value) => value ? /^[0-9]+$/.test(value) : true
    })
  });

  /* SERVICE ID */
  YupInstance.addMethod(Yup.string, "serviceId", function () {
    return this.test({
      name: "serviceId",
      message: () => t("validation.serviceId"),
      test: (value) => value ? /^[0-9]+$/.test(value) : true
    })
  });

  /* Latitude */
  YupInstance.addMethod(Yup.string, "lat", function () {
    return this.test({
      name: "lat",
      message: () => t("validation.latitude"),
      test: (value) => value
        ? /^-?\d{1,2}\.{1}\d{6,7}$/.test(value) && Number(value) <= 90 && Number(value) >= -90
        : true
    })
  });

  /* Longitude */
  YupInstance.addMethod(Yup.string, "lng", function () {
    return this.test({
      name: "lng",
      message: () => t("validation.longitude"),
      test: (value) => value
        ? /^-?\d{1,3}\.{1}\d{6,7}$/.test(value) && Number(value) <= 180 && Number(value) >= -180
        : true
    })
  });

  /* ID Prefix */
  YupInstance.addMethod(Yup.string, "idPrefix", function () {
    return this.test({
      name: "idPrefix",
      message: () => t("validation.idPrefix"),
      test: (value) => value ? /^[A-Z, a-z]{2}$/.test(value) : true
    })
  });

  /* keyVaultName */
  /* eslint-disable no-useless-escape */
  YupInstance.addMethod(Yup.string, "keyVaultName", function () {
    return this.test({
      name: "keyVaultName",
      message: () => t("validation.keyVaultName"),
      test: (value) => value ? /^([A-Za-z0-9\-])+$/.test(value) : true
    })
  });  
  /* eslint-disable no-useless-escape */

  /* Number */
  YupInstance.addMethod(Yup.number, "required",  function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Date */
  YupInstance.addMethod(Yup.date, "required",  function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Array */
  YupInstance.addMethod(Yup.array, "required",  function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Object */
  YupInstance.addMethod(Yup.object, "required",  function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Boolean */
  YupInstance.addMethod(Yup.boolean, "required",  function (m) {
    return this.test({
      name: "required",
      message: () => m || t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });
};

export default YupInstance as any;
