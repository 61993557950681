import React, { useCallback } from "react";
import clsx from "clsx";
import Drawer, { DrawerProps } from "~/components/UI/Drawer";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Button,
  makeStyles,
  createStyles,
  Theme,
  CardActionArea,
} from "~/components/UI";
import Alerts from "./Alerts";
import { closeSidebar } from "~/store/alerts/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { INCIDENT_STATUS } from "~/api/incidents/enums";
import Routes from "~/constants/routes";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      boxSizing: "border-box",
    },
    closeBtn: {
      cursor: "pointer",
    },
    app: {
      boxSizing: "border-box",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: "100%",
    },
    appShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
  })
);

interface AlertPanelProps {
  children: React.ReactChildren | React.ReactNode;
}

const AlertPanel = ({
  children,
  ...other
}: AlertPanelProps & Omit<DrawerProps, "onClose">) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpenSideBar = useSelector(
    (store: ReduxStore.State) => store.alerts.isOpenSideBar
  );
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const showAllIncidents = useCallback(() => {
    history.push(`${Routes.INCIDENTS}?status=${INCIDENT_STATUS.OPENED}`);
  }, [history]);

  if (!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)) {
    return <div className={classes.app}>{children}</div>;
  }

  return (
    <>
      <div
        className={clsx(classes.app, {
          [classes.appShift]: isOpenSideBar,
        })}
      >
        {children}
      </div>
      <Drawer
        {...other}
        open={isOpenSideBar}
        variant="persistent"
        anchor="right"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box p={5.5}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">{t("title.alerts")}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="small"
                className={classes.closeBtn}
                onClick={() => {
                  dispatch(closeSidebar());
                }}
              >
                {t("button.close")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} pt={6} p={4} overflow="auto">
          <Alerts />
        </Box>
        <Box pb={3} bgcolor="background.default">
          <Divider />
          <CardActionArea onClick={showAllIncidents}>
            <Box textAlign="center" p={5} bgcolor="background.paper">
              <Typography variant="subtitle1" color="textSecondary">
                {t("button.showAllOpenedIncidents")}
              </Typography>
            </Box>
          </CardActionArea>
          <Divider />
        </Box>
      </Drawer>
    </>
  );
};

export default AlertPanel;
