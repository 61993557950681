import InvoiceDurationCard from "~/components/Metrics/InvoiceDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

interface InvoiceDurationProps {
  service: Api.ServiceDto;
}

const InvoiceDuration = ({ service }: InvoiceDurationProps) => {
  const { t } = useTranslation();
  const getInvoiceDuration = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getInvoiceDurationByService({
        serviceId: service.serviceId,
        siteId: service.siteId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <InvoiceDurationCard
      title={`${t("title.invoiceDurationBy")} ${t("text.service")}: ${
        service.serviceId
      }`}
      onUpdate={getInvoiceDuration}
    />
  );
};

export default InvoiceDuration;
