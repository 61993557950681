import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CommonTile from "./CommonTile";
import { numeralFormatter } from "~/components/Metrics/Charts/Common";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const BusinessTransactionCountsTile = () => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const onUpdate = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await api.dashboardMetrics.getBusinessTransactionsCountMetric();
      return data;
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (!hasAll(PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS)) return null;

  return (
    <CommonTile
      label={t("title.businessTransactions")}
      trendDescription={t("text.lastWeek")}
      formattingValue={numeralFormatter}
      onUpdate={onUpdate}
    />
  );
};

export default BusinessTransactionCountsTile;
