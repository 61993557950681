import { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  DialogContent,
  Table,
  LinearProgress,
} from "~/components/UI";
import Dialog, { DialogProps, Title } from "~/components/UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { currencyString } from "~/utils/format";
import Highlighter from "react-highlight-words";
import Search from "~/components/Search";
import _ from "lodash";
import api from "~/api";

interface UpdateServicesProps extends DialogProps {
  service?: any;
  onClose?: () => void;
}

let searchWord: string | undefined = "";

const defaultColumns = [
  {
    key: "articleCode",
    i18nKey: "vendorCode",
    render: (articleCode: string) => (
      <Highlighter
        searchWords={[searchWord || ""]}
        textToHighlight={articleCode?.toString()}
      />
    ),
  },
  {
    key: "articleName",
    i18nKey: "name",
    render: (articleCode: string) => (
      <Highlighter
        searchWords={[searchWord || ""]}
        textToHighlight={articleCode}
      />
    ),
  },
  {
    key: "barCodes",
    i18nKey: "barcode",
    render: (barCodes: { Code: string }[]) =>
      Array.isArray(barCodes) && barCodes[0] ? (
        <Highlighter
          searchWords={[searchWord || ""]}
          textToHighlight={barCodes[0].Code}
        />
      ) : (
        ""
      ),
  },
  {
    key: "price",
    i18nKey: "price",
    render: (value: number) => (
      <Highlighter
        searchWords={[searchWord || ""]}
        textToHighlight={currencyString(value)}
      />
    ),
  },
];

const GoodsDialog = ({
  open,
  siteId,
  onClose,
}: UpdateServicesProps & { siteId?: string }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<GoodsApi.GoodDto[]>();
  const [filteredData, setFilteredData] = useState<GoodsApi.GoodDto[]>();
  const [searchValue, setSearchValue] = useState<string>();

  const getColumns = useCallback(
    () =>
      defaultColumns.map((c) => {
        return i18nTableColumn(c, t);
      }),
    [t]
  );

  const getGoods = useCallback(async () => {
    if (!siteId) return;
    setLoading(true);
    try {
      const { data } = await api.goods.getGoodsForSite(siteId);
      setDataSource([...data.data]);
    } finally {
      setLoading(false);
    }
  }, [siteId]);

  useEffect(() => {
    getGoods();
    setDataSource(undefined);
  }, [siteId]);

  useEffect(() => {
    if (searchValue && dataSource) {
      setFilteredData(
        dataSource.filter(({ articleCode, articleName, barCodes, price }) => {
          return Boolean(
            Object.keys(
              _.pickBy(
                {
                  articleCode,
                  articleName,
                  barCodes,
                  price: currencyString(price),
                },
                (value) => {
                  const str =
                    Array.isArray(value) && value[0] && value[0].Code
                      ? value[0].Code.toString()
                      : value?.toString()?.toLowerCase();
                  return str && str.toLowerCase().includes(searchValue);
                }
              )
            ).length
          );
        })
      );
    } else {
      setFilteredData([]);
    }
    searchWord = searchValue;
  }, [searchValue, dataSource]);

  return (
    <Dialog
      title={
        <Grid container alignContent="center">
          <Grid item>
            <Title>
              {t("title.goods")}{" "}
              <Typography component="span" variant="h3" color="textSecondary">
                {searchValue
                  ? filteredData && filteredData.length
                  : dataSource && dataSource.length}
              </Typography>
            </Title>
          </Grid>
          <Box pl={5} width={350}>
            <Search
              placeholder={t("text.searchForArticleName")}
              onSearch={setSearchValue}
            />
          </Box>
        </Grid>
      }
      open={open}
      onClose={() => {
        setSearchValue(undefined);
        onClose && onClose();
      }}
      closable
    >
      <DialogContent>
        <Box pb={4} flexGrow={1} overflow="auto">
          <LinearProgress hidden={!loading} />
          <Table
            columns={getColumns()}
            stickyHeader
            dataSource={searchValue ? filteredData : dataSource}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GoodsDialog;
