import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  LinearProgress,
  Divider,
  Box,
  SubSection,
} from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import _ from "lodash";
import Routes from "~/constants/routes";
import api from "~/api";

interface DetailsOfServicesProps {
  posId: string;
  size?: "default" | "small";
}

type GroupedByCategory = { title: string; services: Api.ServiceDto[] }[];

function groupByCategory(services: Api.ServiceDto[]): GroupedByCategory {
  return _.chain(services)
    .groupBy("category")
    .map((value, key) => ({ title: key, services: value }))
    .value();
}

const ServiceItem = ({ service }: { service: Api.ServiceDto }) => (
  <Box py={2}>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          <Link
            to={`${Routes.SERVICES}?serviceId=${service.serviceId}&siteId=${service.siteId}`}
          >
            {service.serviceName} / ID: {service.serviceId}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <IncidentsIcon incidents={service.incidents} size="small" />
      </Grid>
    </Grid>
  </Box>
);

/**
 * @memberof POSes
 * @component
 * @desc Render information about available services.
 * @property {string} posId POS id
 * @property {"default" | "small"} size table size
 */

const DetailsOfServices = ({
  posId,
  size = "default",
}: DetailsOfServicesProps) => {
  const [loading, setLoading] = useState(false);
  const [groupedServices, setGroupedServices] = useState<GroupedByCategory>();

  async function getServices(posId: string) {
    setLoading(true);
    try {
      const { data } = await api.poses.getSerivices(posId);
      const groupedList = groupByCategory(data.data);
      setGroupedServices(groupedList);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getServices(posId);
  }, [posId]);

  if (loading) {
    return <LinearProgress />;
  } else if (groupedServices && groupedServices.length) {
    return (
      <Box px={3}>
        <Grid container>
          <Grid item sm={size !== "small" ? 6 : 12}>
            <Box p={2}>
              {groupedServices.map((category, index) => (
                <SubSection
                  key={index}
                  title={`${category.title} (${category.services.length})`}
                  collapsed
                >
                  {category.services.map((service, index) => (
                    <>
                      {!!index && <Divider key={`service-divider-${index}`} />}
                      <ServiceItem
                        key={`service-item-${index}`}
                        service={service}
                      />
                    </>
                  ))}
                </SubSection>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

export default DetailsOfServices;
