import { Box, Typography } from "~/components/UI";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import GraphicGridContainer from "./GraphicGridContainer";
import { useTranslation } from "react-i18next";
import { ANIMATION_DURATION, dateFormatter, numeralFormatter } from "./Common";

interface QueuesCreatedProps {
  period?: MetricsAPI.eTimePeriod;
  list?: MetricsAPI.MetricDataDto;
  yAxis?: string;
}

const TooltipContent = ({ time, queues }: { time: string; queues: string }) => {
  const { t } = useTranslation();
  return (
    <Box border={1} borderColor="divider" p={3} bgcolor="background.paper">
      <Typography gutterBottom>
        <strong>{t("label.time")}:</strong> {time}
      </Typography>
      <Typography>
        <strong>{t("label.queues")}:</strong> {queues}
      </Typography>
    </Box>
  );
};

const QueuesCreated = ({ period, list, yAxis }: QueuesCreatedProps) => {
  return (
    <GraphicGridContainer yAxis={yAxis}>
      <BarChart data={list as any}>
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="createdAt"
          domain={["auto", "auto"]}
          tickCount={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={dateFormatter(period)}
        />
        <YAxis
          type="number"
          allowDecimals={false}
          domain={[0, "auto"]}
          tickLine={false}
          axisLine={false}
          width={25}
          tickFormatter={numeralFormatter}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            return active && payload && payload.length ? (
              <TooltipContent
                time={dateFormatter(period)(label)}
                queues={payload[0].value}
              />
            ) : null;
          }}
        />
        <Bar
          animationDuration={ANIMATION_DURATION}
          dataKey="count"
          fill="#D9F3DB"
        />
      </BarChart>
    </GraphicGridContainer>
  );
};

export default QueuesCreated;
