import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "../";
import MUIOutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import useFastField from "~/hook/useFastField";
import FieldError from "../Form/FieldError";
// import { useDebouncedCallback } from "use-debounce";
import { withStyles, createStyles } from "@material-ui/core/styles";

type InputProps = Omit<OutlinedInputProps, "error"> & {
  loading?: boolean;
  name?: string;
  touched?: boolean;
  error?: string | boolean;
};

// const INPUT_DELAY = 100;

const OutlinedInputWrapper = React.memo(
  ({ touched, error, name, loading, ...props }: InputProps) => {
    const [innerValue, setInnerValue] = useState("");

    useEffect(() => {
      if (props.value) {
        setInnerValue(props.value as string);
      } else {
        setInnerValue("");
      }
    }, [props.value]);

    // const debouncedHandleOnChange = useDebouncedCallback(
    //   (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (props.onChange) {
    //       props.onChange(event);
    //     }
    //   },
    //   INPUT_DELAY
    // );

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        const newValue = event.currentTarget.value;
        setInnerValue(newValue);

        if (props.onChange) {
          props.onChange(event);
        }
        // debouncedHandleOnChange(event);
      },
      []
    );

    return (
      <>
        <MUIOutlinedInput
          {...props}
          value={innerValue}
          name={name}
          onChange={handleOnChange}
          error={Boolean(error)}
          endAdornment={
            <>
              {loading && <CircularProgress size={15} />}
              {props.endAdornment}
            </>
          }
        />
        {error && <FieldError>{error}</FieldError>}
      </>
    );
  }
);

const OutlinedInput = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      backgroundColor: "transparent",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      padding: "10px 10px 10px 20px",
      boxSizing: "border-box",
      minWidth: 205,
    },
    input: {
      padding: 0,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
  })
)(OutlinedInputWrapper);

const FormikInput = ({ name, ...props }: InputProps) => {
  const [field, { error, touched }] = useFastField(name || "");
  return (
    <OutlinedInput
      {...field}
      value={field.value || ""}
      {...props}
      {...{ touched, error, name }}
    />
  );
};

const Input = ({
  formikControll,
  ...inputProps
}: InputProps & { formikControll?: boolean }) => {
  return formikControll && inputProps.name ? (
    <FormikInput {...inputProps} />
  ) : (
    <OutlinedInput {...inputProps} />
  );
};

export default React.memo(Input);
