import { Typography, Box } from "~/components/UI";
import {
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import GraphicGridContainer from "./GraphicGridContainer";
import { ANIMATION_DURATION, numeralFormatter, dateFormatter } from "./Common";
import { useTranslation } from "react-i18next";

interface ClientsChartProps {
  period?: MetricsAPI.eTimePeriod;
  list?: MetricsAPI.MetricDataDto;
}

const TooltipContent = ({
  time,
  clients,
}: {
  time: string;
  clients: string;
}) => {
  const { t } = useTranslation();
  return (
    <Box border={1} borderColor="divider" p={3} bgcolor="background.paper">
      <Typography gutterBottom>
        <strong>{t("label.time")}:</strong> {time}
      </Typography>
      <Typography>
        <strong>{t("label.clients")}:</strong> {clients}
      </Typography>
    </Box>
  );
};

const ClientsChart = ({ period, list }: ClientsChartProps) => {
  if (!list) return null;

  return (
    <GraphicGridContainer>
      <LineChart data={list as any}>
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="createdAt"
          domain={["auto", "auto"]}
          tickCount={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={dateFormatter(period)}
        />
        <YAxis
          type="number"
          allowDecimals={false}
          domain={[0, "auto"]}
          tickLine={false}
          axisLine={false}
          width={25}
          tickFormatter={numeralFormatter}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            return active && payload && payload.length ? (
              <TooltipContent
                time={dateFormatter(period)(label)}
                clients={payload[0].value}
              />
            ) : null;
          }}
        />
        <Line
          type="linear"
          dataKey="count"
          strokeWidth={3}
          dot={false}
          stroke="#56C75B"
          activeDot={{ r: 10, strokeWidth: 2, fill: "#56C75B" }}
          animationDuration={ANIMATION_DURATION}
        />
      </LineChart>
    </GraphicGridContainer>
  );
};

export default ClientsChart;
