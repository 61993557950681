import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Table,
  Typography,
  Switch,
  MUIFormControlLabel,
  ControlsGroup,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import _ from "lodash";

const defaultColumns = [
  {
    key: "name",
    i18nKey: "roleName",
  },
  {
    key: "status",
    i18nKey: "enabled",
    cellOptions: {
      align: "right",
      width: 50,
    },
  },
];

export type Role = {
  name: string;
  roleId: string;
  status: boolean;
};

interface RolesManagementProps {
  roles: Role[];
  onChange: (roles: string[]) => void;
}

export const getActivatedRoles = (roles: Role[]) =>
  roles.filter((r) => r.status).map((r) => r.roleId);

const RolesManagement = ({ roles, onChange }: RolesManagementProps) => {
  const [state, setState] = useState<{ [key: string]: Role }>();
  const [showEnabled, setShowEnabled] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const { t } = useTranslation();

  const onUpdateRole = useCallback(
    (roleId: string, checked: boolean) => {
      setState((state) => {
        if (state && state[roleId]) {
          state[roleId].status = checked;
        }
        const activatedRoles = getActivatedRoles(_.values(state));
        onChange(activatedRoles);
        return { ...state } || {};
      });
    },
    [state]
  );

  const getFilteredList = useCallback(
    (roles: Role[]) => {
      if (!Array.isArray(roles)) return [];
      if (!showEnabled && !showDisabled) return roles;

      return roles.filter(
        (r) => (showEnabled && r.status) || (showDisabled && !r.status)
      );
    },
    [showEnabled, showDisabled]
  );

  useEffect(() => {
    if (roles) {
      const newState = _.flatten(_.values(roles)).reduce(
        (o, p) => Object.assign(o, { [p.roleId]: p }),
        {}
      );
      setState(newState);
    } else {
      setState({});
    }
  }, [roles]);

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "status":
        return {
          ...column,
          render: (_value: unknown, row: Role) => (
            <Switch
              checked={row.status}
              onChange={(_e, checked) => {
                onUpdateRole(row.roleId, checked);
              }}
            />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <>
      <Box pb={4}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">{t("title.roles")}</Typography>
          </Grid>
          <Grid item>
            <ControlsGroup>
              <MUIFormControlLabel
                control={
                  <Checkbox
                    checked={showEnabled}
                    onChange={(_e, checked) => setShowEnabled(checked)}
                    size="small"
                  />
                }
                label={t("option.showEnabled")}
              />
              <MUIFormControlLabel
                control={
                  <Checkbox
                    checked={showDisabled}
                    onChange={(_e, checked) => setShowDisabled(checked)}
                    size="small"
                  />
                }
                label={t("option.showDisabled")}
              />
            </ControlsGroup>
          </Grid>
        </Grid>
      </Box>
      <Box border={1} borderColor="divider" mb={4} flexGrow={1}>
        <Table
          columns={columns}
          dataSource={getFilteredList(_.values(state))}
          rowKey="roleId"
        />
      </Box>
    </>
  );
};

export default RolesManagement;
