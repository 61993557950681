import { useState, useCallback, useEffect } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Routes from "~/constants/routes";
import Metrics from "./Components/Metrics";
import { RouteChildrenProps } from "react-router";
import api from "~/api";

/**
 * @namespace Sites
 */

const SiteMetricsPage = ({
  location,
  match,
}: RouteChildrenProps<{ siteId: string }, { site?: Api.SiteDetailsDto }>) => {
  const [site, setSite] = useState<Api.SiteDetailsDto>();
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.sites"), href: Routes.SITES },
      {
        label: site ? site.name : match ? match.params.siteId : "",
        href: Routes.SITES + `?siteId=${match && match.params.siteId}`,
      },
      { label: t("title.metrics") },
    ];
  }, [t, site, match]);

  const getSite = async (siteId: string) => {
    try {
      const {
        data: { data },
      } = await api.sites.getSite(siteId);
      setSite(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setSite(location.state.site);
    } else if (match) {
      getSite(match.params.siteId);
    }
  }, [location]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Metrics />
    </>
  );
};

export default SiteMetricsPage;
