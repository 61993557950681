import { createContext } from 'react';

export interface PaymentServicesContextProps {
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (payment: PaymentServicesApi.PaymentServiceDto) => void;
  onUpdate?: (id: string, formData: PaymentServicesApi.UpdatePaymentService) => Promise<void>;
  onDelete?: (payment: PaymentServicesApi.PaymentServiceDto) => Promise<void>;
}

/**
 * @memberof PaymentServices
 * @component
 * @desc React context for providing methods of Payment Services to child components.
 * @property {Function} onCreate - callback for open the Create Service dialog. (formData: PaymentServicesApi.CreatePaymentService) => Promise<void>
 * @property {Function} onEdit - callback for open the Edit Service dialog. (payment: PaymentServicesApi.PaymentServiceDto) => void
 * @property {Function} onUpdate - callback for update a Service. (id: string, formData: PaymentServicesApi.UpdatePaymentService) => Promise<void>
 * @property {Function} onDelete - callback for the delete a Service. (payment: PaymentServicesApi.PaymentServiceDto) => Promise<void>
 */

const PaymentServicesContext = createContext<PaymentServicesContextProps>({});


export default PaymentServicesContext;
