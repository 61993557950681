import QueuesCreatedCard from "~/components/Metrics/QueuesCreatedCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const Queues = ({ posId }: { posId: string }) => {
  const { t } = useTranslation();
  const getQueues = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getQueuesByPOS({
        posId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getQueuesOnline = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getQueuesOnlineByPOS({
        posId,
        ...query,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <QueuesCreatedCard
      title={`${t("title.queuesCreatedBy")} POS: ${posId}`}
      onUpdate={getQueues}
      onUpdateOnline={getQueuesOnline}
    />
  );
};

export default Queues;
