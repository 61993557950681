import { useState, useEffect } from "react";
import { Grid, Select } from "~/components/UI";
import BaseCard from "./BaseCard";
import FinancialTransactionCount from "./Charts/FinancialTransactionCount";
import { eTimeInterval } from "./TimeIntervalSelector";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";
import {
  FINANCIAL_TRANSACTION_FILTER,
  FINANCIAL_TRANSACTION_FILTER_I18n,
} from "~/api/metrics/enums";

interface FinancialTransactionCountsCardProps {
  title?: string;
  onUpdate: (
    params: MetricsAPI.GetMetricQuery & MetricsAPI.GetFinancialTransactionQuery
  ) => Promise<MetricsAPI.FinancialTransactionCountDto | undefined>;
}

const FinancialTransactionCountsCard = ({
  title,
  onUpdate,
}: FinancialTransactionCountsCardProps) => {
  const { t } = useTranslation(["enums"]);
  const { hasAll } = usePermissions();
  const [filter, setFilter] = useState(FINANCIAL_TRANSACTION_FILTER.COMPLETED);
  const [filterOptions, setFilterOptions] = useState<
    { label: string; value: any }[]
  >([]);

  useEffect(() => {
    setFilterOptions([
      {
        label: t(
          FINANCIAL_TRANSACTION_FILTER_I18n[FINANCIAL_TRANSACTION_FILTER.COMPLETED]
        ),
        value: FINANCIAL_TRANSACTION_FILTER.COMPLETED,
      },
      {
        label: t(
          FINANCIAL_TRANSACTION_FILTER_I18n[
            FINANCIAL_TRANSACTION_FILTER.FAILED_BY_CLIENT
          ]
        ),
        value: FINANCIAL_TRANSACTION_FILTER.FAILED_BY_CLIENT,
      },
      {
        label: t(
          FINANCIAL_TRANSACTION_FILTER_I18n[
            FINANCIAL_TRANSACTION_FILTER.FAILED_BY_INTERNAL
          ]
        ),
        value: FINANCIAL_TRANSACTION_FILTER.FAILED_BY_INTERNAL,
      },
    ]);
  }, [t]);

  if (!hasAll(PERMISSION.READ_FINANCIAL_TRANSACTIONS_METRICS))
    return null;

  return (
    <Grid item lg={12} xl={6}>
      <BaseCard
        title={title || "Financial Transaction Counts"}
        timeIntervals={[
          eTimeInterval.LAST_MINUTE,
          eTimeInterval.LAST_HOUR,
          eTimeInterval.LAST_24_HOURS,
        ]}
        onUpdate={onUpdate}
        extraParams={{ filter }}
        extra={
          <Select
            variant="outlined"
            value={filter}
            options={filterOptions}
            onChange={(e) =>
              setFilter(e.target.value as MetricsAPI.eFinancialTransactionFilter)
            }
          />
        }
      >
        {(data) =>
          data ? (
            <FinancialTransactionCount period={data.period} list={data.list} />
          ) : null
        }
      </BaseCard>
    </Grid>
  );
};

export default FinancialTransactionCountsCard;
