import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Section,
  Typography,
  LinearProgress,
  Divider,
  Pagination,
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { getSortStr } from "~/utils/helpers";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof AuditZReportLog
 * @component
 * @desc AuditZReportLog Controller.
 */

const AuditZReportLogs = () => {
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [filters, setFilters] = useState<ZReportsJournalApi.GetReportsQuery>();
  const [query, setQuery] = useState<ZReportsJournalApi.GetReportsQuery>();
  const [limit, setLimit] = useState<number>();
  const [data, setData] = useState<ZReportsJournalApi.ReportDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const { t } = useTranslation();

  const getAuditZReportLogs = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta },
        } = await api.zReportsJournal.getReports(limit, page || currentPage, {
          ...query,
          sort: query?.sort || "-createdAt",
        });
        data && setData(data);
        meta && setPagination(meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [query, data, pagination, limit]
  );

  const onSort = (field: string, order: "desc" | "asc") => {
    setSort(getSortStr(field, order));
  };

  const onChangePagination = useCallback(
    (page: number) => {
      getAuditZReportLogs(page);
    },
    [query, limit]
  );

  const onChangeFilters = useCallback((filters) => {
    setPagination(undefined);
    setFilters(filters);
  }, []);

  useEffect(() => {
    setQuery({ sort, ...filters });
  }, [sort, filters]);

  useEffect(() => {
    query && getAuditZReportLogs();
  }, [query, limit]);

  return (
    <>
      <Box>
        <Section
          title={
            <Typography variant="h4">
              {t("title.auditZReportLog")}{" "}
              <Typography variant="h4" component="span" color="textSecondary">
                {pagination && pagination.totalCount}
              </Typography>
            </Typography>
          }
          extra={
            <Filter initialValues={filters} onChangeFilter={onChangeFilters} />
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto">
        <Table
          dataSource={data}
          hasMore={true}
          dataLength={data ? data.length : 0}
          onSort={onSort}
        />
      </Box>
      <Box>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default AuditZReportLogs;
