import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import SelectUniqueService from "~/components/CommonFormControls/SelectUniqueService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";


interface UpdateBundleProps extends Omit<DialogProps, "onClose"> {
  bundle?: Api.BundleDto;
  onClose?: (result?: boolean) => void;
}

const defaultValues = {
  name: "",
  id: "",
  serviceId: "",
  filter: ""
} as Api.CreateBundle | Api.UpdateBundle;

/**
 * @memberof Bundles
 * @component
 * @desc Dialog for creating or editing a bundle.
 * @property {Api.BundleDto} bundle 
 * @property {Function} onClose - passes true if the bundle was created/updated successfully. (result?: boolean) => void;
 */

const AddService = ({ bundle, open, onClose }: UpdateBundleProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape(
    {
      name: yup.string().required(),
      serviceId: yup.string().required(),
    }
  );

  const onSubmit = useCallback(
    async (values) => {

      setLoading(true);

      try {
        if (isEditMode && bundle && bundle.id) {
          await api.services.addService(
            bundle.id,
            values.serviceId
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.bundles.createBundle(values as Api.CreateBundle);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }

        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, bundle, onClose]
  );

  useEffect(() => {
    if (bundle && bundle.id) {
      const {
        name,
        id,
        filter
      } = bundle;
      setInitialValues({
        ...defaultValues,
        name,
        id,
        filter
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [bundle]);

  return (
    <Dialog
      open={open}
      title={t("title.addToServices")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <FormControlLabel label={t("label.bundleName")}>
                <OutlinedInput name="name" formikControll disabled />
              </FormControlLabel>
              <FormControlLabel label={t("label.serviceName")}>
                <SelectUniqueService
                  name="serviceId"
                />
              </FormControlLabel>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddService;
