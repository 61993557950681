import { useCallback } from "react";
import {
  Box,
  Typography,
  Label,
  Button,
  StatusIndicator,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Routes from "~/constants/routes";
import { INCIDENT_STATUS } from '~/api/incidents/enums';

interface SummaryProps {
  sourceType: Api.eSourceType;
  totalQuantity?: number;
  active?: number;
  disabled?: number;
  openedIncidents?: number;
}

const Summary = ({
  sourceType,
  totalQuantity,
  active,
  disabled,
  openedIncidents,
}: SummaryProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const openIncidents = useCallback(() => {
    history.push(`${Routes.INCIDENTS}?sourceType=${sourceType}&status=${INCIDENT_STATUS.OPENED}`);
  }, [history, sourceType]);

  return (
    <Box bgcolor="background.paper" height={275}>
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">
          {t("title.aggregatedInformation")}
        </Typography>
      </Box>
      <Box p={4}>
        <Label text={t("label.totalQuantity")}>{totalQuantity || 0}</Label>
        <Label text={`${t("text.active")} / ${t("text.disabled")}`}>
          <Box display="flex" alignItems="center">
            <StatusIndicator status="active" /> {active || 0} /
            <StatusIndicator status="disabled" /> {disabled || 0}
          </Box>
        </Label>
        <Label text={t("label.totalNumberOfOpenIncidents")}>
          {openedIncidents || 0}
          <Box display="inline" ml={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={openIncidents}
            >
              {t("button.showIncidents")}
            </Button>
          </Box>
        </Label>
      </Box>
    </Box>
  );
};

export default Summary;
