import { Switch, Route, Redirect } from "react-router";
import AlertPanel from "~/components/AlertPanel";
import SideMenu from "~/components/SideMenu";
import Header from "~/components/Header";
import ROUTES from "~/constants/routes";
import SearchResults from "./SearchResults";
import MyProfile from "./MyProfile";
import Dashboard from "./Dashboard";
import Catalogs from "./Catalogs";
import Journals from "./Journals";
import Settings from "./Settings";
import { ConfirmProvider } from "~/components/UI/ConfirmDialog";
import CreateIncidentProvider from "~/components/Incidents/CreateIncident/CreateIncidentProvider";
import InitializationApp from "~/InitializationApp";
import SideBarFilterProvider from "~/components/SideBarFilter/SideBarFilterProvider";

import Metrics from "./Metrics";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
      height: "100%",
      overflow: "auto",
      boxSizing: "border-box",
      "@media (max-width:600px)": {
        padding: theme.spacing(2),
      },
    },
  })
);

const Main = () => {
  const classes = useStyles();

  return (
    <InitializationApp>
      <ConfirmProvider>
        <CreateIncidentProvider>
          <SideBarFilterProvider>
            <AlertPanel>
              <Header>
                <SideMenu>
                  <main className={classes.main}>
                    <Switch>
                      <Route
                        exact
                        path={ROUTES.SEARCH_RESULTS}
                        component={SearchResults}
                      />
                      <Route
                        exact
                        path={ROUTES.MY_PROFILE}
                        component={MyProfile}
                      />
                      <Route
                        exact
                        path={ROUTES.DASHBOARD}
                        component={Dashboard}
                      />

                      <Route path={ROUTES.CATALOGS} component={Catalogs} />
                      <Route path={ROUTES.JOURNALS} component={Journals} />

                      <Route exact path={ROUTES.METRICS} component={Metrics} />
                      <Route path={ROUTES.SETTINGS} component={Settings} />
                      <Route
                        component={() => <Redirect to={ROUTES.DASHBOARD} />}
                      />
                    </Switch>
                  </main>
                </SideMenu>
              </Header>
            </AlertPanel>
          </SideBarFilterProvider>
        </CreateIncidentProvider>
      </ConfirmProvider>
    </InitializationApp>
  );
};

export default Main;
