import { enumToI18nKey } from '~/utils/helpers';

export const REPORT_STATUS = {
  EQUAL: "equal",
  NOT_MATCH: "not_match",
  NOT_FOUND: "not_found"
} as { [key: string]: ZReportsJournalApi.eReportStatus };

export const REPORT_STATUS_I18n = enumToI18nKey(REPORT_STATUS, 'REPORT_STATUS');

export const BATCH_STATUS = {
  PROCESSING: "processing",
  APPROVED: "approved",
  CANCELED: "canceled",
  REFUNDED: "refunded",
  REFUND_IN_PROGRESS: "refandinprogress",
  REFUND_ERROR: "refund_error"
} as { [key: string]: ZReportsJournalApi.eBatchStatus };

export const BATCH_STATUS_I18n = enumToI18nKey(BATCH_STATUS, 'BATCH_STATUS');

export const TRANSACTION_STATUS = {
  PROCESSING: "processing",
  APPROVED: "approved",
  CANCELED: "canceled",
  COMPLETED: "completed",
  REFUNDED: "refunded",
  REFUND_IN_PROGRESS: "refandinprogress",
  REFUND_ERROR: "refund_error"
} as { [key: string]: ZReportsJournalApi.eTransactionStatus };

export const TRANSACTION_STATUS_I18n = enumToI18nKey(TRANSACTION_STATUS, 'TRANSACTION_STATUS');