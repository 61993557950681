import { Grid } from "~/components/UI";
import Card from "./Card";
import Skeleton from "../../CommonSkeleton";

interface ListProps {
  loading?: boolean;
  services?: Api.ServiceDto[];
  onSelectService?: (service?: Api.ServiceDto) => void;
}

const List = ({ loading, services, onSelectService }: ListProps) => {
  if (loading) {
    return <Skeleton />;
  }

  return (
    <Grid container direction="column" spacing={2}>
      {services
        ? services.map((s) => (
            <Grid item key={s.id}>
              <Card
                service={s}
                onClick={() => onSelectService && onSelectService(s)}
              />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default List;
