import { useState, useEffect } from "react";
import {
  Grid,
  Divider,
  Typography,
  LinearProgress,
  Box,
  Card,
  Section,
  Icon,
} from "~/components/UI";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Sites from "./Sites";
import POSes from "./POSes";
import Services from "./Services";
import Groups from "./Groups";
import Roles from "./Roles";
import Users from "./Users";
import PaymentServices from "./PaymentServices";
import Bundles from "./Bundles";
import ServiceCategory from "./ServiceCategory";

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="subtitle1">
      <Icon name="NoSearchResults" className="mr-10" />{" "}
      {t("text.noSearchResults")}
    </Typography>
  );
};

interface CatalogsProps {
  searchQuery?: string;
  data?: Api.SearchResults;
}

const catalogs = [
  "site",
  "pos",
  "service",
  "user",
  "role",
  "group",
  "paymentService",
  "bundle",
  "serviceCategory"
];

/**
 * @memberof GlobalSearch
 * @component
 * @desc   Render results for catalogs.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResults} data - api response
 */

const Catalogs = ({ searchQuery, data }: CatalogsProps) => {
  const [count, setCount] = useState<number | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      let count = _.values(
        _.pickBy(data, (_value, key) => catalogs.includes(key))
      ).reduce(
        (acc: number, catalog) =>
          catalog && catalog.count ? acc + catalog.count : acc,
        0
      );
      setCount(count);
    } else {
      setCount(undefined);
    }
  }, [data]);

  if (searchQuery && data && count) {
    return (
      <Card>
        <Box mb={4}>
          <Typography variant="h4">
            {t("title.catalogs")} ({count})
          </Typography>
        </Box>
        <Divider />
        <Grid container>
          <Grid item sm={6}>
            <Sites data={data.site} searchQuery={searchQuery} />
            <POSes data={data.pos} searchQuery={searchQuery} />
            <Services data={data.service} searchQuery={searchQuery} />
            <Groups data={data.group} searchQuery={searchQuery} />
            <Roles data={data.role} searchQuery={searchQuery} />
            <Users data={data.user} searchQuery={searchQuery} />
            <PaymentServices
              data={data.paymentService}
              searchQuery={searchQuery}
            />
            <Bundles data={data.bundle} searchQuery={searchQuery} />
            <ServiceCategory
              data={data.serviceCategory}
              searchQuery={searchQuery}
            />
          </Grid>
        </Grid>
      </Card>
    );
  } else if (searchQuery && !count) {
    return (
      <Card>
        <NoResults />
      </Card>
    );
  }

  return null;
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render search results.
 */

const SearchResults = () => {
  const [count, setCount] = useState<number | undefined>();
  const {
    loading,
    data,
    searchQuery = "",
  } = useSelector((store: ReduxStore.State) => store.search);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      let count = _.values(data).reduce(
        (acc: number, d) => (d && d.count ? acc + d.count : acc),
        0
      );
      setCount(count);
    } else {
      setCount(undefined);
    }
  }, [data]);

  return (
    <Section
      title={
        <Typography variant="h4">
          {t("title.searchResults")}{" "}
          <Typography variant="h4" component="span" color="primary">
            {searchQuery ? `"${searchQuery}"` : null}
          </Typography>
        </Typography>
      }
    >
      <Box>
        <Typography variant="body1" color="textSecondary">
          {typeof count === "number" && `${count} ${t("text.resultsFound")}`}
        </Typography>
      </Box>
      <Box py={4}>
        <LinearProgress hidden={!loading} />
        <Catalogs {...{ searchQuery, data }} />
      </Box>
    </Section>
  );
};

export default SearchResults;
