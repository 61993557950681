import { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Box, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

interface BundlesResultProps {
  data?: Api.SearchResult<Api.BundleDto>;
  searchQuery: string;
}

const Bundle = ({
  bundle,
  searchQuery,
}: {
  searchQuery: string;
  bundle: Api.BundleDto;
}) => {
  const openPaymentService = useCallback(() => {
    history.push(
      generatePath(`${ROUTES.BUNDLES}?id=${bundle.id}`)
    );
  }, [bundle]);

  return (
    <ListItem button onClick={openPaymentService}>
      <Typography variant="body2" color="textSecondary">
        <Typography variant="body2" component="span" color="primary">
          ID
        </Typography>
        :{" "}
        <Highlighter searchWords={[searchQuery]} textToHighlight={bundle.id} />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        ,{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={bundle.name}
        />
      </Typography>
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the Payment Services catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<Api.BundleDto>} data - api response
 */

const Bundles = ({ data, searchQuery }: BundlesResultProps) => {
  const { t } = useTranslation();

  // const onShowAll = useCallback(() => {
  // setTimeout(() => {
  // history.push(generatePath(`${ROUTES.SITE_CROSS_SELL_BUNDLES}?search=${searchQuery}`, { siteId: bundle.siteId }));
  // }, 0);
  // }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.crossSellBundles")} ({data.count})
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid> */}
          </Grid>
        </Box>
        <List>
          {data.data.map((b, index) => (
            <Bundle key={index} searchQuery={searchQuery} bundle={b} />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default Bundles;
