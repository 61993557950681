import { ImageList, ImageListItem } from "~/components/UI";
import clsx from "clsx";
import Metrics from "./Components/Metrics";
import Catalogs from "./Components/Catalogs";
import Journals from "./Components/Journals";
import { useSelector } from "react-redux";
import usePermissions from "~/hook/usePermissions";
import { METRICS } from "~/api/permissions/enums";

/**
 * @namespace Dashboard
 */

const DashboardRoutes = () => {
  const { hasAny } = usePermissions();
  const settings = useSelector(
    (state: ReduxStore.State) => state.settings.dashboardSettings
  );

  return (
    <ImageList cols={2} gap={20} rowHeight="auto">
      <ImageListItem cols={2} className={clsx({ hideBlock: !hasAny(METRICS) })}>
        <Metrics settings={settings && settings.metrics} />
      </ImageListItem>
      <ImageListItem cols={2}>
        <Catalogs settings={settings && settings.widgets} />
      </ImageListItem>
      <ImageListItem cols={2}>
        <Journals settings={settings && settings.widgets} />
      </ImageListItem>
    </ImageList>
  );
};

export default DashboardRoutes;
