import { useState, useCallback, useEffect } from "react";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";
import Metrics from "./Components/Metrics";
import { RouteChildrenProps } from "react-router";
import Routes from "~/constants/routes";
import { generatePath } from "react-router";
import api from "~/api";

/**
 * @namespace Services
 */

const POSesPage = ({
  location,
  match,
}: RouteChildrenProps<
  { paymentId: string },
  { paymentService?: PaymentServicesApi.PaymentServiceDetailsDto }
>) => {
  const [paymentService, setPaymentService] =
    useState<PaymentServicesApi.PaymentServiceDetailsDto>();
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.paymentServices"), href: Routes.PAYMENT_SERVICES },
      {
        label: paymentService
          ? paymentService.paymentServiceType
          : match
          ? match.params.paymentId
          : "",
        href: generatePath(Routes.PAYMENT_SERVICE_METRICS, {
          paymentId: match ? match.params.paymentId : 0,
        }),
      },
      { label: t("title.metrics") },
    ];
  }, [t, paymentService, match]);

  const getService = async (paymentId: string) => {
    try {
      const {
        data: { data },
      } = await api.paymentServices.getPaymentService(paymentId);
      setPaymentService(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setPaymentService(location.state.paymentService);
    } else if (match) {
      getService(match.params.paymentId);
    }
  }, [location]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Metrics paymentService={paymentService} />
    </>
  );
};

export default POSesPage;
