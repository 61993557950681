import { Grid } from "~/components/UI";
import BaseCard from "./BaseCard";
import { eTimeInterval } from "./TimeIntervalSelector";
import InvoiceDuration from "./Charts/InvoiceDuration";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";

interface InvoiceDurationCardProps {
  title?: string;
  onUpdate: (
    queryParams: any
  ) => Promise<MetricsAPI.MetricsResponceDto | undefined>;
}

const InvoiceDurationCard = ({ title, onUpdate }: InvoiceDurationCardProps) => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const getData = async (query: MetricsAPI.GetMetricQuery) => {
    try {
      const data = await onUpdate({
        ...query,
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  if (!hasAll(PERMISSION.READ_INVOICING_DURATION_METRICS)) return null;

  return (
    <Grid item lg={12} xl={6}>
      <BaseCard
        title={title || "Invoice Duration"}
        timeIntervals={[eTimeInterval.LAST_HOUR, eTimeInterval.LAST_24_HOURS]}
        onUpdate={getData}
      >
        {(data) =>
          data ? (
            <InvoiceDuration
              period={data.period}
              list={data.list}
              yAxis={t("title.invoicingTiming")}
            />
          ) : null
        }
      </BaseCard>
    </Grid>
  );
};

export default InvoiceDurationCard;
