import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  LinearProgress,
  Divider,
  Box,
  SubSection,
} from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { groupByCategory, GroupedByCategory } from "./utils";
import Routes from "~/constants/routes";
import api from "~/api";
import { useTranslation } from "react-i18next";

interface DetailsOfServicesProps {
  siteId: string;
  enabled?: boolean;
  size?: "default" | "small";
}

const ServiceItem = ({ service }: { service: Api.ServiceDto }) => {

  const { t } = useTranslation();

  return (
    <Box py={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              <Link
                to={`${Routes.SERVICES}?serviceId=${service.serviceId}&siteId=${service.siteId}`}
              >
                {service.serviceName} / ID: {service.serviceId}
              </Link>
            </Typography>
          </Grid>
          {service && service.crossSales && service.crossSales.length ?
            <Grid md={8} container alignItems="center">
              <Typography variant="body1" color="textSecondary">
                {t("title.bundles")}:
              </Typography>
              {service.crossSales.map(bundle => <Box px={1}> <Typography variant="body1" color="textSecondary">
                {bundle.name}
              </Typography>
              </Box>
              )}
            </Grid>
            : null}
        </Grid>
        <Grid item>
          <IncidentsIcon incidents={service.incidents} />
        </Grid>
      </Grid>
    </Box>
  )
};

/**
 * @memberof Sites
 * @component
 * @desc Render information about available services.
 * @property {string} siteId Site id
 * @property {"default" | "small"} size table size
 */

const DetailsOfServices = ({
  siteId,
  enabled,
  size = "default",
}: DetailsOfServicesProps) => {
  const [loading, setLoading] = useState(false);
  const [groupedServices, setGroupedServices] = useState<GroupedByCategory>();

  async function getServices(siteId: string) {
    setLoading(true);
    try {
      const { data } = await api.sites.getServices(siteId);
      const groupedList = groupByCategory(data.data);
      setGroupedServices(groupedList);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getServices(siteId);
  }, [siteId, enabled]);

  if (loading) {
    return <LinearProgress />;
  } else if (groupedServices && groupedServices.length) {
    return (
      <Box px={3}>
        <Grid container>
          <Grid item sm={size !== "small" ? 6 : 12}>
            <Box p={2}>
              {groupedServices.map((category, index) => (
                <SubSection
                  key={`section-${index}`}
                  title={`${category.title} (${category.services.length})`}
                  collapsed
                >
                  {category.services.map((service, index) => (
                    <>
                      {!!index && <Divider key={`service-divider-${index}`} />}
                      <ServiceItem
                        key={`service-item-${index}`}
                        service={service}
                      />
                    </>
                  ))}
                </SubSection>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

export default DetailsOfServices;
