import { useState } from "react";
import clsx from "clsx";
import Card, { Props as CardProps } from "~/components/UI/Card";
import {
  Grid,
  Typography,
  Divider,
  Box,
  Icon,
  IconButton,
  IconContainer,
  makeStyles,
  createStyles,
} from "~/components/UI";
import { SOURCE_TYPE_I18n } from "~/api/common";
import { INCIDENT_TYPE_I18n } from "~/api/incidents/enums";
import { useTranslation } from "react-i18next";
import { timeString, formatDateWithTime } from "~/utils/format";
import { Link } from "react-router-dom";
import Routes from "~/constants/routes";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    header: {
      position: "relative",
    },
    root: {
      paddingTop: 40,
      backgroundColor: theme.palette.background.level1,
    },
    iconContainer: {
      position: "absolute",
      margin: "0 20px 0",
      fontSize: 22,
      color: "white",
      boxShadow: theme.shadows[5],
      zIndex: 1,
    },
    container: {
      marginRight: 15,
    },
    closeBtnContainer: {
      position: "absolute",
      top: 15,
      right: 15,
    },
    close: {
      color: theme.palette.text.icon,
    },
    time: {
      fontSize: theme.typography.fontSize,
    },
    issuer: {
      fontSize: theme.typography.fontSize,
      fontWeight: "bold",
    },
    divider: {
      width: "100%",
      marginBottom: 15,
    },
    skip: {
      animation: `$skip 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: "forwards",
    },
    link: {
      textDecoration: "underline",
    },
    "@keyframes skip": {
      "0%": {
        transform: "translateX(0px)",
      },
      "50%": {
        transform: "translateX(999px)",
      },
      "100%": {
        height: 0,
        transform: "translateX(999px)",
      },
    },
  })
);

export interface AlertCardProps extends Omit<CardProps, "onClose"> {
  alert: AlertsApi.AlertDto;
  onClose?: (alertId: string) => void;
}

const isToday = (date: string) => {
  return new Date().getDay() === new Date(date).getDay();
};

const AlertCard = ({ alert, onClose, ...other }: AlertCardProps) => {
  const { t } = useTranslation(["translation", "enums"]);
  const [isSkip, setSkip] = useState(false);
  const classes = useStyles();

  return (
    <Box mb={4} className={clsx(classes.header, { [classes.skip]: isSkip })}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconContainer
            className={classes.iconContainer}
            variant="circle"
            color="error"
          >
            <Icon name="AlertError" />
          </IconContainer>
        </Grid>
        <Grid item>
          <Typography
            className={classes.time}
            variant="body2"
            color="textSecondary"
          >
            {isToday(alert.createdAt)
              ? `${t("text.todayAt")} ${timeString(alert.createdAt)}`
              : formatDateWithTime(alert.createdAt)}
          </Typography>
        </Grid>
      </Grid>
      <Card
        {...other}
        className={clsx(classes.root, { [classes.skip]: isSkip })}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm
            container
            direction="column"
            className={classes.container}
          >
            <Box mb={4}>
              <Typography variant="body1">
                <b>ID:</b>{" "}
                <Link
                  className={classes.link}
                  to={`${Routes.INCIDENTS}?incidentId=${alert.sourceId}`}
                >
                  {alert.sourceId}
                </Link>
              </Typography>
              <Typography variant="body1">
                <b>{t(`enums:${SOURCE_TYPE_I18n[alert.sourceType]}`)}</b> (
                {t(`enums:${INCIDENT_TYPE_I18n[alert.type]}`)})
              </Typography>
            </Box>
            <div>
              <Box mb={2}>
                <Typography variant="subtitle2">{alert.subject}</Typography>
              </Box>
              <Box mb={4}>
                <Typography variant="body1" color="textPrimary">
                  {alert.description}
                </Typography>
              </Box>
            </div>
            <Divider className={classes.divider} />
            <Grid container alignContent="center">
              <Grid item>
                <Icon name="User" size="large" className="mr-5" />
              </Grid>
              <Grid item>
                <Typography className={classes.issuer}>
                  {alert.issuer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.closeBtnContainer}>
            <IconButton
              onClick={() => {
                onClose && onClose(alert.alertId);
                setSkip(true);
              }}
              aria-label="close"
              size="small"
              color="inherit"
            >
              <Icon className={classes.close} name="Close" />
            </IconButton>
          </div>
        </Grid>
      </Card>
    </Box>
  );
};

export default AlertCard;
