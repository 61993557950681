import { useEffect, useState } from "react";
import Summary from "../Summary";
import { LinearProgress } from "~/components/UI";
import api from "~/api";
import { SOURCE_TYPE } from '~/api/common';

const ServicesSummary = () => {
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] =
    useState<DashboardCatalogsAPI.DashboardCatalogDto>();

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await api.dashboardCatalogs.getServicesSummary();
      setSummaryData(data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      {summaryData && (
        <Summary
          sourceType={SOURCE_TYPE.SERVICE}
          active={summaryData.active}
          disabled={summaryData.disabled}
          totalQuantity={summaryData.totalQuantity}
          openedIncidents={summaryData.incidents.count}
        />
      )}
    </>
  );
};

export default ServicesSummary;
