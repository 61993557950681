import { useState, useEffect, useCallback } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Switch,
} from "~/components/UI";
import SelectServiceCategory from "~/components/CommonFormControls/SelectServiceCategory";
import SelectServiceTemplates from "~/components/CommonFormControls/SelectServiceTemplates";
import SelectSite from "~/components/CommonFormControls/SelectSite";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";
import AddBundleForm from "./CreateServicesDialog/AddBundle";

interface UpdateServiceProps extends Omit<DialogProps, "onClose"> {
  service?: Api.ServiceDto;
  onClose?: (result?: boolean) => void;
}

const defaultValues: Api.UpdateService = {
  globalCatalog: false,
  isAvailableForDelivery: false,
  enabled: false,
  bundles: []
};

/**
 * @memberof Services
 * @component
 * @desc Dialog for editing a service.
 * @property {Api.ServiceDto} service Service data
 * @property {Function} onClose - passes true if the service was updated successfully. (result?: boolean) => void;
 */

const CreateServicesDialog = ({
  open,
  service,
  onClose,
}: UpdateServiceProps) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [serviceDetails, setServiceDetails] = useState<Api.ServiceDto | null>(null);
  const { t } = useTranslation();


  const onSubmit = useCallback(
    async (formData: Api.UpdateService) => {

      if (!service?.description && 'description' in formData && formData?.description === '') {
        delete formData.description;
      }
      const { bundles, ...data } = formData

      const bundleIds = bundles &&
        bundles.length &&
        bundles.map((bundle) => bundle.id) || [];

      if (service && service.serviceId) {
        setLoading(true);
        try {
          await api.services.updateService(
            service.serviceId,
            service.siteId,
            { ...data, bundleIds }
          );
          toast.success(t("text.recordSuccessfullyUpdated"));
          onClose && onClose(true);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [service, t]
  );

  const validationSchema = yup.object().shape({
    siteId: yup.string().required(),
    categoryId: yup.string().required(),
    serviceId: yup.string().serviceId().required(),
    templateId: yup.string().required(),
    enabled: yup.boolean(),
    globalCatalog: yup.boolean(),
  });

  const getDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.services.getServiceDetails(
        service ? service.serviceId : "",
        service ? service.siteId : ""
      );
      setServiceDetails(data.data);
    } finally {
      setLoading(false);
    }
  }, [service]);

  useEffect(() => {
    if (service) {
      getDetails();
    } else {
      setInitialValues(defaultValues);
    }
  }, [service]);

  useEffect(()=>{
    console.log(serviceDetails);
    if (serviceDetails) {
      const {
        siteId,
        categoryId,
        serviceId,
        templateId,
        enabled,
        globalCatalog,
        isAvailableForDelivery,
        serviceMaxQueue,
        description,
        crossSales: bundles
      } = serviceDetails;


      setInitialValues({
        siteId,
        categoryId,
        serviceId,
        templateId,
        enabled,
        globalCatalog,
        isAvailableForDelivery,
        serviceMaxQueue,
        description,
        bundles
      });
    }
  },[serviceDetails])

  return (
    serviceDetails ?
    <Dialog
      open={open}
      title={`${t("title.editService")}: ${service && service.serviceId}`}
      onClose={() => {
        !loading && onClose && onClose();
        setServiceDetails(null)
      }}
      closable
    >
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
          enableReinitialize: true,
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <DialogContent>
              <FormControlLabel label={t("label.serviceID")}>
                <OutlinedInput name="serviceId" formikControll />
              </FormControlLabel>
              <FormControlLabel label={t("label.serviceCategory")}>
                <SelectServiceCategory
                  name="categoryId"
                  value={values.categoryId}
                  fullWidth
                  onSelected={(c) =>
                    setFieldValue("serviceMaxQueue", c.serviceMaxQueue)
                  }
                  onChange={(e) => {
                    setFieldValue("categoryId", e.target.value);
                  }}
                />
              </FormControlLabel>
              <FormControlLabel label={t("label.serviceMaxQueue")}>
                <OutlinedInput
                  name="serviceMaxQueue"
                  type="number"
                  formikControll
                />
              </FormControlLabel>
              <FormControlLabel label={t("label.serviceName")}>
                <SelectServiceTemplates
                  name="templateId"
                  defaultInputValue={service?.serviceName}
                  serviceCategoryId={values.categoryId}
                  filterOptions={(options) =>
                    options.filter((o) =>
                      values.categoryId
                        ? o.serviceCategoryId === values.categoryId
                        : true
                    )
                  }
                  editMode
                />
              </FormControlLabel>
              <FormControlLabel
                label={t("label.availableForDelivery")}
                alignItems="center"
              >
                <Checkbox name="isAvailableForDelivery" formikControll />
              </FormControlLabel>
              <FormControlLabel label={t("label.siteName")}>
                <SelectSite
                  defaultInputValue={service?.siteName}
                  name="siteId"
                />
              </FormControlLabel>
              <FormControlLabel
                label={t("label.globalCatalog")}
                alignItems="center"
              >
                <Checkbox name="globalCatalog" formikControll />
              </FormControlLabel>
              <FormControlLabel label={t("label.enabled")} alignItems="center">
                <Switch name="enabled" formikControll />
              </FormControlLabel>
              <FormControlLabel label={t("label.description")}>
                <OutlinedInput
                  name="description"
                  formikControll
                  multiline
                  rows={4}
                  fullWidth
                />
              </FormControlLabel>
              <AddBundleForm />
            </DialogContent>
            <DialogActions>
              <Button
                loading={loading}
                onClick={() => handleSubmit()}
                color="primary"
                variant="contained"
              >
                {t("button.save")}
              </Button>
              <Button
                disabled={loading}
                onClick={() => onClose && onClose()}
                variant="contained"
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog> :
    null
  );
};

export default CreateServicesDialog;
