import ClientsCard from "~/components/Metrics/ClientsCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const { t } = useTranslation();
  const getClientMetrics = async (params: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getClientByNetwork(params);

      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getClientsOnlineMetrics = async (params: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getClientOnlineByNetwork(params);

      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ClientsCard
      title={`${t("title.clientsBy")} ${t("text.network")}`}
      onUpdate={getClientMetrics}
      onUpdateOnline={getClientsOnlineMetrics}
    />
  );
};

export default Clients;
