import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class PaymentServicesApi {
  rootPath: string = "/payment-services";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.paymentServices');
  }

  createService = (data: PaymentServicesApi.CreatePaymentService): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDto>> =>
  this.instance.post(this.rootPath, data);
  
  getPaymentServices = (limit: number = 10, page: number = 1, query?: PaymentServicesApi.GetAllPaymentServicesQuery): Promise<Api.GetAll<PaymentServicesApi.PaymentServiceDto>> =>
  this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });
  
  getPaymentService = (id: string): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDetailsDto>>=>
  this.instance.get(`${this.rootPath}/${id}`);
  
  updatePaymentService = (id: string, data: PaymentServicesApi.UpdatePaymentService): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDto>>  =>
    this.instance.put(`${this.rootPath}/${id}`, data);

  getSites = (id: string, limit: number = 10, page: number = 1, query?: PaymentServicesApi.SitesQuery): Promise<Api.GetOne<PaymentServicesApi.GetSitesDto>> => 
    this.instance.get(`${this.rootPath}/${id}/sites-list`, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) }});
  
  getPaymentSites = (id: string, limit: number = 10, page: number = 1, query?: PaymentServicesApi.SitesQuery): Promise<Api.GetAll<PaymentServicesApi.SiteDto>>=>
    this.instance.get(`${this.rootPath}/${id}/sites`, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  getPaymentSite = (paymentId: string, siteId: string): Promise<Api.GetAll<PaymentServicesApi.POSDto>>=>
    this.instance.get(`${this.rootPath}/${paymentId}/sites/${siteId}`);

  updateSites = (id: string, data: PaymentServicesApi.UpdateSites): Promise<{}>  =>
    this.instance.put(`${this.rootPath}/${id}/sites`, data);

  updateSiteService = (id: string, siteId: string, data: PaymentServicesApi.UpdateSite): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDto>>  =>
    this.instance.put(`${this.rootPath}/${id}/sites/${siteId}`, data);

  deleteSiteService = (id: string, siteId: string): Promise<{}>  =>
    this.instance.delete(`${this.rootPath}/${id}/sites/${siteId}`); 

  updatePOSService = (id: string, posId: string, data: PaymentServicesApi.UpdatePOS): Promise<Api.GetOne<PaymentServicesApi.PaymentServiceDto>>  =>
    this.instance.put(`${this.rootPath}/${id}/poses/${posId}`, data);

  deletePaymentService = (id: string): Promise<{}> =>
    this.instance.delete(`${this.rootPath}/${id}`);
  
  updateByPaymentServiceType = (serviceType: PaymentServicesApi.ePaymentServiceType, enabled: boolean): Promise<any> =>
    this.instance.put(`${this.rootPath}/service/${serviceType}`, { enabled });

  refundOfPayment = (transactionId: string, data: PaymentServicesApi.RefundOfPaymentRequestDto) => 
    this.instance.put(`${this.rootPath}/${transactionId}/refund-of-paymen`, data);

  getPaymentServiceTypes = (): Promise<Api.GetOne<{ [key: string]: string }>> => 
    this.instance.get(`${this.rootPath}/types/list`);
}
