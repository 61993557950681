import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  LinearProgress,
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
} from "~/components/UI";
import Table from "./Table";
import UpdateServicesCategoryDialog from "./UpdateServicesCategoryDialog";
import { toast } from "react-toastify";
import ServicesCategoriesContext from "./servicesCategoriesContext";
import { getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useTranslation } from "react-i18next";
import RowsPerPage from "~/components/RowsPerPage";
import { useQueryParams, StringParam } from "use-query-params";
import api from "~/api";

/**
 * @memberof ServicesCategories
 * @component
 * @desc Services Categories Controller.
 */

const ServicesCategories = () => {
  const [servicesCategories, setServicesCategories] =
    useState<ServicesCategoriesApi.ServicesCategoryDto[]>();
  const [query, setQuery] = useState<Api.GetServicesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [openCreateServicesDialog, setCreateServicesDialog] = useState<
    boolean | undefined
  >();
  const [queryParams] = useQueryParams({
    categoryId: StringParam,
  });
  const [editServicesCategory, setEditServicesCategory] =
    useState<ServicesCategoriesApi.ServicesCategoryDto>();
  const deleteConfirm = useConfirmDialog("delete");
  const { t } = useTranslation();

  const getServicesCatigories = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      try {
        setLoading(true);
        const { data } = await api.servicesCategories.getServicesCategories(
          limit,
          page || currentPage,
          query
        );
        data.data && setServicesCategories(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCreateService = () => {
    setCreateServicesDialog(true);
  };

  const onCloseUpdatingDialog = useCallback(
    (
      servicesCategory?: ServicesCategoriesApi.ServicesCategoryDto,
      created?: boolean
    ) => {
      setCreateServicesDialog(false);
      setEditServicesCategory(undefined);
      getServicesCatigories();
      // if (servicesCategory && created) {
      //   setServicesCategories((list) =>
      //     list ? [servicesCategory, ...list] : [servicesCategory]
      //   );
      //   setPagination(
      //     (pagination) =>
      //       ({
      //         ...pagination,
      //         totalCount:
      //           pagination && pagination.totalCount
      //             ? pagination.totalCount + 1
      //             : 1,
      //       } as Api.PaginationSchema)
      //   );
      // } else if (servicesCategory) {
      //   onUpdateItem(servicesCategory);
      // }
    },
    [pagination]
  );

  // const onUpdateItem = useCallback(
  //   (servicesCategory: ServicesCategoriesApi.ServicesCategoryDto) => {
  //     setServicesCategories((servicesCategories) =>
  //       servicesCategories
  //         ? updateArrayItem(servicesCategories, servicesCategory, "categoryId")
  //         : []
  //     );
  //   },
  //   []
  // );

  const onDelete = useCallback(
    async (servicesCategory: ServicesCategoriesApi.ServicesCategoryDto) => {
      await deleteConfirm(servicesCategory?.name);
      await api.servicesCategories.deleteServicesCategory(
        servicesCategory.categoryId
      );
      getServicesCatigories();
      toast.success(t("text.recordSuccessfullyDeleted"));
    },
    [t]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = React.useMemo(
    () => ({
      onSort,
      onDelete,
      onEdit: setEditServicesCategory,
    }),
    [servicesCategories]
  );

  const onChangePagination = (page: number) => {
    getServicesCatigories(page);
  };

  useEffect(() => {
    setQuery(queryParams as ServicesCategoriesApi.GetServicesCategoriesQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getServicesCatigories();
  }, [query, limit]);

  return (
    <ServicesCategoriesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.servicesCategories")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <Button
                prefixIcon={<Icon name="Plus" />}
                variant="contained"
                onClick={onCreateService}
              >
                {t("button.createNew")}
              </Button>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <Table dataSource={servicesCategories} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <UpdateServicesCategoryDialog
        open={openCreateServicesDialog || Boolean(editServicesCategory)}
        servicesCategory={editServicesCategory}
        onClose={onCloseUpdatingDialog}
      />
    </ServicesCategoriesContext.Provider>
  );
};

export default ServicesCategories;
