import { useContext, useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import ServicesCategoriesContext from "./servicesCategoriesContext";
import { useTranslation } from "react-i18next";

interface ServicesCategoryOptionsProps {
  servicesCategory: ServicesCategoriesApi.ServicesCategoryDto;
  hideEditOptions?: boolean;
}

/**
 * @memberof Services
 * @component
 * @desc Service options
 * @property {Api.ServiceDto} service - Service data;
 * @requires ServicesContext
 */

const ServicesCategoryOptions = ({
  servicesCategory,
}: ServicesCategoryOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onDelete } = useContext(ServicesCategoriesContext);
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<void>) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const options = [
    {
      label: t("option.edit"),
      onClick: () => onEdit && onEdit(servicesCategory),
    },
    {
      label: t("option.delete"),
      onClick: () => onDelete && onUpdateHandler(onDelete(servicesCategory)),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default ServicesCategoryOptions;
