import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";
import BusinessOperations from "./BusinessOperations";

/**
 * @namespace BusinessOperations
 */

const BusinessOperationsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.journals") },
      { label: t("title.businessOperations") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <BusinessOperations />
    </>
  );
};

export default BusinessOperationsPage;
