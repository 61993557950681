import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Divider,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  makeStyles,
  createStyles,
} from "~/components/UI";
import { Formik, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { mapValues } from "lodash";
import usePortal from "~/hook/usePortal";
import useSideBarFilter from "./useSideBarFilter";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
    },
  })
);

type FChildren = (
  values: FormikValues
) => React.ReactChild | React.ReactChild[];
interface SideBarFilterProps {
  openByDefault?: boolean;
  initialValues?: FormikValues;
  onChangeFilter?: (data: FormikValues) => Promise<void> | void;
  children?: FChildren | React.ReactChild | React.ReactChild[];
}

const SideBarPortal = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const target = usePortal("right-side-filter-container", classes.container);
  return createPortal(children, target);
};

const SideBarFilterContainer = ({
  openByDefault,
  initialValues,
  children,
  onChangeFilter,
}: SideBarFilterProps) => {
  const [isOpen, open, close] = useSideBarFilter();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = (formData: { [key: string]: any }) => {
    const data = mapValues(formData, (value) => {
      return value instanceof Date && !isNaN(value.valueOf())
        ? value.toISOString()
        : value;
    });
    const res = onChangeFilter && onChangeFilter(data);

    if (res && res.finally) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    openByDefault && setTimeout(open, 0);
  }, []);

  return (
    <Box display="inline-block">
      <Button
        prefixIcon={<Icon name="Filter" />}
        color={isOpen ? "primary" : "default"}
        variant="contained"
        onClick={() => (isOpen ? close() : open())}
      >
        {t("button.filter")}
      </Button>
      <SideBarPortal>
        <Formik
          {...{
            initialValues: initialValues || {},
            onSubmit,
            enableReinitialize: true,
          }}
        >
          {({ values, handleSubmit, setValues, touched }) => (
            <Box
              onKeyPress={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  handleSubmit();
                }
              }}
              display="flex" flexDirection="column" height="100%">
              <SidebarHeader
                title={t("title.filter")}
                closeBtn
                onClose={close}
              />
              <Box flexGrow={1} overflow="auto">
                <SidebarContent>
                  {typeof children === "function" ? children(values) : children}
                </SidebarContent>
              </Box>
              <SidebarFooter>
                <Divider />
                <Box pt={6}>
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="primary"
                      loading={loading}
                      disabled={loading || !touched}
                      onClick={() => handleSubmit()}
                    >
                      {t("button.applyFilters")}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setValues({});
                        handleSubmit();
                      }}
                    >
                      {t("button.clear")}
                    </Button>
                  </ButtonGroup>
                </Box>
              </SidebarFooter>
            </Box>
          )}
        </Formik>
      </SideBarPortal>
    </Box>
  );
};

export default SideBarFilterContainer;
