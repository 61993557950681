import { Switch, Route } from "react-router";
import ROUTES from "~/constants/routes";
import Services from "./Services";
import ServicesCategories from "./ServicesCategories";
import Metrics from "./Metrics";

const ServicesRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.SERVICES} exact component={Services} />
      <Route
        path={ROUTES.SERVICES_CATEGORIES}
        exact
        component={ServicesCategories}
      />
      <Route path={ROUTES.SERVICE_METRICS} exact component={Metrics} />
    </Switch>
  );
};

export default ServicesRoutes;
