import { useState, useCallback, useEffect } from "react";
import DashboardTile from "~/components/Metrics/DashboardTile";

interface CommonTileProps {
  label?: string;
  trendDescription?: string;
  formattingValue?: (value: any) => string;
  onUpdate?: () => Promise<
    DashboardMetricsAPI.DashboardMetricsResponseDto | undefined
  >;
}

type TrendSymbol = "up" | "down" | undefined;

const getTrendSymbol = (trend?: number): TrendSymbol => {
  if (trend) {
    if (trend > 0) {
      return "up";
    } else if (trend < 0) {
      return "down";
    } else {
      return undefined;
    }
  }
  return undefined;
};

const CommonTile = ({
  label,
  trendDescription,
  formattingValue,
  onUpdate,
}: CommonTileProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] =
    useState<DashboardMetricsAPI.DashboardMetricsResponseDto>();
  const [trend, setTrend] = useState<TrendSymbol>();

  const refreshData = useCallback(async () => {
    if (onUpdate) {
      setLoading(true);
      try {
        const d = await onUpdate();
        setTrend(getTrendSymbol(d && d.trend));
        setData(d);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [onUpdate]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <DashboardTile
      loading={loading}
      label={label}
      value={
        formattingValue
          ? formattingValue(data && data.count)
          : data && data.count
      }
      trend={trend}
      trendValue={data ? data.trend : 0}
      trendDescription={trendDescription}
    />
  );
};

export default CommonTile;
