import Tiles, { TilesProps } from "~/components/UI/Tiles";
import { Box } from "~/components/UI";
import BundleCard from "./BundleCard";

/**
 * @memberof Bundles
 * @component
 * @desc Render tiles of POSes.
 * @property {Api.BundleDto[]} list - POSes data
 */

const BundleTiles = (props: Omit<TilesProps<Api.BundleDto> & {}, "renderTaile">) => (
  <Box pb={2}>
    <Tiles
      {...props}
      customHeight={true}
      renderTaile={(bundle, key) => <BundleCard key={key} bundle={bundle} />}
    />
  </Box>
);

export default BundleTiles;
