import { useCallback } from "react";
import Bundles from "./Components/Bundles";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @namespace Bundles
 */

const BundlesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.bundles") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Bundles />
    </>
  );
};

export default BundlesPage;
