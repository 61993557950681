import moment from 'moment';
import i18n from '~/packages/i18n';
import Constants from "~/constants";
import { TFunction } from "i18next";

const { REACT_APP_CURRENCY_CODE } = Constants;

/**
 * Format a string to the mobile phone. Example: 80(399)565644 => +80 (399) 565644
 * @function phoneNumber
 * @param {string} value - Phone number.
 * @return {string}
 */

export const phoneNumber = (value: string) =>
  typeof value === "string"
    ? value.replace(/^\D*(\d{2})\D*(\d{3})\D*(\d{3})\D*(\d{2})\D*(\d{2})/, "+$1 ($2) $3 $4 $5")
    : "";

/**
 * Return a formatted string. Example: 2021.02.18
 * @function formatDate
 * @param {string} value - date.
 * @return {string}
 */

export const formatDate = (value?: string) => {
  if (!value || !moment(value).isValid()) return '';

  return moment(value).format("YYYY.MM.DD");
}

/**
 * Return a formatted string. Example: 2021.02.18 - 11:49:23
 * @function formatDateWithTime
 * @param {string} value - date.
 * @return {string}
 */

export const formatDateWithTime = (value?: string) => {
  if (!value || !moment(value).isValid()) return '';

  return moment(value).format("YYYY.MM.DD - HH:mm:ss");
}

/**
 * Return a formatted string. Example: 11:49:23
 * @function formatDateWithTime
 * @param {string} value - date.
 * @return {string}
 */

export const timeString = (value?: string) => {
  if (!value || !moment(value).isValid()) return '';

  return moment(value).format("HH:mm:ss");
}

/**
 * Return a formatted string. Example: 8bc7bfc8...
 * @function formatDateWithTime
 * @param {string} value - date.
 * @return {string}
 */

export const formatUUID = (id?: string) => {
  if (typeof id === 'string') {
    return id.length > 8 ? `${id.substring(0, 8)}...` : id;
  }
  return '';
}

export const currencyString = (number?: number | string, currency?: string) =>
  typeof number === 'number'
    ? new Intl.NumberFormat(
      i18n.language === 'ua'
        ? 'ru'
        : i18n.language,
      {
        style: 'decimal',
        currencyDisplay: 'code',
        currency: currency || 'UAH',
        minimumFractionDigits: 2,
        useGrouping: true
      }).format(number / 100) + " " + 'UAH'
    : '';

export const quantityString = (value: number | string, unit?: 'ml' | 'pcs' | 'wh', t?: TFunction<string>) => {
  switch (unit) {
    case 'ml':
      if (typeof value !== 'number' && typeof value !== 'string') {
        return value;
      }

      if (!(Number(value) > 0)) {
        return value;
      }
      const liters = Number(value) / 1000;
      return `${Number(value) % 1000 ? liters.toFixed(3) : liters.toFixed(0)} ${t ? t('label.liters') : ''}`;
    case 'pcs':
      return `${value} ${t ? t('label.pcs') : ''}`;
    case 'wh':
      if (typeof value !== 'number' && typeof value !== 'string') {
        return value;
      }

      if (!(Number(value) > 0)) {
        return value;
      }
      const whats = Number(value) / 1000;
      return `${Number(value) % 1000 ? whats.toFixed(3) : whats.toFixed(0)} ${t ? t('label.kwh') : ''}`;

    default:
      return value;
  }
}