import ENV from "~/constants";
import {
  UserAgentApplication,
  LogLevel,
  AuthenticationParameters,
  Configuration,
} from "msal";
import { CacheOptions } from "msal/lib-commonjs/Configuration";

const clientId = ENV.REACT_APP_AD_CLIENT_ID;
const tenantId = ENV.REACT_APP_AD_TENANT_ID;
const redirectUri = window.location.origin;

const config = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  } as CacheOptions,
  system: {
    loggerOptions: {
      loggerCallback: (level: number, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    navigateFrameWait: 2000,
  },
} as Configuration;

export const msalAuth = new UserAgentApplication(config);

const currentAccount = msalAuth.getAccount();

const tokenConfig = {
  scopes: ["User.Read"],
  account: currentAccount,
  forceRefresh: false,
};

msalAuth.handleRedirectCallback((error) => {
  if (error) {
    console.error(error);
  }
});

export const getToken = () =>
  msalAuth
    .acquireTokenSilent(tokenConfig)
    .catch(() => msalAuth.acquireTokenRedirect(tokenConfig))
    .then((res) => {
      if (res && res.idToken) {
        return res;
      } else {
        const account = msalAuth.getAccount();
        const ssoRequest = {
          scopes: [],
          loginHint: account.name,
        } as AuthenticationParameters;
        return msalAuth.ssoSilent(ssoRequest).catch((e) => {
          msalAuth.loginRedirect(tokenConfig);
          return e;
        });
      }
    })
    .then((res) => res.accessToken);
