import { useState, useEffect } from "react";
import { Box, LinearProgress, Grid, InputLabel } from "~/components/UI";
import ReactJson from 'react-json-view';
import { withStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DetailsColumn from "~/components/DetailsColumn";
import api from "~/api";

interface SystemLogDetailsProps {
  data: SystemLogJournalApi.SystemLogDto;
}

const Container = withStyles(() =>
  createStyles({
    root: {
      maxHeight: 300,
      overflow: "auto",
      maxWidth: "100%",
      flexWrap: "nowrap",
    },
  })
)(Box);

/**
 * @memberof SystemLog
 * @component
 * @desc Render detailed information about an System log.
 * @property {SystemLogJournalApi.SystemLogDto} data - System log data;
 */

const SystemLogDetails = ({ data }: SystemLogDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [systemLogDetails, setSystemLogDetails] =
    useState<SystemLogJournalApi.SystemLogDetailsDto>();
  const { t } = useTranslation();

  async function getEventDetailes(id: string) {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await api.systemLogJournal.getSystemLog(id);
      setSystemLogDetails(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEventDetailes(data.id);
  }, [data]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (systemLogDetails) {
    return (
      <Container>
        <DetailsColumn title={t("title.context")}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InputLabel>ID</InputLabel>
            </Grid>
            <Grid item>{data.id}</Grid>
          </Grid>
          <ReactJson src={JSON.parse(systemLogDetails.contextData)} />
        </DetailsColumn>
      </Container>
    );
  } else {
    return null;
  }
};

export default SystemLogDetails;
