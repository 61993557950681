import { useState, useCallback, useEffect } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Metrics from "./Components/Metrics";
import { RouteChildrenProps } from "react-router";
import Routes from "~/constants/routes";
import api from "~/api";

/**
 * @namespace POSes
 */

const POSesPage = ({
  location,
  match,
}: RouteChildrenProps<{ posId: string }, { pos?: Api.PosDetailsDto }>) => {
  const [pos, setPos] = useState<Api.PosDetailsDto>();
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.poses"), href: Routes.POSES },
      {
        label: pos ? pos.posId : match ? match.params.posId : "",
        href: Routes.POSES + `?posId=${match && match.params.posId}`,
      },
      { label: t("title.metrics") },
    ];
  }, [t, pos, match]);

  const getPOS = async (posId: string) => {
    try {
      const {
        data: { data },
      } = await api.poses.getPos(posId);
      setPos(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setPos(location.state.pos);
    } else if (match) {
      getPOS(match.params.posId);
    }
  }, [location]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Metrics />
    </>
  );
};

export default POSesPage;
