import Routes from "~/constants/routes";
import CommonTile from "./CommonTile";
import Table from "~/pages/Journals/SystemLog/Table";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const SystemLogWidget = () => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  if (!hasAll(PERMISSION.READ_JOURNAL_LOG_SYSTEM)) return null;

  const getData = () =>
    api.systemLogJournal
      .getSystemLogs(5, { sort: "-timestamp" })
      .then((res) => res.data.data);

  return (
    <CommonTile
      title={t("title.systemLog")}
      showAllText={t("button.showAll")}
      onUpdate={getData}
      showAllLink={Routes.SYSTEM_LOG}
    >
      {(data) => <Table dataSource={data || []} hideSorting={true} />}
    </CommonTile>
  );
};

export default SystemLogWidget;
