import { Switch } from "react-router";
import ROUTES from "~/constants/routes";
import Incidents from "./Incidents";
import UserActivityLog from "./UserActivityLog";
import BusinessOperations from "./BusinessOperations";
import SystemLog from "./SystemLog";
import EventsLog from "./EventsLog";
import AuditZReportLog from "./AuditZReportLog";
import PrivateRoute from "~/components/PrivateRoute";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const Journals = () => {
  const { hasAll } = usePermissions();

  return (
    <Switch>
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_BUSINESS_OPERATIONS)}
        path={ROUTES.BUSINESS_OPERATIONS}
        component={BusinessOperations}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_EVENT_LOG)}
        path={ROUTES.EVENTS}
        component={EventsLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)}
        path={ROUTES.INCIDENTS}
        component={Incidents}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS)}
        path={ROUTES.USER_ACTIVITY}
        component={UserActivityLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_LOG_SYSTEM)}
        path={ROUTES.SYSTEM_LOG}
        component={SystemLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_Z_REPORT)}
        path={ROUTES.AUDIT_Z_REPORT_LOG}
        component={AuditZReportLog}
      />
    </Switch>
  );
};

export default Journals;
