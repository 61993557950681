import { Grid, Typography, Box } from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import ExpandableTile from "~/components/ExpandableTile";
import BundleOptions from "./BundleOptions";

interface BundleCardProps {
  bundle: Api.BundleDto;
}

/**
 * @memberof POSes
 * @component
 * @desc Render a Card of bundle.
 * @property {Api.BundleDto} bundle - bundle data;
 */

const BundleCard = ({ bundle }: BundleCardProps) => {
  return (
    <ExpandableTile >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IncidentsIcon incidents={bundle.incidents} />
            </Grid>
            <Grid item>
              <BundleOptions bundle={bundle} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={1}>
        <Typography variant="subtitle1">
          {bundle.name} (ID: {bundle.id})
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography variant="body2">{bundle.serviceName}</Typography>
      </Box>
    </ExpandableTile>
  );
};

export default BundleCard;
