import BillsCard from "~/components/Metrics/BillsCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const Bills = () => {
  const { t } = useTranslation();
  const getBillsMetrics = async (params: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getBillsByNetwork(params);
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BillsCard
      title={`${t("title.averageBillBy")} ${t("text.network")}`}
      onUpdate={getBillsMetrics}
    />
  );
};

export default Bills;
