import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import SelectEnum from "~/components/CommonFormControls/SelectEnum";
import DatePicker from "~/components/CommonFormControls/DatePicker";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { KEY_PURPOSE, KEY_PURPOSE_I18n } from "~/api/keyManagement/enums";
import api from "~/api";
import { saveFile } from "~/utils/helpers";

interface CreateKeyDialogProps extends Omit<DialogProps, "onClose"> {
  onClose?: (key?: KeyManagementApi.KeyDto) => void;
}

const defaultValues: KeyManagementApi.CreateKey = {
  expirationDate: new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).toISOString(),
  name: "",
  content: ""
};

const ExpirationDatePickerOptions = {
  minDate: "today"
};

/**
 * @memberof KeyManagementPage
 * @component
 * @desc Dialog for creating a Key.
 * @property {Function} onClose - passes key data if the key was created. (key?: KeyManagementApi.KeyDto) => void;
 */

const CreateKeyDialog = ({ open, onClose }: CreateKeyDialogProps) => {
  const [initialValues, setInitialValues] =
    useState<KeyManagementApi.CreateKey>(defaultValues);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    expirationDate: yup.string().required(),
    name: yup.string().required(),
    purpose: yup.string().required(),
    content: yup.string().when("purpose", {
      is: (value: KeyManagementApi.eKeyPurpose) =>
        value !== KEY_PURPOSE.MASTER_PASS_ENCRYPTION_KEY &&
        value !== KEY_PURPOSE.MASTER_PASS_MAC_KEY,
      then: () => yup.string().required()
    }).when("isGenerate", {
      is: (value: boolean) =>
        value,
      then: () => yup.string()
    }),

  });

  const onSubmit = useCallback(
    async (data) => {
      const formData = { ...data, content: btoa(data.content) };
      setLoading(true);
      try {
        const { data } = await api.keyManagement.createKey(formData);
        if (data && data.data && data.data.dataUrl && data.data.filename) {
          const { dataUrl, filename } = data.data;
          dataUrl && filename && saveFile(dataUrl, filename.slice(0, -4) + "(public_key)" + filename.slice(-4));
        }
        onClose && onClose(data.data);
      } finally {
        setLoading(false);
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (open) {
      setInitialValues(defaultValues);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t("title.addKey")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label={t("label.keyName")}>
                  <OutlinedInput
                    name="name"
                    formikControll
                    autoComplete="false"
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.keyPurpose")}>
                  <SelectEnum
                    name="purpose"
                    enumValue={KEY_PURPOSE}
                    enumI18n={KEY_PURPOSE_I18n}
                  />
                </FormControlLabel>
                {
                  values.purpose === KEY_PURPOSE.INBOUND_VT_SIGN_KEY &&
                  <FormControlLabel label={t("label.isGenerate")}>
                    <Checkbox
                      name="isGenerate"
                      size="medium"
                      formikControll
                    />
                  </FormControlLabel>
                }
                <FormControlLabel label={t("label.keyContent")}>
                  <OutlinedInput
                    name="content"
                    disabled={values.isGenerate === true && values.purpose === KEY_PURPOSE.INBOUND_VT_SIGN_KEY}
                    formikControll
                    multiline
                    rows={10}
                    fullWidth
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.expirationDate")}>
                  <DatePicker
                    name="expirationDate"
                    options={ExpirationDatePickerOptions}
                  />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateKeyDialog;
