import { useCallback } from 'react'
import { Button, Grid, Tabs, Divider, Box, makeStyles, createStyles } from "~/components/UI";
import { useTranslation } from "react-i18next";
import ClientTab from "./ClientTab";
import OrderTab from "./OrderTab";
import InvoiceTab from "./InvoiceTab";
import PaymentTab from "./PaymentTab";
import ReceiptTab from "./ReceiptTab";
import Routes from "~/constants/routes";

interface BusinessOperationDetailsProps {
  operationId: string;
  data: JournalBusinessOperationsApi.BusinessOperationDto;
}

const getStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 400,
      maxHeight: 400,
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
    tabsContainer: {
      height: "calc(100% - 100px)",
    },
  })
);

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render detailed information about an Business Operation.
 * @property {JournalBusinessOperationsApi.BusinessOperationDto} data - Business Operation data;
 */

const BusinessOperationDetails = ({ data }: BusinessOperationDetailsProps) => {
  const classes = getStyles();
  const { t } = useTranslation();

  const goToEvensLog = useCallback(() => {
    const win = window.open(`${Routes.EVENTS}?search=${data.orderId}`, "_blank");
    win?.focus();
  }, [data]);

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      alignItems="stretch"
    >
      <Box flex={1}>
        <Tabs
          tabs={[
            {
              label: t("label.client"),
              content: <ClientTab orderId={data.orderId} />,
            },
            {
              label: t("label.order"),
              content: <OrderTab orderId={data.orderId} />,
            },
            {
              label: t("label.invoice"),
              hidden: !data.invoiceId,
              content: <InvoiceTab invoiceId={data.invoiceId} />,
            },
            {
              label: t("label.payment"),
              hidden: !data.invoiceId,
              content: <PaymentTab invoiceId={data.invoiceId} />,
            },
            {
              label: t("label.receipt"),
              hidden: !data.invoiceId,
              content: <ReceiptTab invoiceId={data.invoiceId} />,
            },
          ]}
        />
      </Box>
      <Divider />
      <Box p={4}>
        <Button variant="outlined" onClick={goToEvensLog}>
          {t("button.eventLog")}
        </Button>
      </Box>
    </Grid>
  );
};

export default BusinessOperationDetails;
