import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import {
  USER_ACTIVITY_STATUS,
  USER_ACTIVITY_STATUS_I18n,
  USER_ACTIVITY_TYPE,
  USER_ACTIVITY_TYPE_I18n,
} from "~/api/userActivityJournal/enums";
import { SOURCE_TYPE, SOURCE_TYPE_I18n } from "~/api/common";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface FilterProps {
  initialValues?: UserActivityJournalApi.GetUserActivityQuery;
  onChangeFilter: (
    data: UserActivityJournalApi.GetUserActivityQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer {...props}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.type")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="type"
            enumValue={USER_ACTIVITY_TYPE}
            enumI18n={USER_ACTIVITY_TYPE_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={USER_ACTIVITY_STATUS}
            enumI18n={USER_ACTIVITY_STATUS_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.source")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="sourceType"
            enumValue={SOURCE_TYPE}
            enumI18n={SOURCE_TYPE_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
