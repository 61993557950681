import * as React from "react";
import { useEffect, useState } from "react";

export interface FlipCardProps {
  cardZIndex?: string;
  containerStyle?: {};
  isFlipped?: boolean;
  flipSpeedBackToFront?: number;
  flipSpeedFrontToBack?: number;
  cardStyles?: { front?: {}; back?: {} };
  infinite?: boolean;
  flipDirection?: "horizontal" | "vertical";
  children: [React.ReactNode, React.ReactNode];
}

const FlipCard: React.FC<FlipCardProps> = (props) => {
  const {
    cardStyles,
    cardZIndex,
    containerStyle,
    flipDirection,
    flipSpeedFrontToBack,
    flipSpeedBackToFront,
    infinite,
  } = props;
  const { back, front } = cardStyles || {};

  const [isFlipped, setFlipped] = useState(props.isFlipped);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (props.isFlipped !== isFlipped) {
      setFlipped(props.isFlipped);
      setRotation((c) => c + 180);
    }
  }, [props.isFlipped]);

  const getComponent = (key: 0 | 1) => {
    if (props.children.length !== 2) {
      throw new Error(
        "Component ReactCardFlip requires 2 children to function"
      );
    }
    return props.children[key];
  };

  const frontRotateY = `rotateY(${
    infinite ? rotation : isFlipped ? 180 : 0
  }deg)`;
  const backRotateY = `rotateY(${
    infinite ? rotation + 180 : isFlipped ? 0 : -180
  }deg)`;
  const frontRotateX = `rotateX(${
    infinite ? rotation : isFlipped ? 180 : 0
  }deg)`;
  const backRotateX = `rotateX(${
    infinite ? rotation + 180 : isFlipped ? 0 : -180
  }deg)`;

  const styles: any = {
    back: {
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      height: "100%",
      left: "0",
      position: isFlipped ? "relative" : "absolute",
      top: "0",
      transform: flipDirection === "horizontal" ? backRotateY : backRotateX,
      transformStyle: "preserve-3d",
      transition: `${flipSpeedFrontToBack}s`,
      width: "100%",
      ...back,
    },
    container: {
      perspective: "1000px",
      zIndex: `${cardZIndex}`,
    },
    flipper: {
      height: "100%",
      position: "relative",
      width: "100%",
    },
    front: {
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      height: "100%",
      left: "0",
      position: isFlipped ? "absolute" : "relative",
      top: "0",
      transform: flipDirection === "horizontal" ? frontRotateY : frontRotateX,
      transformStyle: "preserve-3d",
      transition: `${flipSpeedBackToFront}s`,
      width: "100%",
      zIndex: "2",
      ...front,
    },
  };

  return (
    <div
      className="react-card-flip"
      style={{ ...styles.container, ...containerStyle }}
    >
      <div className="react-card-flipper" style={styles.flipper}>
        <div className="react-card-front" style={styles.front}>
          {getComponent(0)}
        </div>

        <div className="react-card-back" style={styles.back}>
          {typeof isFlipped === "boolean" ? getComponent(1) : null}
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
