import { useState, useCallback, useEffect } from "react";
import api from "~/api";
import {
  Box,
  Button,
  Section,
  ButtonGroup,
  Pagination,
  LinearProgress,
} from "~/components/UI";
import { useParams } from "react-router";
import Table from "./Table";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import Filter from "./Filter";
import ManageSitesDialog from "../ManageSitesDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";

interface LinkedEntitiesProps {
  paymentService: PaymentServicesApi.PaymentServiceDetailsDto;
}

const LinkedEntities = ({ paymentService }: LinkedEntitiesProps) => {
  const { paymentId } = useParams<{ paymentId?: string }>();
  const [openManageSites, setOpenManageSites] = useState(false);
  const [query, setQuery] = useState<PaymentServicesApi.SitesQuery>();
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<PaymentServicesApi.SiteDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const { t } = useTranslation();

  const onCloseManageSitesDialog = (result?: boolean) => {
    setOpenManageSites(false);
    if (result) {
      getAssignedSites();
    }
  };

  const getAssignedSites = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      if (!paymentId) return;
      try {
        setLoading(true);
        const { data } = await api.paymentServices.getPaymentSites(
          paymentId,
          20,
          page || currentPage,
          query
        );
        data.data && setSites(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [paymentId, query, pagination]
  );

  const onChangeFilters = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getAssignedSites(page);
  };

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onUpdateItem = useCallback((site: PaymentServicesApi.SiteDto) => {
    setSites((sites) => updateArrayItem(sites, site, "siteId"));
  }, []);

  const onUpdate = useCallback(
    async (site: PaymentServicesApi.SiteDto) => {
      if (!paymentId) return;
      try {
        const {
          data: { data },
        } = await api.paymentServices.updateSiteService(
          paymentId,
          site.siteId,
          site
        );
        onUpdateItem({ ...site, enabled: data.enabled });
        toast.success(
          `${site.name} ${data.enabled ? t("text.active") : t("text.disabled")}`
        );
      } catch (e) {
        console.error(e);
      }
    },
    [paymentId, onUpdateItem, t]
  );

  const onDelete = useCallback(
    async (site: PaymentServicesApi.SiteDto) => {
      if (!paymentId) return;
      try {
        await api.paymentServices.deleteSiteService(paymentId, site.siteId);
        getAssignedSites();
        toast.success("success");
      } catch (e) {
        console.error(e);
      }
    },
    [paymentId]
  );

  useEffect(() => {
    paymentId && getAssignedSites(1);
  }, [paymentId]);

  useEffect(() => {
    getAssignedSites();
  }, [query]);

  return (
    <>
      <Section
        title={t("title.linkedEntities")}
        extra={
          <ButtonGroup>
            <Filter onChangeFilter={onChangeFilters} />
            <CanIUse permissions={PERMISSION.EDIT_PAYMENT_SERVICE}>
              <Button
                variant="contained"
                onClick={() => setOpenManageSites(true)}
              >
                {t("button.manageEntities")}
              </Button>
            </CanIUse>
          </ButtonGroup>
        }
      />
      <Box
        flexGrow={1}
        minHeight={500}
        overflow="auto"
        bgcolor="background.paper"
      >
        <LinearProgress hidden={!loading} />
        <Table
          serviceEnabled={paymentService.enabled}
          dataSource={sites}
          onUpdateItem={onUpdateItem}
          onSort={onSort}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </Box>
      <Box>
        {pagination && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CanIUse permissions={PERMISSION.EDIT_PAYMENT_SERVICE}>
        <ManageSitesDialog
          paymentId={paymentId}
          open={openManageSites}
          onClose={onCloseManageSitesDialog}
        />
      </CanIUse>
    </>
  );
};

export default LinkedEntities;
