import clsx from "clsx";
import {
  Card,
  Icon,
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from "~/components/UI";
import Skeleton from "@material-ui/lab/Skeleton";

export interface MetricsProps {
  loading?: boolean;
  value?: string | number;
  label?: string;
  trend?: "up" | "down";
  trendValue?: string | number;
  trendDescription?: string;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      height: "100%",
    },
    root: {
      width: "205px",
      boxSizing: "border-box",
    },
    value: {
      fontSize: 28,
    },
    trend: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 15,
      height: 15,
      fontSize: 9,
      "& > *": {
        position: "relative",
        zIndex: 1,
      },
      "&:before": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        content: '""',
        borderRadius: "50%",
        opacity: 0.2,
      },
    },
    up: {
      color: theme.palette.success.main,
      "&:before": {
        backgroundColor: theme.palette.success.light,
      },
    },
    down: {
      color: theme.palette.error.main,
      "&:before": {
        backgroundColor: theme.palette.error.light,
      },
    },
    icon: {
      fontSize: 12,
    },
    trendValue: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      marginLeft: 5,
    },
    trendDescription: {
      "&:first-letter": {
        textTransform: "capitalize",
      },
    },
  })
);

const MetricSkeleton = ({ label }: Pick<MetricsProps, "label">) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <Typography variant="h5" color="textSecondary">
        {label}
      </Typography>
      <>
        <Box flex={1}>
          <Skeleton>
            <Typography className={classes.value} variant="subtitle1">
              value
            </Typography>
          </Skeleton>
        </Box>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Skeleton>
            <Typography>trendDescription</Typography>
          </Skeleton>
        </Grid>
      </>
    </Card>
  );
};

const Metrics = ({
  loading,
  value,
  label,
  trend,
  trendValue,
  trendDescription,
}: MetricsProps) => {
  const classes = useStyles();

  if (loading) return <MetricSkeleton label={label} />;

  return (
    <Card className={classes.container}>
      <div>
        <Typography variant="h5" color="textSecondary">
          {label}
        </Typography>
      </div>
      <Box flex={1}>
        <Typography className={classes.value} variant="subtitle1">
          {value}
        </Typography>
      </Box>
      <Grid container justifyContent="flex-start" alignItems="center">
        <span
          className={clsx(classes.trend, {
            [classes.up]: trend === "up",
            [classes.down]: trend === "down",
          })}
        >
          {trend && (
            <Icon name={trend === "up" ? "TrendArrowUp" : "TrendArrowDown"} />
          )}
        </span>
        <span
          className={clsx(classes.trendValue, {
            [classes.up]: trend === "up",
            [classes.down]: trend === "down",
          })}
        >
          {trendValue}%
        </span>
        <Box ml={5}>
          <Typography
            className={classes.trendDescription}
            variant="body1"
            color="textSecondary"
          >
            {trendDescription}
          </Typography>
        </Box>
      </Grid>
    </Card>
  );
};

export default Metrics;
