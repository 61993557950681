import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import history from "~/packages/history";
import alerts from "./alerts/reducer";
import auth from './auth/reducer';
import settings from "./settings/reducer";
import profile from "./profile/reducer";
import services from './services/reducer';
import search from './search/reducer';

const Middleware = applyMiddleware(thunk, routerMiddleware(history));

export const store = createStore(
  combineReducers({
    router: connectRouter(history),
    alerts,
    auth,
    settings,
    profile,
    services,
    search
  }),
  process.env.NODE_ENV === "production"
    ? compose(Middleware)
    : composeWithDevTools(Middleware)
);
