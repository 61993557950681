import { Grid, Typography, Box, StatusIndicator } from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import ExpandableTile from "~/components/ExpandableTile";
import ServiceDetails from "./ServiceDetails";
import ServiceOptions from "./ServiceOptions";

interface ServiceCardProps {
  service: Api.ServiceDto;
}

/**
 * @memberof Service
 * @component
 * @desc Render a Card of Service.
 * @property {Api.ServiceDto} service - Service data;
 */

const ServiceCard = ({ service }: ServiceCardProps) => {
  return (
    <ExpandableTile
      expandedContentRender={<ServiceDetails service={service} />}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary">
            <StatusIndicator status={service.enabled ? "active" : "disabled"} />
            {service.serviceName} (ID: {service.serviceId})
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IncidentsIcon incidents={service.incidents} />
            </Grid>
            <Grid item>
              <ServiceOptions service={service} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={1}>
        <Typography variant="subtitle1">{service.siteName}</Typography>
      </Box>
      <Box mb={5}>
        <Typography variant="body1">{service.address}</Typography>
      </Box>
    </ExpandableTile>
  );
};

export default ServiceCard;
