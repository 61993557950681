import { Box, Label, Grid } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @memberof Sites
 * @component
 * @desc Render more information about site.
 * @property {Api.SiteDto} site Site data
 * @property {"default" | "small"} size table size
 */

interface MoreInformationProps {
  site: Api.SiteDto;
}

const MoreInformation = ({ site }: MoreInformationProps) => {
  const { t } = useTranslation();

  return (
    <Box px={3}>
      <Grid container>
        <Grid item>
          <Label text={t("label.latitude")}>{site.latitude}</Label>
          <Label text={t("label.longitude")}>{site.longitude}</Label>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoreInformation;
