import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Label,
  FormControlLabel,
  OutlinedInput,
  Switch,
  Typography,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import SelectPaymentServiceType from "~/components/CommonFormControls/SelectPaymentServiceType";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";
import SelectPurposeKey from "~/components/CommonFormControls/SelectPurposeKey";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";

interface CUPaymentServiceProps extends Omit<DialogProps, "onClose"> {
  paymentService?: PaymentServicesApi.PaymentServiceDto;
  onClose?: (paymentService?: PaymentServicesApi.PaymentServiceDto) => void;
}

const defaultValues = {
  paymentServiceType: "",
  enabled: false,
  merchantId: "",
  gatewayId: "",
  signKeyId: "",
  verifyKeyId: "",
  terminalId: "",
} as
  | PaymentServicesApi.CreatePaymentService
  | PaymentServicesApi.UpdatePaymentService;

/**
 * @memberof PaymentServices
 * @component
 * @desc Create or Update a Payment Service dialog.
 * @property {PaymentServicesApi.PaymentServiceDto} paymentService - Service data
 * @property {Function} onClose - close cb (paymentService?: PaymentServicesApi.PaymentServiceDto | boolean) => void
 * @requires PaymentServicesContext
 */

const CUPaymentService = ({
  paymentService,
  open,
  onClose,
}: CUPaymentServiceProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    paymentServiceType: yup.string().required(),
    enabled: yup.boolean().required(),
    ...(paymentService && paymentService.paymentId ? {} : {
      gatewayId: yup.string().when("paymentServiceType", {
        is: (value: string) => (value === "GooglePay"),
        then: () => yup.string().required()
      }),
    }),
    merchantId: yup.string().required(),
    signKeyId: yup.string().required(),
    verifyKeyId: yup.string().required(),
    terminalId: yup.string().required()
  });
  const onSubmit = useCallback(
    async ({gatewayId , ...data}) => {
      let result;
      const formData = {...data, ...(gatewayId === "" ? {} : {gatewayId})}; 
      setLoading(true);
      try {
        if (paymentService) {
          result = await api.paymentServices.updatePaymentService(
            paymentService.paymentId,
            formData
          );
          toast.success(t("text.recordSuccessfullyUpdated"));
        } else {
          result = await api.paymentServices.createService(formData);
        }
        onClose && onClose(result.data.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [paymentService, onClose]
  );

  useEffect(() => {
    if (paymentService) {
      const {
        merchantId,
        gatewayId,
        signKeyId,
        verifyKeyId,
        enabled,
        paymentServiceType,
        terminalId,
      } = paymentService;
      setInitialValues({
        paymentServiceType,
        enabled,
        gatewayId,
        merchantId,
        signKeyId,
        verifyKeyId,
        terminalId,
      });
    } else {
      setInitialValues(defaultValues);
    }
  }, [paymentService]);

  return (
    <Dialog
      open={open}
      title={
        paymentService
          ? `${t("title.editPaymentService")}: ${paymentService.paymentServiceType
          }`
          : t("title.addPaymentService")
      }
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {paymentService && (
                  <Label text="ID">
                    <Typography>{paymentService.paymentId}</Typography>
                  </Label>
                )}
                <FormControlLabel label={t("label.paymentService")}>
                  <Box width={205}>
                    <SelectPaymentServiceType
                      name="paymentServiceType"
                      variant="outlined"
                      formikControll
                      fullWidth
                    />
                  </Box>
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                {(paymentService && paymentService.paymentServiceType === "GooglePay") ||
                  (values.paymentServiceType === "GooglePay") ?
                  <FormControlLabel label={t("label.gatewayID")}>
                    <OutlinedInput name="gatewayId" formikControll fullWidth />
                  </FormControlLabel>
                  : null
                }
                <FormControlLabel label={t("label.merchantID")}>
                  <OutlinedInput name="merchantId" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.signKeyName")}>
                  <SelectPurposeKey
                    defaultInputValue={
                      paymentService &&
                      paymentService.signKeyId
                    }
                    name="signKeyId"
                    keyPurpose={KEY_PURPOSE.INBOUND_VT_SIGN_KEY}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.verifyKeyName")}>
                  <SelectPurposeKey
                    defaultInputValue={
                      paymentService &&
                      paymentService.verifyKeyId
                    }
                    name="verifyKeyId"
                    keyPurpose={KEY_PURPOSE.INBOUND_VT_VERIFY_KEY}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.terminalId")}>
                  <OutlinedInput name="terminalId" formikControll fullWidth />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {paymentService ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CUPaymentService;
