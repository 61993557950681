import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import api from "~/api";

interface FormikSelectPOSProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (options: Api.PosDto[], state: any) => Api.PosDto[];
}

interface SelectPOSProps {
  inputValue?: string;
  name?: string;
  onChange?: (pos?: Api.PosDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (options: Api.PosDto[], state: any) => Api.PosDto[];
}

const requestSearchPOS = _.memoize((search: string) =>
  api.poses.getAll(20, 1, { search, siteId: "null" })
);

export const SelectPOS = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue,
  }: SelectPOSProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Api.PosDto[]>([]);
    const [pos, setPos] = useState<Partial<Api.PosDto> | null>(null);
    const { t } = useTranslation();

    const searchPOSes = useCallback(
      _.debounce((posId: string) => {
        setLoading(true);
        requestSearchPOS(posId)
          .then(({ data }) => {
            setSearchResults(data.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500),
      []
    );

    const onChangeInputValue = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value);
        onChangeValue && onChangeValue(e.target.value);
        searchPOSes(e.target.value || "");
      },
      []
    );

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.name);
          setPos(newValue);
        } else {
          setValue("");
          setPos(null);
        }
        onChange && onChange(newValue);
      },
      [onChange]
    );

    const getOptionSelected = useCallback(
      (o: Api.PosDto, value: Api.PosDto) => o.posId === value.posId,
      []
    );

    const getOptionLabel = useCallback((o: Api.PosDto) => o.posId, []);

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setPos(null);
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchPOSes("");
      return () => {
        requestSearchPOS.cache.clear && requestSearchPOS.cache.clear();
      };
    }, []);

    return (
      <Autocomplete
        value={pos}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText={t("text.noResultsFound")}
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            loading={loading}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FormikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FormikSelectPOSProps & SelectPOSProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChange = useCallback(
    (pos?: Api.PosDto) => {
      field.onChange({
        target: {
          name,
          value: pos || "",
        },
      });
    },
    [field]
  );

  return (
    <SelectPOS
      {...field}
      {...other}
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={field.value || defaultInputValue}
    />
  );
};

export default React.memo(FormikContainer);
