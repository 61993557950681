import { useState, useCallback, useEffect } from "react";
import { LinearProgress } from "~/components/UI";
import Breadcrumbs from "~/components/Breadcrumbs";
import { RouteComponentProps } from "react-router-dom";
import PaymentService from "./Components/PaymentService";
import { useTranslation } from "react-i18next";

import Routes from "~/constants/routes";
import api from "~/api";

interface PaymentServicePageProps
  extends RouteComponentProps<
    { paymentId: string },
    {},
    { paymentServiceMeta?: PaymentServicesApi.PaymentServiceDto }
  > {}

/**
 * @namespace PaymentService
 */

const PaymentServicePage = ({ match, location }: PaymentServicePageProps) => {
  const { paymentId } = match.params;
  const { paymentServiceMeta } = location.state || {};
  const [label, setLabel] = useState(
    paymentServiceMeta ? paymentServiceMeta.paymentServiceType : ""
  );
  const [loading, setLoading] = useState(false);
  const [paymentService, setPaymentService] =
    useState<PaymentServicesApi.PaymentServiceDetailsDto>();
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.paymentServices"), href: Routes.PAYMENT_SERVICES },
    ];
  }, [t]);

  const getPaymentServiceDetails = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { data: paymentService },
      } = await api.paymentServices.getPaymentService(paymentId);
      setPaymentService(paymentService);
      if (!label) setLabel(paymentService.name);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [paymentId, label]);

  useEffect(() => {
    getPaymentServiceDetails();
  }, []);

  return (
    <>
      <Breadcrumbs links={[...getBreadcrumbs(), { label }]} />
      <LinearProgress hidden={!loading} />
      <PaymentService
        paymentService={paymentService}
        onUpdate={(service) => setPaymentService(service)}
      />
    </>
  );
};

export default PaymentServicePage;
