import { useContext, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import BusinessOperationsContext from "./businessOperationsContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface BusinessOperationOptionsProps {
  businessOperation: JournalBusinessOperationsApi.BusinessOperationDto;
}

/**
 * @memberof BusinessOperations
 * @component
 * @desc Business Operation options. Using a BusinessOperationsContext.
 * @property {JournalBusinessOperationsApi.BusinessOperationDto} businessOperation - Business Operation data;
 * @requires BusinessOperationsContext
 */

const BusinessOperationOptions = ({
  businessOperation,
}: BusinessOperationOptionsProps) => {
  const { onUpdateItem } = useContext(BusinessOperationsContext);
  const [createIncident] = useCreateIncidentDialog(
    SOURCE_TYPE.BUSINESS_OPERATION
  );
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (businessOperation.incidents) {
        businessOperation.incidents.count++;
        businessOperation.incidents.incidentIds.push(incident.incidentId);
      } else {
        businessOperation.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(businessOperation);
    },
    [businessOperation, onUpdateItem]
  );

  if (!hasAny([PERMISSION.BUSINESS_OPERATIONS_INCIDENTS])) {
    return null;
  }

  const options = [
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.BUSINESS_OPERATIONS_INCIDENTS),
      onClick: () =>
        createIncident(businessOperation.orderId, onCreatedIncident),
    },
  ];

  return <Dropdown options={options} />;
};

export default BusinessOperationOptions;
