import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch,
  LinearProgress,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import yup from "~/packages/yup";
import AddPos from "./AddPos";
import { deleteEmptyFields } from "~/utils/helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface UpdateSiteProps extends Omit<DialogProps, "onClose"> {
  site?: Api.SiteDto;
  onClose?: (result?: boolean | Api.SiteDto) => void;
}

type FormState = Api.UpdateSite;

const defaultValues: FormState = {
  enabled: false,
  services: [],
  poses: [],
};

const getSubmitData = ({
  siteId,
  poses,
  services,
  ...other
}: FormState): Api.CreateSite | Api.UpdateSite => {
  return {
    ...other,
    siteId,
    poses: poses ? poses.map(({ posId }) => ({ posId })) : [],
  };
};

/**
 * @memberof Sites
 * @component
 * @desc Dialog for creating or editing a site.
 * @property {Api.SiteDto} site Site id
 * @property {Function} onClose - passes true if the site was created/updated successfully. (result?: boolean) => void;
 */

const UpdateSite = ({ site, open, onClose }: UpdateSiteProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMeta, setLoadingMeta] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData) => {
      setLoading(true);
      try {
        if (isEditMode && site && site.siteId) {
          const { data } = await api.sites.updateSite(
            site.siteId,
            getSubmitData(formData) as Api.UpdateSite
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
          onClose && onClose(data.data);
        } else {
          await api.sites.createSite(getSubmitData(formData) as Api.CreateSite);
          toast.success(t("text.recordWasSuccessfullyCreated"));
          onClose && onClose(true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, site, onClose, t]
  );

  const validationSchema = yup.object().shape({
    siteId: yup.string().required(),
    name: yup.string().required(),
    enabled: yup.boolean().required(),
    address: yup.string().required(),
    longitude: yup.string().lng().required(),
    latitude: yup.string().lat().required(),
    description: yup.string(),
    services: yup.array(),
    poses: yup.array(),
  });

  const init = async (site?: Api.SiteDto) => {
    if (site) {
      const {
        siteId = "",
        name = "",
        enabled = false,
        address = "",
        description = "",
        longitude,
        latitude,
      } = site;
      let poses = [] as Api.PosDto[];
      let services = [] as Api.ServiceDto[];
      const initialValues = deleteEmptyFields({
        siteId,
        name,
        enabled,
        address,
        description,
        services,
        poses,
        longitude,
        latitude,
      });
      setInitialValues(initialValues);
      setEditMode(true);
      setLoadingMeta(true);
      try {
        const posesResponse = await api.sites.getPoses(site.siteId);
        const servicesResponse = await api.sites.getServices(site.siteId);

        poses = posesResponse.data.data;
        services = servicesResponse.data.data;
        setInitialValues({
          ...initialValues,
          services,
          poses: poses || [],
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingMeta(false);
      }
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  };

  useEffect(() => {
    init(site);
  }, [site]);

  return (
    <Dialog
      open={open}
      title={
        site && site.siteId
          ? `${t("title.editSite")} # ${site.siteId}`
          : t("title.createNewSite")
      }
      onClose={() => onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label="ID">
                  <OutlinedInput name="siteId" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.siteName")}>
                  <OutlinedInput name="name" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.address")}>
                  <OutlinedInput name="address" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.longitude")}>
                  <OutlinedInput name="longitude" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.latitude")}>
                  <OutlinedInput name="latitude" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput name="description" formikControll fullWidth />
                </FormControlLabel>
              </Box>
              {loadingMeta && <LinearProgress />}
              <AddPos />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleSubmit()}
                color="primary"
                loading={loading}
                disabled={loadingMeta}
                variant="contained"
              >
                {site && site.siteId ? t("button.save") : t("button.create")}
              </Button>
              <Button
                disabled={loading || loadingMeta}
                onClick={() => onClose && onClose()}
                variant="outlined"
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateSite;
