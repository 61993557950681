import { Grid, Typography, Box, StatusIndicator } from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import Card from "../../CommonCard";
import POSOptions from "~/pages/Catalogs/POSes/POSes/Components/POSOptions";

interface POSCardProps {
  pos: Api.PosDto;
  onClick: () => void;
}

/**
 * @memberof POSes
 * @component
 * @desc Render a Card of POS.
 * @property {Api.PosDto} pos - POS data;
 */

const POSCard = ({ pos, onClick }: POSCardProps) => (
  <Card onClick={onClick}>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="subtitle1">
          <StatusIndicator status={pos.enabled ? "active" : "disabled"} />
          POS ID: {pos.posId}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <IncidentsIcon incidents={pos.incidents} />
          </Grid>
          <Grid item>
            <POSOptions pos={pos} hideEditOptions={true} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Box mb={1}>
      <Typography variant="subtitle1">
        {pos.siteName} (ID: {pos.siteId})
      </Typography>
    </Box>
    <Box mb={5}>
      <Typography variant="body2">{pos.address}</Typography>
    </Box>
  </Card>
);

export default POSCard;
