import { Switch, StatusIndicator } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import SiteDetails from "./SiteDetails";
import SiteOptions from "./SiteOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "siteId",
    sort: true,
    i18nKey: "siteId",
  },
  {
    key: "connected",
    sort: true,
    i18nKey: "siteName",
    render: (connected: boolean, row: PaymentServicesApi.SiteDto) => (
      <>
        <StatusIndicator status={connected ? "active" : "disabled"} />
        {row.name}
      </>
    ),
  },
  {
    key: "address",
    sort: true,
    i18nKey: "address",
  },
  {
    key: "enabled",
    sort: true,
    i18nKey: "enabled",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
    },
  },
];

/**
 * @memberof PaymentService
 * @component
 * @desc Render Linked Entities table of a Payment Service.
 * @property {PaymentServicesApi.SiteDto[]} dataSource - Sites data
 * @property {Function} onUpdate - callback for update a Site. (data: PaymentServicesApi.SiteDto) => void
 * @property {Function} onDelete - callback for the delete a Site. (data: PaymentServicesApi.SiteDto) => Promise<void> | void
 */

interface SitesTableProps
  extends Omit<TableProps<PaymentServicesApi.SiteDto>, "columns"> {
  serviceEnabled: boolean;
  onUpdate?: (data: PaymentServicesApi.SiteDto) => void;
  onUpdateItem?: (data: PaymentServicesApi.SiteDto) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onDelete?: (data: PaymentServicesApi.SiteDto) => Promise<void> | void;
}

const SitesTable = ({
  serviceEnabled,
  onUpdate,
  onUpdateItem,
  onSort,
  onDelete,
  ...props
}: SitesTableProps) => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, site: PaymentServicesApi.SiteDto) => (
            <ConfirmDialog
              placement="top"
              disabled={
                !serviceEnabled || !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
              }
              onConfirm={() =>
                onUpdate && onUpdate({ ...site, enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={
                  !serviceEnabled || !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
                }
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, row: PaymentServicesApi.SiteDto) => (
            <SiteOptions
              site={row}
              serviceEnabled={serviceEnabled}
              onDelete={onDelete}
              onUpdateItem={onUpdateItem}
              onUpdate={onUpdate}
            />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      stickyHeader
      onRequestSort={onSort}
      rowKey="siteId"
      expandable={{
        highlightRow: true,
        expandedRowRender: (site: PaymentServicesApi.SiteDto) => (
          <SiteDetails serviceEnabled={serviceEnabled} site={site} />
        ),
      }}
    />
  );
};

export default SitesTable;
