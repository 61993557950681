import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "~/components/UI";

export enum LayoutType {
  TABLE = "table",
  TILES = "tiles",
}

interface ListLayoutSwitcherProps {
  defaultValue: LayoutType;
  onChange?: (type: LayoutType) => void;
}

const LAYOUT_TYPE_KEY = "layoutType";

const ListLayoutSwitcher = ({
  defaultValue,
  onChange,
}: ListLayoutSwitcherProps) => {
  const search = useMemo(() => new URLSearchParams(window.location.search), []);
  const [value, setValue] = useState(defaultValue || eLayoutType.TABLE);
  const history = useHistory();

  const onChangeType = useCallback(
    (type: LayoutType) => {
      search.set(LAYOUT_TYPE_KEY, type);
      history.replace("?" + search.toString());
      setValue(type);
      onChange && onChange(type);
    },
    [history, search, onChange]
  );

  useEffect(() => {
    const layoutType = search.get(LAYOUT_TYPE_KEY) as LayoutType;
    if (layoutType) onChangeType(layoutType);
  }, [onChangeType, search]);

  return (
    <>
      <Button
        iconContainer={true}
        variant="contained"
        color={value === eLayoutType.TILES ? "primary" : "default"}
        onClick={() => onChangeType(eLayoutType.TILES)}
      >
        <Icon name="Tiles" />
      </Button>
      <Button
        iconContainer={true}
        variant="contained"
        color={value === eLayoutType.TABLE ? "primary" : "default"}
        onClick={() => onChangeType(eLayoutType.TABLE)}
      >
        <Icon name="List" />
      </Button>
    </>
  );
};

export const eLayoutType = LayoutType;

export default React.memo(ListLayoutSwitcher);
