import { useEffect, useState } from "react";
import { Box, Select } from "~/components/UI";
import { useField } from "formik";
import SelectSiteAutocomplete from "../CommonFormControls/SelectSite";
import api from "~/api";

interface SelectSiteProps {
  fieldName: string;
}

const SelectSite = ({ fieldName }: SelectSiteProps) => {
  const [poses, setPOSes] = useState<Api.PosDto[]>([]);
  const [field] = useField(fieldName);

  const getPOSes = async (siteId: string) => {
    try {
      const {
        data: { data },
      } = await api.sites.getPoses(siteId);
      setPOSes(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (field.value) {
      getPOSes(field.value);
    }
  }, [field.value]);

  return (
    <>
      <SelectSiteAutocomplete name={fieldName} />
      {field.value ? (
        <Box pt={4}>
          <Select
            name="posId"
            variant="outlined"
            placeholder="Select POS"
            options={poses.map((p) => ({ label: p.posId, value: p.posId }))}
            formikControll
            fullWidth
          />
        </Box>
      ) : null}
    </>
  );
};

export default SelectSite;
