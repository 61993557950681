import { useCallback, useState, useMemo } from "react";
import * as React from "react";
import {
  PaymentServiceDialogsContext,
  Options,
} from "./PaymentServiceDialogsContext";
import CUServiceDialog from "./CUServiceDialog";

export interface CreateIncidentDProps {
  children: React.ReactNode;
}

export const PaymentServiceDialogsProvider = ({
  children,
}: CreateIncidentDProps) => {
  const [paymentService, setPaymentService] =
    useState<PaymentServicesApi.PaymentServiceDto>();
  const [isShown, setShown] = useState(false);
  const [options, setOptions] = useState<Options>();

  const createPaymentService = useCallback((options) => {
    setPaymentService(undefined);
    setOptions(options);
    setShown(true);
  }, []);

  const updatePaymentService = useCallback((paymentService, options) => {
    setPaymentService(paymentService);
    setOptions(options);
    setShown(true);
  }, []);

  const onClose = useCallback(
    (paymentService?: PaymentServicesApi.PaymentServiceDto) => {
      setShown(false);
      if (paymentService && options) {
        options.onClose && options.onClose(paymentService);
      }
    },
    [options]
  );

  const contextValue = useMemo(
    () => ({ createPaymentService, updatePaymentService }),
    []
  );

  return (
    <PaymentServiceDialogsContext.Provider value={contextValue}>
      <React.Fragment>{children}</React.Fragment>
      <CUServiceDialog
        paymentService={paymentService}
        open={isShown}
        onClose={onClose}
      />
    </PaymentServiceDialogsContext.Provider>
  );
};

export default PaymentServiceDialogsProvider;
