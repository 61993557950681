import { enumToI18nKey } from '~/utils/helpers';

export const METRIC_ENTITY = {
  PSP_PAYMENT_DURATION: 'psp-payment-duration',
  CLIENT_PAYMENT_DURATION: 'client-payment-duration',
  FINANCIAL_TRANSACTION_COUNTS: "financial-transaction-counts",
  CLIENTS: 'clients',
  AVERAGE_CHECK: 'average-bill',
  AMOUNT_OF_COLLECTED_MONEY: 'amount-of-collected-money',
  BUSINESS_TRANSACTION_COUNTS: 'business-transaction-counts'
} as { [key: string]: DashboardSettingsApi.eMetricEntity }

export const METRIC_ENTITY_I18n = enumToI18nKey(METRIC_ENTITY, 'METRIC_ENTITY');

export const WIDGET_ENTITY = {
  SITE: "site",
  POS: "pos",
  SERVICE: "service",
  PAYMENT_SERVICE: 'payment-service',
  JOURNAL_BUSINESS_OPERATIONS: "journal-business-operations",
  EVENT_LOG: "event-log",
  JOURNAL_INCIDENTS: "journal-incidents",
  JOURNAL_LOG_SYSTEM: "journal-log-system",
  JOURNAL_OF_USERS_ACTIONS: "journal-of-users-actions"
} as { [key: string]: DashboardSettingsApi.eWidgetEntity }

export const JournalEntitys = [
  WIDGET_ENTITY.JOURNAL_BUSINESS_OPERATIONS,
  WIDGET_ENTITY.EVENT_LOG,
  WIDGET_ENTITY.JOURNAL_INCIDENTS,
  WIDGET_ENTITY.JOURNAL_LOG_SYSTEM,
  WIDGET_ENTITY.JOURNAL_OF_USERS_ACTIONS,
];

export const LANGUAGE = {
  UA: "ua",
  ENG: "en",
  RU: "ru"
} as { [key: string]: DashboardSettingsApi.eLanguage }

export const WIDGET_ENTITY_I18n = enumToI18nKey(WIDGET_ENTITY, 'WIDGET_ENTITY');