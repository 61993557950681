import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Divider,
  LinearProgress,
  Box,
  Tabs,
  Button,
  ButtonGroup,
} from "~/components/UI";
import AllIncidentsButton from "~/components/Incidents/AllIncidentsButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import DetailsOfServices from "./DetailsOfServices";
import DetailsOfPaymentMethods from "./DetailsOfPaymentMethods";
import MoreInformation from "./MoreInformation";
import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import ROUTES from "~/constants/routes";
import api from "~/api";
import GoToRelatedProcessesButton from "~/components/GoToRelatedProcessesButton";
import { SOURCE_TYPE } from "~/api/common";

interface POSDetailsProps {
  size?: "default" | "small";
  pos: Api.PosDto;
}

const getStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 285,
    },
    growBox: {
      overflow: "auto",
    },
    tabsContainer: {
      height: "calc(100% - 35px)",
    },
  })
);

/**
 * @memberof POSes
 * @component
 * @desc Render detailed information about a POS.
 * @property {Api.PosDto} pos - POS data;
 * @property {"default" | "small"} size - container size;
 */

const POSDetails = ({ pos, size }: POSDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [posDetails, setPosDetails] = useState<Api.PosDetailsDto>();
  const classes = getStyles();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();
  const history = useHistory();

  async function getPOS(id: string) {
    setLoading(true);
    try {
      const { data } = await api.poses.getPos(id);
      setPosDetails(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const goToMetrics = useCallback(() => {
    history.push(generatePath(ROUTES.POS_METRICS, { posId: pos.posId }), {
      pos,
    });
  }, [history, pos]);

  useEffect(() => {
    getPOS(pos.posId);
  }, [pos.posId]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (posDetails) {
    return (
      <Grid
        container
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
        classes={{
          container: classes.container,
        }}
      >
        <Box flexGrow={1} className={classes.growBox}>
          <Tabs
            className={classes.tabsContainer}
            variant="scrollable"
            scrollButtons="auto"
            tabs={[
              {
                label: `${t("title.services")} (${
                  posDetails.serviceCount || 0
                })`,
                hidden: !hasAll(PERMISSION.READ_SERVICE),
                content: <DetailsOfServices posId={pos.posId} size={size} />,
              },
              {
                label: `${t("title.paymentServices")} (${
                  posDetails.paymentServiceCount || 0
                })`,
                hidden: !hasAll(PERMISSION.READ_PAYMENT_SERVICE),
                content: (
                  <DetailsOfPaymentMethods posId={pos.posId} size={size} />
                ),
              },
              {
                label: t("title.moreInformation"),
                content: <MoreInformation pos={pos} size={size} />,
              },
            ]}
          />
        </Box>
        <Box pt={3} px={3}>
          <Divider />
          <Box py={3}>
            <ButtonGroup>
              <AllIncidentsButton variant="outlined" />
              <GoToRelatedProcessesButton
                sourceId={pos.posId}
                sourceType={SOURCE_TYPE.POS}
                variant="outlined"
              />
              <Button variant="outlined" onClick={goToMetrics}>
                {t("button.metrics")}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Grid>
    );
  } else {
    return null;
  }
};

export default POSDetails;
