import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "~/components/UI";
import CommonTile from "../CommonTile";
import SiteDetails from "./SiteDetails";
import Summary from "./Summary";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import SitesContext from "~/pages/Catalogs/Sites/Sites/Components/sitesContext";
import Routes from "~/constants/routes";
import { updateArrayItem } from "~/utils/helpers";
import { toast } from "react-toastify";
import List from "./List";
import api from "~/api";

interface SitesProps {
  setting: DashboardSettingsApi.Widget;
}

const Sites = ({ setting }: SitesProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState<Api.SiteDto>();
  const [sites, setSites] = useState<Api.SiteDto[]>();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const getList = async () => {
    setLoading(true);
    try {
      const { data } = await api.sites.getAll(2, 1, {
        sortBy: setting.currentSortBy,
      });
      setSites(data.data);
      setTotalCount(
        data.meta && data.meta.pagination ? data.meta.pagination.totalCount : 0
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateItem = useCallback(
    (site: Api.SiteDto) => {
      setSites((sites) =>
        sites ? updateArrayItem(sites, site, "siteId") : []
      );
      if (selectedSite && site.id === selectedSite.id) {
        setSelectedSite(site);
      }
    },
    [selectedSite]
  );

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdateSite) => {
      try {
        const {
          data: { data: site },
        } = await api.sites.updateSite(id, formData);
        onUpdateItem(site);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [sites, t, onUpdateItem]
  );

  const contextValue = useMemo(
    () => ({
      onUpdateItem,
      onUpdate,
    }),
    [sites]
  );

  useEffect(() => {
    getList();
  }, []);

  if (!hasAll(PERMISSION.READ_SITE)) return null;

  return (
    <SitesContext.Provider value={contextValue}>
      <CommonTile
        title={
          <Typography variant="h4">
            {t("title.sites")}{" "}
            <Typography variant="h4" component="span" color="textSecondary">
              {totalCount}
            </Typography>
          </Typography>
        }
        hideShowAll={Boolean(selectedSite)}
        showAllLink={Routes.SITES}
        showAllText={`${t("button.showAll")} ${t("title.sites")}`}
      >
        {selectedSite ? (
          <SiteDetails
            site={selectedSite}
            onClose={() => setSelectedSite(undefined)}
          />
        ) : (
          <List
            loading={loading}
            sites={sites}
            onSelectSite={setSelectedSite}
          />
        )}
        <Summary />
      </CommonTile>
    </SitesContext.Provider>
  );
};

export default Sites;
