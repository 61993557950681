import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import {
  BUSINESS_OPERATION_ORIGIN_OF_ORDER,
  BUSINESS_OPERATION_ORIGIN_OF_ORDER_I18n,
  BUSINESS_OPERATION_STATUS,
  BUSINESS_OPERATION_STATUS_I18n,
  BUSINESS_OPERATION_STAGE_TYPE,
  BUSINESS_OPERATION_STAGE_TYPE_I18n,
} from "~/api/journalBusinessOperations/enums";
import { useTranslation } from "react-i18next";
import api from "~/api";
import moment from "moment";
import SelectSiteBO from "~/components/CommonFormControls/SelectSiteBO";

interface FilterProps {
  initialValues?: JournalBusinessOperationsApi.GetBusinessOperationQuery;
  onChangeFilter: (
    data: JournalBusinessOperationsApi.GetBusinessOperationQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer {...props}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={BUSINESS_OPERATION_STATUS}
            enumI18n={BUSINESS_OPERATION_STATUS_I18n}
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.stage")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="state"
            enumValue={BUSINESS_OPERATION_STAGE_TYPE}
            enumI18n={BUSINESS_OPERATION_STAGE_TYPE_I18n}
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.site")} collapsed defaultOpen={true}>
          {/* <SelectValue
            name="siteId"
            loadOptions={() =>
              api.businessOperationsJournal
                .getSitesFilter()
                .then(({ data }) =>
                  data.data.map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          /> */}
          <SelectSiteBO name="siteId"/>
        </SidebarGroup>

        <SidebarGroup
          label={t("label.paymentWay")}
          collapsed
          defaultOpen={true}
        >
          <SelectValue
            name="paymentService"
            loadOptions={() =>
              api.businessOperationsJournal
                .getPaymentServicesFilter()
                .then(({ data }) =>
                  data.data.map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>

        <SidebarGroup
          label={t("label.serviceCategory")}
          collapsed
          defaultOpen={true}
        >
          <SelectValue
            name="orderType"
            loadOptions={() =>
              api.businessOperationsJournal
                .getServiceCategoriesFilter()
                .then(({ data }) =>
                  data.data.map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.origin")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="originOfOrder"
            enumValue={BUSINESS_OPERATION_ORIGIN_OF_ORDER}
            enumI18n={BUSINESS_OPERATION_ORIGIN_OF_ORDER_I18n}
          />
        </SidebarGroup>
        <IncludeFieldGroup
          label={t("title.openedIcidents")}
          fieldName="hasOpenedIncidents"
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
