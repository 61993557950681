import { Box, Typography, useTheme } from "~/components/UI";
import { useTranslation } from "react-i18next";
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_I18n,
} from "~/api/zReportsJournal/enum";

interface TransactionStatusProps {
  status: ZReportsJournalApi.eTransactionStatus;
}

/**
 * @memberof AuditZReportLog
 * @component
 * @desc Render status of ZReport.
 * @property {ZReportsJournalApi.eReportStatus} status - ZReport status;
 */

const BatchStatus = ({ status }: TransactionStatusProps) => {
  const { t } = useTranslation(["enums"]);
  const theme = useTheme();
  let color = theme.palette.text.primary;

  switch (status) {
    case TRANSACTION_STATUS.APPROVED:
      color = theme.palette.primary.main;
      break;
    case TRANSACTION_STATUS.IN_PROGRESS:
    case TRANSACTION_STATUS.REFUND_IN_PROGRESS:
    case TRANSACTION_STATUS.REVERSAL_IN_PROGRESS:
    case TRANSACTION_STATUS.COMPLETED:
    case TRANSACTION_STATUS.CREATED:
      color = theme.palette.info.main;
      break;
    case TRANSACTION_STATUS.REFUNDED:
    case TRANSACTION_STATUS.REVERSALED:
      color = theme.palette.warning.main;
      break;
    case TRANSACTION_STATUS.REFUND_ERROR:
    case TRANSACTION_STATUS.REVERSAL_ERROR:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.error.main;
  }

  return (
    <Box color={color}>
      <Typography variant="body1">
        {t(TRANSACTION_STATUS_I18n[status])}
      </Typography>
    </Box>
  );
};

export default BatchStatus;
