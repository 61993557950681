import { useState, useEffect } from "react";
import Select, { OptionProps, SelectProps } from "~/components/UI/Select";
import api from "~/api";

const SelectPaymentServiceType = (props: SelectProps) => {
  const [options, setOptions] = useState<OptionProps[]>([]);

  const getOptions = async () => {
    const { data: { data } } = await api.paymentServices.getPaymentServiceTypes();
    const options = Object.keys(data).map(key => ({ label: data[key] , value: key }))
    setOptions(options)
  }

  useEffect(() => {
    getOptions();
  }, []);
  
  return (
    <Select
      {...props}
      options={options}
      variant="outlined"
    />
  );
}

export default SelectPaymentServiceType;
