import { Box, makeStyles, createStyles } from "~/components/UI";
import { ResponsiveContainer } from "recharts";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      fontSize: 12,
      display: "flex",
      alignContent: "stretch",
      flexDirection: "column",
      overflow: "hidden",
    },
    yLabelContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    graphicContainer: {
      flex: 1,
    },
  })
);

interface GraphicGridContainerProps {
  yAxis?: string;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> &
    React.ReactNode;
}

const GraphicGridContainer = ({
  yAxis,
  children,
}: GraphicGridContainerProps) => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Box className={classes.yLabelContainer}>{yAxis}</Box>
        <Box className={classes.graphicContainer}>
          <ResponsiveContainer width="100%" height="100%">
            {children}
          </ResponsiveContainer>
        </Box>
      </div>
  );
};

export default GraphicGridContainer;
