import React from 'react';
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import SelectEnum from "~/components/SideBarFilter/SelectEnum";
import SelectSite from "~/components/SideBarFilter/SelectSite";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import {
  PRIORITY,
  PRIORITY_I18n,
  API,
  API_I18n,
} from "~/api/eventsLogJournal/enums";
import { SOURCE_TYPE, SOURCE_TYPE_I18n } from "~/api/common";
import { useTranslation } from "react-i18next";
import api from "~/api";
import moment from "moment";

interface FilterProps {
  initialValues?: UserActivityJournalApi.GetUserActivityQuery;
  onChangeFilter: (
    data: UserActivityJournalApi.GetUserActivityQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer
      {...props}
      openByDefault={typeof props.initialValues === 'object' && !!Object.keys(props.initialValues).length}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.priority")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="priority"
            enumValue={PRIORITY}
            enumI18n={PRIORITY_I18n}
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.source")} collapsed defaultOpen={true}>
          <SelectEnum
            fieldName="source"
            enumValue={SOURCE_TYPE}
            enumI18n={SOURCE_TYPE_I18n}
          />
        </SidebarGroup>

        <SidebarGroup label={t("label.site")} collapsed defaultOpen={true}>
          <SelectSite fieldName="siteId" />
        </SidebarGroup>

        <SidebarGroup label={t("label.eventType")} collapsed defaultOpen={true}>
          <SelectValue
            name="eventType"
            loadOptions={() =>
              api.eventsLogJournal
                .getFilterEventTypes()
                .then(({ data }) =>
                  [...data.data].sort().map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>

        <SidebarGroup
          label={t("label.apiVersion")}
          collapsed
          defaultOpen={true}
        >
          <EnumCheckboxGroup
            fieldName="api"
            enumValue={API}
            enumI18n={API_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
