import {
  XAxis as RXAxis,
  YAxis as RYAxis,
  CartesianGrid as RCartesianGrid,
} from "recharts";
import numeral from "numeral";
import moment from "moment";
import Constants from "~/constants";
import { currencyString } from "~/utils/format";

const { REACT_APP_CURRENCY_SYMBOL } = Constants;

export const XAxis = (props: any) => (
  <RXAxis
    {...props}
    dataKey="createdAt"
    domain={["auto", "auto"]}
    tickCount={0}
    interval={0}
    tickLine={false}
    axisLine={false}
  />
);

export const YAxis = (props: any) => (
  <RYAxis
    {...props}
    type="number"
    allowDecimals={false}
    domain={[0, "auto"]}
    width={80}
    tickLine={false}
    axisLine={false}
  />
);

export const CartesianGrid = () => (
  <RCartesianGrid vertical={false} strokeDasharray="2" />
);

export const numeralFormatter = (value: number) =>
  typeof value === "number" ? numeral(value).format("0a") : "";

export const dateFormatter =
  (period?: MetricsAPI.eTimePeriod) => (date: string) => {
    if (!period) return moment(date).format("HH:mm");

    switch (period) {
      case "1m":
        return moment(date).format("mm:ss");
      case "1h":
        return moment(date).format("HH:mm");
      default:
        return moment(date).format("HH:mm");
    }
  };

export const moneyTickFormatter = (value: number) =>
  typeof value === "number"
    ? REACT_APP_CURRENCY_SYMBOL + numeral(value / 100).format("0.0a")
    : "";

export const currencyCodeFormatter = (value: number) =>
  typeof value === "number" ? currencyString(value) : "";

export const timeTickFormatter = (value: number) =>
  typeof value === "number" ? `${value / 1000}s` : "";

export const ANIMATION_DURATION = 300;

export const colors = [
  "#56C75B",
  "#56A5C7",
  "#AF0DFF",
  "#FA7211",
  "#E0CF0B",
  "#00F5A9",
  "#0922E0",
  "#A5E00B",
  "#FF0DD1",
  "#FAA411",
];
