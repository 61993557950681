import { Box, Typography } from "~/components/UI";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import Clients from "./Components/Clients";
import Bills from "./Components/Bills";
import CollectedMoney from "./Components/CollectedMoney";
import ClientPaymentDuration from "./Components/ClientPaymentDuration";
import PSPPaymentDuration from "./Components/PSPPaymentDuration";
import BusinessTransactionsCount from "./Components/BusinessTransactionsCount";
import FinancialTransactionCounts from "./Components/FinancialTransactionCounts";
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";
import { useTranslation } from "react-i18next";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

const Metrics = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4">{t("title.metrics")}</Typography>
      <Box pt={6}>
        <NoPermissionsForMetrics>
          <CanIUse permissions={PERMISSION.READ_MONEY_METRICS}>
            <MetricGroup title={t("title.money")}>
              <Bills />
              <CollectedMoney />
            </MetricGroup>
          </CanIUse>
          <CanIUse permissions={PERMISSION.READ_CLIENTS_METRICS}>
            <MetricGroup title={t("title.clients")}>
              <Clients />
            </MetricGroup>
          </CanIUse>
          <CanIUse permissions={PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS}>
            <MetricGroup title={t("title.businessTransactions")}>
              <BusinessTransactionsCount />
            </MetricGroup>
          </CanIUse>
          <CanIUse
            permissions={PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS}
          >
            <MetricGroup title={t("title.clientPaymentDuration")}>
              <ClientPaymentDuration />
            </MetricGroup>
          </CanIUse>
          <CanIUse permissions={PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS}>
            <MetricGroup title={t("title.PSPPaymentDuration")}>
              <PSPPaymentDuration />
            </MetricGroup>
          </CanIUse>
          <CanIUse permissions={PERMISSION.READ_FINANCIAL_TRANSACTIONS_METRICS}>
            <MetricGroup title={t("title.financialTransactionCounts")}>
              <FinancialTransactionCounts />
            </MetricGroup>
          </CanIUse>
        </NoPermissionsForMetrics>
      </Box>
    </>
  );
};

export default Metrics;
