import { useCallback } from "react";
import Sites from "./Components/Sites";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @namespace Sites
 */

const SitesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.sites") }];
  }, [t]);
  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Sites />
    </>
  );
};

export default SitesPage;
