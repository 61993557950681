import { useState, useEffect } from "react";
import { Box, Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from "recharts";
import GraphicGridContainer from "./GraphicGridContainer";
import {
  ANIMATION_DURATION,
  timeTickFormatter,
  dateFormatter,
  colors,
} from "./Common";

interface PaymentDurationProps {
  period?: MetricsAPI.eTimePeriod;
  list?: any[];
  yAxis?: string;
}

const TooltipContent = ({
  time,
  payload,
}: {
  time: string;
  payload: { name: string; fill: string; value: number }[];
}) => {
  const { t } = useTranslation();

  return (
    <Box border={1} borderColor="divider" p={3} bgcolor="background.paper">
      <Typography gutterBottom>
        <strong>{t("label.time")}:</strong> {time}
      </Typography>
      <Typography>
        {payload && payload.length
          ? payload.map((i) => (
              <Typography gutterBottom>
                <strong style={{ color: i.fill }}>{i.name}:</strong>{" "}
                {timeTickFormatter(i.value)}
              </Typography>
            ))
          : null}
      </Typography>
    </Box>
  );
};

const PaymentDuration = ({ period, list, yAxis }: PaymentDurationProps) => {
  const [barKeys, setBarKeys] = useState<string[]>([]);

  useEffect(() => {
    if (list && list.length) {
      const keys = Object.keys(list[0]).filter((key) => key !== "createdAt");
      setBarKeys(keys);
    } else {
      setBarKeys([]);
    }
  }, [list]);

  if (!list) return null;

  return (
    <GraphicGridContainer yAxis={yAxis}>
      <BarChart data={list as any}>
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="createdAt"
          domain={["auto", "auto"]}
          tickCount={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={dateFormatter(period)}
        />
        <YAxis
          type="number"
          allowDecimals={false}
          domain={[0, "auto"]}
          tickLine={false}
          axisLine={false}
          width={45}
          tickFormatter={timeTickFormatter}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            return active && payload && payload.length ? (
              <TooltipContent
                time={dateFormatter(period)(label)}
                payload={payload}
              />
            ) : null;
          }}
        />
        {barKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            fill={colors[index]}
            animationDuration={ANIMATION_DURATION}
          />
        ))}
        <Legend
          verticalAlign="bottom"
          align="left"
          iconType="square"
          iconSize={20}
        />
      </BarChart>
    </GraphicGridContainer>
  );
};

export default PaymentDuration;
