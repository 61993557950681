import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  MUIFormControlLabel,
  Icon,
  styled,
  makeStyles,
} from "~/components/UI";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
  SortableElementProps,
} from "react-sortable-hoc";
import { arrayMove } from "~/utils/helpers";
import { ENV } from "~/constants";
import { useTranslation } from "react-i18next";
import { METRIC_ENTITY_I18n } from "~/api/dashboardSettings/enums";

export const useStyles = makeStyles((theme: CustomTheme.RootObject) => ({
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
}));

const DragAndDropIcon = styled(Icon)(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    fontSize: theme.typography.fontSize - 2,
    height: "auto",
    cursor: "move",
    lineHeight: "1.5",
    verticalAlign: "top",
  })
);

const DragHandle = SortableHandle(() => (
  <DragAndDropIcon name="DragAndDropBtn" />
));

interface SortableMetricProps extends SortableElementProps {
  metric: DashboardSettingsApi.Metric;
  disabledCheckbox?: boolean;
  onChangeStatus: (status: boolean) => void;
}

const SortableMetric = SortableElement(
  ({ metric, disabledCheckbox, onChangeStatus }: SortableMetricProps) => {
    const { t } = useTranslation("enums");

    return (
      <Box py={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <DragHandle />
          </Grid>
          <Grid item>
            <Typography variant="body2">{metric.position}</Typography>
          </Grid>
          <Box
            flexGrow={1}
            p={2}
            border={1}
            borderColor="divider"
            bgcolor="background.level2"
          >
            <MUIFormControlLabel
              label={t(METRIC_ENTITY_I18n[metric.entity])}
              control={
                <Checkbox
                  checked={metric.enabled}
                  disabled={disabledCheckbox && !metric.enabled}
                  onChange={(_e, checked) => onChangeStatus(checked)}
                />
              }
            />
          </Box>
        </Grid>
      </Box>
    );
  }
);

interface MetricsProps {
  metrics?: DashboardSettingsApi.Metric[];
  onChange?: (data: DashboardSettingsApi.SetMetric[]) => void;
}

const Container = SortableContainer(
  ({ children }: { children: React.ReactChild[] }) => {
    return <div>{children}</div>;
  }
);

const Metrics = ({ metrics, onChange }: MetricsProps) => {
  const classes = useStyles();
  const [list, setList] = useState<DashboardSettingsApi.Metric[]>([]);
  const disabledAllCheckboxes =
    metrics &&
    metrics.filter((m) => m.enabled).length >=
      Number(ENV.REACT_APP_MAX_LIMIT_FOR_METRICS_ON_DASHBOARD);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (!list) return;
      const newList = arrayMove(list, oldIndex, newIndex).map((m, i) => ({
        ...m,
        position: `${i + 1}`,
      }));
      setList(newList);
    },
    [list]
  );

  const onChangeEnabled = useCallback(
    (index: number, enabled: boolean) => {
      if (!list) return;
      const newList = [...list];
      newList[index].enabled = enabled;
      setList(newList);
    },
    [list]
  );

  useEffect(() => {
    onChange &&
      onChange(
        list.map(({ metricId, position, enabled = false, entity }) => ({
          metricId,
          position,
          enabled,
          entity,
        }))
      );
  }, [list]);

  useEffect(() => {
    let newList: DashboardSettingsApi.Metric[] = [];
    if (metrics) {
      newList = metrics.map((m, index) =>
        typeof m.position !== "string"
          ? { ...m, position: String(index + 1) }
          : m
      );
    }
    setList(newList);
  }, []);

  if (!list) return null;

  return (
    <Container
      onSortEnd={onSortEnd}
      helperClass={classes.sorting}
      useDragHandle
    >
      {list.map((m, index) => (
        <SortableMetric
          key={`metric-item-${index}`}
          index={index}
          metric={m}
          disabledCheckbox={disabledAllCheckboxes}
          onChangeStatus={(enabled) => onChangeEnabled(index, enabled)}
        />
      ))}
    </Container>
  );
};

export default Metrics;
