import { Typography, Box, Section } from "~/components/UI";
import { useTranslation } from "react-i18next";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import ClientPaymentDuration from "./ClientPaymentDuration";
import PSPPaymentDuration from "./PSPPaymentDuration";
import FinancialTransactionCounts from './FinancialTransactionCounts';
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

interface MetricsProps {
  paymentService?: PaymentServicesApi.PaymentServiceDetailsDto;
}

const Metrics = ({ paymentService }: MetricsProps) => {
  const { t } = useTranslation();

  if (!paymentService) return null;

  return (
    <Box pt={4} pr={2}>
      <Section
        title={
          <Typography variant="h4">
            {paymentService &&
              `${t("title.metricsOf")} ${paymentService.paymentServiceType}`}
          </Typography>
        }
      />
      <NoPermissionsForMetrics>
        <CanIUse permissions={PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS}>
          <MetricGroup title={t("title.clientPaymentDuration")}>
            <ClientPaymentDuration paymentId={paymentService.paymentId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS}>
          <MetricGroup title={t("title.PSPPaymentDuration")}>
            <PSPPaymentDuration paymentId={paymentService.paymentId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_FINANCIAL_TRANSACTIONS_METRICS}>
          <MetricGroup title={t("title.financialTransactionCounts")}>
            <FinancialTransactionCounts paymentId={paymentService.paymentId} />
          </MetricGroup>
        </CanIUse>
      </NoPermissionsForMetrics>
    </Box>
  );
};

export default Metrics;
