import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import PaymentServices from "./Components/PaymentServices";
import { useTranslation } from "react-i18next";

/**
 * @namespace PaymentServices
 */

const PaymentServicesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.paymentServices") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <PaymentServices />
    </>
  );
};

export default PaymentServicesPage;
