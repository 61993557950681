import { ThunkAction } from 'redux-thunk';
import Constants from "./constants";
import _ from 'lodash';
import { updateArrayItem } from '~/utils/helpers';
import api from '~/api';

const { ACTIONS } = Constants;

export const getServiceCategories = (): ThunkAction<void, ReduxStore.State, ServicesStore.eActionType, any> => 
  async (dispatch) => {
    const { data } = await api.services.getServiceCategories();
    dispatch({
      type: ACTIONS.SET_SERVICE_CATEGORIES,
      payload: data.data,
    })
  }

export const getServicesTemplates = (): ThunkAction<void, ReduxStore.State, ServicesStore.eActionType, any> =>
  async (dispatch) => {
    const { data } = await api.serviceTemplates.getServiceTemplates();
    dispatch({
      type: ACTIONS.SET_SERVICE_TEMPLATES,
      payload: data.data,
    });
  }

export const setServicesTemplates = (templates: ServiceTemplatesApi.ServiceTemplateDto[]) => ({
    type: ACTIONS.SET_SERVICE_TEMPLATES,
    payload: templates,
  })

export const addServicesTemplate = (template: ServiceTemplatesApi.AddServiceTemplate) =>
  async (dispatch: Function, getState: () => ReduxStore.State) => {
    const serviceTemplates = getState().services.serviceTemplates || [];
    const { data } = await api.serviceTemplates.addTemplate(template);
    dispatch(setServicesTemplates([...serviceTemplates, data.data]));
    return Promise.resolve(data.data);
  }

export const updateServicesTemplate = (templateId: string, template: ServiceTemplatesApi.UpdateServiceTemplate) =>
  async (dispatch: Function, getState: () => ReduxStore.State) => {
    const serviceTemplates = getState().services.serviceTemplates;
    const { data } = await api.serviceTemplates.updateTemplate(templateId, template);
    dispatch(setServicesTemplates(updateArrayItem(serviceTemplates, data.data, "id") || []));
    return Promise.resolve(data.data);
  }

export const deleteServicesTemplate = (id: string) =>
  async (dispatch: Function, getState: () => ReduxStore.State) => {
    const serviceTemplates = getState().services.serviceTemplates || [];
    await api.serviceTemplates.deleteTemplate(id);
    dispatch(setServicesTemplates( _.remove(serviceTemplates, i => i.id !== id)))
  }