import i18n from "i18next";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { enumToI18nKey } from '~/utils/helpers';

export const SOURCE_TYPE = {
  BUSINESS_OPERATION: 'Business Operation',
  EVENT_LOG: 'Event Log',
  SITE: 'Site',
  POS: 'Pos',
  SERVICE: 'Service',
  PAYMENT_SERVICE: 'Payment Service',
  KEY_MANAGEMENT: 'Key Management',
  USER: 'User',
  GROUP: 'Group',
  ROLE: 'Role',
  INCIDENT: 'Journal Incident',
  SERVICE_CATEGORY: 'Service Category',
  Z_REPORTS: 'Z-Reports',
  BUNDLES: "Bundles",
  USER_ROLE: "User Role",
} as { [key: string]: Api.eSourceType }

export const SOURCE_TYPE_I18n = enumToI18nKey(SOURCE_TYPE, 'SOURCE_TYPE');

export const POS_TYPE = {
  POS: "POS",
  MOBILE: "Mobile",
  SELF_POS: "Self POS"
} as { [key: string]: Api.ePOSType }

export const POS_TYPE_I18n = enumToI18nKey(POS_TYPE, 'POS_TYPE');

class HttpError {
  private sourceTranslations: { [key: string]: string}

  constructor() {
    this.sourceTranslations = {};
  }

  findSourceName = (path?: string): string | undefined => {
    if (path) {
      const key = Object.keys(this.sourceTranslations).find(key => path.startsWith(key))

      if (key) {
        return this.sourceTranslations[key];
      }
    }

    return undefined;
  }

  private handle403 = (response: AxiosResponse<Api.ErrorResponse>) => {
    let message = ''
    const sourceNameTKey = this.findSourceName(response.config.url)

    if (sourceNameTKey) {
      message = `${i18n.t(sourceNameTKey)}: `
    }

    switch(response.config.method) {
      case 'get':
        message += i18n.t("httpErrors.get403")
        break;
      case 'post':
        message += i18n.t("httpErrors.post403")
        break;
      case 'put':
        message += i18n.t("httpErrors.put403")
        break;
      case 'delete':
        message += i18n.t("httpErrors.delete403")
        break;
      default:
        message += i18n.t("httpErrors.403")
    }

    return message
  }

  handleHttpError = (response: AxiosResponse<Api.ErrorResponse>) => {
    let message = "something went wrong!"

    switch (response.status) {
      case 403:
        message = this.handle403(response);
        break;
      default:
        if (response.data) {
          if (Array.isArray(response.data.errors)) {
            message = response.data.errors.map((e) => e.message || "").join(". ");
          } else if (typeof response.data.message === 'string' ) {
            message = response.data.message
          }
        }
    }

    toast.error(message);

    return Promise.reject(response);
  }

  addSource = (path: string, tKey: string) => {
    this.sourceTranslations[path] = tKey;
  }
}

export const httpError = new HttpError()