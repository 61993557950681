import { Typography, Box, Section } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import InvoiceDuration from "./InvoiceDuration";
import QueuesCreated from "./QueuesCreated";
import BusinessTransactionsCount from "./BusinessTransactionsCount";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

const Metrics = () => {
  const { t } = useTranslation();
  const { posId } = useParams<{ posId?: string }>();

  if (!posId) return null;

  return (
    <Box pt={4} pr={2}>
      <Section
        title={<Typography variant="h4">{t("title.metricsOfPos")}</Typography>}
      />
      <NoPermissionsForMetrics>
        <CanIUse permissions={PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS}>
          <MetricGroup title={t("title.businessTransactions")}>
            <BusinessTransactionsCount posId={posId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_QUEUES_METRICS}>
          <MetricGroup title={t("title.queues")}>
            <QueuesCreated posId={posId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_INVOICING_DURATION_METRICS}>
          <MetricGroup title={t("title.invoicingDuration")}>
            <InvoiceDuration posId={posId} />
          </MetricGroup>
        </CanIUse>
      </NoPermissionsForMetrics>
    </Box>
  );
};

export default Metrics;
