import { Box, Input, Icon, SidebarGroup } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import SelectEnum from "~/components/SideBarFilter/SelectEnum";
import { useTranslation } from "react-i18next";
import { KEY_PURPOSE, KEY_PURPOSE_I18n } from "~/api/keyManagement/enums";

interface FilterProps {
  onChangeFilter: (data: KeyManagementApi.GetKeyQuery) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup
          label={t("label.keyPurpose")}
          collapsed
          defaultOpen={true}
        >
          <SelectEnum
            fieldName="purpose"
            enumValue={KEY_PURPOSE}
            enumI18n={KEY_PURPOSE_I18n}
          />
        </SidebarGroup>
        <IncludeFieldGroup
          label={t("title.openedIcidents")}
          fieldName="hasOpenedIncidents"
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
