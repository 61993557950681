import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import SelectSite from "~/components/SideBarFilter/SelectSite";
import {
  API,
  API_I18n,
  LOG_TYPE,
  LOG_TYPE_I18n,
} from "~/api/systemLogJournal/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";
import api from "~/api";

interface FilterProps {
  initialValues?: UserActivityJournalApi.GetUserActivityQuery;
  onChangeFilter: (
    data: UserActivityJournalApi.GetUserActivityQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer {...props}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.source")} collapsed defaultOpen={true}>
          <SelectValue
            name="service"
            loadOptions={() =>
              api.systemLogJournal
                .getSources()
                .then(({ data }) =>
                  [...data.data].sort().map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.site")} collapsed defaultOpen={true}>
          <SelectSite fieldName="siteId" />
        </SidebarGroup>
        <SidebarGroup label={t("label.severity")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="logType"
            enumValue={LOG_TYPE}
            enumI18n={LOG_TYPE_I18n}
          />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.apiVersion")}
          collapsed
          defaultOpen={true}
        >
          <EnumCheckboxGroup
            fieldName="apiVersion"
            enumValue={API}
            enumI18n={API_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
