import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class DashboardMetricsAPI {
  rootPath: string = "/dashboard-metrics";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.dashboardMetrics');
  }

  getMoneyMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/money`)
  getBillsMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/bills`)
  getClientsMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/clients`)
  getBusinessTransactionsCountMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/business-transactions-count`)
  getClientPaymentDurationMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration`)
  getPSPPaymentDurationMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration`)
  getFinancialTransactionsMetric = (): Promise<Api.GetOne<DashboardMetricsAPI.DashboardMetricsResponseDto>> =>
    this.instance.get(`${this.rootPath}/financial-transactions`)
}
