import React, { useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  IconButton,
  Box,
  Button,
  Typography,
  FormControlLabel,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import {
  FieldArray,
  useField,
  useFormikContext,
  FormikHelpers,
  FormikState,
  FieldArrayRenderProps,
} from "formik";
import FrmikSelectPOS from "~/components/CommonFormControls/SelectPOS";
import { useTranslation } from "react-i18next";

interface POSProps {
  pos: Api.PosDto;
  index: number;
  onChange: (pos: Api.PosDto, index: number) => void;
  onDelete: (index: number) => void;
  filter: (poses: Api.PosDto[]) => Api.PosDto[];
}

const POS = React.memo(
  ({ pos, index, onChange, onDelete, filter }: POSProps) => {
    const { t } = useTranslation();

    return (
      <FormControlLabel
        key={pos.posId}
        label={t("label.posId")}
        extra={
          <ConfirmDialog onConfirm={() => onDelete(index)}>
            <IconButton>
              <Icon name="Delete" />
            </IconButton>
          </ConfirmDialog>
        }
      >
        <Box display="inline-block" mt={1}>
          <Typography variant="body1">{pos.posId}</Typography>
        </Box>
      </FormControlLabel>
    );
  }
);

interface POSesProps {
  poses: Api.PosDto[];
  arrayHelpers: FieldArrayRenderProps;
  filter: (poses: Api.PosDto[]) => Api.PosDto[];
}

const POSes = React.memo(({ poses, arrayHelpers, filter }: POSesProps) => {
  const onChange = useCallback((pos: Api.PosDto, index: number) => {
    arrayHelpers.replace(index, pos);
  }, []);

  const onDelete = useCallback((index: number) => {
    arrayHelpers.remove(index);
  }, []);

  if (!Array.isArray(poses)) return null;

  return (
    <>
      {poses.map((pos: Api.PosDto, index: number) => (
        <POS key={pos.posId} {...{ pos, index, onChange, onDelete, filter }} />
      ))}
    </>
  );
});

interface AddPOSFormProps {
  defaultExpanded?: boolean;
}

type FormSubData = { poses: []; addPos: Api.PosDto };

/**
 * @memberof Sites
 * @component
 * @desc Form for add a POS for a Site. Use Formik context. Updating field 'poses'.
 * @property {boolean}   defaultExpanded Default state
 */

const AddPOSForm = ({ defaultExpanded }: AddPOSFormProps) => {
  const {
    values,
    setFieldValue,
  }: FormikState<FormSubData> & FormikHelpers<FormSubData> = useFormikContext();
  const [field] = useField<Api.PosDto[]>("poses");
  const { t } = useTranslation();

  const filterOptions = useCallback(
    (poses: Api.PosDto[]) =>
      poses.filter((p) => !field.value.find((subP) => subP.posId === p.posId)),
    [field.value]
  );

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary>
        {t("title.poses")} ({field.value ? field.value.length : 0})
      </AccordionSummary>
      <AccordionDetails>
        <FieldArray
          name="poses"
          render={(arrayHelpers) => (
            <>
              <POSes
                {...{ poses: field.value, arrayHelpers, filter: filterOptions }}
              />
              <FormControlLabel label={t("label.addPos")}>
                <Box display="inline-block">
                  <FrmikSelectPOS
                    name="addPos"
                    filterOptions={filterOptions}
                    onChange={(posId) => setFieldValue("addPos", { posId })}
                  />
                </Box>
                <Box display="inline-block" ml={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!values.addPos}
                    onClick={() => {
                      setFieldValue("addPos", "");
                      arrayHelpers.push(values.addPos);
                    }}
                  >
                    {t("button.add")}
                  </Button>
                </Box>
              </FormControlLabel>
            </>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AddPOSForm;
