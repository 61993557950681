import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress,
  Typography,
} from "~/components/UI";
import ListLayoutSwitcher, {
  LayoutType,
  eLayoutType,
} from "~/components/ListLayoutSwitcher";
import Filter from "./Filter";
import Table from "./Table";
import Tiles from "./Tiles";
import UpdatePOSDialog from "./UpdatePOSDialog";
import { toast } from "react-toastify";
import { useQueryParams, StringParam } from "use-query-params";
import POSesContext from "./posesContext";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import RowsPerPage from "~/components/RowsPerPage";
import useInterval from "~/hook/useInterval";
import ENV from "~/constants";
import api from "~/api";

const REFRESH_STATUS_INTERVAL = ENV.REACT_APP_POS_STATUS_REFRESH_TIME * 1000;

interface ListProps {
  type: LayoutType;
  data?: Api.PosDto[];
}

const List = React.memo(({ type, data }: ListProps) => {
  return type === eLayoutType.TABLE ? (
    <Table dataSource={data} />
  ) : (
    <Tiles list={data} />
  );
});

/**
 * @memberof POSes
 * @component
 * @desc POSes Controller.
 * @requires posesContext
 */

const POSList = () => {
  const [layoutType, setLayoutType] = useState(eLayoutType.TABLE);
  const [poses, setPOSes] = useState<Api.PosDto[]>();
  const [selectedPOSes, setSelectedPOSes] = useState<string[]>([]);
  const [query, setQuery] = useState<Api.GetPOSesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean | undefined>();
  const [editPos, setEditPos] = useState<Api.PosDto>();
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    posId: StringParam,
  });
  const { t } = useTranslation();

  const getPoses = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const { data } = await api.poses.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setPOSes(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onRefreshActiveStates = useCallback(async () => {
    if (!poses) return;
    try {
      const { data } = await api.poses.getActiveState(
        poses.map((p) => p.posId)
      );
      setPOSes((poses) => {
        if (Array.isArray(poses)) {
          let newPoses = [...poses];
          data?.data.forEach((p) => {
            newPoses = updateArrayItem(newPoses, p, "posId") as Api.PosDto[];
          });
          return newPoses;
        }
        return [];
      });
    } catch (e) {
      console.log(e);
    }
  }, [poses]);

  const onCreateGasStation = useCallback(() => {
    setEditPos(undefined);
    setOpenEditDialog(true);
  }, []);

  const onEdit = useCallback((data: Api.PosDto) => {
    setEditPos(data);
    setOpenEditDialog(true);
  }, []);

  const onUpdateItem = useCallback((pos: Api.PosDto) => {
    setPOSes((poses) => (poses ? updateArrayItem(poses, pos, "posId") : []));
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdatePOS) => {
      try {
        const {
          data: { data: pos },
        } = await api.poses.updatePos(id, formData);
        onUpdateItem(pos);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [poses, t]
  );

  const onDelete = useCallback(
    async (pos: Api.PosDto) => {
      await deleteConfirm(pos?.posId);
      await api.poses.deletePos(pos.posId);
      getPoses();
      toast.success(t("text.recordSuccessfullyDeleted"));
    },
    [t]
  );

  const onDeletePOSes = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedPOSes?.length }));
    await api.poses.deletePoses(selectedPOSes);
    getPoses();
    toast.success(t("text.recordsSuccessfullyDeleted"));
    setSelectedPOSes([]);
  }, [t, selectedPOSes]);

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getPoses(page);
  };

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = React.useMemo(
    () => ({
      onSort,
      onSelect: setSelectedPOSes,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete,
    }),
    [poses]
  );

  const onCloseEditDialog = (result?: boolean) => {
    setOpenEditDialog(false);
    setEditPos(undefined);
    result && pagination && getPoses(pagination.page);
  };

  // useInterval(onRefreshActiveStates, REFRESH_STATUS_INTERVAL);

  useEffect(() => {
    setQuery(queryParams as Api.GetPOSesQuery);
  }, [queryParams]);

  useEffect(() => {
    setSelectedPOSes([]);
  }, [layoutType]);

  useEffect(() => {
    query && getPoses();
  }, [query, limit]);

  return (
    <POSesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.poses")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_POS}>
                <Button
                  hidden={!selectedPOSes.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeletePOSes}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_POS}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateGasStation}
                >
                  {t("button.createNew")}
                </Button>
              </CanIUse>
              <ListLayoutSwitcher
                defaultValue={layoutType}
                onChange={setLayoutType}
              />
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <List type={layoutType} data={poses} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <UpdatePOSDialog
        pos={editPos}
        open={openEditDialog}
        onClose={onCloseEditDialog}
      />
    </POSesContext.Provider>
  );
};

export default POSList;
