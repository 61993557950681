import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class ZReportsJournalApi {
  rootPath: string = "/journal-z-reports";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.auditZReportLog')
  }

  getReports = (limit: number = 10, page: number = 1, query?: ZReportsJournalApi.GetReportsQuery): Promise<Api.GetAll<ZReportsJournalApi.ReportDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } })

  getReport = (reportId: string): Promise<Api.GetAll<ZReportsJournalApi.ReportDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${reportId}`)
}
