import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Card, LinearProgress } from "~/components/UI";
import TimeIntervalSelector, {
  TimeMeta,
  TimeInterval,
  eTimeInterval,
  RefreshTimeForInterval,
} from "~/components/Metrics/TimeIntervalSelector";
import useInterval from "~/hook/useInterval";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface BaseCardProps {
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  extraParams?: { [key: string]: string };
  timeIntervals?: any[];
  onUpdate?: (queryParams: any) => Promise<any> | void;
  children?: (data: any) => React.ReactNode;
}

export const useStyles = makeStyles(() =>
  createStyles({
    metricCard: {},
    loaderContainer: {
      display: "block",
      height: "100%",
      width: "100%",
      margin: 0,
    },
    header: {
      position: "relative",
      marginBottom: 20,
      minHeight: 40,
    },
    extra: {},
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      width: "100%",
      height: 435,
    },
  })
);

const BaseCardSkeleton = () => {
  const classes = useStyles();
  return <Skeleton className={classes.loaderContainer}></Skeleton>;
};

const getPeriod = (value: TimeInterval) => {
  switch (value) {
    case eTimeInterval.LAST_MINUTE:
      return "1m";
    case eTimeInterval.LAST_HOUR:
      return "1h";
    case eTimeInterval.LAST_24_HOURS:
      return "24h";
  }
};

const BaseCard = ({
  title,
  extra,
  extraParams,
  timeIntervals,
  children,
  onUpdate,
}: BaseCardProps) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(
    timeIntervals && timeIntervals[0]
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [refreshTime, setRefreshTime] = useState(
    currentTab
      ? (RefreshTimeForInterval[currentTab as TimeInterval] as number)
      : null
  );

  const refreshData = useCallback(() => {
    if (onUpdate) {
      const res = onUpdate({ period: getPeriod(currentTab), ...extraParams });
      if (res && res.finally) {
        setLoading(true);
        res.then((data) => {
          setData(data);
        });
        res.finally(() => {
          setLoading(false);
        });
      }
    } else {
      setData(undefined);
    }
  }, [onUpdate, currentTab, extraParams]);

  const onChangeTimeInterval = useCallback(
    (timeInterval: TimeInterval, meta: TimeMeta) => {
      setCurrentTab(timeInterval);
      meta && meta.refreshTime && setRefreshTime(meta.refreshTime);
    },
    []
  );

  useInterval(() => {
    if (loading) return;
    refreshData();
  }, refreshTime);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
  }, [currentTab, extraParams]);

  return (
    <Card>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        <Grid item>
          {typeof title === "string" ? (
            <Typography variant="h4">{title}</Typography>
          ) : (
            title
          )}
        </Grid>
        <Grid item>
          <div className={classes.extra}>{extra && extra}</div>
        </Grid>
      </Grid>
      {timeIntervals && (
        <TimeIntervalSelector
          timeIntervalsConfig={timeIntervals}
          defaultValue={timeIntervals && timeIntervals[0]}
          onChange={onChangeTimeInterval}
        />
      )}
      <div className={classes.content}>
        {<LinearProgress hidden={!loading || !data} />}
        {!data ? <BaseCardSkeleton /> : children && children(data)}
      </div>
    </Card>
  );
};

export default BaseCard;
