import React from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { Link } from "~/components/UI";
import { SOURCE_TYPE } from "~/api/common";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface LinkToObjectProps {
  sourceType: Api.eSourceType;
  sourceId: string;
  siteId?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const LinkToObject = ({
  sourceType,
  sourceId,
  siteId,
  children,
}: LinkToObjectProps) => {
  const { t } = useTranslation();
  let link = "";
  let value = sourceId;

  switch (sourceType) {
    case SOURCE_TYPE.BUSINESS_OPERATION:
      link = `${ROUTES.BUSINESS_OPERATIONS}?search=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.BUNDLES:
      link = `${ROUTES.BUNDLES}?search=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.SITE:
      link = `${ROUTES.SITES}?siteId=${sourceId}`;
      value = sourceId && `${t("option.site")} #${sourceId}`;
      break;
    case SOURCE_TYPE.POS:
      link = `${ROUTES.POSES}?posId=${sourceId}`;
      value = sourceId && `POS #${sourceId}`;
      break;
    case SOURCE_TYPE.SERVICE:
      link = `${ROUTES.SERVICES}?serviceId=${sourceId}`;
      value = sourceId && `${t("option.service")} #${sourceId}`;
      break;
    case SOURCE_TYPE.PAYMENT_SERVICE:
      link = sourceId
        ? generatePath(ROUTES.PAYMENT_SERVICE, { paymentId: sourceId })
        : "";
      value = sourceId && `${t("option.paymentService")} #${sourceId}`;
      break;
    case SOURCE_TYPE.USER:
      link = `${ROUTES.USERS}?userId=${sourceId}`;
      value = sourceId && `${t("option.user")} #${sourceId}`;
      break;
    case SOURCE_TYPE.GROUP:
      link = `${ROUTES.GROUPS}?groupId=${sourceId}`;
      value = sourceId && `${t("option.group")} #${sourceId}`;
      break;
    case SOURCE_TYPE.ROLE:
      link = `${ROUTES.USER_ROLES}?roleId=${sourceId}`;
      value = sourceId && `${t("option.role")} #${sourceId}`;
      break;
    case SOURCE_TYPE.SERVICE_CATEGORY:
      link = `${ROUTES.SERVICES_CATEGORIES}?categoryId=${sourceId}`;
      value = sourceId && `${t("option.serviceCategory")} #${sourceId}`;
      break;
    case SOURCE_TYPE.KEY_MANAGEMENT:
      link = `${ROUTES.KEY_MANAGEMENT}?keyId=${sourceId}`;
      value = sourceId;
      break;
    default:
      link = "";
  }

  return (
    <RouterLink to={link}>
      <Link color="textPrimary" underline="always">
        {children || value}
      </Link>
    </RouterLink>
  );
};

export default React.memo(LinkToObject);
