import { Box, Grid, Typography } from "~/components/UI";
import BaseCard from "./BaseCard";
import BillsCharts from "./Charts/AverageBillChart";
import { eTimeInterval } from "./TimeIntervalSelector";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";
import { currencyCodeFormatter } from "./Charts/Common";

interface BillsProps {
  title?: string;
  onUpdate: (
    queryParams: any
  ) => Promise<MetricsAPI.BillsBySiteDto | undefined>;
}

const Bills = ({ title, onUpdate }: BillsProps) => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  if (!hasAll(PERMISSION.READ_MONEY_METRICS)) return null;

  return (
    <Grid item sm={12} md={6} xs={3}>
      <BaseCard
        title={title || "Average Bill"}
        timeIntervals={[eTimeInterval.LAST_HOUR, eTimeInterval.LAST_24_HOURS]}
        onUpdate={onUpdate}
      >
        {(data) =>
          data ? (
            <>
              <Box py={4} textAlign="end">
                <Typography variant="subtitle1" color="primary">
                  {currencyCodeFormatter(data.average || 0)}{" "}
                  <Typography variant="body2" color="textPrimary">
                    {data.period === "1h"
                      ? t("text.lastHour")
                      : `${t("text.last")} ${t("text.day")}`}
                  </Typography>
                </Typography>
              </Box>
              <BillsCharts period={data.period} list={data.list} />
            </>
          ) : null
        }
      </BaseCard>
    </Grid>
  );
};

export default Bills;
