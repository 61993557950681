import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface SiteOptionsProps {
  site: PaymentServicesApi.SiteDto;
  serviceEnabled: boolean;
  onUpdate?: (data: PaymentServicesApi.SiteDto) => void;
  onUpdateItem?: (data: PaymentServicesApi.SiteDto) => void;
  onDelete?: (data: PaymentServicesApi.SiteDto) => Promise<void> | void;
}

/**
 * @memberof PaymentService
 * @component
 * @desc Render options for a Site.
 * @property {PaymentServicesApi.SiteDto} site - Sites data
 * @property {Function} onUpdate - callback for update a Site. (data: PaymentServicesApi.SiteDto) => void
 * @property {Function} onDelete - callback for the delete a Site. (data: PaymentServicesApi.SiteDto) => Promise<void> | void
 */

const SiteOptions = ({
  site,
  serviceEnabled,
  onUpdate,
  onUpdateItem,
  onDelete,
}: SiteOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { enabled } = site;
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.SITE);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (site.incidents) {
        site.incidents.count++;
        site.incidents.incidentIds.push(incident.incidentId);
      } else {
        site.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(site);
    },
    [site, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_PAYMENT_SERVICE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const getOptions = () => {
    return [
      {
        label: enabled ? t("option.disable") : t("option.enable"),
        disabled: !serviceEnabled,
        hidden: !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE),
        onClick: () =>
          onUpdate && onUpdateHandler(onUpdate({ ...site, enabled: !enabled })),
      },
      {
        label: t("option.delete"),
        hidden: !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE),
        confirmation: {
          cancelText: t("button.no"),
          okText: t("button.yes"),
          title: t("text.confirmationRequest"),
          onConfirm: () => onDelete && onUpdateHandler(onDelete(site)),
        },
      },
      {
        label: t("option.createIncident"),
        hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
        onClick: () => createIncident(site.siteId, onCreatedIncident),
      },
    ];
  };

  return <Dropdown loading={loading} options={getOptions()} />;
};

export default SiteOptions;
