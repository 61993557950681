import { useCallback } from "react";
import ServicesCategories from "./Components/ServicesCategories";
import Breadcrumbs from "~/components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import ROUTES from "~/constants/routes";

/**
 * @namespace ServicesCategories
 */

const ServicesCategoriesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.services"), href: ROUTES.SERVICES },
      { label: t("title.servicesCategories") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <ServicesCategories />
    </>
  );
};

export default ServicesCategoriesPage;
