import { enumToI18nKey } from "~/utils/helpers";

export const SERVICE = {
  CLIENT_GATEWAY: "CLIENT GATEWAY",
  POS_GATEWAY: "POS GATEWAY",
  VIRTUAL_POS: "VIRTUAL POS",
  SELF_CHECKOUT: "SELF CHECKOUT",
  MOBILE_CASHIER: "MOBILE CASHIER",
} as { [key: string]: SystemLogJournalApi.eService };

export const SERVICE_I18n = enumToI18nKey(SERVICE, "SERVICE");

export const API = {
  V1: "v1",
  V2: "v2",
  V3: "v3",
} as { [key: string]: SystemLogJournalApi.eApi };

export const API_I18n = enumToI18nKey(API, "API");

export const LOG_TYPE = {
  ERROR: "Error",
  LOG: "Log",
} as { [key: string]: SystemLogJournalApi.eLogType };

export const LOG_TYPE_I18n = enumToI18nKey(LOG_TYPE, "LOG_TYPE");

export const SEVERITY = {
  EMERGENCY: "Emergency",
  ALERT: "Alert",
  CRITICAL: "Critical",
} as { [key: string]: SystemLogJournalApi.eSeverity };

export const SEVERITY_I18n = enumToI18nKey(SEVERITY, "SEVERITY");
