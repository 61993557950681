import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress,
} from "~/components/UI";
import Filter from "./Filter";
import Table from "./Table";
import GroupDetails from "./GroupDetails";
import AddGroupDialog from "./AddGroupDialog";
import GroupSettingsDialog from "./GroupSettingsDialog";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { useQueryParams, StringParam } from "use-query-params";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import useConfirmDialog from "~/hook/useConfirmDialog";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof Groups
 * @component
 * @desc Groups Controller.
 */

const Groups = () => {
  const [groups, setGroups] = useState<GroupsApi.GroupDto[]>();
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [addGroup, setAddGroup] = useState(false);
  const [editGroup, setEditGroup] = useState<GroupsApi.GroupDto>();
  const [query, setQuery] = useState<GroupsApi.GetGroupsQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    groupId: StringParam,
  });
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_GROUP)
      ? {
          hideSelectAll: false,
          onChange: setSelectedGroups,
        }
      : undefined
  );

  const getGroups = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const { data } = await api.groups.getGroups(
          limit,
          page || currentPage,
          query
        );
        data.data && setGroups(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onAddGroup = useCallback(() => {
    setAddGroup(true);
  }, []);

  const onUpdateItem = (group: GroupsApi.GroupDto) => {
    setGroups((groups) => updateArrayItem(groups, group, "groupId"));
  };

  const onUpdateGroupStatus = async (
    groupId: string,
    data: GroupsApi.UpdateGroup
  ) => {
    try {
      const {
        data: { data: group },
      } = await api.groups.updateGroup(groupId, data);
      onUpdateItem(group);
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseDialod = useCallback((res?: boolean) => {
    res && getGroups();
    setAddGroup(false);
    setEditGroup(undefined);
  }, []);

  const onDelete = useCallback(async (group: GroupsApi.GroupDto) => {
    await deleteConfirm(group?.name);
    try {
      await api.groups.deleteGroup(group.groupId);
    } catch (e) {
      console.error(e);
    } finally {
      getGroups();
    }
  }, []);

  const onDeleteGroups = useCallback(async () => {
    await deleteConfirm(`${selectedGroups?.length} ${t("text.records")}`);
    try {
      await api.groups.deleteGroups(selectedGroups);
    } catch (e) {
      return e;
    } finally {
      getGroups();
      return true;
    }
  }, [selectedGroups]);

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = (page: number) => {
    getGroups(page);
  };

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  useEffect(() => {
    setQuery(queryParams as GroupsApi.GetGroupsQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getGroups();
  }, [query, limit]);

  return (
    <>
      <Box pr={2}>
        <Section
          title={t("title.groups")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_GROUP}>
                <Button
                  hidden={!selectedGroups.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteGroups}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_GROUP}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onAddGroup}
                >
                  {t("button.addGroup")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          dataSource={groups}
          onSort={onSort}
          onEdit={setEditGroup}
          onUpdateItem={onUpdateItem}
          onUpdate={onUpdateGroupStatus}
          onDelete={onDelete}
          rowKey="groupId"
          rowSelection={rowSelection}
          expandable={{
            highlightRow: true,
            expandedRowRender: (group) => <GroupDetails group={group} />,
          }}
        />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CanIUse permissions={PERMISSION.CREATE_GROUP}>
        <AddGroupDialog open={addGroup} onClose={onCloseDialod} />
      </CanIUse>
      <CanIUse permissions={PERMISSION.EDIT_GROUP}>
        <GroupSettingsDialog
          open={!!editGroup}
          group={editGroup}
          onClose={onCloseDialod}
        />
      </CanIUse>
    </>
  );
};

export default Groups;
