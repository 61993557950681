import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CommonTile from "./CommonTile";
import api from "~/api";

const FinancialTransactionCountsTile = () => {
  const { t } = useTranslation();
  const onUpdate = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await api.dashboardMetrics.getFinancialTransactionsMetric();
      return data;
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <CommonTile
      label={t("title.financialTransaction")}
      trendDescription={t("text.lastWeek")}
      onUpdate={onUpdate}
    />
  );
};

export default FinancialTransactionCountsTile;
