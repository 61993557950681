import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "~/components/UI";
import CommonTile from "../CommonTile";
import Summary from "./Summary";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import ServicesContext from "~/pages/Catalogs/Services/Services/Components/servicesContext";
import { updateArrayItem } from "~/utils/helpers";
import Routes from "~/constants/routes";
import ServiceDetails from "./ServiceDetails";
import List from "./List";
import api from "~/api";

interface ServicesProps {
  setting: DashboardSettingsApi.Widget;
}

const Services = ({ setting }: ServicesProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState<Api.ServiceDto>();
  const [services, setServices] = useState<Api.ServiceDto[]>();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const getList = async () => {
    setLoading(true);
    try {
      const { data } = await api.services.getAll(2, 1, {
        sortBy: setting.currentSortBy,
      });
      setServices(data.data);
      setTotalCount(
        data.meta && data.meta.pagination ? data.meta.pagination.totalCount : 0
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateItem = useCallback(
    (service: Api.ServiceDto) => {
      setServices((services) =>
        services ? updateArrayItem(services, service, "serviceId") : []
      );
      if (selectedService && service.id === selectedService.id) {
        setSelectedService(service);
      }
    },
    [selectedService]
  );

  const onUpdate = useCallback(
    async (id: string, siteId: string, formData: Api.UpdateSite) => {
      try {
        const {
          data: { data: service },
        } = await api.services.updateService(id, siteId, formData);
        onUpdateItem(service);
      } catch (e) {
        console.error(e);
      }
    },
    [services, t, onUpdateItem]
  );

  const contextValue = useMemo(
    () => ({
      onUpdateItem,
      onUpdate,
    }),
    [services]
  );

  useEffect(() => {
    getList();
  }, []);

  if (!hasAll(PERMISSION.READ_SERVICE)) return null;

  return (
    <ServicesContext.Provider value={contextValue}>
      <CommonTile
        title={
          <Typography variant="h4">
            {t("title.services")}{" "}
            <Typography variant="h4" component="span" color="textSecondary">
              {totalCount}
            </Typography>
          </Typography>
        }
        hideShowAll={Boolean(selectedService)}
        showAllLink={Routes.SERVICES}
        showAllText={`${t("button.showAll")} ${t("title.services")}`}
      >
        {selectedService ? (
          <ServiceDetails
            service={selectedService}
            onClose={() => setSelectedService(undefined)}
          />
        ) : (
          <List
            loading={loading}
            services={services}
            onSelectService={setSelectedService}
          />
        )}
        <Summary />
      </CommonTile>
    </ServicesContext.Provider>
  );
};

export default Services;
