import { useState } from "react";
import { makeStyles, createStyles } from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import Table, { TableProps } from "~/components/UI/Table/Table";
import ZReportDetails from "./ZReportDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime, currencyString } from "~/utils/format";
import LinkToObject from "~/components/LinkToObject";
import ReportStatus from "./ReportStatus";
import { SOURCE_TYPE } from "~/api/common";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flexGrow: 1,
    },
  })
);

interface AuditZReportTableProps
  extends Omit<
    TableProps<ZReportsJournalApi.ReportDto>,
    "columns" | "rowSelection"
  > {
  onNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  {
    key: "createdAt",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "audit_number",
    sort: true,
    i18nKey: "auditNumber",
  },
  {
    key: "siteId",
    sort: true,
    i18nKey: "siteId",
    render: (siteId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.SITE} sourceId={siteId}>
        {siteId}
      </LinkToObject>
    ),
    cellOptions: {
      width: 200,
    },
  },
  {
    key: "posId",
    sort: true,
    i18nKey: "posId",
    render: (posId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.POS} sourceId={posId}>
        {posId}
      </LinkToObject>
    ),
    cellOptions: {
      width: 200,
    },
  },
  {
    key: "posTransactionCount",
    i18nKey: "posTransactions",
  },
  {
    key: "serverTransactionCount",
    i18nKey: "serverTransactions",
  },
  {
    key: "posAmount",
    i18nKey: "posAmount",
    render: (posAmount?: number) => currencyString(posAmount),
  },
  {
    key: "serverAmount",
    i18nKey: "serverAmount",
    render: (serverAmount?: number) => currencyString(serverAmount),
  },
  {
    key: "status",
    sort: true,
    i18nKey: "status",
    render: (status: ZReportsJournalApi.eReportStatus) => (
      <ReportStatus status={status} />
    ),
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
];

/**
 * @memberof AuditZReportLogs
 * @component
 * @desc Render table of ZReports Logs.
 * @property {ZReportsJournalApi.ReportDto[]} dataSource - ZReports log data
 */

const AuditZReportTable = ({
  onSort,
  onNext,
  hasMore,
  dataLength,
  ...props
}: AuditZReportTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [columns] = useState(defaultColumns.map((c) => i18nTableColumn(c, t)));

  return (
    <div className={classes.wrapper}>
      <Table
        {...props}
        columns={columns}
        expandable={{
          highlightRow: true,
          expandedRowRender: (data: ZReportsJournalApi.ReportDto) => (
            <ZReportDetails data={data} />
          ),
        }}
        onRequestSort={onSort}
        rowKey="timestamp"
        stickyHeader
      />
    </div>
  );
};

export default AuditZReportTable;
