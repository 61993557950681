import { useContext, useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import SitesContext from "./sitesContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface SiteOptionsProps {
  site: Api.SiteDto;
  hideEditOptions?: boolean;
}

/**
 * @memberof Sites
 * @component
 * @desc Site options. Using a SitesContext.
 * @property {Api.SiteDto} site - Site data;
 * @requires SitesContext
 */

const SiteOptions = ({ site, hideEditOptions }: SiteOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onUpdateItem, onEdit, onUpdate, onDelete, onCreateQRZip } = useContext(SitesContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.SITE);
  const { enabled } = site;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (site.incidents) {
        site.incidents.count++;
        site.incidents.incidentIds.push(incident.incidentId);
      } else {
        site.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(site);
    },
    [site, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_SITE,
      PERMISSION.DELETE_SITE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_SITE),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(onUpdate(site.siteId, { enabled: !enabled })),
      },
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_SITE) || hideEditOptions,
      onClick: () => onEdit && onEdit(site),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_SITE) || hideEditOptions,
      onClick: () => onDelete && onUpdateHandler(onDelete(site)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(site.siteId, onCreatedIncident),
    },
    {
      label: t("option.createQRCodes"),
      hidden: hideEditOptions,
      onClick: () => onCreateQRZip && onCreateQRZip(site),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default SiteOptions;
