import { Switch, Route } from "react-router";
import ROUTES from "~/constants/routes";
import POSes from "./POSes";
import Metrics from "./Metrics";

const POSesRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.POS_METRICS} exact component={Metrics} />
      <Route path={ROUTES.POSES} exact component={POSes} />
    </Switch>
  );
};

export default POSesRoutes;
