import React, { useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  IconButton,
  Box,
  Button,
  Typography,
  FormControlLabel,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import {
  FieldArray,
  useField,
  useFormikContext,
  FormikHelpers,
  FormikState,
  FieldArrayRenderProps,
} from "formik";
import FrmikSelectBundle from "~/components/CommonFormControls/SelectBundle";
import { useTranslation } from "react-i18next";

interface BundleProps {
  bundle: Api.BundleDto;
  index: number;
  onChange: (bundle: Api.BundleDto, index: number) => void;
  onDelete: (index: number) => void;
  filter: (bundles: Api.BundleDto[]) => Api.BundleDto[];
}

const Bundle = React.memo(
  ({ bundle, index, onChange, onDelete, filter }: BundleProps) => {
    const { t } = useTranslation();

    return (
      <FormControlLabel
        key={bundle.id}
        label={t("label.bundleId")}
        extra={
          <ConfirmDialog onConfirm={() => onDelete(index)}>
            <IconButton>
              <Icon name="Delete" />
            </IconButton>
          </ConfirmDialog>
        }
      >
        <Box display="inline-block" mt={1}>
          <Typography variant="body1">{bundle.id}</Typography>
        </Box>
      </FormControlLabel>
    );
  }
);

interface BundlesProps {
  bundles: Api.BundleDto[];
  arrayHelpers: FieldArrayRenderProps;
  filter: (bundles: Api.BundleDto[]) => Api.BundleDto[];
}

const Bundles = React.memo(({ bundles, arrayHelpers, filter }: BundlesProps) => {
  const onChange = useCallback((pos: Api.BundleDto, index: number) => {
    arrayHelpers.replace(index, pos);
  }, []);

  const onDelete = useCallback((index: number) => {
    arrayHelpers.remove(index);
  }, []);

  if (!Array.isArray(bundles)) return null;

  return (
    <>
      {bundles.map((bundle: Api.BundleDto, index: number) => (
        <Bundle key={bundle.id} {...{ bundle, index, onChange, onDelete, filter }} />
      ))}
    </>
  );
});

interface AddBundleFormProps {
  defaultExpanded?: boolean;
}

type FormSubData = { bundles: []; addBundle: Api.BundleDto };

/**
 * @memberof Sites
 * @component
 * @desc Form for add a Bundle for a Service. Use Formik context. Updating field 'bundles'.
 * @property {boolean}   defaultExpanded Default state
 */

const AddBundleForm = ({ defaultExpanded }: AddBundleFormProps) => {
  const {
    values,
    setFieldValue,
  }: FormikState<FormSubData> & FormikHelpers<FormSubData> = useFormikContext();
  const [field] = useField<Api.BundleDto[]>("bundles");
  const { t } = useTranslation();

  const filterOptions = useCallback(
    (bundles: Api.BundleDto[]) =>
    bundles.filter((p) => !field.value.find((subP) => subP.id === p.id)),
    [field.value]
  );

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary>
        {t("title.bundles")} ({field.value ? field.value.length : 0})
      </AccordionSummary>
      <AccordionDetails>
        <FieldArray
          name="bundles"
          render={(arrayHelpers) => (
            <>
              <Bundles
                {...{ bundles: field.value, arrayHelpers, filter: filterOptions }}
              />
              <FormControlLabel label={t("label.addBundle")}>
                <Box display="inline-block">
                  <FrmikSelectBundle
                    name="addBundle"
                    // filterOptions={filterOptions}
                    onChange={(id) => setFieldValue("addBundle", { id })}
                  />
                </Box>
                <Box display="inline-block" ml={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!values.addBundle}
                    onClick={() => {
                      setFieldValue("addBundle", "");
                      arrayHelpers.push(values.addBundle);
                    }}
                  >
                    {t("button.add")}
                  </Button>
                </Box>
              </FormControlLabel>
            </>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AddBundleForm;
