import { useState, useCallback } from "react";
import {
  Box,
  Grid,
  Checkbox,
  Select,
  Typography,
  Table,
  MUIFormControlLabel,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { ENV } from "~/constants";
import {
  WIDGET_ENTITY,
  JournalEntitys,
  WIDGET_ENTITY_I18n,
} from "~/api/dashboardSettings/enums";
interface WidgetsProps {
  widgets: DashboardSettingsApi.Widget[];
  onChange: (index: number, value: DashboardSettingsApi.Widget) => void;
}

interface SelectorProps {
  initialValue?: string;
  widget: DashboardSettingsApi.Widget;
  onChange: (value: string) => void;
}

interface MultipleSelectorProps {
  initialValue?: string[];
  widget: { [key: string]: any };
  optionsFieldKey: string;
  onChange: (value: string[]) => void;
}

const MultipleSelector = ({
  initialValue,
  widget,
  optionsFieldKey,
  onChange,
}: MultipleSelectorProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue || []);
  const field: string[] =
    widget[optionsFieldKey] && Array.isArray(widget[optionsFieldKey])
      ? (widget[optionsFieldKey] as string[])
      : [];

  return (
    <MUIFormControlLabel
      labelPlacement="start"
      label=""
      control={
        <Select
          value={value}
          renderValue={(selected: any) => (
            <Typography variant="subtitle2">{`${t("label.selected")} ${
              selected ? selected.length : 0
            }`}</Typography>
          )}
          options={field.map((value: string) => ({
            label: value,
            value: value,
          }))}
          multiple
          displayEmpty
          onChange={(e) => {
            setValue(e.target.value as string[]);
            onChange && onChange(e.target.value as string[]);
          }}
        />
      }
    />
  );
};

const SortBySelector = ({ widget, initialValue, onChange }: SelectorProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  return widget.sortBy ? (
    widget.sortBy.length && widget.sortBy.length > 1 ? (
      <MUIFormControlLabel
        labelPlacement="start"
        label={`${t("label.sortBy")}:`}
        control={
          <Select
            value={value}
            options={widget.sortBy.map((value) => ({
              label: t("serverText." + value),
              value: value,
            }))}
            onChange={(e) => {
              setValue(e.target.value as string);
              onChange && onChange(e.target.value as string);
            }}
          />
        }
      />
    ) : (
      <Typography variant="body1">{t("serverText." + widget.sortBy[0])}</Typography>
    )
  ) : null;
};

const Widgets = ({ widgets, onChange }: WidgetsProps) => {
  const { t } = useTranslation(["translation", "enums"]);
  const disabledJournalsByLimit =
    widgets.filter((w) => JournalEntitys.includes(w.entity) && w.status)
      .length >= Number(ENV.REACT_APP_MAX_LIMIT_FOR_JOURNALS_ON_DASHBOARD);

  const handleChangeItem = useCallback(
    (widget: DashboardSettingsApi.Widget) => {
      const index = widgets.findIndex((w) => w.widgetId === widget.widgetId);
      if (index !== -1 && onChange) {
        onChange(index, widget);
      }
    },
    [widgets, onChange]
  );

  const columns = [
    {
      key: "entity",
      title: t("label.widgetsName"),
      render: (
        entity: DashboardSettingsApi.eWidgetEntity,
        row: DashboardSettingsApi.Widget
      ) => (
        <Grid container spacing={2}>
          <Grid item>
            <Checkbox
              checked={row.status}
              disabled={
                disabledJournalsByLimit &&
                !row.status &&
                JournalEntitys.includes(entity)
              }
              onChange={(_e, status) => handleChangeItem({ ...row, status })}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t(`enums:${WIDGET_ENTITY_I18n[entity]}`)}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      key: "any",
      title: t("label.dataDisplayPrinciple"),
      cellOptions: {
        align: "right",
      },
      render: (_value: unknown, row: DashboardSettingsApi.Widget) =>
        row.entity === WIDGET_ENTITY.PAYMENT_SERVICE ? (
          <MultipleSelector
            initialValue={row.currentServices}
            optionsFieldKey="services"
            widget={row}
            onChange={(currentServices) =>
              handleChangeItem({ ...row, currentServices })
            }
          />
        ) : (
          <SortBySelector
            initialValue={row.currentSortBy}
            widget={row}
            onChange={(currentSortBy) =>
              handleChangeItem({ ...row, currentSortBy })
            }
          />
        ),
    },
  ];

  return (
    <Box border={1} borderColor="divider">
      <Table columns={columns} dataSource={widgets} rowKey="widgetId" />
    </Box>
  );
};

export default Widgets;
