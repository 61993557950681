import Button, { Props as ButtonProps } from "~/components/UI/Button";
import ROUTES from "~/constants/routes";
import { useHistory } from "react-router";
import { stringify } from "query-string";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface AllIncidentsButtonProps extends ButtonProps {
  incidents?: IncidentsApi.IncidentsCount;
}

const AllIncidentsButton = ({
  incidents,
  ...other
}: AllIncidentsButtonProps) => {
  let history = useHistory();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  if (!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)) {
    return null;
  }

  return (
    <Button
      {...other}
      onClick={() =>
        history.push(
          `${ROUTES.INCIDENTS}?${stringify({
            incidentIds: incidents ? incidents.incidentIds : [],
          })}`
        )
      }
    >
      {t("button.allIncidents")}
    </Button>
  );
};

export default AllIncidentsButton;
