import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Typography,
  LinearProgress,
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
} from "~/components/UI";
import ListLayoutSwitcher, {
  LayoutType,
  eLayoutType,
} from "~/components/ListLayoutSwitcher";
import Filter from "./Filter";
import Table from "./Table";
import Tiles from "./Tiles";
import UpdateSiteDialog from "./EditSiteDialog";
import CreateQRZipDialog from "./CreateQRZipDialog";
import { toast } from "react-toastify";
import { useQueryParams, StringParam } from "use-query-params";
import SitesContext from "./sitesContext";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

interface ListProps {
  type: LayoutType;
  data?: Api.SiteDto[];
  ref: React.MutableRefObject<any>;
}

const List = React.memo(({ type, data }: ListProps) => {
  const tableRef = useRef<any>(null);

  useEffect(() => {}, [data]);
  return type === eLayoutType.TABLE ? (
    <Table ref={tableRef} dataSource={data} />
  ) : (
    <Tiles list={data} />
  );
});

/**
 * @memberof Sites
 * @component
 * @desc Sites Controller.
 * @requires SitesContext
 */

const SitesList = () => {
  const [loading, setLoading] = useState(true);
  const [layoutType, setLayoutType] = useState(eLayoutType.TABLE);
  const [sites, setSites] = useState<Api.SiteDto[]>();
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [query, setQuery] = useState<Api.GetSitesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [openEditDialog, setOpenEditDialog] = useState<boolean | undefined>();
  const [editSite, setEditSite] = useState<Api.SiteDto>();
  const [qrZipSiteData, setQRZipSiteData] = useState<Api.SiteDto>();
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    siteId: StringParam,
  });
  const tableRef = useRef<{ resetSelectedRows: () => {} }>();
  const { t } = useTranslation();

  const getSites = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const { data } = await api.sites.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setSites(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCreateSite = () => {
    setEditSite(undefined);
    setOpenEditDialog(true);
  };

  const onUpdateItem = (site: Api.SiteDto) => {
    // removes UI lags when refreshing the list
    setTimeout(() => {
      setSites((sites) => (sites ? updateArrayItem(sites, site, "id") : []));
    }, 0)
  };

  const onEdit = useCallback((site: Api.SiteDto) => {
    setEditSite(site);
    setOpenEditDialog(true);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdateSite) => {
      try {
        const {
          data: { data: site },
        } = await api.sites.updateSite(id, formData);
        onUpdateItem({ ...site, updatedAt: new Date().toISOString() });
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [sites, t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (site: Api.SiteDto) => {
      await deleteConfirm(site?.name);
      try {
        await api.sites.deleteSite(site.siteId);
        getSites();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, pagination]
  );

  const onDeleteSites = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedSites?.length }));
    try {
      await api.sites.deleteSites(selectedSites);
      getSites();
      toast.success(t("text.recordsSuccessfullyDeleted"));
      setSelectedSites([]);
      tableRef && tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [t, selectedSites, tableRef]);

  const onCloseEditDialog = useCallback(
    (result?: Api.SiteDto | boolean) => {
      setOpenEditDialog(false);
      setEditSite(undefined);
      if (result) {
        if (typeof result === "object") {
          onUpdateItem({ ...result, updatedAt: new Date().toISOString() });
        } else {
          getSites();
        }
      }
    },
    [sites, editSite]
  );

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getSites(page);
  };

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = useMemo(
    () => ({
      onSort,
      onSelect: setSelectedSites,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete,
      onCreateQRZip: setQRZipSiteData
    }),
    [sites]
  );

  useEffect(() => {
    setQuery(queryParams as Api.GetSitesQuery);
  }, [queryParams]);

  useEffect(() => {
    setSelectedSites([]);
  }, [layoutType]);

  useEffect(() => {
    query && getSites();
  }, [query, limit]);

  return (
    <SitesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.sites")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_SITE}>
                <Button
                  hidden={!selectedSites.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteSites}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_SITE}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateSite}
                >
                  {t("button.createNew")}
                </Button>
              </CanIUse>
              <ListLayoutSwitcher
                defaultValue={layoutType}
                onChange={setLayoutType}
              />
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <List
          {...{
            ref: tableRef,
            type: layoutType,
            data: sites,
          }}
        />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <UpdateSiteDialog
        site={editSite}
        open={openEditDialog}
        onClose={onCloseEditDialog}
      />
      <CreateQRZipDialog
        open={!!qrZipSiteData}
        site={qrZipSiteData}
        onClose={() => setQRZipSiteData(undefined)}
      />
    </SitesContext.Provider>
  );
};

export default SitesList;
