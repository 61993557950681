import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch,
  Section,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import SelectSite from "~/components/CommonFormControls/SelectSite";
import SelectEnum from "~/components/CommonFormControls/SelectEnum";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { POS_TYPE, POS_TYPE_I18n } from "~/api/common";
import { saveFile, toBoolean } from "~/utils/helpers";
import api from "~/api";

import ENV from "~/constants";

interface UpdatePOSProps extends Omit<DialogProps, "onClose"> {
  pos?: Api.PosDto;
  onClose?: (result?: boolean) => void;
}

const useCheckSignature = toBoolean(ENV.REACT_APP_USE_OPTION_POS_SIGNATURE_VERIFICATION)

const defaultValues = {
  type: POS_TYPE.POS,
  posId: "",
  enabled: false,
  siteId: "",
  certificate: false,
  generateCertificate: false,
  signatureKey: "",
  checkSignature: !useCheckSignature,
} as Api.CreatePOS | Api.UpdatePOS;

/**
 * @memberof POSes
 * @component
 * @desc Dialog for creating or editing a POS.
 * @property {Api.PosDto} pos Pos
 * @property {Function} onClose - passes true if the POS was created/updated successfully. (result?: boolean) => void;
 */

const UpdatePOS = ({ pos, open, onClose }: UpdatePOSProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape(
    {
      type: yup.string().required(),
      posId: yup.string().posId().required(),
      siteId: yup.string(),
      enabled: yup.boolean().required(),
      certificate: yup.boolean(),
      checkSignature: yup.boolean(),
      signatureKey: yup
        .string().onlyCharacters()
        .when(["checkSignature", "certificate", "generateCertificate"], {
          is: (
            checkSignature: boolean,
            certificate: boolean,
            generateCertificate: boolean
          ) => checkSignature && !certificate && !generateCertificate,
          then: yup.string().onlyCharacters().required(),
        }),
      generateCertificate: yup
        .boolean()
        .when(["checkSignature", "signatureKey", "certificate"], {
          is: (
            checkSignature: boolean,
            signatureKey: string,
            certificate: boolean
          ) => checkSignature && !certificate && (!signatureKey || !signatureKey.length),
          then: yup.boolean().oneOf([true], t("validation.required")),
        }),
    },
    ["generateCertificate", "signatureKey", "certificate"]
  );

  const onSubmit = useCallback(
    async (values) => {
      let responce: Api.GetOne<Api.PosDto>;
      setLoading(true);

      if (!pos?.description && 'description' in values && values?.description === '') {
        delete values.description;
      }

      try {
        if (isEditMode && pos && pos.posId) {
          responce = await api.poses.updatePos(
            pos.posId,
            values as Api.UpdatePOS
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          responce = await api.poses.createPos(values as Api.CreatePOS);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }

        if (responce && responce.data.data) {
          const { dataUrl, filename } = responce.data.data;
          dataUrl && filename && saveFile(dataUrl, filename);
        }

        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, pos, onClose]
  );

  useEffect(() => {
    if (pos && pos.posId) {
      const {
        posId,
        siteId,
        enabled,
        description,
        checkSignature,
        signatureKey,
        certificate,
        type,
      } = pos;
      setInitialValues({
        ...defaultValues,
        posId,
        siteId,
        enabled,
        description,
        checkSignature,
        signatureKey,
        certificate: Boolean(certificate),
        type,
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [pos]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editPos") : t("title.createNewPos")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label={t("label.type")}>
                  <SelectEnum
                    name="type"
                    enumValue={POS_TYPE}
                    enumI18n={POS_TYPE_I18n}
                    formikControll
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.siteName")}>
                  <SelectSite
                    name="siteId"
                    defaultInputValue={
                      pos && pos.siteId
                        ? `${pos.siteName} (ID:${pos.siteId})`
                        : ""
                    }
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.posId")}>
                  <OutlinedInput name="posId" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
                <Section title={t("title.signature")}>
                  <Divider />
                  <Box pt={3}>
                    {
                      useCheckSignature && 
                      <FormControlLabel label={t("label.signatureVerification")}>
                        <Checkbox
                          name="checkSignature"
                          size="medium"
                          formikControll
                        />
                      </FormControlLabel>
                    }
                    <FormControlLabel label={t("label.key")}>
                      <OutlinedInput name="signatureKey" formikControll />
                    </FormControlLabel>
                    <Box hidden={!isEditMode}>
                      <FormControlLabel label={t("label.certificate")}>
                        <>
                          {values.certificate
                            ? t("text.generated")
                            : t("text.not_generated")}
                        </>
                      </FormControlLabel>
                    </Box>
                    <FormControlLabel label={t("label.generateCertificate")}>
                      <Checkbox
                        name="generateCertificate"
                        size="medium"
                        formikControll
                      />
                    </FormControlLabel>
                  </Box>
                </Section>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdatePOS;
