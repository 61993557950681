import { createContext } from 'react';

export interface BusinessOperationsContextProps {
  onUpdateItem?: (businessOperation: JournalBusinessOperationsApi.BusinessOperationDto) => void;
}

/**
 * @memberof BusinessOperations
 * @component
 */

const BusinessOperationsContext = createContext<BusinessOperationsContextProps>({});


export default BusinessOperationsContext;