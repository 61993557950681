import { useContext, useState } from "react";
import Table, { TableProps } from "~/components/UI/Table/Table";
import BundleOptions from "./BundleOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import BundlesContext from "./bundlesContext";

const defaultColumns = [
  {
    key: "id",
    sort: true,
    i18nKey: "bundleId",
  },
  {
    key: "name",
    i18nKey: "name",
  },
  {
    key: "serviceName",
    i18nKey: "serviceName",
  },
  {
    key: "serviceId",
    i18nKey: "serviceId",
  },
  {
    key: "filter",
    i18nKey: "filter",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Bundles
 * @component
 * @desc Bundle options. Using a BundlesContext.
 * @property {Api.BundleDto[]} dataSource - Bundles data
 * @requires BundlesContext
 */

const BundleTable = (props: Omit<TableProps<Api.BundleDto>, "columns">) => {
  const { onSelect, onSort } = useContext(BundlesContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_BUNDLE)
      ? {
          hideSelectAll: false,
          onChange: onSelect,
        }
      : undefined
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, row: Api.BundleDto) => (
            <BundleOptions bundle={row} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      onRequestSort={onSort}
      rowSelection={rowSelection}
      rowKey="id"
      stickyHeader
    />
  );
};

export default BundleTable;
