import { enumToI18nKey } from '~/utils/helpers';

export const TIME_PERIOD = {
  LAST_MINUTE: '1m',
  LAST_HOUR: '1h',
  LAST_24H: '24h',
} as { [key: string]: MetricsAPI.eTimePeriod }

export const BUSINESS_TRANSACTIONS_FILTER = {
  ALL: 'All',
  COMPLETED: 'Completed',
  FAILED_BY_CLIENT: 'Failed by client',
  FAILED_BY_INTERNAL: 'Failed by internal',
} as { [key: string]: MetricsAPI.eBusinessTransactionsFilter }

export const BUSINESS_TRANSACTIONS_FILTER_I18n = enumToI18nKey(BUSINESS_TRANSACTIONS_FILTER, 'BUSINESS_TRANSACTIONS_FILTER');

export const FINANCIAL_TRANSACTION_FILTER = {
  COMPLETED: 'Completed',
  FAILED_BY_CLIENT: 'Failed by client',
  FAILED_BY_INTERNAL: 'Failed by internal',
} as { [key: string]: MetricsAPI.eFinancialTransactionFilter }

export const FINANCIAL_TRANSACTION_FILTER_I18n = enumToI18nKey(FINANCIAL_TRANSACTION_FILTER, 'FINANCIAL_TRANSACTION_FILTER');