import { Switch, Route } from "react-router";
import ROUTES from "~/constants/routes";
import Sites from "./Sites";
import Metrics from "./Metrics";

const SitesRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.SITE_METRICS} exact component={Metrics} />
      <Route path={ROUTES.SITES} exact component={Sites} />
    </Switch>
  );
};

export default SitesRoutes;
