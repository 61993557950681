import { useState, useCallback } from "react";
import { Box, Input, Icon, IconButton, Button, Grid } from "~/components/UI";
import { useTranslation } from "react-i18next";

interface SearchProps {
  placeholder?: string;
  onSearch?: (value: string) => void | Promise<undefined>;
}

const Search = ({ placeholder, onSearch }: SearchProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSearchHandler = useCallback(() => {
    const res = onSearch && onSearch(searchValue);
    if (res && res.finally) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, [searchValue]);

  const onClearHandler = useCallback(() => {
    setSearchValue("");
    onSearch && onSearch("");
  }, [onSearch]);

  const onKeyPress = (keyCode: number) => {
    if (keyCode === 13) {
      onSearchHandler();
    }
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      <Box flexGrow={1}>
        <Input
          placeholder={placeholder}
          startAdornment={<Icon name="Lens" />}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={(e) => {
            onKeyPress(e.nativeEvent.keyCode);
          }}
        />
      </Box>
      <Box width={20}>
        <div hidden={!searchValue}>
          <IconButton size="small" onClick={onClearHandler}>
            <Icon name="Close" />
          </IconButton>
        </div>
      </Box>
      <Box width={70}>
        <div hidden={!searchValue}>
          <Button
            loading={loading}
            variant="text"
            color="primary"
            onClick={onSearchHandler}
          >
            {t("button.search")}
          </Button>
        </div>
      </Box>
    </Grid>
  );
};

export default Search;
