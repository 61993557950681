import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import KeysContext from "./keysContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import { saveFile } from "~/utils/helpers";
import api from "~/api";

interface KeyOptionsProps {
  keyData: KeyManagementApi.KeyDto;
}

/**
 * @memberof KeyManagementPage
 * @component
 * @desc Key options. Using a Key's Context.
 * @property {KeyManagementApi.KeyDto} key - Key data;
 * @requires KeysContext
 */

const KeyOptions = ({ keyData }: KeyOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdateItem, onDelete } = useContext(KeysContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.KEY_MANAGEMENT);

  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (keyData.incidents) {
        keyData.incidents.count++;
        keyData.incidents.incidentIds.push(incident.incidentId);
      } else {
        keyData.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(keyData);
    },
    [keyData, onUpdateItem]
  );

  const downloadPubKey = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.keyManagement.getPublicRSAKey(id);
      if (data && data.data && data.data.dataUrl && data.data.filename) {
        const { dataUrl, filename } = data.data;
        dataUrl && filename && saveFile(dataUrl, filename.slice(0, -4) + "(public_key)" + filename.slice(-4));
      }
    } finally {
      setLoading(false);
    }
  };

  if (
    !hasAny([
      PERMISSION.EDIT_KEY_MANAGEMENT,
      PERMISSION.DELETE_KEY_MANAGEMENT,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_KEY_MANAGEMENT),
      onClick: () => onEdit && onEdit(keyData),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_KEY_MANAGEMENT),
      onClick: () => onDelete && onUpdateHandler(onDelete(keyData)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(keyData.keyId, onCreatedIncident),
    },
    {
      label: t("option.downloadpubkey"),
      hidden: !hasAll(PERMISSION.EDIT_KEY_MANAGEMENT) && keyData.purpose !== "Inbound VT Sign Key",
      onClick: () => downloadPubKey(keyData.keyId)
    }
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default KeyOptions;
