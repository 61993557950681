import { useContext, useCallback } from "react";
import { IncidentsContext } from "./CreateIncidentContext";

type CreateIncidentForSource = (sourceId: string, onCreated?: (incident: IncidentsApi.IncidentDto) => void) => void;

/**
 * React hook for Incidents dialogs windows
 */
export const useCreateIncidentDialog = (sourceType?: Api.eSourceType): [CreateIncidentForSource] => {
  const context = useContext(IncidentsContext);
  const createIncident: CreateIncidentForSource = useCallback((sourceId, onCreated) => {
    context.createIncident(
      sourceId, { onCreated, sourceType }
    );
  }, []);

  return [createIncident];
};

export default useCreateIncidentDialog;
