import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import {
  Table,
  Box,
  Typography,
  Grid,
  LinearProgress,
  Switch,
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "~/api";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface SiteDetailsProps {
  site: PaymentServicesApi.SiteDto;
  serviceEnabled: boolean;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      maxHeight: 280,
    },
  })
)(Grid);

const defaultColumns = [
  {
    key: "title",
    render: () => (
      <Typography variant="body1" color="textSecondary">
        ID
      </Typography>
    ),
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
  },
  {
    key: "posId",
  },
  {
    key: "action",
    render: () => (
      <Typography variant="body1" color="textSecondary">
        Enabled
      </Typography>
    ),
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
  },
  {
    key: "enabled",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
  },
];

/**
 * @memberof PaymentService
 * @component
 * @desc Render details of a Site.
 * @property {PaymentServicesApi.SiteDto} site - Sites data
 */

const SiteDetails = ({ site, serviceEnabled }: SiteDetailsProps) => {
  const { paymentId } = useParams<{ paymentId?: string }>();
  const [loading, setLoading] = useState(false);
  const [POSes, setPOSes] = useState<PaymentServicesApi.POSDto[]>([]);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const onUpdatePOS = useCallback(
    async (posId: string, data: PaymentServicesApi.UpdatePOS) => {
      if (!paymentId) return;
      try {
        const {
          data: { data: service },
        } = await api.paymentServices.updatePOSService(paymentId, posId, data);
        setPOSes((POSes) =>
          POSes.map((p) =>
            p.posId === posId ? { ...p, enabled: service.enabled } : p
          )
        );
        toast.success(
          `POS: ${posId} ${
            data.enabled ? t("text.active") : t("text.disabled")
          }`
        );
      } catch (e) {
        console.error(e);
      }
    },
    [paymentId, POSes, t]
  );

  const columns = defaultColumns.map((c) => {
    switch (c.key) {
      case "enabled":
        return {
          ...c,
          render: (enabled: boolean, pos: PaymentServicesApi.POSDto) => (
            <ConfirmDialog
              disabled={
                !serviceEnabled || !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
              }
              placement="top"
              onConfirm={() => onUpdatePOS(pos.posId, { enabled: !enabled })}
            >
              <Switch
                checked={enabled}
                disabled={
                  !serviceEnabled || !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
                }
              />
            </ConfirmDialog>
          ),
        };
      default:
        return c;
    }
  });

  const getPOSes = useCallback(
    async (siteId: string) => {
      if (!paymentId) return;
      try {
        setLoading(true);
        const { data } = await api.paymentServices.getPaymentSite(
          paymentId,
          siteId
        );
        setPOSes(data.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [paymentId]
  );

  useEffect(() => {
    getPOSes(site.siteId);
  }, [site]);

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">POS</Typography>
      </Box>
      <LinearProgress hidden={!loading} />
      <Grid container>
        <Grid item md={6} sm={12}>
          <Table
            columns={columns}
            hideHeader
            dataSource={POSes}
            rowKey="id"
            size="small"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SiteDetails;
