import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import { useTranslation } from "react-i18next";
import api from "~/api";
import _ from "lodash";

interface FormikSelectSiteProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (options: { label: string, value: string }[], state: any) => { label: string, value: string }[];
}

interface SelectSiteProps {
  name?: string;
  inputValue?: string;
  onChange?: (site?: { label: string, value: string }) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (options: Api.PosDto[], state: any) => Api.PosDto[];
}

const requestSearchSite = _.memoize((search: string) =>
  api.businessOperationsJournal.getSitesFilter(100, 1, { search })
);

const SelectSiteBO = React.memo(
  ({
    onChange,
    onBlur = () => { },
    touched,
    error,
    filterOptions,
    inputValue,
  }: SelectSiteProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<{ label: string, value: string }[]>([]);
    const [site, setSite] = useState<{ label: string, value: string } | null>(null);
    const { t } = useTranslation();

    const searchSite = useCallback(
      _.debounce((name: string) => {
        setLoading(true);
        requestSearchSite(name)
          .then(({ data }) =>
            data.data.map((o) => ({ label: o, value: o }))
          )
          .then((data) => {
            setSearchResults(data);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500),
      []
    );

    const onChangeInputValue = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value);
        searchSite(e.target.value || "");
      },
      [setValue, searchSite]
    );

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.value);
          setSite(newValue);
        } else {
          setValue("");
          setSite(null);
        }
        onChange && onChange(newValue);
      },
      [onChange, setValue]
    );

    const getOptionSelected = useCallback(
      (o: { label: string, value: string }, value: { label: string, value: string }) => o.value === value.value,
      []
    );

    const getOptionLabel = useCallback(
      (o: { label: string, value: string }) => o.value,
      []
    );

    useEffect(() => {
      searchSite("");
    }, [searchSite]);

    useEffect(() => {
      if (!inputValue && value) {
        setValue("");
        setSite(null);
      }
    }, [inputValue]);

    return (
      <Autocomplete
        value={site}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText={t("text.noResultsFound")}
        options={searchResults}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderInput={(params: any) => (
          <OutlinedInput
            loading={loading}
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={value}
            touched={touched}
            error={error}
            onChange={onChangeInputValue}
            fullWidth
          />
        )}
      />
    );
  }
);

const FormikContainer = ({
  name,
  defaultInputValue,
}: FormikSelectSiteProps & SelectSiteProps) => {
  const [field, { error, touched }] = useField(name);
  const onChange = useCallback(
    (site?: { label: string, value: string }) => {
      field.onChange({
        target: {
          name,
          value: site ? site.value : "",
        },
      });
    },
    [field]
  );

  return (
    <SelectSiteBO
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default React.memo(FormikContainer);
