import { Switch } from "react-router";
import ROUTES from "~/constants/routes";
import Sites from "./Sites";
import POSes from "./POSes";
import Services from "./Services";
import UserRoles from "./UserRoles";
import Users from "./Users";
import PaymentServices from "./PaymentServices";
import Groups from "./Groups";
import KeyManagement from './KeyManagement';
import PrivateRoute from "~/components/PrivateRoute";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import BundlesPage from "./Bundles";

const CatalogsRoutes = () => {
  const { hasAll } = usePermissions();

  return (
    <Switch>
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_SITE)}
        path={ROUTES.SITES}
        component={Sites}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_POS)}
        path={ROUTES.POSES}
        component={POSes}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_SERVICE)}
        path={ROUTES.SERVICES}
        component={Services}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_ROLE)}
        path={ROUTES.USER_ROLES}
        component={UserRoles}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_USER)}
        path={ROUTES.USERS}
        component={Users}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_GROUP)}
        path={ROUTES.GROUPS}
        component={Groups}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_PAYMENT_SERVICE)}
        path={ROUTES.PAYMENT_SERVICES}
        component={PaymentServices}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_KEY_MANAGEMENT)}
        path={ROUTES.KEY_MANAGEMENT}
        component={KeyManagement}
      />
       <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_BUNDLE)}
        path={ROUTES.BUNDLES}
        component={BundlesPage}
      />
    </Switch>
  );
};

export default CatalogsRoutes;
