
const ROUTES = {
  LOGIN: "/login",

  DASHBOARD: "/dashboard",
  SEARCH_RESULTS: "/search-results",

  CATALOGS: '/catalogs',

  SITES: "/catalogs/sites",
  SITE_METRICS: "/catalogs/sites/:siteId/metrics",

  BUNDLES: "/catalogs/bundles",

  POSES: "/catalogs/poses",
  POS_METRICS: "/catalogs/poses/:posId/metrics",

  SERVICES: "/catalogs/services",
  SERVICES_CATEGORIES: "/catalogs/services/services-categories",
  SERVICE_METRICS: "/catalogs/services/:serviceId/metrics",

  KEY_MANAGEMENT: '/catalogs/key-management',

  USER_ROLES: "/catalogs/user-roles",
  USERS: "/catalogs/users",
  GROUPS: "/catalogs/groups",

  PAYMENT_SERVICES: "/catalogs/payment-services",
  PAYMENT_SERVICE: "/catalogs/payment-services/:paymentId",
  PAYMENT_SERVICE_METRICS: "/catalogs/payment-services/:paymentId/metrics",

  JOURNALS: "/journals",
  BUSINESS_OPERATIONS: '/journals/journal-business-operations',
  INCIDENTS: '/journals/incidents',
  USER_ACTIVITY: '/journals/user-activity',
  EVENTS: '/journals/events',
  SYSTEM_LOG: '/journals/system-log',
  AUDIT_Z_REPORT_LOG: '/journals/audit-z-report-log',
  
  METRICS: "/metrics",
  MY_PROFILE: '/my-profile',

  SETTINGS: '/settings',
  COMPANY_PROFILE: '/settings/company-profile',
  ID_PREFIXES: '/settings/id-prefixes',

  LOGOUT: '/logout',
  FORBIDDEN: '/forbidden'
};


export default ROUTES;
