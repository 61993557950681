import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Table,
  Switch,
  Grid,
  LinearProgress,
  Typography,
} from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { updateArrayItem } from "~/utils/helpers";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const defaultColumns = [
  {
    key: "paymentServiceType",
    i18nKey: "paymentService",
  },
  {
    key: "paymentId",
    title: "ID",
  },
  {
    key: "merchantId",
    i18nKey: "merchantID",
  },
  {
    key: "signKeyId",
    sort: true,
    i18nKey: "signKeyName",
    // render: (id: string) => <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={id} />,
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "verifyKeyId",
    sort: true,
    i18nKey: "verifyKeyName",
    // render: (id: string) => <LinkToObject sourceType={SOURCE_TYPE.KEY_MANAGEMENT} sourceId={id} />,
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "terminalId",
    i18nKey: "terminalId",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: Api.Incidents) => (
      <IncidentsIcon incidents={incidents} size="small" />
    ),
  },
];

interface DetailsOfPaymentMethodsProps {
  posId: string;
  size?: "default" | "small";
}

/**
 * @memberof POSes
 * @component
 * @desc Render information about available Payment Methods.
 * @property {string} posId POS id
 */

const DetailsOfPaymentMethods = ({ posId }: DetailsOfPaymentMethodsProps) => {
  const [loading, setLoading] = useState(false);
  const [paymentServices, setPaymentServices] =
    useState<PaymentServicesApi.PaymentServiceDto[]>();
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const onUpdate = useCallback(
    async (id: string, formData: PaymentServicesApi.UpdatePaymentService) => {
      try {
        const {
          data: { data },
        } = await api.paymentServices.updatePOSService(id, posId, formData);
        setPaymentServices((paymentServices) =>
          updateArrayItem(paymentServices, data, "paymentId")
        );
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [posId, t]
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "paymentServiceType":
        return {
          ...column,
          render: (
            paymentServiceType: string,
            pService: PaymentServicesApi.PaymentServiceDto
          ) => (
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <ConfirmDialog
                  placement="top"
                  disabled={
                    !pService.globalEnabled ||
                    !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
                  }
                  onConfirm={() =>
                    onUpdate(pService.paymentId, {
                      enabled: !pService.enabled,
                    } as PaymentServicesApi.UpdatePaymentService)
                  }
                >
                  <Switch
                    checked={pService.globalEnabled && pService.enabled}
                    disabled={
                      !pService.globalEnabled ||
                      !hasAll(PERMISSION.EDIT_PAYMENT_SERVICE)
                    }
                    size="small"
                  />
                </ConfirmDialog>
              </Grid>
              <Grid item>
                <Typography variant="body1">{paymentServiceType}</Typography>
              </Grid>
            </Grid>
          ),
        };
      default:
        return column;
    }
  });

  async function getPaymentServices(posId: string) {
    setLoading(true);
    try {
      const { data } = await api.poses.getPaymentServices(posId);
      setPaymentServices(data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPaymentServices(posId);
  }, [posId]);

  if (loading) {
    return <LinearProgress />;
  } else if (paymentServices) {
    return (
      <Box px={3}>
        <Table
          columns={columns}
          dataSource={paymentServices}
          size="small"
          rowKey="paymentId"
        />
      </Box>
    );
  } else {
    return null;
  }
};

export default DetailsOfPaymentMethods;
