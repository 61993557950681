import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  // Typography,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";
// import { uuid } from 'uuidv4';

interface UpdateServicesCategoryProps extends Omit<DialogProps, "onClose"> {
  servicesCategory?: ServicesCategoriesApi.ServicesCategoryDto;
  onClose?: (
    servicesCategory?: ServicesCategoriesApi.ServicesCategoryDto,
    created?: boolean
  ) => void;
}

const defaultValues = {
  categoryId: '',
  serviceMaxQueue: 1,
  globalCatalog: false,
  fixedQueue: false,
} as
  | ServicesCategoriesApi.AddServicesCategory
  | ServicesCategoriesApi.UpdateServicesCategory;

/**
 * @memberof ServicesCategories
 * @component
 * @desc Dialog for creating or editing a Services Category.
 * @property {ServicesCategoriesApi.ServicesCategoryDto} servicesCategory a Services Category
 * @property {Function} onClose - passes true if the services Category was created/updated successfully. (result?: boolean) => void;
 */

const UpdateServicesCategoryDialog = ({
  servicesCategory,
  open,
  onClose,
}: UpdateServicesCategoryProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    categoryId: yup.string().max(36, t("validation.max", { count: 36 })),
    name: yup.string().required(),
    serviceMaxQueue: yup.number().min(0).required(),
    description: yup.string(),
    globalCatalog: yup.boolean().required(),
    fixedQueue: yup.boolean().required(),
  });

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (isEditMode && servicesCategory && servicesCategory.categoryId) {
          const {
            data: { data },
          } = await api.servicesCategories.updateServicesCategory(
            servicesCategory.categoryId,
            values as ServicesCategoriesApi.UpdateServicesCategory
          );
          onClose && onClose(data, true);
        } else {
          const {
            data: { data },
          } = await api.servicesCategories.addServicesCategory(
            values as ServicesCategoriesApi.AddServicesCategory
          );
          onClose && onClose(data, true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, onClose]
  );

  useEffect(() => {
    if (servicesCategory && servicesCategory.id) {
      const { categoryId, name, serviceMaxQueue, description, globalCatalog, fixedQueue } =
        servicesCategory;
      setInitialValues({
        categoryId,
        name,
        serviceMaxQueue,
        description,
        globalCatalog,
        fixedQueue,
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [servicesCategory]);

  return (
    <Dialog
      open={open}
      title={
        isEditMode
          ? t("title.editServiceCategory")
          : t("title.createServiceCategory")
      }
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {/* {servicesCategory && (
                  <FormControlLabel label={t("label.idServiceCategory")}>
                    <Typography variant="body1">
                      {servicesCategory.categoryId}
                    </Typography>
                  </FormControlLabel>
                )} */}
                <FormControlLabel label={t("label.categoryId")}>
                  <OutlinedInput name="categoryId" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.serviceCategoryName")}>
                  <OutlinedInput name="name" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.serviceMaxQueue")}>
                  <OutlinedInput
                    name="serviceMaxQueue"
                    type="number"
                    formikControll
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.fixedNumberOfQueues")}>
                  <Checkbox name="fixedQueue" size="medium" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.globalCatalog")}>
                  <Checkbox name="globalCatalog" size="medium" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateServicesCategoryDialog;
