import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Divider,
  Section,
  Pagination,
  Typography,
  LinearProgress,
  Button,
  Grid,
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useQueryParams, StringParam } from "use-query-params";
import BusinessOperationsContext from "./businessOperationsContext";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof BusinessOperations
 * @component
 * @desc Business Operations Controller.
 */

const BusinessOperations = () => {
  const [loading, setLoading] = useState(false);
  const [queryParams] = useQueryParams({
    search: StringParam,
    siteId: StringParam,
    posId: StringParam,
    serviceId: StringParam,
    paymentServiceId: StringParam,
  });
  const [query, setQuery] =
    useState<JournalBusinessOperationsApi.GetBusinessOperationQuery>({
      ...queryParams,
      sort: "-createdAt",
    } as JournalBusinessOperationsApi.GetBusinessOperationQuery);
  const [limit, setLimit] = useState<number>();
  const [data, setData] =
    useState<JournalBusinessOperationsApi.BusinessOperationDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const { t } = useTranslation();

  const getBusinessOperations = useCallback(
    async (page?: number) => {
      setLoading(true);
      const currentPage = pagination ? pagination.page : 1;
      try {
        const {
          data: { data, meta },
        } = await api.businessOperationsJournal.getBusinessOperations(
          limit,
          page || currentPage,
          {
            ...query,
            sort: query?.sort || "-createdAt",
          }
        );
        data && setData(data);
        meta && setPagination(meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({
        ...query,
        sort: getSortStr(field, order),
      });
    },
    [query]
  );

  const onChangePagination = useCallback(
    (page: number) => {
      getBusinessOperations(page);
    },
    [query, limit]
  );

  const onChangeFilters = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const contextValue = useMemo(
    () => ({
      onUpdateItem: (
        businessOperation: JournalBusinessOperationsApi.BusinessOperationDto
      ) =>
        setData((data) =>
          data ? updateArrayItem(data, businessOperation, "orderId") : []
        ),
    }),
    [data]
  );

  useEffect(() => {
    query && getBusinessOperations();
  }, [query, limit]);

  return (
    <BusinessOperationsContext.Provider value={contextValue}>
      <Box>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.businessOperations")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={<Grid container>
            <Button
              color="default"
              variant="contained"
              style={{marginRight: "10px"}}
              onClick={() => {
                query && getBusinessOperations();
              }}
            >
              {t("button.refresh")}
            </Button>
            <Filter onChangeFilter={onChangeFilters} />
          </Grid>}
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto">
        <Table dataSource={data} onSort={onSort} />
      </Box>
      <Box>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </BusinessOperationsContext.Provider>
  );
};

export default BusinessOperations;
