import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class SystemLogJournalApi {
  rootPath: string = "/journal-business-operations";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.businessOperations');
  }

  getBusinessOperations = (limit: number = 10, page: number = 1, query?: JournalBusinessOperationsApi.GetBusinessOperationQuery): Promise<Api.GetAll<JournalBusinessOperationsApi.BusinessOperationDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });
  getClient = (orderId: string): Promise<Api.GetOne<JournalBusinessOperationsApi.ClientOperationDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${orderId}/client`);
  getOrder = (orderId: string): Promise<Api.GetOne<JournalBusinessOperationsApi.OrderOperationDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${orderId}/order`);
  getInvoice = (invoiceId: string): Promise<Api.GetOne<JournalBusinessOperationsApi.InvoiceOperationDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${invoiceId}/invoice`);
  getPayment = (invoiceId: string): Promise<Api.GetOne<JournalBusinessOperationsApi.PaymentOperationDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${invoiceId}/payment`);
  getReceipt = (invoiceId: string): Promise<Api.GetOne<{}>> =>
    this.instance.get(`${this.rootPath}/${invoiceId}/receipt`);
  // Filters
  getSitesFilter = (limit: number = 10, page: number = 1, query?: Api.GetSitesQuery ): Promise<Api.GetOne<string[]>> => 
    this.instance.get(`${this.rootPath}/sites`, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });
  getPaymentServicesFilter = (): Promise<Api.GetOne<string[]>> => 
    this.instance.get(`${this.rootPath}/payment_services`);
  getServiceCategoriesFilter = (): Promise<Api.GetOne<string[]>> => 
    this.instance.get(`${this.rootPath}/service_categories`);
}
