import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import { useTranslation } from "react-i18next";
import { formatUUID } from "~/utils/format";
import api from "~/api";

interface FilterProps {
  onChangeFilter: (
    data: IncidentsApi.GetIncidentsQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        {/* <SidebarGroup
          label={t("label.serviceCategory")}
          collapsed
          defaultOpen={true}
        >
          <SelectServiceCategory name="categoryId" formikControll fullWidth />
        </SidebarGroup> */}
        <SidebarGroup
          label={t("label.serviceCategory")}
          collapsed
          defaultOpen={true}
        >
          <SelectValue
            name="serviceCategory"
            loadOptions={() =>
              api.poses.getFilterByServiceCategories().then(({ data }) =>
                data.data.map(({ categoryId, name }) => ({
                  label: `[ ${formatUUID(categoryId)} ] ${name}`,
                  value: categoryId,
                }))
              )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <IncludeFieldGroup label={t("table.enabled")} fieldName="enabled" />
        <SidebarGroup
          label={t("label.paymentService")}
          collapsed
          defaultOpen={true}
        >
          <SelectValue
            name="paymentService"
            loadOptions={() =>
              api.poses.getFilterByPaymentServices().then(({ data }) =>
                data.data.map(({ paymentId, paymentServiceType }) => ({
                  label: `[ ${formatUUID(paymentId)} ] ${paymentServiceType}`,
                  value: paymentId,
                }))
              )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <IncludeFieldGroup
          label={t("title.openedIcidents")}
          fieldName="hasOpenedIncidents"
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
