import { useState, useCallback, useEffect } from "react";
import { Autocomplete, OutlinedInput, FieldError } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import { useTranslation } from "react-i18next";
import api from "~/api";
import _ from "lodash";

interface FormikSelectServiceProps {
  name: string;
  defaultInputValue?: string;
}
interface SelectUniqueServiceProps {
  inputValue?: string;
  name?: string;
  siteId?: string;
  onChange?: (service?: Api.UniqueService) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (options: Api.UniqueService[], state: any) => Api.UniqueService[];
}

const requestSearchService = _.memoize(() =>
  api.services.getUniqueServices()
);

const SelectUniqueService = ({
  name = "",
  onChange,
  onChangeValue,
  onBlur = () => {},
  error,
  filterOptions,
  inputValue,
}: SelectUniqueServiceProps) => {
  const [value, setValue] = useState<string | undefined>(inputValue || "");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Api.UniqueService[]>([]);
  const [service, setService] = useState<Partial<Api.UniqueService> | null>(null);
  const { t } = useTranslation();

  const onChangeInputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
    },
    [setValue, onChangeValue]
  );

  useEffect(() => {
    if (name && !inputValue) {
      setValue("");
      setService(null);
    }
  }, [name, inputValue]);

  useEffect(() => {
      setLoading(true);
      requestSearchService()
        .then(({ data }) => {
          setSearchResults(data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    return () => {
      requestSearchService.cache.clear && requestSearchService.cache.clear();
    };
  }, []);

  return (
    <Autocomplete
     
      value={service}
      inputValue={value}
      onChange={(_e, newValue) => {
        if (newValue) {
          setValue(`${newValue.serviceName} (ID:${newValue.serviceId})`);
          setService(newValue);
        } else {
          setValue("");
          setService(null);
        }
        onChange && onChange(newValue);
      }}
      getOptionSelected={(o: Api.UniqueService, value: Api.UniqueService) =>
        o.serviceId === value.serviceId
      }
      onBlur={onBlur}
      noOptionsText={t("text.noResultsFound")}
      options={searchResults}
      getOptionLabel={(o: Api.UniqueService) => o.serviceName}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <>
          <OutlinedInput
            style={{minWidth: "380px"}}
            loading={loading}
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={params.inputProps}
            error={!!error}
            onChange={onChangeInputValue}
          />
          { error && <FieldError>{error}</FieldError>}
        </>
      )}
    />
  );
};

const FormikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FormikSelectServiceProps & SelectUniqueServiceProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <SelectUniqueService
      {...field}
      {...other}
      onChange={(service?: Api.UniqueService) => {
        field.onChange({
          target: {
            name,
            value: service?.serviceId || "",
          },
        });
      }}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default FormikContainer;
