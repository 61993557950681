import Select, { SelectProps } from "~/components/UI/Select";
import { SOURCE_TYPE } from "~/api/common";
import { useTranslation } from "react-i18next";

const SelectIncidentSourceType = (props: SelectProps) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t("option.site"),
      value: SOURCE_TYPE.SITE,
    },
    {
      label: "POS",
      value: SOURCE_TYPE.POS,
    },
    {
      label: t("option.service"),
      value: SOURCE_TYPE.SERVICE,
    },
    {
      label: t("option.paymentService"),
      value: SOURCE_TYPE.PAYMENT_SERVICE,
    },
    {
      label: t("option.keyManagment"),
      value: SOURCE_TYPE.KEY_MANAGEMENT,
    },
    {
      label: t("option.user"),
      value: SOURCE_TYPE.USER,
    },
    {
      label: t("option.group"),
      value: SOURCE_TYPE.GROUP,
    },
    {
      label: t("option.businessOperation"),
      value: SOURCE_TYPE.BUSINESS_OPERATION,
    },
  ];

  return <Select {...props} options={options} variant="outlined" />;
};

export default SelectIncidentSourceType;
