import * as React from "react";

export type Options = {
  onClose?: (
    paymentService: PaymentServicesApi.PaymentServiceDto
  ) => void
} 

export type CreatePaymentService = (options?: Options) => void;

export type UpdatePaymentService = (
  paymentService?: PaymentServicesApi.PaymentServiceDto,
  options?: Options
) => void;


/**
 * The shape of the PaymentServiceDialogs context
 */
export interface PaymentServiceDialogsType {
  createPaymentService: CreatePaymentService;
  updatePaymentService: UpdatePaymentService;
}

/**
 * Throw error when PaymentServiceDialogs Context is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    "Attempted to call useIncidents outside of modal context. Make sure your app is rendered inside IncidentsProvider."
  );
};

/**
 * Payment Service Context Object
 */
export const PaymentServiceDialogsContext = React.createContext<PaymentServiceDialogsType>({
  createPaymentService: invariantViolation,
  updatePaymentService: invariantViolation
});

PaymentServiceDialogsContext.displayName = 'PaymentServiceDialogsContext';