import { Grid } from "~/components/UI";
import Card from "./Card";
import Skeleton from "../../CommonSkeleton";

interface ListProps {
  loading?: boolean;
  sites?: Api.SiteDto[];
  onSelectSite?: (site?: Api.SiteDto) => void;
}

const List = ({ loading, sites, onSelectSite }: ListProps) => {
  if (loading) {
    return <Skeleton />;
  }

  return (
    <Grid container direction="column" spacing={2}>
      {sites
        ? sites.map((s) => (
            <Grid item key={s.id}>
              <Card site={s} onClick={() => onSelectSite && onSelectSite(s)} />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default List;
