import Select, { SelectProps } from "~/components/UI/Select";
import { INCIDENT_TYPE } from "~/api/incidents/enums";
import { useTranslation } from "react-i18next";

const SelectIncidentType = (props: SelectProps) => {
  const { t } = useTranslation(["enums"]);

  const options = [
    {
      label: t("INCIDENT_TYPE.EXTERNAL"),
      value: INCIDENT_TYPE.EXTERNAL,
    },
    {
      label: t("INCIDENT_TYPE.INTERNAL"),
      value: INCIDENT_TYPE.INTERNAL,
    },
  ];

  return <Select {...props} options={options} variant="outlined" />;
};

export default SelectIncidentType;
