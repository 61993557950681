import { enumToI18nKey } from '~/utils/helpers';

export const ID_PREFIX_ENTITY = {
  SITE: "Site",
  POS: "POS",
  SERVICE: "Service",
  SERVICE_CATEGORY: "Service category",
  PAYMENT_SERVICE: "Payment Service",
  ROLE: "Role",
  USER: "User",
  GROUP: "Group",
  BUNDLES: "Bundle",
  KEY_MANAGEMENT_INTERNAL: "Key Management Internal"
} as { [key: string]: IdPrefixApi.eEntity }

export const ID_PREFIX_ENTITY_I18n = enumToI18nKey(ID_PREFIX_ENTITY, 'ID_PREFIX_ENTITY');