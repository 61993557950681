import { createContext } from 'react';

export interface ServicesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (service?: Api.ServiceDto) => void;
  onUpdateItem?: (service: Api.ServiceDto) => void;
  onUpdate?: (id: string, siteId: string, formData: Api.UpdateService) => Promise<void>;
  onDelete?: (service: Api.ServiceDto) => Promise<void>;
  showGoodsDialog?: (siteId?: string) => void;
  onCreateQRCode?: (service: Api.ServiceDto) => void;
}

const ServicesContext = createContext<ServicesContextProps>({});


export default ServicesContext;
