import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  Typography,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import api from "~/api";
import { saveFile } from '~/utils/helpers';

interface CreateQRZipDialogProps extends DialogProps {
  site?: Api.SiteDto;
  onClose?: () => void;
}

const CreateQRZipDialog = ({
  open,
  site,
  onClose,
}: CreateQRZipDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<string>();
  const fileName = `${site?.name}.zip`;

  const onCloseHandler = () => {
    setFile(undefined);
    onClose && onClose();
  }

  const onDownload = useCallback(() => {
    if(file) {
      saveFile(window.URL.createObjectURL(file), fileName)
    }
    onCloseHandler();
  }, [file, fileName]);

  const getQRZip = async () => {
    try {
      setError(undefined)
      setLoading(true);

      const response: any = await api.sites.getQRZip(site?.siteId);
      const file = new File([response.data], fileName, {
        type: response.headers["content-type"],
      })

      setFile(file);
    } catch (error: any) {
      if (Array.isArray(error.errors)) {
        const errors = error.errors as Array<Api.Error>;
        setError(errors.map(({ message }) => message).join('\n'))
      }
    } finally {
      setLoading(false);
    }
  }

  const QRStatus = useMemo(() => {
    if (file) {
      return t("title.qrCodesGenerated");
    } else if (error) {
      return t("label.error");
    } else {
      return " ";
    }
  }, [t, file, error]);

  useEffect(() => {
    site && getQRZip();
  }, [site]);

  return (
    <Dialog
      title={QRStatus}
      open={open}
      onClose={onCloseHandler}
      closable={!loading}
      maxWidth="xs"
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {
            loading &&
            <CircularProgress />
          }
          {
            !loading && file && <Typography>{fileName}</Typography>
          }
          {
            error &&
            <>
              <Typography color="error">{t('text.createQRCodeError')}</Typography>
              <Typography color="error">{error}.</Typography>
            </>
          }
        </Box>
      </DialogContent>
      <DialogActions>
          {
            !loading && file &&
            <Button
              loading={loading}
              onClick={onDownload}
              color="primary"
              variant="contained"
            >
              {t("button.download")}
            </Button>
          }
          {
            !loading && 
            <Button
              disabled={loading}
              onClick={onCloseHandler}
              variant="contained"
            >
              {t("button.close")}
            </Button>
          }
      </DialogActions>
    </Dialog>
  );
};

export default CreateQRZipDialog;
