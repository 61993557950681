import { memo, useCallback } from "react";
import { Button, ButtonProps } from "~/components/UI";
import { SOURCE_TYPE } from "~/api/common";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface GoToRelatedProcessesButtonProps extends ButtonProps {
  sourceType: Api.eSourceType;
  sourceId: string;
}

const GoToRelatedProcessesButton = ({
  sourceType,
  sourceId,
  ...otherProps
}: GoToRelatedProcessesButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToRelatedProcesses = useCallback(() => {
    let link = ROUTES.BUSINESS_OPERATIONS;

    switch (sourceType) {
      case SOURCE_TYPE.SITE:
        link += `?siteId=${sourceId}`;
        break;
      case SOURCE_TYPE.POS:
        link += `?posId=${sourceId}`;
        break;
      case SOURCE_TYPE.SERVICE:
        link += `?serviceId=${sourceId}`;
        break;
      case SOURCE_TYPE.PAYMENT_SERVICE:
        link += `?paymentServiceId=${sourceId}`;
        break;
      default:
        link = "";
    }
    history.push(link);
  }, [history, sourceType, sourceId]);

  return (
    <Button {...otherProps} onClick={goToRelatedProcesses}>
      {t("button.relatedProcesses")}
    </Button>
  );
};

export default memo(GoToRelatedProcessesButton);
