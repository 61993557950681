import { useState, useContext, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Icon,
  Button,
  ButtonGroup,
  Label,
  LinearProgress,
} from "~/components/UI";
import AllIncidentsButton from "~/components/Incidents/AllIncidentsButton";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ServicesContext from "./servicesContext";
import { useHistory, generatePath } from "react-router";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";
import GoToRelatedProcessesButton from "~/components/GoToRelatedProcessesButton";
import { SOURCE_TYPE } from "~/api/common";
import api from "~/api";

interface ServiceDetailsProps {
  service: Api.ServiceDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      minHeight: 280,
    },
  })
)(Grid);

/**
 * @memberof Services
 * @component
 * @desc Render detailed information about a service.
 * @property {Api.ServiceDto} service - Service data;
 */

const ServiceDetails = ({ service }: ServiceDetailsProps) => {
  const { showGoodsDialog } = useContext(ServicesContext);
  const [loading, setLoading] = useState(false);
  const [serviceDetails, setServiceDetails] = useState<Api.ServiceDto>();
  const { t } = useTranslation();
  const history = useHistory();

  const getDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.services.getServiceDetails(
        service.serviceId,
        service.siteId
      );
      setServiceDetails(data.data);
    } finally {
      setLoading(false);
    }
  }, [service]);

  useEffect(() => {
    if (service) {
      getDetails();
    }
  }, [service]);

  const goToMetrics = useCallback(() => {
    history.push(
      generatePath(ROUTES.SERVICE_METRICS, { serviceId: service.serviceId }),
      {
        service,
      }
    );
  }, [history, service]);

  if (loading) return <LinearProgress />;

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">{t("title.details")}</Typography>
      </Box>
      <Box p={4} flexGrow={1}>
        <Grid container wrap="nowrap">
          <Grid item md={12}>
            <Label text={t("label.globalCatalog")}>
              <>
                <Box display="inline" mr={2}>
                  {service.globalCatalog ? (
                    <Icon
                      name="CheckCircleOutline"
                      size="small"
                      color="success"
                    />
                  ) : (
                    <Icon name="DangerousOutline" size="small" color="error" />
                  )}
                </Box>
                {service.globalCatalog ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      showGoodsDialog && showGoodsDialog(service.siteId);
                    }}
                  >
                    {t("button.showGoods")}
                  </Button>
                ) : null}
              </>
            </Label>
            <Label text={t("label.serviceCategory")}>{service.category}</Label>
            <Label text={t("label.serviceMaxQueue")}>
              {service.serviceMaxQueue}
            </Label>
            <Label text={t("label.availableForDelivery")}>
              {service.isAvailableForDelivery ? (
                <Icon name="CheckCircleOutline" size="small" color="success" />
              ) : (
                <Icon name="DangerousOutline" size="small" color="error" />
              )}
            </Label>
          </Grid>
          <Grid item md={12}>
            <Label text={t("label.crossSellBundles")}>
              {serviceDetails &&
                serviceDetails.crossSales &&
                serviceDetails.crossSales.map((b) => b.name).join(", ")}
            </Label>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <ButtonGroup>
          <AllIncidentsButton variant="outlined" />
          <GoToRelatedProcessesButton
            sourceId={service.serviceId}
            sourceType={SOURCE_TYPE.SERVICE}
            variant="outlined"
          />
          <Button variant="outlined" onClick={goToMetrics}>
            {t("button.metrics")}
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
};

export default ServiceDetails;
