import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import BundlesContext from "./bundlesContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface BundleOptionsProps {
  bundle: Api.BundleDto;
  hideEditOptions?: boolean;
}

/**
 * @memberof POSes
 * @component
 * @desc Bundle options. Using a BundlesContext.
 * @property {Api.BundleDto} bundle - Bundle data;
 * @requires BundlesContext
 */

const POSOptions = ({ bundle, hideEditOptions }: BundleOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdateItem, onDelete, onAddService } = useContext(BundlesContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.POS);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (bundle.incidents) {
        bundle.incidents.count++;
        bundle.incidents.incidentIds.push(incident.incidentId);
      } else {
        bundle.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(bundle);
    },
    [bundle, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_BUNDLE,
      PERMISSION.DELETE_BUNDLE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_BUNDLE) || hideEditOptions,
      onClick: () => onEdit && onEdit(bundle),
    },
    {
      label: t("option.addToServices"),
      hidden: !hasAll(PERMISSION.DELETE_POS) || hideEditOptions,
      onClick: () => onAddService && onUpdateHandler(onAddService(bundle)),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.EDIT_BUNDLE) || hideEditOptions,
      onClick: () => onDelete && onUpdateHandler(onDelete(bundle)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(bundle.id, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default POSOptions;
