import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { Grid, Typography, CardActionArea } from "@material-ui/core";
import { Collapse, Icon, IconButton } from ".";

import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface SectionProps {
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  collapsed?: boolean;
  className?: string;
  openByDefault?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "block",
    },
    header: {
      paddingTop: "2px",
      position: "relative",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary,
      textTransform: "capitalize",
    },
    extra: {},
    hidden: {
      borderTop: `1px solid ${theme.palette.divider}`,
      visibility: "visible",
    },
    hideBtn: {
      fontSize: 10,
    },
  })
);

const Header = ({
  title,
  extra,
  collapsed,
  open,
}: {
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  collapsed?: boolean;
  open?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.header}
    >
      {typeof title === "string" ? (
        <Typography variant="h4">{title}</Typography>
      ) : (
        title
      )}
      <div className={classes.extra} onMouseDown={(e) => e.stopPropagation()}>
        {extra && extra}
        {collapsed && (
          <IconButton className={classes.hideBtn}>
            {open ? <Icon name="ArrowTop" /> : <Icon name="ArrowDown" />}
          </IconButton>
        )}
      </div>
    </Grid>
  );
};

const Section = ({
  title,
  extra,
  children,
  collapsed,
  openByDefault,
  className,
}: SectionProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(openByDefault);

  const сlickHandler = useCallback(() => {
    if (collapsed) {
      setOpen((open) => !open);
    }
  }, [collapsed]);

  return (
    <div className={clsx(classes.root, className)}>
      {collapsed ? (
        <CardActionArea onClick={сlickHandler}>
          <Header {...{ title, extra, collapsed, open }} />
        </CardActionArea>
      ) : (
        <Header {...{ title, extra, collapsed, open }} />
      )}
      {collapsed ? (
        <Collapse
          in={open}
          classes={{
            hidden: classes.hidden,
          }}
        >
          {children}
        </Collapse>
      ) : (
        children
      )}
    </div>
  );
};

export default React.memo(Section);
