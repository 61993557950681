import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Divider,
  Section,
  Pagination,
  Typography,
  LinearProgress,
} from "~/components/UI";
import UsersActivitiesContext from "./usersActivitiesContext";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { getSortStr } from "~/utils/helpers";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof UsersActivities
 * @component
 * @desc UsersActivities Controller.
 */

const UsersActivities = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] =
    useState<UserActivityJournalApi.GetUserActivityQuery>({
      sort: "-createdAt",
    });
  const [limit, setLimit] = useState<number>();
  const [data, setData] = useState<UserActivityJournalApi.UserActivityDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const { t } = useTranslation();

  const getUsersActivities = useCallback(
    async (page?: number) => {
      setLoading(true);
      const currentPage = pagination ? pagination.page : 1;

      try {
        const {
          data: { data, meta },
        } = await api.userActivityJournal.getUsersActivities(
          limit,
          page || currentPage,
          {
            ...query,
            sort: query?.sort || "-createdAt",
          }
        );
        data && setData(data);
        meta && setPagination(meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = useCallback(
    (page: number) => {
      getUsersActivities(page);
    },
    [query, limit]
  );

  const onChangeFilters = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onRestoreObject = async (
    usersActivity: UserActivityJournalApi.UserActivityDto
  ) => {
    await api.userActivityJournal.restoreObject(
      usersActivity.sourceId,
      usersActivity.sourceType
    );
    getUsersActivities();
  };

  useEffect(() => {
    query && getUsersActivities();
  }, [query, limit]);

  return (
    <UsersActivitiesContext.Provider value={{ onRestoreObject }}>
      <Box>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.userActivityLog")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={<Filter onChangeFilter={onChangeFilters} />}
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto">
        <Table dataSource={data} onSort={onSort} />
      </Box>
      <Box>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </UsersActivitiesContext.Provider>
  );
};

export default UsersActivities;
