import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class ServicesCategoriesApi {
  rootPath: string = "/service-categories";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.servicesCategories');
  }

  addServicesCategory = (data: ServicesCategoriesApi.AddServicesCategory): Promise<Api.GetOne<ServicesCategoriesApi.ServicesCategoryDto>> =>
    this.instance.post(this.rootPath, data);

  updateServicesCategory = (categoryId: string, data: ServicesCategoriesApi.UpdateServicesCategory): Promise<Api.GetOne<ServicesCategoriesApi.ServicesCategoryDto>> =>
    this.instance.put(`${this.rootPath}/${categoryId}`, data);

  getServicesCategories = (limit: number = 10, page: number = 1, query?: ServicesCategoriesApi.GetServicesCategoriesQuery): Promise<Api.GetAll<ServicesCategoriesApi.ServicesCategoryDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  deleteServicesCategory = (categoryId: string): Promise<{}> =>
    this.instance.delete(`${this.rootPath}/${categoryId}`);
}
