import { createContext } from 'react';

export interface UsersActivitiesContextProps {
  onRestoreObject?: (usersActivity: UserActivityJournalApi.UserActivityDto) => Promise<void> | void;
}

/**
 * @memberof UsersActivities
 * @component
 * @desc React context for providing methods of UsersActivities to child components.
 * @property {Function} onRestoreObject - callback for restoring an object.
 */

const UsersActivitiesContext = createContext<UsersActivitiesContextProps>({});


export default UsersActivitiesContext;
