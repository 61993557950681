import { useState, useCallback } from "react";
import { Grid, Icon, Typography, IconButton, Spin } from "~/components/UI";
import BaseCard from "./BaseCard";
import { eTimeInterval } from "./TimeIntervalSelector";
import ClientsMetric from "./Charts/ClientsMetric";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";

interface ClientsProps {
  title?: string;
  onUpdate: (
    queryParams: any
  ) => Promise<MetricsAPI.ClientNetworkDto | undefined>;
  onUpdateOnline: (queryParams: any) => Promise<number | undefined>;
}

const Clients = ({ title, onUpdate, onUpdateOnline }: ClientsProps) => {
  const [loading, setLoading] = useState(false);
  const [latestQuery, setLatestQuery] = useState<MetricsAPI.GetMetricQuery>();
  const [data, setData] = useState<MetricsAPI.ClientNetworkDto>();
  const [onlineData, setOnlineData] = useState<number>();
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const getClientsOnline = async (query: MetricsAPI.GetMetricQuery) => {
    setLatestQuery(query);
    try {
      const count = await onUpdateOnline({
        ...query,
      });
      setOnlineData(count);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getClients = async (query: MetricsAPI.GetMetricQuery) => {
    setLatestQuery(query);
    getClientsOnline(query);
    try {
      const data = await onUpdate({
        ...query,
      });
      setData(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const onRefresh = useCallback(async () => {
    if (!latestQuery) return;
    setLoading(true);
    try {
      await getClientsOnline(latestQuery);
    } finally {
      setLoading(false);
    }
  }, [latestQuery]);

  if (!hasAll(PERMISSION.READ_CLIENTS_METRICS)) return null;

  return (
    <Grid item sm={12} md={6} xs={3}>
      <BaseCard
        title={title || "Clients"}
        timeIntervals={[
          eTimeInterval.LAST_MINUTE,
          eTimeInterval.LAST_HOUR,
          eTimeInterval.LAST_24_HOURS,
        ]}
        onUpdate={getClients}
        extra={
          <Grid container alignItems="center">
            {data && (
              <>
                <Typography component="span" variant="body2" color="primary">
                  {`${typeof onlineData === "number" ? onlineData : ""} ${t(
                    "text.clientsOnline"
                  )}`}
                </Typography>
                <IconButton disabled={loading} onClick={onRefresh}>
                  <Spin spinning={loading}>
                    <Icon name="Refresh" />
                  </Spin>
                </IconButton>
              </>
            )}
          </Grid>
        }
      >
        {(data) =>
          data ? <ClientsMetric period={data.period} list={data.list} /> : null
        }
      </BaseCard>
    </Grid>
  );
};

export default Clients;
