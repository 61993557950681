import { useMemo, useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  Typography,
  LinearProgress,
} from "~/components/UI";
import Table from "./Table";
import { toast } from "react-toastify";
import PaymentServicesContext from "./paymentServicesContext";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { useTranslation } from "react-i18next";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useQueryParams, StringParam } from "use-query-params";
import Filter from "./Filter";
import useConfirmDialog from "~/hook/useConfirmDialog";
import usePaymentServiceDialog from "~/components/PaymentService/Dialogs/usePaymentServiceDialogs";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof PaymentServices
 * @component
 * @desc Payment Services controller.
 * @requires PaymentServicesContext
 */

const PaymentServices = () => {
  const [paymentServices, setPaymentServices] =
    useState<PaymentServicesApi.PaymentServiceDto[]>();
  const [query, setQuery] =
    useState<PaymentServicesApi.GetAllPaymentServicesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    paymentId: StringParam,
  });
  const { t } = useTranslation();
  const { createPaymentService } = usePaymentServiceDialog();

  const getPaymentServices = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const { data } = await api.paymentServices.getPaymentServices(
          limit,
          page || currentPage,
          query
        );
        data.data && setPaymentServices(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCreated = useCallback(() => {
    getPaymentServices();
  }, []);

  const onUpdateItem = useCallback(
    (pService: PaymentServicesApi.PaymentServiceDto) => {
      setPaymentServices((services) =>
        services ? updateArrayItem(services, pService, "paymentId") : []
      );
    },
    [paymentServices]
  );

  const onUpdate = useCallback(
    async (id: string, formData: PaymentServicesApi.UpdatePaymentService) => {
      try {
        const {
          data: { data: pService },
        } = await api.paymentServices.updatePaymentService(id, formData);
        onUpdateItem(pService);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [paymentServices, t]
  );

  const onDelete = useCallback(
    async (paymentService: PaymentServicesApi.PaymentServiceDto) => {
      await deleteConfirm(paymentService?.paymentServiceType);
      try {
        await api.paymentServices.deletePaymentService(
          paymentService.paymentId
        );
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      } finally {
        getPaymentServices();
      }
    },
    [t]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onUpdate,
      onDelete,
    }),
    [paymentServices]
  );

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getPaymentServices(page);
  };

  useEffect(() => {
    setQuery(queryParams as PaymentServicesApi.GetAllPaymentServicesQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getPaymentServices();
  }, [query, limit]);

  return (
    <PaymentServicesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.paymentServices")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_PAYMENT_SERVICE}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={() => createPaymentService({ onClose: onCreated })}
                >
                  {t("button.addPayment")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table dataSource={paymentServices} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </PaymentServicesContext.Provider>
  );
};

export default PaymentServices;
