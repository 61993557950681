import { StatusIndicator } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { USER_ACTIVITY_STATUS } from "~/api/userActivityJournal/enums";

interface UserActivityStatusProps {
  status: UserActivityJournalApi.eUserActivityStatus;
}

/**
 * @memberof UserActivity
 * @component
 * @desc Render status of UserActivity.
 * @property {UserActivityJournalApi.eUserActivityStatus} status - UserActivity status;
 */

const UserActivityStatus = ({ status }: UserActivityStatusProps) => {
  const { t } = useTranslation(["enums"]);

  switch (status) {
    case USER_ACTIVITY_STATUS.SUCCESS:
      return (
        <StatusIndicator
          status="active"
          label={t("USER_ACTIVITY_STATUS.SUCCESS")}
        />
      );
    case USER_ACTIVITY_STATUS.FAILED:
      return (
        <StatusIndicator
          status="error"
          label={t("USER_ACTIVITY_STATUS.FAILED")}
        />
      );
    default:
      return null;
  }
};

export default UserActivityStatus;
