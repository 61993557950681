import { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface PaymentServicesResultProps {
  data?: Api.SearchResult<PaymentServicesApi.PaymentServiceDto>;
  searchQuery: string;
}

const PaymentService = ({
  paymentService,
  searchQuery,
}: {
  searchQuery: string;
  paymentService: PaymentServicesApi.PaymentServiceDto;
}) => {
  const openPaymentService = useCallback(() => {
    history.push(
      `${ROUTES.PAYMENT_SERVICES}?paymentId=${paymentService.paymentId}`
    );
  }, [paymentService]);

  return (
    <ListItem button onClick={openPaymentService}>
      <Typography variant="body2" color="textSecondary">
        <Typography variant="body2" component="span" color="primary">
          ID
        </Typography>
        :{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={paymentService.paymentId}
        />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        ,{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={paymentService.paymentServiceType}
        />
      </Typography>
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the Payment Services catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<PaymentServicesApi.PaymentServiceDto>} data - api response
 */

const PaymentServices = ({ data, searchQuery }: PaymentServicesResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.PAYMENT_SERVICES}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogPaymentServices")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((s, index) => (
            <PaymentService
              key={index}
              searchQuery={searchQuery}
              paymentService={s}
            />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default PaymentServices;
