import { Typography, Box, Section } from "~/components/UI";
import { useTranslation } from "react-i18next";
import InvoiceDuration from "./InvoiceDuration";
import QueuesCreated from "./QueuesCreated";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

interface MetricsProps {
  service?: Api.ServiceDto;
}

const Metrics = ({ service }: MetricsProps) => {
  const { t } = useTranslation();

  if (!service) return null;

  return (
    <Box pt={4} pr={2}>
      <Section
        title={
          <Typography variant="h4">
            {service && `${t("title.metricsOf")} ${service.serviceName}`}
          </Typography>
        }
      />
      <NoPermissionsForMetrics>
        <CanIUse permissions={PERMISSION.READ_INVOICING_DURATION_METRICS}>
          <MetricGroup title={t("title.invoicingDuration")}>
            <InvoiceDuration service={service} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_QUEUES_METRICS}>
          <MetricGroup title={t("title.queues")}>
            <QueuesCreated service={service} />
          </MetricGroup>
        </CanIUse>
      </NoPermissionsForMetrics>
    </Box>
  );
};

export default Metrics;
