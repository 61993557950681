import { Grid, Typography, Box, StatusIndicator } from "~/components/UI";
import Card from "../../CommonCard";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import SiteOptions from "~/pages/Catalogs/Sites/Sites/Components/SiteOptions";

interface CardProps {
  site: Api.SiteDto;
  onClick: () => void;
}

const SiteCard = ({ site, onClick }: CardProps) => {
  return (
    <Card onClick={onClick}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            ID: {site.siteId}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IncidentsIcon incidents={site.incidents} />
            </Grid>
            <Grid item>
              <SiteOptions site={site} hideEditOptions={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={1}>
        <Typography variant="body1">
          <StatusIndicator status={site.enabled ? "active" : "disabled"} />
          {site.name}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle1">{site.address}</Typography>
      </Box>
    </Card>
  );
};

export default SiteCard;
