import CollectedMoneyCard from "~/components/Metrics/CollectedMoneyCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const CollectedMoney = () => {
  const { t } = useTranslation();
  const getCollectedMoney = async (params: MetricsAPI.GetMetricQuery) => {
    try {
      const { data } = await api.metrics.getMoneyByNetwork(params);
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CollectedMoneyCard
      title={`${t("title.amountOfCollectedMoneyBy")} ${t("text.network")}`}
      onUpdate={getCollectedMoney}
    />
  );
};

export default CollectedMoney;
