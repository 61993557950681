import { useCallback } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface POSesResultProps {
  data?: Api.SearchResult<Api.PosDto>;
  searchQuery: string;
}

const POS = ({
  pos,
  searchQuery,
}: {
  searchQuery: string;
  pos: Api.PosDto;
}) => {
  const { t } = useTranslation();

  const openPOS = useCallback(() => {
    history.push(`${ROUTES.POSES}?posId=${pos.posId}`);
  }, [pos.posId]);

  return (
    <ListItem button onClick={openPOS}>
      <Typography variant="body2" color="textSecondary">
        <Typography variant="body2" component="span" color="primary">
          {t("label.posId")}
        </Typography>
        :{" "}
        <Highlighter searchWords={[searchQuery]} textToHighlight={pos.posId} />
      </Typography>
      <Divider />
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the POSes catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<Api.PosDto>} data - api response
 */

const POSes = ({ data, searchQuery }: POSesResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.POSES}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogPoses")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((p, index) => (
            <POS key={index} searchQuery={searchQuery} pos={p} />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default POSes;
