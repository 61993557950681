import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import api from "~/api";

interface FormikSelectBundleProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (options: Api.BundleDto[], state: any) => Api.BundleDto[];
}

interface SelectBundleProps {
  inputValue?: string;
  name?: string;
  onChange?: (bundle?: Api.BundleDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (options: Api.BundleDto[], state: any) => Api.BundleDto[];
}

const requestSearchBundle = _.memoize((search: string) =>
  api.bundles.getAll(99, 1, { search })
);

export const SelectBundle = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue,
  }: SelectBundleProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Api.BundleDto[]>([]);
    const [bundle, setBundle] = useState<Partial<Api.BundleDto> | null>(null);
    const { t } = useTranslation();

    const searchBundles = useCallback(
      _.debounce((id: string) => {
        setLoading(true);
        requestSearchBundle(id)
          .then(({ data }) => {
            setSearchResults(data.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500),
      []
    );

    const onChangeInputValue = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value);
        onChangeValue && onChangeValue(e.target.value);
        searchBundles(e.target.value || "");
      },
      []
    );

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.name);
          setBundle(newValue);
        } else {
          setValue("");
          setBundle(null);
        }
        onChange && onChange(newValue);
      },
      [onChange]
    );

    const getOptionSelected = useCallback(
      (o: Api.BundleDto, value: Api.BundleDto) => o.id === value.id,
      []
    );

    const getOptionLabel = useCallback((o: Api.BundleDto) => o.id, []);

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setBundle(null);
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchBundles("");
      return () => {
        requestSearchBundle.cache.clear && requestSearchBundle.cache.clear();
      };
    }, []);

    return (
      <Autocomplete
        value={bundle}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText={t("text.noResultsFound")}
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            loading={loading}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FormikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FormikSelectBundleProps & SelectBundleProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChange = useCallback(
    (bundle?: Api.BundleDto) => {
      field.onChange({
        target: {
          name,
          value: bundle || "",
        },
      });
    },
    [field]
  );

  return (
    <SelectBundle
      {...field}
      {...other}
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={field.value || defaultInputValue}
    />
  );
};

export default React.memo(FormikContainer);
