import { createContext } from 'react';

export interface BundlesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (bundle: Api.BundleDto) => void;
  onUpdateItem?: (bundle: Api.BundleDto) => void;
  onUpdate?: (id: string, formData: Api.UpdateBundle) => Promise<void>;
  onDelete?: (bundle: Api.BundleDto) => Promise<void>;
  onAddService?:  (bundle: Api.BundleDto) => void;
}

/**
 * @memberof Bundles
 * @component
 * @desc React context for providing methods of bundlees to child components.
 * @property {Function} onSelect - callback for update selected POSes;
 * @property {Function} onEdit - callback for open the Edit POS dialog. (bundle: Api.PosDto) => void
 * @property {Function} onUpdate - callback for update a POS. (id: string, formData: Api.UpdatePOS) => Promise<void>
 * @property {Function} onDelete - callback for the delete a POS. (bundle: Api.PosDto) => Promise<void>
 */

const BundlesContext = createContext<BundlesContextProps>({});


export default BundlesContext;
