import { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface SitesResultProps {
  data?: Api.SearchResult<Api.SiteDto>;
  searchQuery: string;
}

const Site = ({
  site,
  searchQuery,
}: {
  searchQuery: string;
  site: Api.SiteDto;
}) => {
  const openSite = useCallback(() => {
    history.push(`${ROUTES.SITES}?siteId=${site.siteId}`);
  }, [site.siteId]);

  return (
    <ListItem button onClick={openSite}>
      <Typography variant="body2" color="textSecondary">
        [
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={site.siteId}
        />
        ] ,{" "}
        <Highlighter searchWords={[searchQuery]} textToHighlight={site.name} />,{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={site.address}
        />
      </Typography>
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the Sites catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<Api.SiteDto>} data - api response
 */

const Sites = ({ data, searchQuery }: SitesResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.SITES}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogSites")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((s, index) => (
            <Site key={index} searchQuery={searchQuery} site={s} />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default Sites;
