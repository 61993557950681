import { useCallback } from "react";
import Services from "./Components/Services";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @namespace Services
 */

const ServicesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.services") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Services />
    </>
  );
};

export default ServicesPage;
