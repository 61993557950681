import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  Typography,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import api from "~/api";
import { saveFile } from '~/utils/helpers';

interface CreateQRDialogProps extends DialogProps {
  service?: Api.ServiceDto;
  onClose?: () => void;
}

const CreateQRDialog = ({
  open,
  service,
  onClose,
}: CreateQRDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setQRCodeData] = useState<Api.QRCodeData>();
  const [error, setError] = useState<string>();

  const onCloseHandler = () => {
    setQRCodeData(undefined);
    onClose && onClose();
  }

  const onDownload = useCallback(() => {
    qrCodeData && saveFile(qrCodeData.dataUrl, qrCodeData.filename)
    onCloseHandler();
  }, [qrCodeData]);

  const getQRCode = async () => {
    try {
      setError(undefined)
      setLoading(true);

      const { data } = await api.services.getQRCode(service?.serviceId, service?.siteId);

      setQRCodeData(data.data)
    } catch (error: any) {
      if (Array.isArray(error.errors)) {
        const errors = error.errors as Array<Api.Error>;
        setError(errors.map(({ message }) => message).join('\n'))
      }
    } finally {
      setLoading(false);
    }
  }

  const QRStatus = useMemo(() => {
    if (qrCodeData?.dataUrl) {
      return t("title.qrCodeGenerated");
    } else if (error) {
      return t("label.error");
    } else {
      return " ";
    }
  }, [t, qrCodeData, error]);

  useEffect(() => {
    service && getQRCode();
  }, [service]);

  return (
    <Dialog
      title={QRStatus}
      open={open}
      onClose={onCloseHandler}
      closable={!loading}
      maxWidth="xs"
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {
            loading &&
            <CircularProgress />
          }
          {
            !loading && qrCodeData?.dataUrl &&
            <img
              src={qrCodeData?.dataUrl}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              alt="service qr code"
            />
          }
          {
            error &&
            <>
              <Typography color="error">{t('text.createQRCodeError')}</Typography>
              <Typography color="error">{error}.</Typography>
            </>
          }
        </Box>
      </DialogContent>
      <DialogActions>
          {
            !loading && qrCodeData?.dataUrl &&
            <Button
              loading={loading}
              onClick={onDownload}
              color="primary"
              variant="contained"
            >
              {t("button.download")}
            </Button>
          }
          {
            !loading && 
            <Button
              disabled={loading}
              onClick={onCloseHandler}
              variant="contained"
            >
              {t("button.close")}
            </Button>
          }
      </DialogActions>
    </Dialog>
  );
};

export default CreateQRDialog;
