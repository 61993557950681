import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import Table, { TableProps } from "~/components/UI/Table/Table";
import BusinessOperationDetails from "./BusinessOperationDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime, currencyString } from "~/utils/format";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";
import {
  BUSINESS_OPERATION_STAGE_TYPE_I18n,
  BUSINESS_OPERATION_STATUS_I18n,
} from "~/api/journalBusinessOperations/enums";
import BusinessOperationOptions from "./BusinessOperationOptions";
import EnumValue from "~/components/EnumValue";

interface BusinessOperationsTableProps
  extends Omit<
    TableProps<JournalBusinessOperationsApi.BusinessOperationDto>,
    "columns" | "rowSelection"
  > {
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const statusColor = {
  "COMPLETED": "rgb(39, 78, 177)",
  "ACCEPTED": "rgb(97, 168, 56)",
  "READY": "rgb(255,210,0)"
} as unknown as { [key: string]: JournalBusinessOperationsApi.eStatus }

const defaultColumns = [
  {
    key: "createdAt",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "siteId",
    i18nKey: "siteId",
    render: (id: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.SITE} sourceId={id}>
        {id || " "}
      </LinkToObject>
    ),
  },
  {
    key: "posId",
    i18nKey: "posId",
    render: (id: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.POS} sourceId={id}>
        {id || " "}
      </LinkToObject>
    ),
  },
  {
    key: "serviceId",
    i18nKey: "serviceId",
  },  
  {
    key: "stage",
    i18nKey: "stage",
    render: (stage: string) => (
      <EnumValue value={stage} enumI18n={BUSINESS_OPERATION_STAGE_TYPE_I18n} />
    ),
  },
  {
    key: "origin",
    i18nKey: "origin"
  },
  {
    key: "status",
    i18nKey: "status",
    render: (status: string) => (
      <EnumValue
        value={status}
        enumI18n={BUSINESS_OPERATION_STATUS_I18n}
        color={statusColor[status] || "rgb(244, 67, 54)"}
      />
    ),
  },
  {
    key: "methodOfPayment",
    i18nKey: "methodOfPayment",
  }, 
  {
    key: "totalAmount",
    i18nKey: "totalAmount",
    cellOptions: {
      width: 150,
    },
    render: (totalAmount: number) => currencyString(totalAmount),
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render table of Business Operations.
 * @property {JournalBusinessOperationsApi.BusinessOperationDto[]} dataSource - Business Operations data
 */

const BusinessOperationsTable = ({
  onSort,
  ...props
}: BusinessOperationsTableProps) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            businessOperation: JournalBusinessOperationsApi.BusinessOperationDto
          ) => (
            <BusinessOperationOptions businessOperation={businessOperation} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (
          data: JournalBusinessOperationsApi.BusinessOperationDto
        ) => <BusinessOperationDetails operationId={data.orderId} data={data} />,
      }}
      onRequestSort={onSort}
      rowKey="orderId"
      stickyHeader
    />
  );
};

export default BusinessOperationsTable;
