import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress,
  Typography,
} from "~/components/UI";
import ListLayoutSwitcher, {
  LayoutType,
  eLayoutType,
} from "~/components/ListLayoutSwitcher";
import Filter from "./Filter";
import Table from "./Table";
import Tiles from "./Tiles";
import UpdateBundleDialog from "./UpdateBundleDialog";
import { toast } from "react-toastify";
import { useQueryParams, StringParam } from "use-query-params";
import BundlesContext from "./bundlesContext";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";
import AddService from "./AddService";

interface ListProps {
  type: LayoutType;
  data?: Api.BundleDto[];
}

const List = React.memo(({ type, data }: ListProps) => {
  return type === eLayoutType.TABLE ? (
    <Table dataSource={data} />
  ) : (
    <Tiles list={data} />
  );
});

/**
 * @memberof Bundles
 * @component
 * @desc Bundles Controller.
 * @requires BundlesContext
 */

const BundlesList = () => {
  const [layoutType, setLayoutType] = useState(eLayoutType.TABLE);
  const [bundles, setBundles] = useState<Api.BundleDto[]>();
  const [selectedBundles, setSelectedBundles] = useState<string[]>([]);
  const [query, setQuery] = useState<Api.GetBundlesQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean | undefined>();
  const [openAddDialog, setOpenAddDialog] = useState<boolean | undefined>();
  const [editBundle, setEditBundle] = useState<Api.BundleDto>();
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    id: StringParam,
  });
  const { t } = useTranslation();

  const getBundles = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const { data } = await api.bundles.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setBundles(data.data);
        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCreateGasStation = useCallback(() => {
    setEditBundle(undefined);
    setOpenEditDialog(true);
  }, []);

  const onEdit = useCallback((data: Api.BundleDto) => {
    setEditBundle(data);
    setOpenEditDialog(true);
  }, []);

  const onAddService = useCallback((data: Api.BundleDto) => {
    setEditBundle(data);
    setOpenAddDialog(true);
  }, []);


  const onUpdateItem = useCallback((bundle: Api.BundleDto) => {
    setBundles((bundles) => (bundles ? updateArrayItem(bundles, bundle, "bundleId") : []));
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: Api.UpdateBundle) => {
      try {
        const {
          data: { data: bundle },
        } = await api.bundles.updateBundle(id, formData);
        onUpdateItem(bundle);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [bundles, t]
  );

  const onDelete = useCallback(
    async (bundle: Api.BundleDto) => {
      await deleteConfirm(bundle?.id);
      await api.bundles.deleteBundle(bundle.id);
      getBundles();
      toast.success(t("text.recordSuccessfullyDeleted"));
    },
    [t]
  );

  const onDeleteBundles = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedBundles?.length }));
    await api.bundles.deleteBundles(selectedBundles);
    getBundles();
    toast.success(t("text.recordsSuccessfullyDeleted"));
    setSelectedBundles([]);
  }, [t, selectedBundles]);

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getBundles(page);
  };

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const contextValue = React.useMemo(
    () => ({
      onSort,
      onSelect: setSelectedBundles,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete,
      onAddService
    }),
    [bundles]
  );

  const onCloseEditDialog = (result?: boolean) => {
    setOpenEditDialog(false);
    setEditBundle(undefined);
    result && pagination && getBundles(pagination.page);
  };

  const onCloseAddDialog = (result?: boolean) => {
    setOpenAddDialog(false);
    setEditBundle(undefined);
    result && pagination && getBundles(pagination.page);
  };

  useEffect(() => {
    setQuery(queryParams as Api.GetBundlesQuery);
  }, [queryParams]);

  useEffect(() => {
    setSelectedBundles([]);
  }, [layoutType]);

  useEffect(() => {
    query && getBundles();
  }, [query, limit]);

  return (
    <BundlesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.bundles")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_BUNDLE}>
                <Button
                  hidden={!selectedBundles.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteBundles}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_BUNDLE}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateGasStation}
                >
                  {t("button.createNew")}
                </Button>
              </CanIUse>
              <ListLayoutSwitcher
                defaultValue={layoutType}
                onChange={setLayoutType}
              />
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <List type={layoutType} data={bundles} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <UpdateBundleDialog
        bundle={editBundle}
        open={openEditDialog}
        onClose={onCloseEditDialog}
      />
       <AddService
        bundle={editBundle}
        open={openAddDialog}
        onClose={onCloseAddDialog}
      />
    </BundlesContext.Provider>
  );
};

export default BundlesList;
