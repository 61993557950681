import React from "react";
import { Box, Grid, Typography, ButtonBase, Avatar } from "~/components/UI";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import GlobalSearch from "./GlobalSearch";
import AlertsIcon from "./AlertsIcon";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      height: "100%",
    },
    container: {
      paddingTop: 3,
      padding: 0,
      backgroundColor: theme.palette.background.default,
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    constent: {
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 185,
      height: 30,
      "@media (max-width:600px)": {
        display: "none",
      },
      "& a": {
        fontSize: 0,
      },
    },
    logoImg: {
      color: theme.palette.text.primary,
      maxWidth: 180,
      maxHeight: 30,
    },
    app: {
      flexGrow: 1,
      overflow: "auto",
    },
    appName: {
      marginTop: 2,
      fontSize: 12,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      "@media (max-width:600px)": {
        display: "none",
      },
    },
    userName: {
      "@media (max-width:600px)": {
        display: "none",
      },
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

const Header = ({ children }: Props) => {
  const classes = useStyles();
  const { user } = useSelector(({ settings, auth }: ReduxStore.State) => ({
    theme: settings.theme,
    user: auth.user,
  }));

  return (
    <div className={classes.wrapper}>
      <header className={classes.container}>
        <Box mb={-4}>
          <Grid
            className={classes.constent}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={8} xs={6} lg={6} container alignItems="center">
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <div className={classes.logo}>
                    <a href="/">
                      {user && user.companyLogo && (
                        <img
                          className={classes.logoImg}
                          src={user.companyLogo}
                          alt="logo"
                        />
                      )}
                    </a>
                  </div>
                  <span className={classes.appName}>Gateway Panel</span>
                </Grid>
              </Grid>
              <Grid item sm>
                <Box ml={2}>
                  <GlobalSearch />
                </Box>
              </Grid>
            </Grid>
            <Grid sm={4} xs={6} lg={6} item>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <CanIUse permissions={PERMISSION.READ_JOURNAL_INCIDENTS}>
                    <AlertsIcon />
                  </CanIUse>
                </Grid>
                <Grid item>
                  <ButtonBase onClick={() => history.push(ROUTES.MY_PROFILE)}>
                    <Box mx={2}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Avatar size="small" className="mr-10" />
                        </Grid>
                        <Grid item className={classes.userName}>
                          {user && (
                            <>
                              <Typography variant="body1" color="textPrimary">
                                {user.name}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </header>
      <div className={classes.app}>{children}</div>
    </div>
  );
};

export default Header;
