import { useContext } from "react";
import { Box } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import ServicesCategoryDetails from "./ServicesCategoryDetails";
import ServicesCategoriesContext from "./servicesCategoriesContext";
import ServicesCategoryOptions from "./ServicesCategoryOptions";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "categoryId",
    title: "ID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "name",
    sort: true,
    i18nKey: "name",
  },
  {
    key: "serviceMaxQueue",
    sort: true,
    i18nKey: "serviceMaxQueue",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Services
 * @component
 * @desc Render table of services.
 * @property {Api.ServiceDto[]} dataSource - Services data
 * @requires ServicesContext
 */

const ServicesTable = (
  props: Omit<TableProps<ServicesCategoriesApi.ServicesCategoryDto>, "columns">
) => {
  const { onSort } = useContext(ServicesCategoriesContext);
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            row: ServicesCategoriesApi.ServicesCategoryDto
          ) => <ServicesCategoryOptions servicesCategory={row} />,
        };
      default:
        return column;
    }
  });

  return (
    <Box height="100%" bgcolor="background.paper">
      <Table
        {...props}
        columns={columns}
        stickyHeader
        onRequestSort={onSort}
        rowKey="id"
        expandable={{
          highlightRow: true,
          expandedRowRender: (
            row: ServicesCategoriesApi.ServicesCategoryDto
          ) => <ServicesCategoryDetails servicesCategory={row} />,
        }}
      />
    </Box>
  );
};

export default ServicesTable;
