import { createContext } from 'react';

export interface ServicesCategoriesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (servicesCategory?: ServicesCategoriesApi.ServicesCategoryDto) => void;
  onDelete?: (serviceCategory: ServicesCategoriesApi.ServicesCategoryDto) => Promise<void>;
}

const ServicesCategoriesContext = createContext<ServicesCategoriesContextProps>({});

export default ServicesCategoriesContext;
