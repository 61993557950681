import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { withStyles, createStyles } from "@material-ui/core/styles";

const IButton = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.text.icon,
      fontSize: theme.typography.fontSize + 2,
      padding: theme.spacing(1),
    },
    colorInherit: {
      color: "inherit",
    },
    sizeSmall: {
      fontSize: theme.typography.fontSize - 4,
      padding: 5,
    },
  })
)(({ onClick, ...props }: IconButtonProps) => (
  <IconButton
    {...props}
    onClick={(e) => {
      const currentTarget = e.currentTarget;
      onClick &&
        setTimeout(() => onClick(Object.assign(e, { currentTarget })), 0);
    }}
  />
));

export default IButton;
