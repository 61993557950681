import ClientPaymentDurationCard from "~/components/Metrics/ClientPaymentDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const ClientPaymentDuration = ({ paymentId }: { paymentId: string }) => {
  const { t } = useTranslation();
  const getClientPaymentDurationMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } =
        await api.metrics.getClientPaymentDurationByPaymentService({
          paymentId,
          ...params,
        });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ClientPaymentDurationCard
      title={`${t("title.clientPaymentDurationBy")} ${t(
        "text.service"
      )}: ${paymentId}`}
      onUpdate={getClientPaymentDurationMetrics}
    />
  );
};

export default ClientPaymentDuration;
