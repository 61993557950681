import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Section, ImageList, ImageListItem } from "~/components/UI";
import { useHistory } from "react-router-dom";
import Routes from "~/constants/routes";
import PSPPaymentDurationTile from "./PSPPaymentDurationTile";
import AmountOfCollectedMoneyTile from "./AmountOfCollectedMoneyTile";
import AverageBillTile from "./AverageBillTile";
import BusinessTransactionCountsTile from "./BusinessTransactionCountsTile";
import ClientPaymentDurationTile from "./ClientPaymentDurationTile";
import ClientsTile from "./ClientsTile";
import FinancialTransactionCountsTile from "./FinancialTransactionCountsTile";
import { ENV } from "~/constants";
import { METRIC_ENTITY } from "~/api/dashboardSettings/enums";

const getMetricComponent = (
  setting: DashboardSettingsApi.Metric
): (() => JSX.Element | null) => {
  switch (setting.entity) {
    case METRIC_ENTITY.PSP_PAYMENT_DURATION:
      return PSPPaymentDurationTile;
    case METRIC_ENTITY.AMOUNT_OF_COLLECTED_MONEY:
      return AmountOfCollectedMoneyTile;
    case METRIC_ENTITY.AVERAGE_CHECK:
      return AverageBillTile;
    case METRIC_ENTITY.BUSINESS_TRANSACTION_COUNTS:
      return BusinessTransactionCountsTile;
    case METRIC_ENTITY.CLIENT_PAYMENT_DURATION:
      return ClientPaymentDurationTile;
    case METRIC_ENTITY.CLIENTS:
      return ClientsTile;
    case METRIC_ENTITY.FINANCIAL_TRANSACTION_COUNTS:
      return FinancialTransactionCountsTile;
    default:
      return () => null;
  }
};

interface MetricsProps {
  settings?: DashboardSettingsApi.Metric[];
}

const Metrics = ({ settings }: MetricsProps) => {
  const [components, setComponents] = useState<Array<() => JSX.Element | null>>(
    []
  );
  const { t } = useTranslation();
  const history = useHistory();

  const goToMetrics = useCallback(() => {
    history.push(Routes.METRICS);
  }, [history]);

  useEffect(() => {
    if (settings) {
      const components = settings
        .filter((s) => s.enabled)
        .slice(0, Number(ENV.REACT_APP_MAX_LIMIT_FOR_METRICS_ON_DASHBOARD))
        .map((s) => getMetricComponent(s));

      setComponents(components);
    }
  }, [settings]);

  if (!settings || !settings.length) return null;

  return (
    <Section
      title={t("title.metrics")}
      extra={
        <Button variant="text" size="small" onClick={goToMetrics}>
          {t("button.showAll")}
        </Button>
      }
    >
      <Box mt={4}>
        <ImageList cols={4} gap={20} rowHeight="auto">
          {components &&
            components.map((MetricComponent, index) => (
              <ImageListItem key={index} cols={1}>
                <MetricComponent />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
    </Section>
  );
};

export default Metrics;
