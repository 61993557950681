import Tiles, { TilesProps } from "~/components/UI/Tiles";
import { Box } from "~/components/UI";
import ServiceCard from "./ServiceCard";

interface ServicesTilesProps
  extends Omit<TilesProps<Api.ServiceDto>, "renderTaile"> {}

/**
 * @memberof Services
 * @component
 * @desc Render tiles of services.
 * @property {Api.ServiceDto[]} list - Services data
 */

const ServicesTiles = ({ ...other }: ServicesTilesProps) => (
  <Box pb={2}>
    <Tiles
      {...other}
      customHeight={true}
      renderTaile={(service, key) => (
        <ServiceCard key={key} service={service} />
      )}
    />
  </Box>
);

export default ServicesTiles;
