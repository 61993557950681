import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateKeyDialog from "./CreateKeyDialog";
import UpdateKeyDialog from "./UpdateKeyDialog";
import KeysContext from "./keysContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryParams, StringParam } from "use-query-params";
import usePermissions from "~/hook/usePermissions";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const KeyManagement = () => {
  const [editKey, setEditKey] = useState<KeyManagementApi.KeyDto>();
  const [createKeyDialog, setCreateKeyDialog] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [keys, setKeys] = useState<KeyManagementApi.KeyDto[]>();
  const [query, setQuery] = useState<KeyManagementApi.GetKeyQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    keyId: StringParam
  });
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_KEY_MANAGEMENT)
      ? {
          hideSelectAll: false,
          onChange: setSelectedKeys
        }
      : undefined
  );

  const getKeys = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.keyManagement.getAll(limit, page || currentPage, query);
        data && setKeys(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateKey = () => {
    setCreateKeyDialog(true);
  };

  const onCloseCreateDialog = (key?: KeyManagementApi.KeyDto) => {
    setCreateKeyDialog(false);
    key && setKeys((keys) => (keys && keys.length ? [...keys, key] : [key]));
  };

  const onCloseEditDialog = (key?: KeyManagementApi.KeyDto) => {
    setEditKey(undefined);
    key && onUpdateItem(key);
  };

  const onUpdateItem = useCallback((key: KeyManagementApi.KeyDto) => {
    setKeys((keys) => (key ? updateArrayItem(keys, key, "keyId") : []));
  }, []);

  const onEdit = useCallback((key: KeyManagementApi.KeyDto) => {
    setEditKey(key);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: KeyManagementApi.UpdateKey) => {
      try {
        const {
          data: { data: key }
        } = await api.keyManagement.updateKey(id, formData);
        onUpdateItem(key);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (key: KeyManagementApi.KeyDto) => {
      await deleteConfirm(key?.name);
      try {
        await api.keyManagement.deleteKey(key.keyId);
        getKeys();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getKeys, deleteConfirm]
  );

  const onDeleteKeys = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedKeys?.length }));
    try {
      await api.keyManagement.deleteKeys(selectedKeys);
      toast.success(t("text.recordSuccessfullyDeleted"));
      tableRef.current && tableRef.current.resetSelectedRows();
      setKeys((keys) =>
        keys ? keys.filter((k) => !selectedKeys.includes(k.keyId)) : keys
      );
      await getKeys();
    } catch (e) {
      console.error(e);
    }
  }, [selectedKeys]);

  const onChangePagination = (page: number) => {
    getKeys(page);
  };

  const onChangeFilters = (filters: KeyManagementApi.GetKeyQuery) => {
    pagination.current = undefined;
    setKeys([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(queryParams as KeyManagementApi.GetKeyQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getKeys();
  }, [query, getKeys, limit]);

  return (
    <KeysContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.keyManagment")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_KEY_MANAGEMENT}>
                <Button
                  hidden={!selectedKeys.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteKeys}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_KEY_MANAGMENT}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateKey}
                >
                  {t("button.addKey")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          dataSource={keys}
          onRequestSort={onSort}
          rowKey="keyId"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateKeyDialog open={createKeyDialog} onClose={onCloseCreateDialog} />
      <UpdateKeyDialog
        open={Boolean(editKey)}
        keyData={editKey}
        onClose={onCloseEditDialog}
      />
    </KeysContext.Provider>
  );
};

export default KeyManagement;
