import { darken, lighten, ThemeOptions } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: "ltr",
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  overrides: {},
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    type: "dark",
    primary: {
      main: "#47A04B",
      light: lighten("#47A04B", 0.12),
      dark: darken("#47A04B", 0.12),
      contrastText: "#fff",
    },
    secondary: {
      main: "#edf5ed",
      light: lighten("#edf5ed", 0.12),
      dark: darken("#edf5ed", 0.12),
      contrastText: "#47a04c",
    },
    error: {
      main: "#B51622",
      light: lighten("#B51622", 0.12),
      dark: darken("#B51622", 0.12),
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "#fff",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#47A04B",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    grey: {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#EAEBEF",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "#fff",
      secondary: "#717171",
      disabled: "#C4CCD3",
      hint: "#6B7780",
      icon: "#9E9E9E",
      button: "#a5a5a5",
    },
    divider: "#343434",
    background: {
      paper: "#1E1E1E",
      default: "#121212",
      level2: "#333",
      level1: "#121212",
      tag: "transparent",
      switch: "#121212",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.6)",
      hoverOpacity: 0.01,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(0, 0, 0, 0.7)",
      disabledBackground: "rgba(255, 255, 255, 1)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.8)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    controls: {
      switch: "#707070",
    },
    charts: {
      grid: "#717171",
      axis: "#717171",
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiTypography: {
      variantMapping: {
        h1: "h2",
        h2: "h2",
        h3: "h2",
        h4: "h2",
        h5: "h2",
        h6: "h2",
        subtitle1: "h2",
        subtitle2: "h2",
        body1: "p",
        body2: "span",
      },
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    htmlFontSize: 14,
    fontFamily: '"Plumb", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
      fontSize: "6rem",
      lineHeight: 1.167,
    },
    h2: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
      fontSize: "3.75rem",
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "21px",
    },
    h4: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "18px",
    },
    h5: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "18px",
    },
    h6: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: 200,
      fontSize: "15px",
      lineHeight: "21px",
    },
    subtitle1: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: 1.5,
    },
    subtitle2: {
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: 1.5,
    },
    body1: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.5,
    },
    button: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "capitalize",
    },
    caption: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    overline: {
      fontFamily: '"Plumb", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
    charts: {
      fontFamily: '"Plumb", sans-serif',
      fontSize: "12px",
    },
  },
  shape: {
    borderRadius: 0,
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1000,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  nprogress: {
    color: "#fff",
  },
} as ThemeOptions);

export default theme;
