import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Section,
  Typography,
  LinearProgress,
  Grid,
  Button,
  Icon,
  ButtonGroup,
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { getSortStr } from "~/utils/helpers";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

/**
 * @memberof SystemLog
 * @component
 * @desc SystemLog Controller.
 */

const SystemLogs = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<SystemLogJournalApi.GetSystemLogsQuery>({
    sort: "-timestamp",
  });
  const [data, setData] = useState<SystemLogJournalApi.SystemLogDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [limit, setLimit] = useState<number>(50);
  const { t } = useTranslation();
  const [history, setHistory] = useState<any[]>([]);

  const getSystemLogs = useCallback(
    async (nextDatetime?: string) => {
      setLoading(true);
      try {
        const {
          data: { data: nextData, meta },
        } = await api.systemLogJournal.getSystemLogs(limit, {
          nextDatetime,
          ...{
            ...query,
            sort: query?.sort || "-timestamp",
          },
        });

        setData(nextData);

        meta &&
          setHistory((history) => [...history, meta?.pagination?.nextDatetime]);
        meta && setPagination(meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [query, data, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setData([]);
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangeFilters = useCallback((filters) => {
    setPagination(undefined);
    setData([]);
    setHistory([]);
    setQuery(filters);
  }, []);

  useEffect(() => {
    query && getSystemLogs();
  }, [query, limit]);

  useEffect(() => {
    setHistory([]);
  }, [limit]);

  const onNext = () => {
    getSystemLogs(history[history.length - 1]);
  };

  const onPrev = () => {
    getSystemLogs(history[history.length - 3]);
    setHistory((history) => history.slice(0, -2));
  };

  return (
    <>
      <Box>
        <Section
          title={
            <Typography variant="h4">
              {t("title.systemLog")}{" "}
              <Typography variant="h4" component="span" color="textSecondary">
                {pagination && pagination.totalCount}
              </Typography>
            </Typography>
          }
          extra={<Filter onChangeFilter={onChangeFilters} />}
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto">
        <Table dataSource={data} onSort={onSort} />
      </Box>
      <Box mt={2}>
        <Grid container justifyContent="space-between">
          <RowsPerPage initialValue={limit} onChange={setLimit} />
          <div>
            <ButtonGroup>
              <Button
                iconContainer={true}
                variant="contained"
                onClick={onPrev}
                disabled={history.length <= 1}
              >
                <Icon name="ArrowLeft" />
              </Button>
              <Button iconContainer={true} variant="contained" onClick={onNext}>
                <Icon name="ArrowRight" />
              </Button>
            </ButtonGroup>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default SystemLogs;
