import { Typography, Box, Section } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import Clients from "./Clients";
import InvoiceDuration from "./InvoiceDuration";
import BusinessTransactionsCount from "./BusinessTransactionsCount";
import CollectedMoney from "./CollectedMoney";
import Bills from "./Bills";
import ClientPaymentDuration from "./ClientPaymentDuration";
import PSPPaymentDuration from "./PSPPaymentDuration";
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";
import CanIUse from "~/components/CanIUse";
import { PERMISSION } from "~/api/permissions/enums";

const Metrics = () => {
  const { t } = useTranslation();
  const { siteId } = useParams<{ siteId?: string }>();

  if (!siteId) return null;

  return (
    <Box pr={2}>
      <Section
        title={<Typography variant="h4">{t("title.metricsOfSite")}</Typography>}
      />
      <NoPermissionsForMetrics>
        <CanIUse permissions={PERMISSION.READ_MONEY_METRICS}>
          <MetricGroup title={t("title.money")}>
            <Bills siteId={siteId} />
            <CollectedMoney siteId={siteId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_CLIENTS_METRICS}>
          <MetricGroup title={t("title.clients")}>
            <Clients siteId={siteId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS}>
          <MetricGroup title={t("title.businessTransactions")}>
            <BusinessTransactionsCount siteId={siteId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS}>
          <MetricGroup title={t("title.clientPaymentDuration")}>
            <ClientPaymentDuration siteId={siteId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS}>
          <MetricGroup title={t("title.PSPPaymentDuration")}>
            <PSPPaymentDuration siteId={siteId} />
          </MetricGroup>
        </CanIUse>
        <CanIUse permissions={PERMISSION.READ_INVOICING_DURATION_METRICS}>
          <MetricGroup title={t("title.invoicingDuration")}>
            <InvoiceDuration siteId={siteId} />
          </MetricGroup>
        </CanIUse>
      </NoPermissionsForMetrics>
    </Box>
  );
};

export default Metrics;
