import { Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { REPORT_STATUS, REPORT_STATUS_I18n } from "~/api/zReportsJournal/enum";

interface ReportStatusProps {
  status: ZReportsJournalApi.eReportStatus;
}

/**
 * @memberof AuditZReportLog
 * @component
 * @desc Render status of ZReport.
 * @property {ZReportsJournalApi.eReportStatus} status - ZReport status;
 */

const ReportStatus = ({ status }: ReportStatusProps) => {
  const { t } = useTranslation(["enums"]);
  let color: "textPrimary" | "primary" | "error" = "textPrimary";

  switch (status) {
    case REPORT_STATUS.EQUAL:
      color = "primary";
      break;
    case REPORT_STATUS.NOT_MATCH:
      color = "error";
      break;
    case REPORT_STATUS.NOT_FOUND:
      color = "textPrimary";
      break;
    default:
      color = "textPrimary";
  }

  return (
    <Typography variant="body1" color={color}>
      {t(REPORT_STATUS_I18n[status])}
    </Typography>
  );
};

export default ReportStatus;
