import PSPPaymentDurationCard from "~/components/Metrics/PSPPaymentDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const PSPPaymentDuration = ({ paymentId }: { paymentId: string }) => {
  const { t } = useTranslation();
  const getPSPPaymentDurationMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } = await api.metrics.getPSPPaymentDurationByPaymentService({
        paymentId,
        ...params,
      });
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PSPPaymentDurationCard
      title={`${t("title.pspPaymentDurationBy")} ${t(
        "text.service"
      )}: ${paymentId}`}
      onUpdate={getPSPPaymentDurationMetrics}
    />
  );
};

export default PSPPaymentDuration;
