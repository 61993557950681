import { useState, useEffect, useCallback } from "react";
import { useHistory, generatePath } from "react-router";
import {
  Grid,
  Box,
  Divider,
  LinearProgress,
  Button,
  ButtonGroup,
  Tabs,
  Icon,
} from "~/components/UI";
import AllIncidentsButton from "~/components/Incidents/AllIncidentsButton";
import DetailedInfoAboutService from "./DetailsOfServices";
import DetailedInfoAboutPOS from "./DetailsOfPOSes";
import DetailsOfPaymentMethods from "./DetailsOfPaymentMethods";
import MoreInformation from "./MoreInformation";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import ROUTES from "~/constants/routes";
import api from "~/api";
import GoToRelatedProcessesButton from "~/components/GoToRelatedProcessesButton";
import { SOURCE_TYPE } from "~/api/common";

interface SiteDetailsProps {
  size?: "default" | "small";
  site: Api.SiteDto;
}

const getStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 285,
    },
    growBox: {
      overflow: "auto",
    },
    tabsContainer: {
      height: "calc(100% - 35px)",
    },
  })
);

/**
 * @memberof Sites
 * @component
 * @desc Render detailed information about a site.
 * @property {Api.SiteDto} site - Site data;
 * @property {"default" | "small"} size - container size;
 */

const SiteDetails = ({ site, size }: SiteDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [siteDetails, setSiteDetails] = useState<Api.SiteDetailsDto>();
  const classes = getStyles();
  const { hasAll } = usePermissions();
  const { t } = useTranslation();
  const history = useHistory();

  async function getSite(id: string) {
    setLoading(true);
    try {
      const { data } = await api.sites.getSite(id);
      setSiteDetails(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const goToMetrics = useCallback(() => {
    history.push(generatePath(ROUTES.SITE_METRICS, { siteId: site.siteId }), {
      site,
    });
  }, [history, site]);

  useEffect(() => {
    getSite(site.siteId);
  }, [site.id]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (siteDetails) {
    return (
      <Grid
        container
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
        classes={{
          container: classes.container,
        }}
      >
        <Box flexGrow={1} className={classes.growBox}>
          <Tabs
            className={classes.tabsContainer}
            variant="scrollable"
            scrollButtons="auto"
            tabs={[
              {
                label: `${t("title.poses")} (${siteDetails.posCount || 0})`,
                hidden: !hasAll(PERMISSION.READ_POS),
                content: (
                  <DetailedInfoAboutPOS siteId={site.siteId} enabled={site.enabled} size={size} />
                ),
              },
              {
                label: `${t("title.services")} (${
                  siteDetails.serviceCount || 0
                })`,
                hidden: !hasAll(PERMISSION.READ_SERVICE),
                content: (
                  <DetailedInfoAboutService siteId={site.siteId} enabled={site.enabled} size={size} />
                ),
              },
              {
                label: `${t("title.paymentServices")}  (${
                  siteDetails.paymentServiceCount || 0
                })`,
                hidden: !hasAll(PERMISSION.READ_PAYMENT_SERVICE),
                content: (
                  <DetailsOfPaymentMethods siteId={site.siteId} size={size} />
                ),
              },
              {
                label: t("title.moreInformation"),
                content: <MoreInformation site={site} />,
              },
            ]}
          />
        </Box>
        <Box pt={3} px={3}>
          <Divider />
          <Box py={3}>
            <Grid
              container
              spacing={2}
              justifyContent={size === "small" ? "flex-start" : "space-between"}
            >
              <Grid item>
                <ButtonGroup>
                  <AllIncidentsButton variant="outlined" />
                  <GoToRelatedProcessesButton
                    sourceId={site.siteId}
                    sourceType={SOURCE_TYPE.SITE}
                    variant="outlined"
                  />
                  <Button variant="outlined" onClick={goToMetrics}>
                    {t("button.metrics")}
                  </Button>
                </ButtonGroup>
              </Grid>
              {/* <Grid item>
                {size === "small" ? (
                  <Button
                    variant="outlined"
                    iconContainer={true}
                    onClick={goToBundles}
                  >
                    <Icon name="Shuffle" />
                  </Button>
                ) : (
                  <Button
                    prefixIcon={<Icon name="Shuffle" />}
                    variant="outlined"
                    onClick={goToBundles}
                  >
                    {t("button.crossSellBundles")}
                  </Button>
                )}
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Grid>
    );
  } else {
    return null;
  }
};

export default SiteDetails;
