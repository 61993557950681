import { Card, styled } from "~/components/UI";

const CommonCard = styled(Card)(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    minHeight: 195,
    cursor: "pointer",
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  })
);

export default CommonCard;
