export const ENTITIES = {
    CREATE_SITE: 'create-site',
    READ_SITE : 'read-site',
    EDIT_SITE : 'edit-site',
    DELETE_SITE : 'delete-site',

    CREATE_POS : 'create-pos',
    READ_POS : 'read-pos',
    EDIT_POS :'edit-pos',
    DELETE_POS : 'delete-pos',

    CREATE_PAYMENT_SERVICE : 'create-payment-service',
    READ_PAYMENT_SERVICE : 'read-payment-service',
    EDIT_PAYMENT_SERVICE : 'edit-payment-service',
    DELETE_PAYMENT_SERVICE : 'delete-payment-service',

    CREATE_SERVICE : 'create-service',
    READ_SERVICE : 'read-service',
    EDIT_SERVICE : 'edit-service',
    DELETE_SERVICE : 'delete-service',

    CREATE_KEY_MANAGEMENT: 'create-key-management',
    READ_KEY_MANAGEMENT: 'read-key-management',
    EDIT_KEY_MANAGEMENT: 'edit-key-management',
    DELETE_KEY_MANAGEMENT: 'delete-key-management',

    CREATE_ROLE : 'create-role',
    READ_ROLE : 'read-role',
    EDIT_ROLE : 'edit-role',
    DELETE_ROLE : 'delete-role',

    CREATE_USER : 'create-user',
    READ_USER : 'read-user',
    EDIT_USER : 'edit-user',
    DELETE_USER : 'delete-user',

    CREATE_GROUP : 'create-group',
    READ_GROUP : 'read-group',
    EDIT_GROUP : 'edit-group',
    DELETE_GROUP : 'delete-group',

    READ_JOURNAL_BUSINESS_OPERATIONS : 'read-journal-business-operations',

    READ_EVENT_LOG : 'read-event-log',

    CREATE_JOURNAL_INCIDENTS : 'create-journal-incidents',
    READ_JOURNAL_INCIDENTS : 'read-journal-incidents',
    EDIT_JOURNAL_INCIDENTS : 'edit-journal-incidents',

    READ_JOURNAL_LOG_SYSTEM : 'read-journal-log-system',

    READ_JOURNAL_OF_USERS_ACTIONS : 'read-journal-of-users-actions',

    READ_MONEY_METRICS : 'read-money-metrics',

    READ_CLIENTS_METRICS : 'read-clients-metrics',

    READ_BUSINESS_TRANSACTIONS_METRICS : 'read-business-transactions-metrics',

    READ_FINANCIAL_TRANSACTIONS_METRICS: 'read-financial-transactions-metrics',

    READ_CLIENT_PAYMENT_DURATION_METRICS : 'read-client-payment-duration-metrics',

    READ_PSP_PAYMENT_DURATION_METRICS : 'read-psp-payment-duration-metrics',

    READ_QUEUES_METRICS : 'read-queues-metrics',

    READ_INVOICING_DURATION_METRICS : 'read-invoicing-duration-metrics',

    CREATE_SETTINGS : 'create-settings',
    READ_SETTINGS : 'read-settings',
    EDIT_SETTINGS : 'edit-settings',
    DELETE_SETTINGS : 'delete-settings',

    CREATE_ID_PREFIX: 'create-id-prefix',
    READ_ID_PREFIX: 'read-id-prefix',
    EDIT_ID_PREFIX: 'edit-id-prefix',
    DELETE_ID_PREFIX: 'delete-id-prefix',

    REFUND_OF_PAYMENT: "refund-of-payment",

    READ_Z_REPORT: 'read-z-report',

    CREATE_SYSTEM_SETTINGS: 'create-system-settings',
    READ_SYSTEM_SETTINGS: 'read-system-settings',
    EDIT_SYSTEM_SETTINGS: 'edit-system-settings',
    DELETE_SYSTEM_SETTINGS: 'delete-system-settings',

    CREATE_BUNDLE: 'create-bundle',
    READ_BUNDLE: 'read-bundle',
    EDIT_BUNDLE: 'edit-bundle',
    DELETE_BUNDLE: 'delete-bundle'
} as { [key: string]: PermissionsApi.eBasePermissions }

export const PROCESSES = {
    REFUND_OF_PAYMENT: 'refund-of-payment',
    BUSINESS_OPERATIONS_INCIDENTS: 'business-operations-incidents'
} as { [key: string]: PermissionsApi.eBasePermissions }

export const PERMISSION = Object.assign({},PROCESSES, ENTITIES) as { [key: string]: PermissionsApi.eBasePermissions }

export const METRICS = [
    PERMISSION.READ_MONEY_METRICS,
    PERMISSION.READ_CLIENTS_METRICS,
    PERMISSION.READ_BUSINESS_TRANSACTIONS_METRICS,
    PERMISSION.READ_FINANCIAL_TRANSACTIONS_METRICS,
    PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS,
    PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS,
    PERMISSION.READ_QUEUES_METRICS,
    PERMISSION.READ_INVOICING_DURATION_METRICS,
]

export const PERMISSION_TYPE = {
    CREATE: 'create',
    READ: 'read',
    EDIT: 'edit',
    DELETE: 'delete',
} as { [key: string]: PermissionsApi.ePermissionType }
