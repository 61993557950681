import { useState, useEffect } from "react";
import { Grid, LinearProgress } from "~/components/UI";
import TabContent from "./TabContent";
import api from "~/api";
import Receipt from "~/components/UI/Receipt/Receipt";
import ReactJson from 'react-json-view';

interface ReceiptTabProps {
  invoiceId: string;
}

/**
 * @memberof BusinessOperations
 * @component
 * @desc Render information about Receipt.
 * @property {string} invoiceId invoice id
 */

const ReceiptTab = ({ invoiceId }: ReceiptTabProps) => {
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState<any>();

  async function getReceipt(invoiceId: string) {
    setLoading(true);
    try {
      const { data } = await api.businessOperationsJournal.getReceipt(
        invoiceId
      );
      setReceiptData(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getReceipt(invoiceId);
  }, [invoiceId]);

  if (loading) {
    return <LinearProgress />;
  } else if (receiptData) {
    return (
      <TabContent>
        <Grid container justifyContent="space-between">
          <Grid item style={{ flexGrow: "1" }}>
            <ReactJson src={receiptData} />
          </Grid>

          {receiptData &&
            receiptData.fiscal &&
            receiptData.fiscal.map((fiscal: any) => (
              <Grid item style={{ maxWidth: "300px" }}>
                <Receipt checkData={fiscal} />
              </Grid>
            ))}
        </Grid>
      </TabContent>
    );
  } else {
    return null;
  }
};

export default ReceiptTab;
